import { Stack,Form, Button, ListGroup, Dropdown,Row,Modal,Badge,InputGroup,Popover,OverlayTrigger, Image } from "react-bootstrap";
import {useState,useEffect} from 'react'
import Avatar from 'react-avatar'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'


function TagSettings({settings,members,communitySlug}) {
    const [showEdit, setShowEdit] = useState({tag:{},show:false,newName:''});
    const [settingsSet, setSettingsSet] = useState(false);
    const [searchMembers, setSearchMembers] = useState({});
    const [newMembers, setNewMembers] = useState([]);
    const [editMembers, setEditMembers] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [showDelete, setShowDelete] = useState({id:0,show:false});
    const [tagDetails,setTagDetails] = useState(null);
    const currentId = useSelector(
        (state) => state.communityReducer.communityId
    )
    useEffect(() => {
            if(Object.entries(settings).length > 0) {
                setSettingsSet((prev)=>true)
                setSearchMembers(members)
                setTagDetails(settings)
            }
                
        }, [settings])

    const removeMember = (member) => {
        if(showEdit.show==false){
            let removedNewMembers = newMembers.filter(user => user.id !== member.id);
            setNewMembers(removedNewMembers)
        }else{
            let removedEditMembers = editMembers.filter(user => user.id !== member.id);
            setEditMembers(removedEditMembers)
        }
        
    }

    const searchMember = (memberName) => {
        
        if(memberName == ''){
            setSearchMembers(members) 
           
        }else{
          let newSearchMembers = members.filter(user => {
            if(user.name.toLowerCase().includes(memberName.toLowerCase())){
                return user
            }
            });
            
            setSearchMembers(newSearchMembers)  
           
        }
        
    }

    const getTagDetails = ()=>{
        axios.get("/v2/get_community_tags?commSlug=" + communitySlug).then((response)=>{
            setTagDetails(response.data)
            
        }).catch((error)=>{
            console.log(error)
        })
    }


    const editMemberSetup = (tagId) => {
        let newEditTags = tagDetails.filter(tag => tag.id == tagId)
        let newEditMembers = []
        newEditTags[0].members.forEach(member => {
            var result = members.find((getMember) => {
                return getMember.id == member.memberId;
            });
            newEditMembers.push(result)
        });
        setEditMembers(newEditMembers)
    }

    const addMemberSetup = (member) => {
        if(showEdit.show==false){
            if(!newMembers.includes(member)){
                setNewMembers([...newMembers,member])
            }
            
        }else{
            if(!editMembers.includes(member)){
                setEditMembers([...editMembers,member])
            }
           
        }
        document.body.click()
        
    }
    const addTag = () => {

        if(newTag!=''){
            axios.post('/v2/add_tag',{id: currentId,tagName: newTag,membersTagged:newMembers})
            .then((res) => {
                toast.success('Community Tag Added')
                setNewMembers([])
                getTagDetails()
                document.getElementById('tag').value='';
                setNewTag('')
            }).catch(function (error) {
                toast.error('Community Tag Not Added')
            });
        }else{
            toast.error('Tag Name cannot be empty')
        }
        
        
    }

    const deleteTag = () => {
        axios.post('/v2/delete_communitytag',{tagId:showDelete.id})
            .then((res) => {
                toast.success('Community Tag Deleted')
                setShowDelete(false)
                getTagDetails()
            
            }).catch(function (error) {
                toast.error('Community Tag Not Deleted')
            });
    }

    const editTag = () => {
        let newName = ''
        if (showEdit.newName == undefined){
            newName = showEdit.tag.tag
        }else{
            newName = showEdit.newName
        }
        axios.post('/v2/edit_tag',{id: showEdit.tag.id,tagName: newName,membersTagged:editMembers})
            .then((res) => {
                toast.success('Community Tag Updated')
                setShowEdit({...showEdit,show:false})
                getTagDetails()
            }).catch(function (error) {
                toast.error('Community Tag Not Updated')
            });
    }
    const popover = (
        <Popover id="popover-basic">
          <Popover.Body style={{minWidth:'15rem'}} className="p-0">
            <ListGroup style={{height:'100px',overflow:'auto'}}>
                {searchMembers.length>0 && searchMembers.map((member) =>
                <ListGroup.Item onClick={()=>addMemberSetup(member)} key={member.id}>
                    {(member.profilePic != "")?<Image roundedCircle className="member-image me-3" src={member.profilePic} loading="lazy"/>:<Avatar className="me-2" name={member.name} size="30" textSizeRatio={2.5} round={true}/>}{member.name}
                </ListGroup.Item>
                )}
            </ListGroup>
          </Popover.Body>
        </Popover>
    )
    return (
        <>
            <Stack gap={4}>
                <Form.Group controlId="tag">
                    <Form.Label>Add New Tag</Form.Label>
                    <Form.Control onChange={(event)=>setNewTag(event.target.value)} className="settings-input-group"/>
                </Form.Group>

                <Form.Group controlId="member">
                    <Form.Label>Add Members</Form.Label>
                    <InputGroup className="settings-input-group">
                        <InputGroup.Text ><i className="fa-solid fa-magnifying-glass"></i></InputGroup.Text>
                        <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
                            <Form.Control placeholder="Add new member" onChange={(event)=>searchMember(event.target.value)}/>
                        </OverlayTrigger>
                    </InputGroup>
                    
                </Form.Group>
                <Stack className="flex-wrap" direction="horizontal" gap={3} >
                    {newMembers.map((member) => 
                        <Badge className="p-2" key={`badge-id`+member.id}>{member.name}<i className=" ms-2 fa-sm fa-solid fa-x" onClick={()=>removeMember(member)}></i></Badge>
                    )}
                </Stack>

                <Button className="uuki-btn uuki-community-btn align-self-start" onClick={addTag}>Add Tag</Button>

                <ListGroup>
                    <Form.Label>Current Tags</Form.Label>
                    <Stack gap={3}>
                        {settingsSet && tagDetails.map((tag) =>
                        <ListGroup.Item className="border rounded settings-input-group" key={tag.id}>
                            <Stack direction="horizontal">
                                <span>{tag.tag}</span>
                                <Dropdown className="ms-auto">
                                    <Dropdown.Toggle className="bg-transparent border-0 text-dark" >
                                        <i className="fa-solid fa-ellipsis"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={()=>{setShowEdit({tag:tag,show:true});editMemberSetup(tag.id)}}>Edit Tag</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={()=>setShowDelete({id:tag.id,show:true})}>Delete Tag</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Stack>
                        </ListGroup.Item>
                        )}
                    </Stack>
                </ListGroup>
            
                
            </Stack>

            <Modal show={showDelete.show} onHide={() => setShowDelete(false)} centered>
                <Modal.Header closeVariant="white" closeButton>
                    <Modal.Title>Are you Sure?</Modal.Title>
                </Modal.Header>
                
                <Modal.Footer className="border-0 justify-content-center">
                    <Button className="uuki-delete-btn" onClick={deleteTag}>Delete Tag</Button>
                </Modal.Footer>
            </Modal>
            
            <Modal show={showEdit.show} onHide={() => setShowEdit({...showEdit,show:false})} centered>
                <Modal.Header closeVariant="white" closeButton>
                    <Modal.Title>Edit Tag</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack gap={4}>
                        <Form.Group controlId="lvl">
                            <Form.Label>Enter New Tag Name</Form.Label>
                            <Form.Control autoFocus defaultValue={showEdit.tag.tag} onChange={(event) => setShowEdit({...showEdit,newName:event.target.value})}/>
                        </Form.Group>

                        <div>
                            <Form.Label>Add/Remove Tagged Members</Form.Label>
                            <Form.Group controlId="title">
                                <InputGroup className="mb-3 settings-input-group">
                                    <InputGroup.Text ><i className="fa-solid fa-magnifying-glass"></i></InputGroup.Text>
                                    <OverlayTrigger trigger='click' placement="bottom" overlay={popover} rootClose>
                                        <Form.Control placeholder="Add new member" onChange={(event)=>searchMember(event.target.value)}/>
                                    </OverlayTrigger>
                                </InputGroup>
                            </Form.Group>
                            <Stack className="flex-wrap" direction="horizontal" gap={3}>
                                {editMembers.map((member) =>
                                    <Badge className="p-2" key={`badge-id`+member.id}>{member.name}<i className=" ms-2 fa-sm fa-solid fa-x" onClick={()=>removeMember(member)}></i></Badge>
                                )}   
                            </Stack>
                        </div>
                    </Stack>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-center">
               <Button className="uuki-btn uuki-community-btn" onClick={editTag}>Save</Button>
                </Modal.Footer>
            </Modal> 
        </>
    )
}

export default TagSettings
