import {Form,Stack,Spinner} from 'react-bootstrap'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useState,useEffect} from 'react'
import {useSelector} from 'react-redux'

function NotificationSettings({settings}) {
    const [settingsSet, setSettingsSet] = useState(false);
    const currentId = useSelector(
        (state) => state.communityReducer.communityId
    )

    useEffect(() => {
            (Object.entries(settings).length > 0) && setSettingsSet((prev)=>true)
        }, [settings])

    const changeNotifications = (type,value) => {
        axios.post('/change_notifications',{id: currentId,type:type,value: value})
            .then((res) => {
                toast.success('Notifications Updated')
            
            }).catch(function (error) {
                toast.error('Update Failed')
            });
    }
    return (
        <>
            { settingsSet ?
            <Stack gap={4}>
                <h4 >Email Notifications</h4>
                <Form.Switch className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 " label="Comments on Posts" defaultChecked={(settings.comment==true)?true:false} onChange={(event) => changeNotifications(1,event.target.checked)}/>
                <Form.Switch className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 " label="Replies on Comments" defaultChecked={(settings.reply==true)?true:false} onChange={(event) => changeNotifications(2,event.target.checked)}/>
                <Form.Switch className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 " label="Mentions in Comments" defaultChecked={(settings.mention==true)?true:false} onChange={(event) => changeNotifications(3,event.target.checked)}/>
                <Form.Switch className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 " label="Likes on Posts" defaultChecked={(settings.like==true)?true:false} onChange={(event) => changeNotifications(4,event.target.checked)}/>
                <Form.Switch className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 " label="Likes on Comments" defaultChecked={(settings.comment_like==true)?true:false} onChange={(event) => changeNotifications(5,event.target.checked)}/>
                {/* <Form.Switch className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 " label="Direct Messages" />
                <Form.Switch className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 " label="Mentions in Community Chat" /> */}
            </Stack>
            :
            <div className="d-flex justify-content-center">
                {/* <Spinner animation="border" /> */}
                <span class="loader-spinner"/>
            </div>
            }



        </>
    )
}

export default NotificationSettings
