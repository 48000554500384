import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
	Button,
	Stack,
	Form,
	Image,
	InputGroup,
	Accordion,
} from 'react-bootstrap';
import placeholderImage from '../../../assets/add-image.png';
import { useNavigate } from 'react-router-dom';

function BasicOnboarding({ completeStep, completed,setOnboardKey }) {
	const [image, setImage] = useState('');
	const [name, setName] = useState('');
	const [bio, setBio] = useState('');
	const [location, setLocation] = useState('');
	const [tags, setTags] = useState([]);
	const [deletedTags, setDeletedTags] = useState([]);
	const [links, setLinks] = useState([]);
	const [deletedLinks, setDeletedLinks] = useState([]);
	const [commColor, setCommColor] = useState('#21cd9c');
	const [theme, setTheme] = useState('light');
	const [brandLogo, setBrandLogo] = useState('');
	const [privacy, setPrivacy] = useState(false);
	const browserLocation = useLocation();
	let [searchParams, setSearchParams] = useSearchParams();

	const navigate = useNavigate();
	const currentSlug = useSelector(
		(state) => state.communityReducer.communitySlug
	);
	const currentId = useSelector((state) => state.communityReducer.communityId);

	const removeTag = (id) => {
		let removedNewTags = tags.filter((tag) => tag.id !== id);
		setDeletedTags([...deletedTags, id]);
		setTags(removedNewTags);
	};
	const removeLink = (id) => {
		let removedNewLinks = links.filter((link) => link.id !== id);
		setDeletedLinks([...deletedLinks, id]);
		setLinks(removedNewLinks);
	};
	const addLink = (linkFor) => {
		setLinks([...links, { id: linkFor + 'New', link: '', linkFor: linkFor }]);
	};
	const changeLink = (id, link) => {
		let linkIndex = links.findIndex((link) => link.id == id);
		links[linkIndex].link = link;
	};
	const uploadImage = (type, file) => {
		let getUrl = '';
		let getId = 0;
		if (type == 0) {
			getUrl = '/get_member_image_url';
			getId = searchParams.get('memberId');
		} else if (type == 1) {
			getUrl = '/get_community_brand_logo_url';
			getId = currentId;
		}
		axios
			.get(getUrl, {
				params: { id: getId, filename: file.name },
			})
			.then((res) => {
				res = res.data;
				let url = res.url;
				let fields = res.fields;
				let formData = new FormData();
				for (let key in fields) {
					formData.append(key, fields[key]);
				}
				formData.append('file', file);
				const xhr = new XMLHttpRequest();
				xhr.addEventListener('loadend', uploadComplete, false);
				function uploadComplete(event) {
					let result = 'https://communitydata.uuki.live/' + fields['key'];
					if (type == 0) {
						updateMemberImage(result);
					} else if (type == 1) {
						changeBrandLogo(result);
					}
				}
				xhr.open('POST', url, true);
				xhr.send(formData);
				toast.success('Image Uploaded');
			})
			.catch(function (error) {
				toast.error('Image Upload Failed');
			});
	};
	const updateMemberImage = (imageUrl) => {
		axios
			.post('/add_member_image', {
				id: searchParams.get('memberId'),
				url: imageUrl,
			})
			.then((res) => {
				setImage(imageUrl);
				toast.success('Profile Image Updated');
			})
			.catch(function (error) {
				toast.error('Update Failed');
			});
	};
	const changeBrandLogo = (value) => {
		axios
			.post('/add_community_brand_logo', { id: currentId, url: value })
			.then((res) => {
				setBrandLogo(value);
				toast.success('Community Brand Logo Updated');
			})
			.catch(function (error) {
				toast.error('Update Failed');
			});
	};
	const saveEdit = () => {
		let memberId;
		memberId = searchParams.get('memberId');	
		
		axios
		.post('/v2/editMember', {
			memberId: memberId,
			name: name,
			bio: bio,
			location: location,
			tags: tags,
			links: links,
			deletedTags: deletedTags,
			deletedLinks: deletedLinks,
		})
		.then((res) => {
			// toast.success('Profile Edited');
			completeStep(0);
		})
		.catch(function (error) {
			toast.error('Profile Edit Failed');
		});
	};

	const saveBrandEdit = () => {
		axios
			.post('/v2/editBrand', {
				commId: currentId,
				color: commColor,
				theme: theme,
				logo: brandLogo,
			})
			.then((res) => {
				// toast.success('Branding Updated');
				completeStep(1);
			})
			.catch(function (error) {
				toast.error('Brand Update Failed');
			});
	};
	const changePrivacy = () => {
		axios
			.post('/change_allow_public', { id: currentId, value: privacy })
			.then((res) => {
				// toast.success('Community Privacy Updated');
				completeStep(2);
			})
			.catch(function (error) {
				toast.error('Update Failed');
			});
	};

	return (
		<Stack gap={3}>
			<Stack direction="horizontal">
				<h5>Basic</h5>
				<a className='ms-auto text-decoration-none' onClick={()=>{completeStep([0,1,2]);setOnboardKey('settings')}}>Skip Section</a>
				<Button className="ms-3 uuki-btn uuki-community-btn" onClick={()=>setOnboardKey('settings')}>Next</Button>
			</Stack>
			<span>Get Started with the Basics</span>
			<Accordion flush alwaysOpen>
				<Accordion.Item >
					<Accordion.Header>
						<Form.Check
							className='onboard-check'
							inline
							name='basic'
							type='checkbox'
							disabled
							checked={completed.includes(0) ? true : false}
						/>
						Set up your profile
					</Accordion.Header>
					<Accordion.Body className=' ps-5 mb-3'>
						<Stack gap={3}>
							<span>
								Fill out your profile details for better reach in your community
							</span>
							<Stack gap={3} direction='horizontal'>
								<Image
									roundedCircle
									className='uuki-logo'
									src={image}
									loading='lazy'
									onError={({ currentTarget }) => {
										currentTarget.onerror = null;
										currentTarget.src = placeholderImage;
									}}
								/>
								<label for='file-upload-member' class='custom-file-upload'>
									<div className='uuki-btn uuki-community-btn'>
										Change Image
									</div>
								</label>
								<input
									id='file-upload-member'
									className='d-none'
									type='file'
									onChange={(e) => uploadImage(0, e.target.files[0])}
								/>
							</Stack>
							<Form.Group controlId='memberName'>
								<Form.Label>Enter Name</Form.Label>
								<Form.Control
									defaultValue={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Form.Group>
							<Form.Group controlId='memberBio'>
								<Form.Label>Enter Bio</Form.Label>
								<Form.Control
									defaultValue={bio}
									onChange={(e) => setBio(e.target.value)}
								/>
							</Form.Group>
							<Form.Group controlId='memberLocation'>
								<Form.Label>Enter Location</Form.Label>
								<Form.Control
									defaultValue={location}
									onChange={(e) => setLocation(e.target.value)}
								/>
							</Form.Group>

							<Form.Group controlId='memberLinks'>
								<Form.Label>Add Links</Form.Label>
								{links.length > 0 &&
									links.map((link) => (
										<>
											{link.linkFor == 'fb' ? (
												<InputGroup className='mb-3'>
													<InputGroup.Text>
														<i className='px-1 fa-2x fab fa-facebook-square'></i>
													</InputGroup.Text>
													<Form.Control
														defaultValue={link.link}
														onChange={(e) =>
															changeLink(link.id, e.target.value)
														}
													/>
													<i
														className='fa-solid ms-1  fa-x'
														onClick={() => removeLink(link.id)}></i>
												</InputGroup>
											) : link.linkFor == 'insta' ? (
												<InputGroup className='mb-3'>
													<InputGroup.Text>
														<i className='px-1 fa-2x fab fa-instagram-square'></i>
													</InputGroup.Text>
													<Form.Control
														defaultValue={link.link}
														onChange={(e) =>
															changeLink(link.id, e.target.value)
														}
													/>
													<i
														className='fa-solid ms-1  fa-x'
														onClick={() => removeLink(link.id)}></i>
												</InputGroup>
											) : link.linkFor == 'twit' ? (
												<InputGroup className='mb-3'>
													<InputGroup.Text>
														<i className='px-1 fa-2x fab fa-twitter-square'></i>
													</InputGroup.Text>
													<Form.Control
														defaultValue={link.link}
														onChange={(e) =>
															changeLink(link.id, e.target.value)
														}
													/>
													<i
														className='fa-solid ms-1  fa-x'
														onClick={() => removeLink(link.id)}></i>
												</InputGroup>
											) : link.linkFor == 'li' ? (
												<InputGroup className='mb-3'>
													<InputGroup.Text>
														<i className='px-1 fa-2x fab fa-linkedin'></i>
													</InputGroup.Text>
													<Form.Control
														defaultValue={link.link}
														onChange={(e) =>
															changeLink(link.id, e.target.value)
														}
													/>
													<i
														className='fa-solid ms-1  fa-x'
														onClick={() => removeLink(link.id)}></i>
												</InputGroup>
											) : (
												link.linkFor == 'web' && (
													<InputGroup className='mb-3'>
														<InputGroup.Text>
															<i className='px-1 fa-2x fas fa-globe'></i>
														</InputGroup.Text>
														<Form.Control
															defaultValue={link.link}
															onChange={(e) =>
																changeLink(link.id, e.target.value)
															}
														/>
														<i
															className='fa-solid ms-1  fa-x'
															onClick={() => removeLink(link.id)}></i>
													</InputGroup>
												)
											)}
										</>
									))}
								<Stack gap={2} direction='horizontal'>
									<Button
										className='uuki-community-btn border-0 align-self-start'
										onClick={() => addLink('fb')}>
										<i className='px-1 fa-xl fab fa-facebook-square'></i>
										<i className='fa-solid fa-plus'></i>
									</Button>
									<Button
										className='uuki-community-btn border-0 align-self-start'
										onClick={() => addLink('insta')}>
										<i className='px-1 fa-xl fab fa-instagram-square'></i>
										<i className='fa-solid fa-plus'></i>
									</Button>
									<Button
										className='uuki-community-btn border-0 align-self-start'
										onClick={() => addLink('twit')}>
										<i className='px-1 fa-xl fab fa-twitter-square'></i>
										<i className='fa-solid fa-plus'></i>
									</Button>
									<Button
										className='uuki-community-btn border-0 align-self-start'
										onClick={() => addLink('li')}>
										<i className='px-1 fa-xl fab fa-linkedin'></i>
										<i className='fa-solid fa-plus'></i>
									</Button>
									<Button
										className='uuki-community-btn border-0 align-self-start'
										onClick={() => addLink('web')}>
										<i className='px-1 fa-xl fas fa-globe'></i>
										<i className='fa-solid fa-plus'></i>
									</Button>
								</Stack>
							</Form.Group>
							<Button
								className='uuki-community-btn uuki-btn align-self-start'
								onClick={saveEdit}>
								Save and Continue
							</Button>
						</Stack>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item >
					<Accordion.Header>
						<Form.Check
							className='onboard-check'
							inline
							name='basic'
							type='checkbox'
							disabled
							checked={completed.includes(1) ? true : false}
						/>
						Update your branding
					</Accordion.Header>
					<Accordion.Body className=' ps-5 mb-3'>
						<Stack gap={3}>
							<span>
								Make your community unique and true to your brand with many
								customisation options available in settings, let's get started
								with the basics
							</span>
							<Form.Group controlId='commColor'>
								<Form.Label>Community Color</Form.Label>
								<Stack gap={3} direction='horizontal'>
									<input defaultValue={commColor} type='color' />
									<Button
										className='uuki-btn uuki-community-btn'
										onClick={(event) =>
											setCommColor(event.target.previousSibling.value)
										}>
										Update
									</Button>
								</Stack>
							</Form.Group>
							<Form.Group controlId='defaultTheme'>
								<Form.Label>Member Default Theme</Form.Label>
								<Stack direction='horizontal' gap={2}>
									<Button
										variant='light'
										style={{ border: '1px solid #c6c6c6' }}
										className='uuki-square-btn'
										onClick={() => setTheme('light')}>
										Light
									</Button>
									<Button
										style={{ backgroundColor: '#2d395a', border: 0 }}
										className='uuki-square-btn'
										onClick={() => setTheme('dark')}>
										Dark
									</Button>
								</Stack>
							</Form.Group>
							<Form.Group controlId='brandLogo'>
								<Form.Label>Brand Logo</Form.Label>
								<Stack gap={3} direction='horizontal'>
									<Image
										className='uuki-logo'
										src={brandLogo}
										loading='lazy'
										onError={({ currentTarget }) => {
											currentTarget.onerror = null;
											currentTarget.src = placeholderImage;
										}}
									/>
									<label for='file-upload-brand' class='custom-file-upload'>
										<div className='uuki-btn uuki-community-btn text-center'>
											Update
										</div>
									</label>
									<input
										id='file-upload-brand'
										className='d-none'
										type='file'
										onChange={(e) => uploadImage(1, e.target.files[0])}
									/>
								</Stack>
							</Form.Group>
							<Stack gap={4} direction='horizontal'>
								<Button
									className='uuki-community-btn uuki-btn align-self-start'
									onClick={saveBrandEdit}>
									Save and Continue
								</Button>
								<a className="text-decoration-none" onClick={() => navigate('/settings/' + currentSlug)}>
									Go to Settings
								</a>
							</Stack>
						</Stack>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item >
					<Accordion.Header>
						<Form.Check
							className='onboard-check'
							inline
							name='basic'
							type='checkbox'
							disabled
							checked={completed.includes(2) ? true : false}
						/>
						Check your access
					</Accordion.Header>
					<Accordion.Body className=' mb-3 ps-5'>
						<Stack gap={3}>
							<span>
								Check who can view the content in your community. Only members
								will be able to access the community's content if turned
								private.
							</span>
							<Form.Switch
								className='d-flex flex-row-reverse align-items-center justify-content-between ps-0 w-50'
								label='Turn Community Private'
								onChange={(event) => setPrivacy(event.target.checked)}
							/>
							<Button
								className='uuki-community-btn uuki-btn align-self-start'
								onClick={changePrivacy}>
								Save and Continue
							</Button>
						</Stack>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</Stack>
	);
}

export default BasicOnboarding;
