import {Form,Stack,InputGroup,Dropdown,Button,Modal} from 'react-bootstrap'
import {useState,useEffect} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce';
import {useSelector} from 'react-redux'


function GamificationSettings({setRankSettings,settings}) {
    const [showAdd, setShowAdd] = useState(false);
    const [enableSwitch, setEnableSwitch] = useState(false);
    const [settingsSet, setSettingsSet] = useState(false);
    const [lvlReq, setLvlReq] = useState('');
    const [rankTitle, setRankTitle] = useState('');
    const currentId = useSelector(
        (state) => state.communityReducer.communityId
    )
    useEffect(() => {
        if(Object.entries(settings).length > 0){
            setSettingsSet((prev)=>true)
            setEnableSwitch(settings.enabled)
        }
    }, [settings])
    const changeEnable = (isSet) => {
        setEnableSwitch((prev)=>!prev)
        axios.post('/allow_ranking',{id: currentId,value: isSet})
            .then((res) => {
                toast.success('Community Gamification Updated')
            
            }).catch(function (error) {
                toast.error('Community Gamification Not Updated')
            });
    }
    const changeRank = useDebouncedCallback(
        (id,value,isName) => {
            
            axios.post('/v2/change_rank',{id: id,value:value,isName:isName})
                .then((res) => {
                    toast.success('Rank Updated')
                
                }).catch(function (error) {
                    toast.error('Update Failed')
                });
        },
        1000
    );
    const addRank = () => {
        axios.post('/add_communityrank',{id: currentId,rankName: rankTitle,rank:lvlReq})
            .then((res) => {
                toast.success('Rank Added')
                setRankSettings({...settings,ranks:[...settings.ranks,{id:res.data,name:rankTitle,level:lvlReq}]})
                setShowAdd(false)
            }).catch(function (error) {
                toast.error('Rank Not Added')
            });
    }
    const deleteRank = (id) => {
        axios.post('/delete_communityrank',{id: id})
            .then((res) => {
                toast.success('Rank Deleted')
                let removedNewRanks = settings.ranks.filter(rank => rank.id !== id);
                setRankSettings({...settings,ranks:removedNewRanks})
            
            }).catch(function (error) {
                toast.error('Rank Not Deleted')
            });
    }
    const changePoints = useDebouncedCallback(
        (type,value) => {
            
            axios.post('/v2/change_points',{id: currentId,type:type,value:value})
                .then((res) => {
                    toast.success('Points Updated')
                
                }).catch(function (error) {
                    toast.error('Update Failed')
                });
        },
        1000
    );
    return (
        <>
            <Stack gap={4}>
                <Form.Group className="d-flex" controlId="gamificationCheckbox">
                    <Form.Check type="checkbox" checked={enableSwitch} onChange={(event) => changeEnable(event.target.checked)}/>
                    <Form.Label className="ms-2">Enable Gamification</Form.Label>
                </Form.Group>
                <div>
                    <Form.Label >Customize Points</Form.Label>
                    <Stack gap={3}>
                        <InputGroup className=" w-50">
                            <InputGroup.Text >
                            Likes
                            </InputGroup.Text>
                            <Form.Control defaultValue={settings.likePoints} type="integer"
                            onChange={(event)=>changePoints(0,event.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className=" w-50">
                            <InputGroup.Text >
                            Comments
                            </InputGroup.Text>
                            <Form.Control defaultValue={settings.commentPoints} type="integer"
                            onChange={(event)=>changePoints(1,event.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className=" w-50">
                            <InputGroup.Text >
                            Posts
                            </InputGroup.Text>
                            <Form.Control defaultValue={settings.postPoints} type="integer"
                            onChange={(event)=>changePoints(2,event.target.value)}
                            />
                        </InputGroup>
                    </Stack>
                </div>
                <div>
                    <Form.Label>Edit Ranks</Form.Label>
                    <Stack gap={3}>
                        {settingsSet && settings.ranks.map((rank) =>
                        <Stack direction="horizontal" key={rank.id}>
                            <InputGroup className="settings-input-group">
                                <Form.Control defaultValue={rank.name} onChange={(event)=>changeRank(rank.id,event.target.value,1)}/>
                                <Form.Control defaultValue={rank.level} onChange={(event)=>changeRank(rank.id,event.target.value,0)}/>
                            </InputGroup>
                            <Dropdown drop='end'>
                                <Dropdown.Toggle  className="bg-transparent border-0 text-dark">
                                    <i className="fa-solid fa-ellipsis"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick= {() => deleteRank(rank.id)}>Delete Rank</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Stack>
                        )}
                      
                        <Button className="align-self-start uuki-btn uuki-community-btn " onClick= {() => setShowAdd(true)}>Add New Rank</Button>
                    </Stack>
                    
                </div>
                
            </Stack>
           <Modal show={showAdd} onHide={() => setShowAdd(false)} centered>
                <Modal.Header closeVariant="white" closeButton>
                    <Modal.Title>Add New Rank</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack gap={4}>
                        <Form.Group controlId="title">
                            <Form.Label>Enter Rank Title</Form.Label>
                            <Form.Control  onChange={(event)=>setRankTitle(event.target.value)}/>
                        </Form.Group>
                        <Form.Group controlId="lvl">
                            <Form.Label>Enter Level Required</Form.Label>
                            <Form.Control autoFocus onChange={(event)=>setLvlReq(event.target.value)}/>
                        </Form.Group>
                    </Stack>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-center">
                    <Button className="uuki-btn uuki-community-btn" onClick={addRank}>Add Rank</Button>
                </Modal.Footer>
            </Modal> 
            
        </>
    )
}

export default GamificationSettings
