import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Image, Stack, Button, Form, ProgressBar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import Avatar from 'react-avatar';
import '../../styles/moderatordashboard.css';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export default function ReportedPosts({ slug }) {
	const [data, setData] = useState([]);
	const navigate = useNavigate();
	const isUserLoggedIn = useSelector(
		(state) => state.userReducer.isUserLoggedIn
	);

	useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		axios
			.get('/v2/moderate/' + slug, { params: { type: 1 } })
			.then((response) => {
				setData(response.data.reportedPosts);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deletePost = (id) => {
		axios
			.post('/delete_communitypost', { postId: id })
			.then((response) => {
				toast.success('Post deleted successfully');
				getData();
			})
			.error((err) => {
				console.log(err);
			});
	};

	const ignorePost = (data) => {
		var type = 'Reported post ignored';
		axios
			.post('/ignore_communitypost', {
				postId: data.id,
				type: type,
				userId: data.user_id,
				communityId: data.communityId,
			})
			.then((response) => {
				toast.success('Post ignored successfully');
				getData();
			})
			.error((err) => {
				console.log(err);
			});
	};
	return (
		<>
			{data.length > 0 ? (
				data.map((post) => (
					<div className='uuki-box post px-4 mb-3 pt-3'>
						{post.post_cover !== null && post.post_cover !== '' && (
							<Image fluid src={post.post_cover} />
						)}
						<Stack gap={3} direction='horizontal'>
							{post.user.image_url != '' && post.user.image_url != 'noImage' ? (
								<Image
									src={post.user.image_url}
									className={'post-author-image'}
									roundedCircle
									style={{ cursor: 'pointer' }}
									onClick={() =>
										navigate(
											'/c/' + slug + '/member?memberId=' + post.user.memberId
										)
									}></Image>
							) : (
								<Avatar
									name={post.user.name}
									style={{ cursor: 'pointer' }}
									size={'48'}
									textSizeRatio={2.5}
									round={true}
									onClick={() =>
										navigate(
											'/c/' + slug + '/member?memberId=' + post.user.memberId
										)
									}
								/>
							)}
							<Stack gap={1}>
								{
									<Stack direction='horizontal' gap={2}>
										<span
											className='post-author'
											onClick={() =>
												navigate(
													'/c/' +
														slug +
														'/member?memberId=' +
														post.user.memberId
												)
											}
											style={{ cursor: 'pointer' }}>
											{post.user.name}
										</span>
										{post.user.is_admin && (
											<div className='leaderboard-rank'>Admin</div>
										)}
										{post.user.is_mod && (
											<div className='leaderboard-rank'>Moderator</div>
										)}
										{post.user.tags.length > 0 &&
											post.user.tags.map((tag) => (
												<div className='leaderboard-rank'>{tag}</div>
											))}

										<ReactTimeAgo
											date={post.date}
											locale='en-US'
											timeStyle='mini'
											style={{ opacity: '0.7', fontSize: '0.875rem' }}
										/>

										<div className='d-flex justify-content-end container-delete-approve'>
											<div
												className='me-4'
												style={{
													cursor: 'pointer',
													fontSize: '17px ',
													color: 'green',
												}}
												onClick={(e) => deletePost(post.id)}>
												<i class='fas fa-trash-alt'></i>
											</div>
											<div
												className='me-3'
												style={{
													cursor: 'pointer',
													fontSize: '20px',
													color: 'red',
												}}
												onClick={(e) => ignorePost(post)}>
												<i class='fas fa-times'></i>
											</div>
										</div>
									</Stack>
								}
								<small className='text-muted pb-2'>
									Posted in {post.topic_name}
								</small>
							</Stack>
						</Stack>

						<div className='post-content p-4'>
							<h6
								style={{ cursor: 'pointer' }}
								onClick={() => navigate('/c/' + slug + '/post/' + post.slug)}>
								{post.title}
							</h6>
							{post.type == 'event' && (
								<div className='ms-auto'>
									<small className='me-3'>
										{post.event_info.going} Attending
									</small>
									<Button className="uuki-btn uuki-community-btn">
										
										{post.event_info.attending ? 'Cancel' : 'Attend'}
									</Button>
								</div>
							)}

							{post.type === 'poll' &&
							post.hasOwnProperty('poll_options') != null
								? post.poll_options.map((item) => (
										<>
											<div className='post-item-poll-options-bg' key={item.id}>
												<div className='d-flex'>
													{item.is_option_voted ? (
														<Form.Check
															inline
															name='group1'
															type='radio'
															className='post-item-poll-options-radio'
															checked
															disabled='true'
														/>
													) : (
														<Form.Check
															inline
															name='group1'
															type='radio'
															className='post-item-poll-options-radio'
															disabled='true'
														/>
													)}

													<ProgressBar
														now={item.percentage}
														className='post-item-poll-options-progress'
													/>
												</div>
												<div className='post-item-label-text'>
													{item.value} : {item.hits}
												</div>
											</div>
										</>
								  ))
								: ''}

							{post.type === 'poll' &&
							post.hasOwnProperty('poll_options') != null ? (
								<div className='post-total-votes-text'>
									{post.total_votes} Vote
								</div>
							) : (
								''
							)}

							<div dangerouslySetInnerHTML={{ __html: post.content }} />
						</div>
					</div>
				))
			) : (
				<h4>No reported posts</h4>
			)}
		</>
	);
}
