import { useState, useEffect } from "react";
import {
  Image,
  Form,
  Stack,
  Button,
  Popover,
  ListGroup,
  OverlayTrigger,
  Modal
} from "react-bootstrap";
import "../../../styles/members.css";
import { ReactComponent as ChatIcon } from "../../../assets/navbar-chat.svg";
import Avatar from "react-avatar";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import {
  reloadMembers,
} from "../../../redux/slice/membersSlice";

export default function MemberDetailCard({ data,key,enable_chat,allow_ranking }) {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModerator, setIsModerator] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const [showRemoveSpaceModal, setShowRemoveSpaceModal] = useState(false);
  const dispatch = useDispatch();
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const currentCommId = useSelector(
    (state) => state.communityReducer.communityId
  );
  const currentUserId = useSelector((state) => state.userReducer.userId);
  const currentUserRole = useSelector((state) => state.userReducer.userRole);  
  useEffect(() => {
    if (data != null && data.is_admin) {
      setIsAdmin(true);
    }

    if (data != null && data.is_moderator) {
      setIsModerator(true);
    }
  });
  const popoverMemberActions = (
    <Popover className="navbar-popover mt-3">
      <Popover.Body className="px-3 py-2">
        {(currentUserRole ==2) &&	  
        <ListGroup as="ol">		
          {!isModerator ? (
            <ListGroup.Item
              as="button"
              className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
              onClick={() => handleAddModerator()}
            >
              Add as moderator
            </ListGroup.Item>
          ) : (
            <ListGroup.Item
              as="button"
              className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
              onClick={() => handleRemoveModerator()}
            >
              Remove moderator
            </ListGroup.Item>
          )}		
          {!isAdmin ? (
            <ListGroup.Item
              as="button"
              className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
              onClick={() => handleAddAdmin()}
            >
              Add as admin
            </ListGroup.Item>
          ) : (
            <ListGroup.Item
              as="button"
              className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
              onClick={() => handleRemoveAdmin()}
            >
              Remove as admin
            </ListGroup.Item>
          )}
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
            onClick={() => handleRemoveMember()}
          >
            Remove member
          </ListGroup.Item>
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
            onClick={() => {setShowRemoveSpaceModal(true)
              document.body.click();
            }}
          >
            Remove from space
          </ListGroup.Item>
        </ListGroup>
        }
        {(currentUserRole ==1) &&
        <ListGroup as="ol">
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
            onClick={() => handleRemoveMember()}
          >
            Remove member
          </ListGroup.Item>		
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
            onClick={() => {setShowRemoveSpaceModal(true)
              document.body.click();
            }}
          >
            Remove from topic
          </ListGroup.Item>
        </ListGroup>
        }
        <ListGroup as="ol">
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
            onClick={() => {setShowInfoModal(true)
              document.body.click();
            }}
          >
            View Info
          </ListGroup.Item>		
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  const handleOnHide = ()=>{
    setShowInfoModal(false)
  }

  const handleAddModerator = () => {
    axios
      .post("/add_moderator", {
        id: data.id,
        community: currentCommId,
      })
      .then((response) => {
        toast.success("User added as moderator");
        setIsModerator(true);
        document.body.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleRemoveModerator = () => {
    axios
      .post("/remove_moderator", {
        id: data.id,
        community: currentCommId,
      })
      .then((response) => {
        toast.success("User removed as moderator");
        setIsModerator(false);
        document.body.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleAddAdmin = () => {
    axios
      .post("/add_admin", {
        id: data.id,
        community: currentCommId,
      })
      .then((response) => {
        toast.success("User added as admin");
        setIsAdmin(true);
        document.body.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleRemoveAdmin = () => {
    axios
      .post("/remove_admin", {
        id: data.id,
        community: currentCommId,
      })
      .then((response) => {
        toast.success("User removed as admin");
        setIsAdmin(false);
        document.body.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleRemoveMember = () => {
    axios
      .post("/remove_member", {
        userId: data.user_id,
        communityId: currentCommId,
      })
      .then((response) => {
        toast.success("User removed");

        document.body.click();
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleOnSpaceRemoveHide = () => {
    setShowRemoveSpaceModal(false)
  };

  const removeMemberFromTopic = (id)=>{
    axios.post('/v2/remove_member_from_topic',{id:id})
    .then((response)=>{
      toast.success("Member removed");		
      setShowRemoveSpaceModal(false)	  
      dispatch(reloadMembers(true));
    }).catch((err)=>{
      console.error(err)
    })
  }
  return (
    <>
      <div className="member-detail-card-bg uuki-box">
        <Stack
          style={{ cursor: "pointer" }}
          className="py-3 px-4"
          direction="horizontal"
          gap={3}
        >
          {/* <Image src={profilePic} className="members-profile-pic"/> */}
          {data.image_url != "" ? (
            <Image
              src={data.image_url}
              className="member-profile-image"
              roundedCircle
              loading="lazy"
            />
          ) : (
            <Avatar name={data.name} size="48" textSizeRatio={2} round={true} />
          )}
          <Stack gap={2}>
            <div
              style={{ fontWeight: "600" }}
              onClick={() =>
                navigate("/c/" + currentSlug + "/member?memberId=" + data.id)
              }
            >
              {data.name}
            </div>
            <small>{data.bio}</small>
          </Stack>
          {allow_ranking && <span className="leaderboard-rank">{data.rank}</span>}
          {(data.is_admin || isAdmin) && (
            <div className="leaderboard-rank">Admin</div>
          )}
          {(data.is_moderator || isModerator) && (
            <div className="leaderboard-rank">Moderator</div>
          )}
          {data.tags != null &&
            data.tags.length > 0 &&
            data.tags.map((tag) => (
              <div className="leaderboard-rank">{tag}</div>
            ))}
          {(currentUserRole != 0) &&			
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            className=" ms-auto mb-5"
            overlay={popoverMemberActions}
            rootClose
          >		  
            <i
              style={{ cursor: "pointer" }}
              className="ms-auto mb-5 fa-solid fa-ellipsis"
            ></i>
          </OverlayTrigger>
          }		  
        </Stack>
        {(currentUserId != null && currentUserId === data.user_id) ||
        data.is_blocked ? (
          ""
        ) : (
          <>
           {enable_chat==1 &&  <>
             <hr className="members-hr m-0"></hr>
              <Button
                className="uuki-btn uuki-community-btn mx-4 my-3"
                onClick={() =>
                  navigate(
                    "/chat/" +
                      currentSlug +
                      "?newId=" +
                      data.user_id +
                      "&newName=" +
                      data.name +
                      "&newImage=" +
                      data.image_url
                  )
                }
              >
                <ChatIcon /> Message
              </Button>
           </>}
          </>
        )}
      </div>

      <Modal show={showInfoModal} onHide={handleOnHide} centered>
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Member Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div >
              <div style={{ fontWeight: "600" }}>Name</div>
              <div>{data.name}</div>
            </div>
            <div className="mt-4">
            <div style={{ fontWeight: "600" }}>Email</div>
              <div>{data.email}</div>
            </div>
        </Modal.Body>
        </Modal>

        <Modal show={showRemoveSpaceModal}  onHide={handleOnSpaceRemoveHide} centered>
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Remove from Space</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div >
             {data!=null && data.invited_spaces.length>0?data.invited_spaces.map((space)=>(
             <div className="mb-3">
             <div className="d-flex justify-content-between align-items-center">
              <h6>{space.topic_name}</h6>
              <div><button className="uuki-btn uuki-community-btn" onClick={(e)=>{removeMemberFromTopic(space.id)}}>Remove member</button></div>
             </div>
             </div>)
              
             ):"No topic available."}
            </div>
        </Modal.Body>
        </Modal>
    </>
  );
}
