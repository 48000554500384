import {
  Button,
  Col,
  Container,
  Row,
  Stack,
  Nav,
  Tab,
  Image,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function HomeDashboard({ communities, setKey }) {
  const [commList, setCommList] = useState(null);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();  
  useEffect(() => {
    if (Object.entries(communities).length > 0) {
      setCommList(communities);
    }
  }, [communities]);
  const userId = useSelector((state) => state.userReducer.userId);
  if(searchParams.get('openTab')!=null){	
    setKey(searchParams.get('openTab'))  
  }  
  return (
    <div className="">
      <Stack className="mb-3" direction="horizontal">
        <h4>Communities</h4>
        <Button
          className="ms-auto uuki-btn dashboard-btn"
          onClick={() => setKey("create")}
        >
          <i className="fas fa-plus me-3"></i>Create
        </Button>
      </Stack>
      <Tab.Container id="left-tabs-example" defaultActiveKey="created">
        <Nav variant="pills" className="tabs-border-bottom text-nowrap mb-3">
          <Stack direction="horizontal" gap={2}>
            <Nav.Item className="dashboard-link">
              <Nav.Link
                className="d-flex align-items-center"
                eventKey="created"
              >
                Created Communities
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="dashboard-link">
              <Nav.Link className="d-flex align-items-center" eventKey="joined">
                Joined Communities
              </Nav.Link>
            </Nav.Item>
          </Stack>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="created">
            <Stack
              className="flex-wrap text-nowrap mb-3"
              direction="horizontal"
              gap={3}
            >
              {commList != null &&
                commList.created.map((community) => (
                  <Button
                    key={community.id}
                    onClick={()=>navigate(community.slug)}
                    className="community-button"
                  >
                    {community.logo !== "" && community.logo !== undefined ? (
                      <Image
                        src={community.logo}
                        className="uuki-logo me-2"
                        roundedCircle
                        loading="lazy"
                      ></Image>
                    ) : (
                      <Avatar
                        name={community.name}
                        size="40"
                        className="me-2"
                        style={{ fontSize: "0.5rem" }}
                        // className="mobile-profile-image"
                        round={true}
                      />
                    )}
                    {community.name}
                  </Button>
                ))}
            </Stack>
          </Tab.Pane>
          <Tab.Pane eventKey="joined">
            <Stack
              className="flex-wrap text-nowrap"
              direction="horizontal"
              gap={3}
            >
              {commList != null &&
                commList.joined.map((community) => (
                  <Button
                    key={community.id}
                    onClick={()=>navigate(community.slug)}
                    className="community-button"
                  >
                    {community.logo !== "" && community.logo !== undefined ? (
                      <Image
                        src={community.logo}
                        className="uuki-logo me-2"
                        roundedCircle
                        loading="lazy"
                      ></Image>
                    ) : (
                      <Avatar
                        name={community.name}
                        size="40"
                        className="me-2"
                        style={{ fontSize: "0.5rem" }}
                        // className="mobile-profile-image"
                        round={true}
                      />
                    )}
                    {community.name}
                  </Button>
                ))}
            </Stack>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default HomeDashboard;
