import { useEffect, useState } from 'react';
import { Accordion, Form, Stack, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function SettingsOnboarding({ completeStep, completed,setOnboardKey }) {
	const navigate = useNavigate();

	const currentSlug = useSelector(
		(state) => state.communityReducer.communitySlug
	);
	return (
		<>
			<Stack gap={3}>
			<Stack direction="horizontal">
				<h5>Settings</h5>
				<a className='ms-auto text-decoration-none' onClick={()=>{completeStep([3,4,5]);setOnboardKey('post')}}>Skip Section</a>
				<Button className="ms-3 uuki-btn uuki-community-btn" onClick={()=>setOnboardKey('post')}>Next</Button>
			</Stack>
				<span>
					Check out the various admin features available to run your community
					in the best way possible
				</span>
				<Accordion flush>
					<Accordion.Item >
						<Accordion.Header>
							<Form.Check
								className='onboard-check'
								inline
								name='basic'
								type='checkbox'
								disabled
								checked={completed.includes(3) ? true : false}
							/>
							Set Up Spaces
						</Accordion.Header>
						<Accordion.Body className='ps-5 mb-3'>
							<Stack gap={3}>
								<span>
									Spaces are integral to dividing up your content and events
									into readable sections. Go to <b>Settings -> Spaces</b> to
									add, delete or modify any existing spaces. Manage their
									privacy, type of content and more. You can group similar
									Spaces together into a Space Group for better management.
								</span>
								<span>
									Unhappy with the way spaces are organised? Check out
									<b> Settings -> Reorder Spaces</b> to feely organise them as
									you like.
								</span>

								<Stack gap={4} direction='horizontal'>
									<Button
										className='uuki-community-btn uuki-btn align-self-start'
										onClick={() => {
											completeStep(3);
											navigate('/settings/' + currentSlug + "?openTab=spaceSettings");
										}}>
										Check Settings
									</Button>

									<a className="text-decoration-none" onClick={() => completeStep(3)}>Mark Read</a>
								</Stack>
							</Stack>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item >
						<Accordion.Header>
							<Form.Check
								className='onboard-check'
								inline
								name='basic'
								type='checkbox'
								disabled
								checked={completed.includes(4) ? true : false}
							/>
							Check out Integrations
						</Accordion.Header>
						<Accordion.Body className='ps-5 mb-3'>
							<Stack gap={3}>
								<span>
									Have communities on other platforms to manage? Integrate them
									seamlessly by going into <b>Settings -> Integrations</b>.
									Integrate Discord, Telegram, Twitter for auto-posting any
									content on those platforms that you produce here.
								</span>
								<span>
									Integrate Zoom for easy event management and add your MetaMask
									wallet as well from <b>Settings -> Account</b>.
								</span>

								<Stack gap={4} direction='horizontal'>
									<Button
										className='uuki-community-btn uuki-btn align-self-start'
										onClick={() => {
											completeStep(4);
											navigate('/settings/' + currentSlug + "?openTab=integrations");
										}}>
										Check Settings
									</Button>

									<a className="text-decoration-none" onClick={() => completeStep(4)}>Mark Read</a>
								</Stack>
							</Stack>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item >
						<Accordion.Header>
							<Form.Check
								className='onboard-check'
								inline
								name='basic'
								type='checkbox'
								disabled
								checked={completed.includes(5) ? true : false}
							/>
							Set up Memberships
						</Accordion.Header>
						<Accordion.Body className='ps-5 mb-3'>
							<Stack gap={3}>
								<span>
									Have multiple Limited Entry or Invite Only Spaces you're
									planning? Manage them together and bundle them with
									memberships in <b>Settings -> Memberships</b>.
								</span>
								<span>
									Add a tradional paywall or an NFT based one and start
									monetising your community.
								</span>

								<Stack gap={4} direction='horizontal'>
									<Button
										className='uuki-community-btn uuki-btn align-self-start'
										onClick={() => {
											completeStep(5);
											navigate('/settings/' + currentSlug + "?openTab=memberships");
										}}>
										Check Settings
									</Button>

									<a className="text-decoration-none" onClick={() => completeStep(5)}>Mark Read</a>
								</Stack>
							</Stack>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Stack>
		</>
	);
}

export default SettingsOnboarding;
