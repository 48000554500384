import { useEffect, useState } from 'react';
import { Accordion, Form, Stack, Button } from 'react-bootstrap';

function PostOnboarding({ setKey, completeStep, completed,setOnboardKey }) {
	return (
		<>
			<Stack gap={3}>
			<Stack direction="horizontal">
				<h5>Post</h5>
				<a className='ms-auto text-decoration-none' onClick={()=>{completeStep(6);setOnboardKey('invite')}}>Skip Section</a>
				<Button className="ms-3 uuki-btn uuki-community-btn" onClick={()=>setOnboardKey('invite')}>Next</Button>
			</Stack>
				<span>
					Check out the various admin features available to run your community
					in the best way possible
				</span>
				<Accordion flush alwaysOpen>
					<Accordion.Item >
						<Accordion.Header>
							<Form.Check
								className='onboard-check'
								inline
								name='basic'
								type='checkbox'
								disabled
								checked={completed.includes(6) ? true : false}
							/>
							Add a welcome message
						</Accordion.Header>
						<Accordion.Body className='ps-5 mb-3'>
							<Stack gap={3}>
								<span>
									Posts are the essence of a UUKI community, be sure to keep
									your community updated through posts from time to time. Let's
									get your first post in the community started and others will
									follow.
								</span>
								<Stack gap={4} direction='horizontal'>
									<Button
										className='uuki-community-btn uuki-btn align-self-start'
										onClick={() => {
											completeStep(6);
											setKey('createPost');
										}}>
										Add a post
									</Button>

									<a className="text-decoration-none" onClick={() => completeStep(6)}>Mark Read</a>
								</Stack>
							</Stack>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Stack>
		</>
	);
}

export default PostOnboarding;
