import { Image, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import '../styles/signin.css';
import uukiLogo from '../assets/uuki-logo.png';
import { toast } from 'react-toastify';

import React from 'react';

export default function ResetPassword() {
	const [password, setPassword] = useState('');
	const navigate = useNavigate();

	useEffect(() => {
		console.log(window.location.search);
	});

	const handleClick = (e) => {
		e.preventDefault();
		const tokenString = window.location.search;
		const params = new URLSearchParams(tokenString);
		const token = params.get('token');
		if (password != null && password != '') {
			axios
				.post('/v2/update_password', {
					password: password,
					token: token,
				})
				.then((response) => {
					console.log(response);
					toast.success('Password updated, Please login');
					navigate('/login');
				})
				.catch(({ response }) => {
					if (
						response != null &&
						response.data != null &&
						response.data != '' &&
						response.code == 400
					) {
						toast.error(response.data);
					} else {
						toast.error('Reset Password failed');
					}
				});
		} else {
			toast.error("Password can't be empty.");
		}
	};
	return (
		<>
			<div
				className='d-flex justify-content-center'
				style={{ background: '#F8F9FB' }}>
				<div className='form-container'>
					<div
						className='d-flex flex-column  align-items-center justify-content-center'
						style={{ width: '100%' }}>
						<h4 className='pt-5'>Reset Password</h4>
						<Image
							className='mt-4'
							fluid
							style={{ height: '80px', width: '80px' }}
							src={uukiLogo}
							id='brand_logo'
						/>
					</div>

					<Form onSubmit={handleClick} className='ms-5 me-5 ps-4 pe-4 mt-5'>
						<Form.Group className='mb-3'>
							<Form.Label>Enter Password</Form.Label>

							<Form.Control
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								id='password'
								type='password'
								className='input-login-signup'
								placeholder='Enter Password'
							/>
						</Form.Group>

						<div className='d-flex justify-content-center'>
							<Button className='my-4  w-50 uuki-btn uuki-community-btn' type='submit'>
								Reset
							</Button>
						</div>
					</Form>
					<div className='d-flex justify-content-center'>
						<span> Don't have an account?</span>
						<Link
							className='text-decoration-none ms-1'
							to='/signup'
							style={{ color: 'black', fontWeight: 'bold' }}>
							Sign Up
						</Link>
					</div>
					<div className='d-flex justify-content-center'>
						<span> Already have an account?</span>
						<Link
							className='text-decoration-none ms-1'
							to='/login'
							style={{ color: 'black', fontWeight: 'bold' }}>
							Login
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}
