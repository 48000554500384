import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import {
	Container,
	Col,
	Nav,
	Navbar,
	Offcanvas,
	Image,
	Stack,
	Button,
	Popover,
	OverlayTrigger,
	ListGroup,
} from 'react-bootstrap';
import Avatar from 'react-avatar';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import logoImage from '../../assets/uuki-logo.png';
import { ReactComponent as SettingsIcon } from '../../assets/settings.svg';
import { ReactComponent as PlanIcon } from '../../assets/plan.svg';
import { ReactComponent as PricingIcon } from '../../assets/pricing.svg';
import { ReactComponent as RedeemIcon } from '../../assets/redeem.svg';
import { ReactComponent as HomeIcon } from '../../assets/home.svg';

function NavbarDashboard({ user, setKey }) {
	const navigate = useNavigate();
        let isRunningInCapacitor = Capacitor.isNativePlatform();
	const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
	const DesktopCheck = useMediaQuery({ query: '(min-width: 1024px)' });
	const isBigScreen = useMediaQuery({ query: '(min-width: 1600px)' });
	const [showNavbar, setShowNavbar] = useState(false);
	const logout = (e) => {
		e.preventDefault();
		axios.get('/logout?noRedirect=1').then((res) => {
			window.location.reload(true);
			});
	};
	useEffect(() => {
		document.querySelectorAll('.dashboard-icon').forEach((element) => {
			if (element.parentElement.parentElement.classList.contains('active')) {
				element.setAttribute('filter', 'url(#white-svg)');
			} else {
				element.setAttribute('filter', 'url(#black-svg)');
			}
		});
	});
	const popoverProfile = (
		<Popover className='navbar-popover'>
			<Popover.Header as='h5' className='navbar-popover-header'>
				{user.profile_pic !== '' && user.profile_pic !== undefined ? (
					<Image
						src={user.profile_pic}
						className='uuki-logo me-1'
						roundedCircle
						loading='lazy'></Image>
				) : (
					<Avatar
						name={user.name}
						size='40'
						className='me-1'
						style={{ fontSize: '0.5rem' }}
						// className="mobile-profile-image"
						round={true}
					/>
				)}
				{user.name}
			</Popover.Header>
			<Popover.Body className='px-3 py-2'>
				<ListGroup as='ol'>
					<ListGroup.Item
						as='button'
						className='d-flex justify-content-start border-0'
						onClick={logout}>
						Logout
					</ListGroup.Item>
				</ListGroup>
			</Popover.Body>
		</Popover>
	);
	return (
		<>
			<Navbar fixed='top' className='nav-uuki' expand={false}>
				<Navbar.Brand
					className='d-flex align-items-center'
					href='https://www.uuki.live'>
					<Image className='dashboard-logo me-2'  src={logoImage} />
					<h4>UUKI</h4>
				</Navbar.Brand>
				{isDesktop ? (
					<>
						<OverlayTrigger
							trigger='click'
							placement='bottom'
							rootClose
							overlay={popoverProfile}>
							{user.profile_pic !== '' && user.profile_pic !== undefined ? (
								<Image
									src={user.profile_pic}
									className='desktop-profile-image'
									roundedCircle
									loading='lazy'></Image>
							) : (
								<Avatar
									name={user.name}
									size='40'
									style={{ fontSize: '0.5rem' }}
									// className="mobile-profile-image"
									round={true}
								/>
							)}
						</OverlayTrigger>
					</>
				) : (
					<Navbar.Toggle aria-controls='offcanvasNavbar' onClick={()=>setShowNavbar((prev)=>true)}/>
				)}
				<Navbar.Offcanvas
					id='offcanvasNavbar'
					aria-labelledby='offcanvasNavbarLabel'
					placement='start'
					show={showNavbar}
          			onHide={()=>setShowNavbar((prev)=>false)}>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title id='offcanvasNavbarLabel'>
							Dashboard
						</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Stack className='h-100' gap={2}>
							<Nav variant='pills' className='flex-column text-nowrap'>
								<Stack gap={3}>
									<Nav.Item className='dashboard-link'>
										<Nav.Link eventKey='home' onClick={() => {setKey('home');setShowNavbar((prev)=>false)}}>
											<i className='fas fa-home mx-2'></i>Home
										</Nav.Link>
									</Nav.Item>
									<Nav.Item className='dashboard-link'>
										<Nav.Link
											eventKey='settings'
											onClick={() => {setKey('settings');setShowNavbar((prev)=>false)}}>
											<i className='fas fa-cog mx-2'></i>Settings
										</Nav.Link>
									</Nav.Item> 
                                                                        <>
                                                                        {DesktopCheck ? (
                                                                        <>
									<Nav.Item className='dashboard-link'>
										<Nav.Link eventKey='plan' onClick={() => {setKey('plan');setShowNavbar((prev)=>false)}}>
											<i className='fas fa-info-circle mx-2'></i>My plan
										</Nav.Link>
									</Nav.Item>
									<Nav.Item className='dashboard-link'>
										<Nav.Link
											eventKey='pricing'
											onClick={() => {setKey('pricing');setShowNavbar((prev)=>false)}}>
											<i className='fab fa-bitcoin mx-2'></i>Pricing
										</Nav.Link>
									</Nav.Item>
									<Nav.Item className='dashboard-link'>
										<Nav.Link
											eventKey='redeem'
											onClick={() => {setKey('redeem');setShowNavbar((prev)=>false)}}>
											<i className='fas fa-cookie mx-2'></i>Redeem
										</Nav.Link>
									</Nav.Item>
                                                                        </>
                                                                        ) : null}
                                                                        </>
								</Stack>
							</Nav>
							<Stack
								className='text-nowrap mt-auto'
								direction='horizontal'
								gap={2}>
								<Avatar
									name={user.name}
									size='30'
									textSizeRatio={2.5}
									round={true}
								/>
								<span className='mx'>{user.name}</span>
								<Button onClick={logout} variant='outline-danger'>
									Logout
								</Button>
							</Stack>
						</Stack>
					</Offcanvas.Body>
				</Navbar.Offcanvas>
			</Navbar>
			{isDesktop && (
				<Col className='position-fixed mt-5 py-4 px-0' md={isBigScreen ? 4 : 3}>
					<div className='ms-3 vh-100 mb-5  py-2 overflow-scroll home-left-nav nav-scroll'>
						<Nav
							variant='pills'
							className='scroll-nav fixed-nav flex-column text-nowrap pb-3'>
							<Nav.Item className='home-link dashboard-link'>
								<Nav.Link
									className='main-links d-flex align-items-center'
									eventKey='home'>
									<HomeIcon className='dashboard-icons me-3' />
									Home
								</Nav.Link>
							</Nav.Item>
							<Nav.Item className='home-link dashboard-link'>
								<Nav.Link
									className='main-links d-flex align-items-center'
									eventKey='settings'>
									<SettingsIcon className='dashboard-icons me-3' />
									Settings
								</Nav.Link>
							</Nav.Item>
                                                        <>
                                                        {DesktopCheck ? (
                                                        <>
							<Nav.Item className='home-link dashboard-link'>
								<Nav.Link
									className='main-links d-flex align-items-center'
									eventKey='plan'>
									<PlanIcon className='dashboard-icons me-3' />
									My plan
								</Nav.Link>
							</Nav.Item>
							<Nav.Item className='home-link dashboard-link'>
								<Nav.Link
									className='main-links d-flex align-items-center'
									eventKey='pricing'>
									<PricingIcon className='dashboard-icons me-3' />
									Pricing
								</Nav.Link>
							</Nav.Item>
							<Nav.Item className='home-link dashboard-link'>
								<Nav.Link
									className='main-links d-flex align-items-center'
									eventKey='redeem'>
									<RedeemIcon className='dashboard-icons me-3' />
									Redeem
								</Nav.Link>
							</Nav.Item>
                                                        </>
                                                        ) : null}
                                                        </>
						</Nav>
					</div>
				</Col>
			)}
		</>
	);
}

export default NavbarDashboard;
