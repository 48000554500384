import React from "react";
import { Accordion, Stack, ProgressBar, ListGroup, Image, Row, Col } from "react-bootstrap";
import Avatar from 'react-avatar'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

function StudentTab({ students, stats }) {
  const navigate = useNavigate();

  const communitySlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  return (
    <Stack gap={3}>
        <h6 className="m-0">Student Statistics</h6>
      { stats!=null &&
        <>
        <Row className="g-0">
          <Col className="d-flex flex-column uuki-box p-3" >
            <span>Total Members Enrolled</span>
            <h6><b>{stats.memberCount}</b></h6>
          </Col>
          <Col className="d-flex flex-column uuki-box mx-2 p-3" >
            <span>Average Course Completion</span>
            <h6><b>{Math.round(stats.averageCompletion)} %</b></h6>
          </Col>
          <Col className="d-flex flex-column uuki-box mx-2 p-3" >
            <span>Percent of community enrolled</span>
            <h6><b>{Math.round(stats.enrollPercent)} %</b></h6>
          </Col>
        </Row>
        <div class=' p-3 analytics-block'>
          <span>Student Activity</span>
          <Line
            className='p-3 analytics-block'
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
              },
            }}
            data={{
              labels:Object.keys(stats.completionDates),
              datasets: [
                {
                  label: 'Lessons Completed',
                  data: Object.keys(stats.completionDates).map(
                    (time) => stats.completionDates[time]
                  ),
                  borderColor: getComputedStyle(
                    document.body
                  ).getPropertyValue('--accent'),
                  backgroundColor: getComputedStyle(
                    document.body
                  ).getPropertyValue('--accent'),
                },
              ],
            }}
          />
        </div>
      </>}
      <ListGroup>
        {students != null &&
          students.map((student) => (
            <ListGroup.Item className="p-3">
              <Stack className="w-100 me-4" direction="horizontal">
                <Stack onClick={()=>navigate('/c/'+communitySlug+'/member?memberId='+student.id)} direction="horizontal" gap={3}>
                  {student.image != '' ? (
                    <Image
                      src={student.image}
                      className='trending-post-image '
                      roundedCircle
                      loading='lazy'></Image>
                  ) : (
                    <Avatar
                      name={student.name}
                      size='28'
                      textSizeRatio={2.5}
                      round={true}
                    />
                  )}
                  {student.name}
                </Stack>
                <Stack
                  direction="horizontal"
                  className="align-items-center ms-auto "
                >
                  <ProgressBar
                    style={{ height: "0.2rem", minWidth: "10rem" }}
                    className="me-2"
                    now={student.completion}
                  />
                  <small>{Math.round(student.completion)}%</small>
                </Stack>
              </Stack>
            </ListGroup.Item>
          ))}
      </ListGroup>
    </Stack>
  );
}

export default StudentTab;
