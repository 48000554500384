import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

function CreateDashboard({ setKey }) {
	const [communityTitle, setCommunityTitle] = useState('');
	const userState = useSelector((state) => state.user);
	const navigate = useNavigate();

	const createCommunity = (e) => {
		e.preventDefault();
		axios
			.post('/save_community', { communityTitle: communityTitle })
			.then((res) => {
				navigate('/c/' + res.data);
			});
	};
	return (
		<div className=''>
			<h4 className='mb-3'>
				{' '}
				<Button
					onClick={() => setKey('home')}
					className='text-dark border-0 bg-transparent'>
					<i className='fa-solid fa-arrow-left'></i>
				</Button>
				Add Community
			</h4>
			<Form onSubmit={createCommunity}>
				<Form.Group className='mb-3'>
					<Form.Label>Community Title</Form.Label>
					<Form.Control
						type='text'
						onChange={(e) => setCommunityTitle(e.target.value)}
					/>
				</Form.Group>
				<Button type='submit' className='uuki-btn uuki-dashboard-btn'>
					Save and Continue
				</Button>
			</Form>
		</div>
	);
}

export default CreateDashboard;
