import CourseDashboard from "./course/CourseDashboard";
import CourseOverview from "./course/CourseOverview";
import CoursePreview from "./course/CoursePreview";
import {useState,useEffect} from 'react'
import { useInView } from "react-intersection-observer";
import axios from "axios";
import { useSelector} from "react-redux";

function CourseFeed() {
    const [dataSet, setDataSet] = useState(false)
    const [data, setData] = useState(null)
    const { ref, inView, entry } = useInView({
        threshold: 0,
    });
    const currentTopicId = useSelector((state) => state.communityReducer.topicId);
    const currentId = useSelector((state) => state.communityReducer.communityId);

    useEffect(() => {
        inView==true && 
        axios
        .post("/v2/loadcourse", {
          commId: currentId,
          topicId: currentTopicId,
        })
        .then((res) => {
          setData(res.data);
          setDataSet(true)
        })
        .catch(function (error) {
          console.log("Mark Complete Failed");
        });
    }, [inView])
    return (
        dataSet==true ?
            <>
                {
                    data.dataFor==0 ? <CourseDashboard courseData={data.data}/> :
                    data.dataFor==1 ? <CourseOverview courseData={data.data}/> :
                    <CoursePreview courseData={data.data}/>

                }
            </>
        :
        <div className="p-4 d-flex justify-content-center" ref={ref}>
            <span class="loader-spinner" />
        </div>
    )
}

export default CourseFeed