import {useState,useEffect} from 'react'
import {Stack,Button,Form,Pagination, Tab} from 'react-bootstrap'
import {  useNavigate,useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import InnerHTML from 'dangerously-set-html-content';
import axios from 'axios'
import Countdown from 'react-countdown';
import { useSelector, useDispatch } from "react-redux";
import QuizCompleted from "./QuizCompleted";

function Quiz({communitySlug,topicSlug,setNext,quiz,setCompleted,completed,total,setCompleteShow,memberId}) {
    const navigate = useNavigate();
    const location = useLocation();
    const quizLength = quiz.questions.length
    const [quizStarted, setQuizStarted] = useState(false)
    const [FIBAnswers, setFIBAnswers] = useState([])
    const [quizSubmitted, setQuizSubmitted] = useState(false)
    const [answerView, setAnswerView] = useState(false)
    const [timeTaken, setTimeTaken] = useState({hrs:0,mins:0,secs:0})
    let pageNo = []
    const [currentQuestion,setCurrentQuestion] = useState(1)
    const [newScore, setNewScore] = useState(0)
    const [currentDate,setCurrentDate] = useState(Date.now())
    const isMobile = useMediaQuery({ query: "(max-width: 480px)" });  
    const startQuiz = () => {
        if(quiz.submitted==true){
            axios
            .post("/v2/retake_quiz", {
              quizId: quiz.id,
              commId:currentCommId
            })
            .then((res) => {
                setQuizStarted(true)
                setCurrentDate(Date.now())
              if(Math.round(((completed+1) / total) * 100)==100){
                setCompleteShow(true)
              }
              setCompleted((prev) => prev + 1);
            })
            .catch(function (error) {
              console.log("Submit Failed");
            });
        }else{
            setQuizStarted(true)
            setCurrentDate(Date.now())
        }
    }
    const currentCommId = useSelector(
        (state) => state.communityReducer.communityId
    );
    useEffect(() => {
      if(quizStarted==true){
        submitQuiz()
      }
    }, [location])
    const [answersSelected,setAnswersSelected] = useState([])
    const handleAnswerChecked = (multiCorrect,questionId,answerId, e) => {
        if(e.target.checked==true){
            if(multiCorrect>=2){
                setAnswersSelected([...answersSelected,{questionId:questionId,answerId:answerId}])
            }else{
                let answered = answersSelected.find((answer)=>answer.questionId==questionId)
                if(answered!=undefined){
                    let newAnswers = answersSelected.filter((answer)=>answer.questionId!=questionId)
                    newAnswers.push({questionId:questionId,answerId:answerId})
                    setAnswersSelected(newAnswers)
                }else{
                    setAnswersSelected([...answersSelected,{questionId:questionId,answerId:answerId}])
                }
            }
        }else{
            let newAnswers = answersSelected.filter((answer)=>answer.answerId!=answerId)
            setAnswersSelected(newAnswers)

        }
      };
    const handleAnswerTyped = (questionId,answer) => {
        let answered = FIBAnswers.find((answer)=>answer.questionId==questionId)
        if(answered!=undefined){
            let newAnswers = FIBAnswers.filter((answer)=>answer.questionId!=questionId)
            newAnswers.push({questionId:questionId,answer:answer})
            setFIBAnswers(newAnswers)

        }else{
            setFIBAnswers([...FIBAnswers,{questionId:questionId,answer:answer}])
        }
    }
    const continueQuiz = () => {
        if(Math.round(((completed+1) / total) * 100)==100){
            setCompleteShow(true)
        }
        setCompleted((prev) => prev + 1);
        setNext(quiz.page);
    }
    const submitQuiz = () => {  
        axios
        .post("/v2/submit_quiz", {
              answers: answersSelected,
              FIBAnswers: FIBAnswers,
              quizId: quiz.id,
              commId:currentCommId,
              timeTaken:timeTaken
            })
            .then((res) => {
                setQuizSubmitted(true)
                setNewScore(res.data.score)
        })
        .catch(function (error) {
          console.log("Submit Failed");
        });
    }
    for (let number = 1; number <= quizLength; number++) {
        pageNo.push(
            <Pagination.Item className={(number<(currentQuestion-2) || (number>(currentQuestion+2)))?"d-none":"d-block"} key={number} eventKey={number} onClick={()=>setCurrentQuestion((prev)=>number)} active={number === currentQuestion}>
            {number}
            </Pagination.Item>,
        );
        }
   
    
    return (
        <div className="ms-md-3 ms-xxl-5 me-xxl-3">
            <Stack className="" direction="horizontal">
                <Button
                onClick={() => navigate("/c/" + communitySlug + "/" + topicSlug)}
                style={{color:'var(--fontColor)'}}
                className=" border-0 bg-transparent "
                >
                <i className="fa-solid fa-arrow-left me-2"></i>Back
                </Button>
            </Stack>
            <Stack className="px-3 course-block py-3  uuki-box my-md-4 px-sm-5 " gap={2}>
                {!quizSubmitted ? <>
                    {!quizStarted ? <>
                        <Stack direction="horizontal">
                            <h3 ><b>{quiz.title}</b></h3>
                            <div className="ms-auto d-flex flex-column">
                                {quiz.timer!=0 && <span>{Math.floor(quiz.timer/60)} hr {quiz.timer%60} mins</span>}
                                <small>{quiz.questionCount} Questions</small>
                            </div>
                        </Stack>
                        {quiz.content!="" &&
                        <>
                        <div
                        ><InnerHTML html={quiz.content} /></div>
                        </>
                        }
                        {quiz.submitted==true && <small>Previous Score: {quiz.prevScore}</small>}
                        {(quiz.submitted==true &&quiz.resultView==1) && <Button className="uuki-btn uuki-community-btn align-self-start" onClick={()=>setAnswerView(true)}>Check Answers</Button>}
                        {answerView==true && <QuizCompleted quizId={quiz.id} memberId={memberId}/>}
                        {((quiz.allowRetake==true&&quiz.submitted==true) || quiz.submitted==false) &&
                        <Button className="uuki-btn uuki-community-btn align-self-start" onClick={startQuiz}>{(quiz.allowRetake==true&&quiz.submitted==true)?"Retake":"Start"} Quiz</Button>
                        }
                    </> : <>
                        <Stack direction="horizontal">
                            <h3 ><b>{quiz.title}</b></h3>
                            <div className="ms-auto d-flex flex-column">
                            {quiz.timer!=0 &&<Countdown date={currentDate+(quiz.timer*60000)} onComplete={submitQuiz} onTick={(e)=>setTimeTaken({hrs:e.hours,mins:e.minutes,secs:e.seconds})}/>}
                            </div>
                        </Stack>
                        <Tab.Container id="question-tabs" activeKey={currentQuestion}  onSelect={(k) => setCurrentQuestion(k)}>
                        
                            <Tab.Content>
                                {
                                    quiz.questions.map((question,index=1)=>
                                    <Tab.Pane eventKey={index+1}>
                                        <div class="d-flex pt-3"><span>{(index+1)+"."}</span> 
                                        {quiz.content!="" &&
                                        <>
                                        <InnerHTML html={quiz.content} />
                                        </>
                                        }
                                        </div>
                                        {question.multiCorrect>=2&&<small>(Select Multiple)</small>}
                                        {question.isFIB?
                                            <Form.Control onChange={(e)=>handleAnswerTyped(question.id,e.target.value)}/>
                                        :<Stack gap={2}>
                                        {question.answers
                                            .map((answer) => (
                                                <div
                                                    className="post-item-poll-options-bg position-relative border rounded poll-option d-flex align-items-center"
                                
                                                    key={answer.id}
                                                >
                                                    {/* {item.is_option_voted ? (
                                                        <Form.Check
                                                        inline
                                                        name="group1"
                                                        type="checkbox"
                                                        className="poll-option-radio ps-3 position-absolute"
                                                        checked={true}
                                                        onChange={(e) => handlePollOptionsChecked(item.id, e)}
                                                        />
                                                    ) : ( */}
                                                        <Form.Check
                                                        inline
                                                        name={"question-"+question.id}
                                                        type={question.multiCorrect>=2?"checkbox":"radio"}
                                                        className="poll-option-radio ps-3 position-absolute"
                                                        onChange={(e) => handleAnswerChecked(question.multiCorrect,question.id,answer.id, e)}
                                                        />
                                                    {/* )} */}
                                                    {/* <ProgressBar
                                                        now={item.percentage}
                                                        className="post-item-poll-options-progress bg-transparent h-100"
                                                    ></ProgressBar> */}
                                                    <span
                                                    className="option-text text-truncate text-dark"
                                                    >
                                                    {answer.value}
                                                    </span>
                                                    {/* <small className="me-3 position-absolute end-0">Selected</small> */}
                                                </div>
                                            ))}
                                        </Stack>}
                                    </Tab.Pane>)
                                }
                            </Tab.Content>
                            {currentQuestion!=quizLength && <Button className="uuki-btn uuki-community-btn align-self-end mt-3" onClick={()=>setCurrentQuestion((prev)=>(prev<quizLength)?prev+1:prev)}>Next Question</Button>}

                            <Pagination className="justify-content-center pt-5">
                                <Pagination.First onClick={()=>setCurrentQuestion((prev)=>1)}/>
                                <Pagination.Prev onClick={()=>setCurrentQuestion((prev)=>(prev>1)?prev-1:prev)}/>
                                {pageNo}
                                <Pagination.Next onClick={()=>setCurrentQuestion((prev)=>(prev<quizLength)?prev+1:prev)}/>
                                <Pagination.Last onClick={()=>setCurrentQuestion((prev)=>quizLength)}/>
                            </Pagination>
                        </Tab.Container>
                        <Button className="uuki-btn uuki-community-btn align-self-start mt-3" onClick={submitQuiz}>Submit</Button>
                        </>}
                </> : <>
                    <h3 className="text-center">Congratulations on completing the quiz!</h3>
                    {quiz.totalScore!=0 && 
                    <>
                        <span>{((newScore/quiz.totalScore)*100 >= quiz.passing) ? "You have Passed!" : "You have Failed." }</span>
                        <small>You scored {newScore}/{quiz.totalScore}</small>
                    </>}
                    {quiz.resultView==1 && <Button className="uuki-btn uuki-community-btn align-self-start" onClick={()=>setAnswerView(true)}>Check Answers</Button>}
                    {answerView==true && <QuizCompleted quizId={quiz.id} memberId={memberId}/>}
                    <Button
                        onClick={continueQuiz}
                        className=" uuki-btn uuki-community-btn align-self-start"
                    >
                        Continue
                    </Button>
                </>}
            </Stack>
        </div>
    )
}

export default Quiz
