import { useMediaQuery } from "react-responsive";
import { useEffect, useRef, useState } from "react";
import {
  Container,
  Col,
  Nav,
  Navbar,
  Offcanvas,
  Image,
  Stack,
  Button,
  Popover,
  OverlayTrigger,
  Badge,
  ListGroup,
  InputGroup,
  FormControl,
  Accordion,
  Overlay,
  Modal,
  Form,
} from "react-bootstrap";
import Avatar from "react-avatar";
import axios from "axios";
import { Link, useNavigate,useSearchParams } from "react-router-dom";
import logoImage from "../../assets/uuki-logo.png";
import { ReactComponent as SettingsIcon } from "../../assets/navbar-settings.svg";
import { ReactComponent as ChatIcon } from "../../assets/navbar-chat.svg";
import { ReactComponent as NotificationIcon } from "../../assets/navbar-notification.svg";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { ReactComponent as HomeIcon } from "../../assets/home.svg";
import { ReactComponent as MembersIcon } from "../../assets/members.svg";
import { ReactComponent as MobileHome } from "../../assets/mobile-home.svg";
import { ReactComponent as MobileSearch } from "../../assets/mobile-search.svg";
import { ReactComponent as MobileNotification } from "../../assets/mobile-bell.svg";
import { ReactComponent as MobileChat } from "../../assets/mobile-message.svg";
import { ReactComponent as CoursesIcon } from "../../assets/courses.svg";
import { useDispatch, useSelector } from "react-redux";
import { cookieDetails } from "../../redux/slice/communitySlice";
import {getCookie, deleteCookie } from "../../Utils.js";
import {
  topicDetails,
  communityDetails,
} from "../../redux/slice/communitySlice";
import { jumpToComment } from "../../redux/slice/commentSlice";
import ReactTimeAgo from "react-time-ago";
import { InView } from 'react-intersection-observer';
import { ReactComponent as ShareImage } from "../../assets/share.svg";


import {
  showLoginModal,
  showSignupModal,
} from "../../redux/slice/authenticationSlice";
import LoginModal from "../authentication/LoginModal";
import { toast } from "react-toastify";

function NavbarHome({
  setKey,
  spaces,
  community,
  member,
  setTopicChange,
  inView,
  linksLocation,
  links,
  notifications,
  counter,
  affiliate,
  translate,
  memberInvite,
  memberList
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const currentTopic = useSelector((state) => state.communityReducer.topicSlug);
  const currentCommunityId = useSelector(
    (state) => state.communityReducer.communityId
  );
  const currentLanguages = useSelector(
    (state) => state.languagesReducer.languages
  );
  const currentCommunityLanguage = useSelector(
    (state) => state.communityReducer.communityLanguage
  );
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  const currentUserId = useSelector((state) => state.userReducer.userId);
  const isLoggedIn = useSelector((state) => {
    return state.userReducer.isUserLoggedIn;
  });
  const isToShowLoginModal = useSelector(
    (state) => state.authenticationReducer.showLoginModal
  );
  const currentRole = useSelector((state) => state.userReducer.userRole);

  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1600px)" });
  const [spacesSet, setSpacesSet] = useState(false);
  const [showTooltip, setShowToolTip] = useState(false);
  const [notifSeen, setNotifSeen] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [showInviteMemberPopup, setShowInviteMemberPopup] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  // const [showLoginModal, setshowLoginModal] = useState(false);
  const target = useRef(null);
  const googleTranslateElementInit = () => {
    if(translate == true) {	  
      var userLang =
        navigator.language || navigator.userLanguage || navigator.languages;
      if (userLang.substr(0, 2) != "en") {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            layout:
              window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
          },
          "google_translate_element"
        );
      } else {
        document.querySelectorAll("#google_translate_element").forEach(element => {
          element.style.display ="none";
        });
      }
	}
  };
  const logout = (e) => {
    e.preventDefault();
	if(getCookie("app_url")!=undefined) {		
		deleteCookie("app_url");
		dispatch(cookieDetails({cookieUrl:""}));
		axios.get("/logout?noRedirect=1").then((res) => navigate("/community_code"));
	} else {
      axios.get("/logout?noRedirect=1").then((res) => window.location.reload());
	}
  };
  useEffect(() => {
    spaces != undefined && spaces.length > 0 && setSpacesSet((prev) => true);
    if (notifications != undefined && notifications.length > 0) {
      setNotificationData(notifications);
    }
  }, [spaces, notifications]);

  useEffect(() => {
    document.querySelectorAll(".dashboard-icon").forEach((element) => {
      if (element.parentElement.parentElement.classList.contains("active")) {
        element.setAttribute("filter", "url(#white-svg)");
      } else {
        element.setAttribute("filter", "url(#black-svg)");
      }
    });
  });
  useEffect(() => {
    if(searchParams.get("iframe")==null && translate==true)
   { 
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }
  }, []);
  const handleLoginClick = (e) => {
    e.preventDefault();
    dispatch(showLoginModal(true));
  };
  const handleSignupClick = (e) => {
    e.preventDefault();
    dispatch(showSignupModal(true));
    dispatch(showLoginModal(true));
  };
  const handleJoin = () => {
    axios
      .get("/request_join", {
        params: {
          id: currentUserId,
          commId: currentCommunityId,
        },
      })
      .then((response) => {		  
          window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCancelRequest = () => {
    axios
      .get("/cancel_join", {
        params: {
          id: currentUserId,
          commId: currentCommunityId,
        },
      })
      .then((response) => {
          window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const markRead = (index) => {
    if(!notifSeen.includes(index)){
      setNotifSeen([...notifSeen,index])
      var sendNotifs
      if(index==0){
        sendNotifs = notificationData.slice(0,5)
      }else{
        sendNotifs = notificationData.slice(5,10)
      }
      axios
        .post("/v2/mark_read", {ids: sendNotifs})
        .then((response) => {
            console.log(response)
          })
          .catch((err) => {
              console.error(err);
            });
        }
      };
  const popoverProfile = (
    <Popover className="navbar-popover">
      <Popover.Header as="h5" className="navbar-popover-header">
        {member.image !== "" && member.image !== undefined ? (
          <Image
            src={member.image}
            className="uuki-logo me-1"
            roundedCircle
            loading="lazy"
          ></Image>
        ) : (
          <Avatar
            name={member.name}
            size="40"
            className="me-1"
            style={{ fontSize: "0.5rem" }}
            // className="mobile-profile-image"
            round={true}
          />
        )}
        {member.name}
      </Popover.Header>
      <Popover.Body className="px-3 py-2">
        <ListGroup as="ol">
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start border-0"
            onClick={() => {
              document.body.click();
              navigate("/c/" + currentSlug + "/member?memberId=" + member.id);
            }}
          >
            {currentLanguages[4][currentCommunityLanguage]}
          </ListGroup.Item>
          {member.isAdmin && <ListGroup.Item
            as="button"
            className="d-flex justify-content-start border-0"
            onClick={() =>
              currentHost == "/"
                ? (window.location.href = "https://app.uuki.live/")
                : navigate("/")
            }
          >
            {currentLanguages[5][currentCommunityLanguage]}
          </ListGroup.Item>}
          <div className="content-border"></div>
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start border-0"
            onClick={logout}
          >
            {currentLanguages[6][currentCommunityLanguage]}
          </ListGroup.Item>
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  const popoverSettings = (
    <Popover className="navbar-popover mt-3">
      <Popover.Body className="px-3 py-2">
        <ListGroup as="ol">
          {member.isAdmin && (
            <>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => {
                  document.body.click();
                  navigate("/settings/" + currentSlug);
                }}
              >
                <i className="fa-solid fa-sliders me-2"></i>{" "}
                {currentLanguages[3][currentCommunityLanguage]}
              </ListGroup.Item>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => {
                  document.body.click();
                  navigate("/c/" + currentSlug + "/analytics");
                }}
              >
                <i className="fa-solid fa-chart-line me-2"></i>{" "}
                {currentLanguages[2][currentCommunityLanguage]}
              </ListGroup.Item>
            </>
          )}
          {(member.isAdmin || member.isMod) && (
            <>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => {
                  document.body.click();
                  navigate("/moderate/" + currentSlug);
                }}
              >
                <i className="fa-solid fa-eye me-2"></i>{" "}
                {currentLanguages[1][currentCommunityLanguage]}
              </ListGroup.Item>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => {
                  document.body.click();
                  navigate("/invite_requests/" + currentSlug);
                }}
              >
                <i className="fa-solid fa-table-list me-2"></i>{" "}
                {currentLanguages[75][currentCommunityLanguage]}
              </ListGroup.Item>
            </>
          )}
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  const handleNotificationClick = (notification) => {
    if (notification.post_id != null && notification.post_slug != "") {
      if (notification.comment_id != null || notification.comment_id != "") {
        dispatch(jumpToComment(notification.comment_id));
      }
      navigate("/c/" + currentSlug + "/post/" + notification.post_slug);
    } else if (notification.type.includes("request")) {
      navigate("/invite_requests/" + currentSlug);
    } else if(notification.lesson_id != null && notification.lesson_slug != ""){
      navigate("/course/" + currentSlug + "/" + notification.lesson_slug+"?lessonId="+notification.lesson_id);
    } else if(notification.chat_id != null && notification.chat_id != ""){
      navigate("/chat/" + currentSlug);
    }
    document.body.click();
  };
  const popoverNotifications = (
    <Popover className="navbar-popover mt-3 " style={{ minWidth: "20rem" }}>
      <Popover.Header as="h5" className="navbar-popover-header">
        Notifications
      </Popover.Header>
      <Popover.Body className=" position-relative p-0">
        <ListGroup className="navbar-notif-list px-3 py-2" as="ol">
          {notificationData != null ? (
            notificationData.map((item,index) => (
              <>
                {(index==0||index==5) && 
                  <InView
                    as='div'
                    onChange={(inView, entry) => {
                      inView == true && markRead(index);
                    }}>
                   
                  </InView>}
                <ListGroup.Item
                  as="li"
                  className="d-flex flex-column  py-2 px-1 border-0 border-bottom"
                  key={item.id}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleNotificationClick(item)}
                >
                  <div className="d-flex align-items-center">
                    {item.doneByImage !== "" &&
                    item.doneByImage !== undefined ? (
                      <Image
                        src={item.doneByImage}
                        className="member-image me-3"
                        roundedCircle
                        loading="lazy"
                      ></Image>
                    ) : (
                      <Avatar
                        name={item.doneByName}
                        size="32"
                        style={{ fontSize: "0.5rem" }}
                        className="me-3"
                        round={true}
                      />
                    )}
                    <span
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    ></span>
                  </div>

                  <small className="text-muted ms-5">
                    <ReactTimeAgo
                      date={Date.parse(item.notifyTime)}
                      locale="en-US"
                    />
                  </small>
                </ListGroup.Item>
              </>
            ))
          ) : (
            <>
              <ListGroup.Item
                as="li"
                className="d-flex flex-column  py-2 px-1 border-0 border-bottom"
              >
                <div className="d-flex align-items-center">
                  No new notification.
                </div>
              </ListGroup.Item>
            </>
          )}
          <ListGroup.Item
            as="li"
            className="py-3 border-0"/>
        </ListGroup>
          <div
            className="d-flex justify-content-center border-0 rounded-4 p-2 position-absolute bottom-0 view-all-button mx-1"
          >
            <div onClick={() => setKey("notificationDetail")}>
              <span className="view-all-text">View All Notifications</span>
            </div>
          </div>
      </Popover.Body>
    </Popover>
  );

  const sendSearchRequest = (e) => {
    if (e != null && e.target != null && e.target.value != null) {
      if (e.target.value === "") {
        setShowToolTip(false);
      } else {
        axios
          .post("/v2/search_post", {
            search: e.target.value,
            topicId: currentTopic,
            isHome: true,
            communityId: currentCommunityId,
          })
          .then(function (response) {
            if (
              response != null &&
              response.data != null &&
              response.data.results != null
            ) {
              if (response.data.results.length > 0) {
                setSearchResult(response.data.results);
                setShowToolTip(true);
              } else {
                setShowToolTip(false);
              }
            } else {
              console.log("No result Found");
            }
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
      }
    } else {
      console.log("Error sending search request");
    }
  };

  const searchPopover = (
    <Popover className="ms-3" id="popover-basic">
      <Popover.Body className="search-wrapper uuki-box p-0 ">
        <ListGroup>
          {searchResult !== []
            ? searchResult.map((result) => (
                <ListGroup.Item
                  className="search-result border-0 border-bottom py-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    document.body.click();
                    navigate("/c/" + currentSlug + "/post/" + result.slug);
                  }}
                >
                  <Stack gap={3} direction="horizontal">
                    {result.memberImage !== "" &&
                    result.memberImage !== undefined ? (
                      <Image
                        src={result.memberImage}
                        className="mobile-profile-image"
                        roundedCircle
                        loading="lazy"
                      ></Image>
                    ) : (
                      <Avatar
                        name={result.memberName}
                        size="32"
                        style={{ fontSize: "0.5rem" }}
                        // className="mobile-profile-image"
                        round={true}
                      />
                    )}
                    <Stack>
					  <>
					  {result.title.length>60?
                      (<span style={{ fontWeight: "500" }}>{result.title.slice(0, 60)}...</span>):
					  (<span style={{ fontWeight: "500" }}>{result.title}</span>)
					  }
					  </>
                      <small>
                        Posted in {result.posted_in} by {result.memberName}
                      </small>
                    </Stack>
                  </Stack>
                </ListGroup.Item>
              ))
            : ""}
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  // const setVisibility = () => {
  // 	if (showTooltip) {
  // 		setShowToolTip(false);
  // 	}
  // };


  const handleInviteMember = (event) => {
    event.preventDefault();
    const email = document.getElementById('invite-member-email').value
    axios
      .post("/invite_members", {
       email:email,
       community:currentCommunityId
      })
      .then((response) => {
       toast.success("Invitation sent to the member")
       setShowInviteMemberPopup(false)
      })
      .catch((err) => {
        console.log(err)
        toast.error("Error sending invite.")
        setShowInviteMemberPopup(false)
      });
  };


  return (
    <>
      <Navbar sticky="top" className="nav-uuki nav-home flex-nowrap" expand={false}>
        <Navbar.Brand className="community-name text-truncate">
          {community.logo == "" || community.show_logo == false ? (
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate(currentHost)}
            >
              {community.name}
            </h4>
          ) : (
            <Image
            fluid
              style={{ cursor: "pointer" }}
              onClick={() => navigate(currentHost)}
              loading="lazy"
              className="brand-logo"
              src={community.logo}
            />
          )}
        </Navbar.Brand>
        {isLoggedIn ? (
          <>
            {member.isMember ? (
              <>
                {isDesktop ? (
                  <>
                    {/* <div className="d-flex flex-column"> */}
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      delayShow={200}
                      placement="bottom"
                      // show={showTooltip}
                      overlay={searchPopover}
                    >
                      <InputGroup
                        className="d-flex flex-nowrap search-section "
                        ref={target}
                      >
                        <InputGroup.Text className="border-end-0 " style={{borderColor:'#DFDFDF'}}>
                          <SearchIcon />
                        </InputGroup.Text>

                        <FormControl
                          placeholder="Search a post"
                          className="border-start-0 "
                          id="search-bar"
                          name="search-bar"
                          autoComplete="notrequired"
                          onInput={(e) => sendSearchRequest(e)}
                          // onClick={setVisibility}
                        />
                      </InputGroup>
                    </OverlayTrigger>

                    {/* <Overlay
                target={target.current}
                show={showTooltip}
                placement="bottom"
                shouldUpdatePosition={true}
              >
                {searchResult !== []
                  ? searchResult.map((result) => <div>{result.title}</div>)
                  : ""}
              </Overlay> */}
                    {/* </div> */}
                    {/* <Overlay
                target={target.current}
                show={showTooltip}
                placement="bottom"
                shouldUpdatePosition={true}
              >
                {searchResult !== []
                  ? searchResult.map((result) => <div>{result.title}</div>)
                  : ""}
              </Overlay> */}

                    <Stack direction="horizontal" gap={4}>
                      <div className="position-relative">
                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          overlay={popoverNotifications}
                          rootClose
                        >
                          <NotificationIcon style={{ cursor: "pointer" }} />
                        </OverlayTrigger>
                        <Badge
                          pill
                          className="notification-badge position-absolute top-0 start-100 translate-middle"
                        >
                          {counter.notification}
                        </Badge>
                      </div>
                      {community.enable_chat==1 && <div className="position-relative">
                        <ChatIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/chat/" + currentSlug)}
                        />
                        <Badge
                          pill
                          className="notification-badge position-absolute top-0 start-100 translate-middle"
                        >
                          {counter.chat}
                        </Badge>
                      </div>}
                      {member.isAdmin || member.isMod ? (
                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          rootClose
                          overlay={popoverSettings}
                        >
                          <SettingsIcon style={{ cursor: "pointer" }} />
                        </OverlayTrigger>
                      ) : (
                        <SettingsIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/settings/" + currentSlug)}
                        />
                      )}
                      <OverlayTrigger
                        trigger="click"
                        placement="bottom"
                        rootClose
                        overlay={popoverProfile}
                      >
                        {member.image !== "" && member.image !== undefined ? (
                          <Image
                            src={member.image}
                            className="desktop-profile-image"
                            roundedCircle
                            loading="lazy"
                          ></Image>
                        ) : (
                          <Avatar
                            name={member.name}
                            size="40"
                            style={{ fontSize: "0.5rem", cursor: "pointer" }}
                            // className="mobile-profile-image"
                            round={true}
                          />
                        )}
                      </OverlayTrigger>
                    </Stack>
                  </>
                ) : (
                  <div className="d-flex">
                    {community.enable_chat==1 && <div className="position-relative me-2 mt-1">
                      <ChatIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/chat/" + currentSlug)}
                      />
                      <Badge
                        pill
                        className="notification-badge position-absolute top-0 start-100 translate-middle"
                      >
                        {counter.chat}
                      </Badge>
                    </div>}
                    <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={()=>setShowNavbar((prev)=>true)}/>
                  </div>
                )}
              </>
            ) : (
              <>
                {" "}
                {member.isRequested ? (
                  <>
                    <Stack direction="horizontal" gap="2">
                      <Button className="uuki-community-btn" onClick={handleCancelRequest}>
                        Cancel Request
                      </Button>
                      {!isDesktop &&
                      <>
                      <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={()=>setShowNavbar((prev)=>true)}/>		  
                      </>
                      }					  
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack direction="horizontal" gap="2">
                      <Button className="uuki-community-btn" onClick={handleJoin}>Join</Button>
                      {!isDesktop &&
                      <>
                      <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={()=>setShowNavbar((prev)=>true)}/>		  
                      </>
                      }					  
                    </Stack>
                  </>
                )}				
              </>
            )}
          </>
        ) : (
          <>
            <Stack direction="horizontal" gap="2">
              <Button className="uuki-community-btn" onClick={handleLoginClick}>Login</Button>
			  {isDesktop &&
			  <>
              <Button className="uuki-community-btn" onClick={handleSignupClick}>Signup</Button>
			  </>
			  }
		      {!isDesktop &&
              <>
               <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={()=>setShowNavbar((prev)=>true)}/>		  
              </>
              }			  
            </Stack>
          </>
        )}

        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
          show={showNavbar}
          onHide={()=>setShowNavbar((prev)=>false)}
        >
          <Offcanvas.Header closeButton>
            <Navbar.Brand className="community-name">
            {community.logo == "" || community.show_logo == false ? (
              <h4
                style={{ cursor: "pointer" }}
                onClick={() => navigate(currentHost)}
              >
              {community.name}
              </h4>
            ) : (
              <Image
                fluid
                style={{ cursor: "pointer" }}
                onClick={() => navigate(currentHost)}
                loading="lazy"
                className="brand-logo"
                src={community.logo}
              />
            )}
            </Navbar.Brand>			
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Stack className="h-100" gap={2}>
              <Nav variant="pills" className="flex-column text-nowrap">
                <Stack className="w-100 ">
                  {translate == true && (
                    <div id="google_translate_element"></div>
                  )}
                  {member.isOnboarded == 0 && member.isAdmin == true && (
                    <Nav.Item className="home-link ">
                      <Nav.Link
                        className="px-3 "
                        eventKey="welcomeMember"
                        className="main-links d-flex align-items-center"
                        onClick={() =>
                          {navigate(
                            "/c/" +
                              currentSlug +
                              "/welcomeMember?memberId=" +
                              member.id
                          );setShowNavbar((prev)=>false)}
                        }
                      >
                        <i className="fa-solid fa-circle-play fa-xl me-3"></i>
                        Getting Started
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item className="home-link ">
                    <Nav.Link
                      className="px-3 "
                      eventKey="feed"
                      onClick={() => {navigate(currentHost);setShowNavbar((prev)=>false)}}
                    >
                      <HomeIcon className="dashboard-icons nav-icon me-3" />
                      {currentLanguages[16][currentCommunityLanguage]}
                    </Nav.Link>
                  </Nav.Item>
				  {(memberList || currentRole!=0) &&
                  <Nav.Item className="home-link ">
                    <Nav.Link
                      className="px-3 "
                      eventKey="members"
                      onClick={() => {navigate("/c/" + currentSlug + "/members");setShowNavbar((prev)=>false)}}
                    >
                      <MembersIcon className="dashboard-icons nav-icon me-3" />
                      {currentLanguages[11][currentCommunityLanguage]}
                    </Nav.Link>
                  </Nav.Item>
				  }
                  {community.has_courses && 
                  <Nav.Item className="home-link ">
                    <Nav.Link
                      className="px-3 "
                      eventKey="courses"
                      onClick={() => {navigate("/c/" + currentSlug + "/courses");setShowNavbar((prev)=>false)}}
                    >
                        <CoursesIcon className="dashboard-icons nav-icon me-3" />
                      Courses
                    </Nav.Link>
                  </Nav.Item>}
                  <Nav.Item>
                    {spacesSet &&
                      spaces.map((space) => (
                        <> {community.collapse_spaces==1?<>
                        <Accordion className="mb-1" flush alwaysOpen defaultActiveKey={-1}>
                          <Accordion.Item key={space.id}>
                            <Accordion.Header className="space-group-name nav-header">
                              {space.name}
                            </Accordion.Header>
                            <Accordion.Body className="p-0  nav-body">
                              {space.topics != null
                                ? space.topics.map((topic) => (
                                    (topic.hidden==0 || member.isAdmin) && 
                                    <Nav.Item className="ps-4 home-link">
                                      <Nav.Link
                                        // style={{ fontSize: "1rem" }}
                                        className="px-4  space-name d-flex align-items-center"
                                        eventKey={"feed" + topic.slug}
                                        onClick={() =>
                                          navigate(
                                            "/c/" + currentSlug + "/" + topic.slug
                                          )
                                        }
                                      >
                                        {topic.topic_name}
                                      </Nav.Link>
                                    </Nav.Item>
                                  ))
                                : ""}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        </>:<>
                        <Accordion className="mb-1" flush alwaysOpen>
                          <Accordion.Item key={space.id}>
                            <Accordion.Header className="space-group-name nav-header">
                              {space.name}
                            </Accordion.Header>
                            <Accordion.Body className="p-0  nav-body">
                              {space.topics != null
                                ? space.topics.map((topic) => (
                                    (topic.hidden==0 || member.isAdmin) && 
                                    <Nav.Item className="ps-4 home-link">
                                      <Nav.Link
                                        // style={{ fontSize: "1rem" }}
                                        className="px-4  space-name d-flex align-items-center"
                                        eventKey={"feed" + topic.slug}
                                        onClick={() =>
                                          navigate(
                                            "/c/" + currentSlug + "/" + topic.slug
                                          )
                                        }
                                      >
                                        {topic.topic_name}
                                      </Nav.Link>
                                    </Nav.Item>
                                  ))
                                : ""}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        </>}</>
                      ))}
                  </Nav.Item>
                  {linksLocation == 0 && (
                    <Nav.Item>
                      <Accordion flush alwaysOpen>
                        <Accordion.Item>
                          <Accordion.Header className="nav-header">
                            Links
                          </Accordion.Header>
                          <Accordion.Body className="p-0 mb-3 nav-body">
                            {links != null
                              ? links.map((link) => (
                                  <Nav.Item className="ps-4 home-link">
                                    <Nav.Link
                                      // style={{ fontSize: "1rem" }}
                                      className="px-4 py-2 "
                                      href={link.link}
                                    >
                                      {link.linkName}
                                    </Nav.Link>
                                  </Nav.Item>
                                ))
                              : ""}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Nav.Item>
                  )}

                  {(memberInvite || currentRole!=0)?<Nav.Item className="home-link ">
                    <Nav.Link
                      className="px-3 "
                      onClick={()=>{setShowInviteMemberPopup(true);setShowNavbar((prev)=>false)}}
                    >
                      <ShareImage className="dashboard-icons me-3"   />
                      Invite Member
                    </Nav.Link>
                  </Nav.Item>:""}
                  <Nav.Item>
                    <Accordion flush alwaysOpen>
                      <Accordion.Item>
                        <Accordion.Header className="nav-header">
                          Settings
                        </Accordion.Header>
                        <Accordion.Body className="p-0 mb-3 nav-body">
                          {member.isAdmin && (
                            <>
                              <Nav.Item className="ps-4 home-link">
                                <Nav.Link
                                  // style={{ fontSize: "1rem" }}
                                  className="px-4 py-2 "
                                  onClick={() =>
                                    navigate("/settings/" + currentSlug)
                                  }
                                >
                                  <i className="fa-solid fa-sliders me-2"></i>{" "}
                                  {
                                    currentLanguages[3][
                                      currentCommunityLanguage
                                    ]
                                  }
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="ps-4 home-link">
                                <Nav.Link
                                  // style={{ fontSize: "1rem" }}
                                  className="px-4 py-2 "
                                  onClick={() =>
                                    {navigate("/c/" + currentSlug + "/analytics");setShowNavbar((prev)=>false)}
                                  }
                                >
                                  <i className="fa-solid fa-chart-line me-2"></i>{" "}
                                  {
                                    currentLanguages[2][
                                      currentCommunityLanguage
                                    ]
                                  }
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          )}
                          {(member.isAdmin || member.isMod) && (
                            <>
                              <Nav.Item className="ps-4 home-link">
                                <Nav.Link
                                  // style={{ fontSize: "1rem" }}
                                  className="px-4 py-2 "
                                  onClick={() =>
                                    navigate("/moderate/" + currentSlug)
                                  }
                                >
                                  <i className="fa-solid fa-eye me-2"></i>{" "}
                                  {
                                    currentLanguages[1][
                                      currentCommunityLanguage
                                    ]
                                  }
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="ps-4 home-link">
                                <Nav.Link
                                  // style={{ fontSize: "1rem" }}
                                  className="px-4 py-2 "
                                  onClick={() => {
                                    
                                    navigate("/invite_requests/" + currentSlug);
                                    setShowNavbar((prev)=>false)
                                  }}
                                >
                                  <i className="fa-solid fa-table-list me-2"></i>{" "}
                                  {
                                    currentLanguages[75][
                                      currentCommunityLanguage
                                    ]
                                  }
                                </Nav.Link>
                              </Nav.Item>
                            </>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Nav.Item>
                  {affiliate.show == 1 && (
                    <a
                      className="text-decoration-none"
                      href={affiliate.link}
                      target="_blank"
                    >
                      <small>
                        Powered by <b>UUKI</b>
                      </small>
                    </a>
                  )}
                  {/* <Nav.Item>
                      <Accordion flush alwaysOpen>
                        <Accordion.Item>
                          <Accordion.Header> 
                            {member.name}
                            {member.image !== "" && member.image !== undefined ? (
                              <Image
                                src={member.image}
                                className="mobile-profile-image me-1"
                                roundedCircle
                              ></Image>
                            ) : (
                              <Avatar
                                name={member.name}
                                size="28"
                                style={{ fontSize: "0.5rem" }}
                                className="me-1"
                                // className="mobile-profile-image"
                                round={true}
                              />
                            )}
                          </Accordion.Header>
                          <Accordion.Body className="p-0 mb-3">
                          <Nav.Item className="ps-4 home-link">
                            <Nav.Link
                              style={{ fontSize: "1rem" }}
                              className="px-4 py-2 "
                            >
                              {currentLanguages[4][currentCommunityLanguage]}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="ps-4 home-link">
                            <Nav.Link
                              style={{ fontSize: "1rem" }}
                              className="px-4 py-2 "
                              onClick={() => navigate("/")}
                            >
                              {currentLanguages[5][currentCommunityLanguage]}
                            </Nav.Link>
                          </Nav.Item>
                          <div className="content-border"></div>
                          <Nav.Item className="ps-4 home-link">
                            <Nav.Link
                              style={{ fontSize: "1rem" }}
                              className="px-4 py-2 "
                              onClick={logout}
                            >
                              {currentLanguages[6][currentCommunityLanguage]}
                            </Nav.Link>
                          </Nav.Item>
                        
         
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Nav.Item>
                   */}
                </Stack>
              </Nav>
            </Stack>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
      {isDesktop && (
        <Col
          className={!inView ? "position-fixed mt-5 py-4 px-0" : "mt-2 px-0 "}
          lg={isBigScreen ? 4 : 3}
        >
          <div className="ms-3 vh-100 mb-5  py-2 overflow-scroll home-left-nav nav-scroll">
            <Nav
              variant="pills"
              className=" scroll-nav fixed-nav flex-column text-nowrap pb-3 "
            >
              {translate == true && (
                <div className="py-2" id="google_translate_element"></div>
              )}
              {member.isOnboarded == 0 && member.isAdmin == true && (
                <Nav.Item className="home-link ">
                  <Nav.Link
                    className="px-3 "
                    eventKey="welcomeMember"
                    onClick={() =>
                      navigate(
                        "/c/" +
                          currentSlug +
                          "/welcomeMember?memberId=" +
                          member.id
                      )
                    }
                  >
                    <i className="fa-solid fa-circle-play fa-xl me-3"></i>
                    Getting Started
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item className="home-link ">
                <Nav.Link
                  className="px-3 "
                  eventKey="feed"
                  onClick={() => navigate(currentHost)}
                >
                  <HomeIcon className="dashboard-icons nav-icon me-3" />
                  {currentLanguages[16][currentCommunityLanguage]}
                </Nav.Link>
              </Nav.Item>
              {(memberList || currentRole!=0) &&			  
              <Nav.Item className="home-link">
                <Nav.Link
                  className="px-3 "
                  eventKey="members"
                  onClick={() => navigate("/c/" + currentSlug + "/members")}
                >
                  <MembersIcon className="dashboard-icons nav-icon me-3" />
                  {currentLanguages[11][currentCommunityLanguage]}
                </Nav.Link>
              </Nav.Item>
              }
              {community.has_courses && 
                  <Nav.Item className="home-link ">
                    <Nav.Link
                      className="px-3 "
                      eventKey="courses"
                      onClick={() => navigate("/c/" + currentSlug + "/courses")}
                    >
                        <CoursesIcon className="dashboard-icons nav-icon me-3" />
                      Courses
                    </Nav.Link>
                  </Nav.Item>}
              <Nav.Item className="w-100">
                {spacesSet &&
                  spaces.map((space) => (
                  <> {community.collapse_spaces==1?<>

                    <Accordion className="mb-1" flush alwaysOpen defaultActiveKey={-1}>
                      <Accordion.Item key={space.id}>
                        <Accordion.Header className="space-group-name nav-header">
                          {space.name}
                        </Accordion.Header>
                        <Accordion.Body className="p-0  nav-body">
                          {space.topics != null
                            ? space.topics.map((topic) => (
                                (topic.hidden==0 || member.isAdmin) && 
                                <Nav.Item className="ps-4 home-link">
                                  <Nav.Link
                                    // style={{ fontSize: "1rem" }}
                                    className="px-4  space-name d-flex align-items-center"
                                    eventKey={"feed" + topic.slug}
                                    onClick={() =>
                                      navigate(
                                        "/c/" + currentSlug + "/" + topic.slug
                                      )
                                    }
                                  >
                                    {topic.topic_name}
                                  </Nav.Link>
                                </Nav.Item>
                              ))
                            : ""}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>:<>
                  <Accordion className="mb-1" flush alwaysOpen>
                      <Accordion.Item key={space.id}>
                        <Accordion.Header className="space-group-name nav-header">
                          {space.name}
                        </Accordion.Header>
                        <Accordion.Body className="p-0  nav-body">
                          {space.topics != null
                            ? space.topics.map((topic) => (
                                (topic.hidden==0 || member.isAdmin) && 
                                <Nav.Item className="ps-4 home-link">
                                  <Nav.Link
                                    // style={{ fontSize: "1rem" }}
                                    className="px-4  space-name d-flex align-items-center"
                                    eventKey={"feed" + topic.slug}
                                    onClick={() =>
                                      navigate(
                                        "/c/" + currentSlug + "/" + topic.slug
                                      )
                                    }
                                  >
                                    {topic.topic_name}
                                  </Nav.Link>
                                </Nav.Item>
                              ))
                            : ""}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>}</>
                  ))}
              </Nav.Item>
              {linksLocation == 0 && (
                <Nav.Item>
                  <Accordion flush alwaysOpen>
                    <Accordion.Item>
                      <Accordion.Header className="nav-header">
                        Links
                      </Accordion.Header>
                      <Accordion.Body className="p-0 mb-3 nav-body">
                        {links != null
                          ? links.map((link) => (
                              <Nav.Item className="ps-4 home-link">
                                <Nav.Link
                                  // style={{ fontSize: "1rem" }}
                                  className="px-4 py-2 "
                                  href={link.link}
                                >
                                  {link.linkName}
                                </Nav.Link>
                              </Nav.Item>
                            ))
                          : ""}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Nav.Item>
              )}
              {(memberInvite || currentRole!=0)?<Nav.Item className="home-link ">
                    <Nav.Link
                      className="px-3 "
                      onClick={()=>{setShowInviteMemberPopup(true)}}
                    >
                      <ShareImage className="dashboard-icons me-3" />
                      Invite Member
                    </Nav.Link>
                  </Nav.Item>:""}
              
              <div style={{marginBottom:'100px'}}></div>
            </Nav>
          </div>
        </Col>
      )}

      <LoginModal
        show={isToShowLoginModal}
        onHide={() => dispatch(showLoginModal(false))}
      />
      {!isDesktop && (
        <>
          <Navbar className="nav-uuki nav-home p-0" fixed="bottom">
            <div className="d-flex w-100 justify-content-evenly">
              <Button
                onClick={() => navigate(currentHost)}
                className="bg-transparent border-0 px-4"
              >
                <MobileHome />
              </Button>
              <Button
                className="bg-transparent border-0 px-4"
                onClick={() => setShowSearch(true)}
              >
                <MobileSearch />
              </Button>
              <Button
                className="bg-transparent border-0 px-4"
                onClick={() => setKey("notificationDetail")}
              >
                <div className="postition-relative">
                  <MobileNotification />
                  <Badge
                    pill
                    className="notification-badge mobile-notification position-absolute top-0 mt-2 "
                  >
                    {counter.notification > 0 ? counter.notification : ""}
                  </Badge>
                </div>
              </Button>
              <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose
                overlay={popoverProfile}
              >
                <Button className="bg-transparent border-0 px-4">
                  {member.image !== "" && member.image !== undefined ? (
                    <Image
                      src={member.image}
                      className="mobile-profile-image"
                      roundedCircle
                      loading="lazy"
                    ></Image>
                  ) : (
                    <Avatar
                      name={member.name}
                      size="32"
                      style={{ fontSize: "0.5rem", cursor: "pointer" }}
                      // className="mobile-profile-image"
                      round={true}
                    />
                  )}
                </Button>
              </OverlayTrigger>
            </div>
          </Navbar>
          <Offcanvas
            className="vh-100"
            placement="bottom"
            show={showSearch}
            onHide={() => setShowSearch(false)}
          >
            <Offcanvas.Header closeButton>
              <InputGroup className="d-flex flex-nowrap  " ref={target}>
                <InputGroup.Text className="border-0 ">
                  <SearchIcon />
                </InputGroup.Text>

                <FormControl
                  placeholder="Search a post"
                  className="border-0 "
                  id="search-bar"
                  name="search-bar"
                  autoComplete="notrequired"
                  onInput={(e) => sendSearchRequest(e)}
                />
              </InputGroup>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ListGroup>
                {searchResult !== []
                  ? searchResult.map((result) => (
                      <ListGroup.Item
                        className="search-result border-0 border-bottom py-2"
                        onClick={() => {
                          navigate(
                            "/c/" + currentSlug + "/post/" + result.slug
                          );
                          setShowSearch(false);
                        }}
                      >
                        <Stack gap={3} direction="horizontal">
                          {result.memberImage !== "" &&
                          result.memberImage !== undefined ? (
                            <Image
                              src={result.memberImage}
                              className="mobile-profile-image"
                              roundedCircle
                              loading="lazy"
                            ></Image>
                          ) : (
                            <Avatar
                              name={result.memberName}
                              size="32"
                              style={{ fontSize: "0.5rem" }}
                              // className="mobile-profile-image"
                              round={true}
                            />
                          )}
                          <Stack>
                            <span style={{ fontWeight: "500" }}>
                              <>
					          {result.title.length>30?
                              (<span style={{ fontWeight: "500" }}>{result.title.slice(0, 30)}...</span>):
					          (<span style={{ fontWeight: "500" }}>{result.title}</span>)
					          }
					          </>
                            </span>
                            <small>
                              Posted in {result.posted_in} by{" "}
                              {result.memberName}
                            </small>
                          </Stack>
                        </Stack>
                      </ListGroup.Item>
                    ))
                  : ""}
              </ListGroup>
            </Offcanvas.Body>
          </Offcanvas>


        </>
      )}

      <Modal show={showInviteMemberPopup} onHide={() => setShowInviteMemberPopup(false)} centered>
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Invite Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span >Invite member through email</span>
            <Form.Control type="email" placeholder="name@example.com" className="mt-2" id='invite-member-email'/>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button className='uuki-btn uuki-community-btn' onClick={(e)=>{handleInviteMember(e)}} >
            Invite
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NavbarHome;
