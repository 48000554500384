import React, { useState } from "react";
import {
  Image,
  Form,
  Stack,
  Button,
  Badge,
  Popover,
  OverlayTrigger,
  ListGroup,
} from "react-bootstrap";
import searchImage from "../../../assets/search.svg";
import "../../../styles/members.css";
import { useSelector, useDispatch } from "react-redux";
import {
  searchName,
  searchBio,
  searchLoc,
  searchSpace,
  searchTag,
  searchAdminMod,
  reloadMembers,
} from "../../../redux/slice/membersSlice";


export default function MembersPageHeader({ privateSpaces, tags, count }) {
  const [searchFilter, setSearchFilter] = useState("1");
  const [selectedSpace, setSelectedSpace] = useState(0);
  const [selectedTag, setSelectedTag] = useState(0);

  const dispatch = useDispatch();
  const onFormSubmit = (e) => {
    e.preventDefault();
    // if (e.keyCode === 13) {
      const value = e.target.value;

      switch (searchFilter) {
        case "1":
          dispatch(searchName(value));
          dispatch(searchBio(""));
          dispatch(searchLoc(""));
          dispatch(searchSpace(""));
          dispatch(searchTag(""));
          dispatch(searchAdminMod(""));
          dispatch(reloadMembers(true));

          break;
        case "2":
          dispatch(searchName(""));
          dispatch(searchBio(value));
          dispatch(searchLoc(""));
          dispatch(searchSpace(""));
          dispatch(searchTag(""));
          dispatch(searchAdminMod(""));
          dispatch(reloadMembers(true));

          break;
        case "3":
          dispatch(searchName(""));
          dispatch(searchBio(""));
          dispatch(searchLoc(value));
          dispatch(searchSpace(""));
          dispatch(searchTag(""));
          dispatch(searchAdminMod(""));
          dispatch(reloadMembers(true));

          break;

        default:
          console.log("inside default");
          dispatch(searchName(value));
          dispatch(searchBio(""));
          dispatch(searchLoc(""));
          dispatch(searchSpace(""));
          dispatch(searchTag(""));
          dispatch(searchAdminMod(""));
          dispatch(reloadMembers(true));
      }
    // }
  };

  const onSearchFilterChange = (e) => {
    console.log(e);
    setSearchFilter(e.target.value);
  };

  const onSpaceSelected = (id) => {
    dispatch(searchSpace(id));
    dispatch(reloadMembers(true));
    document.body.click()
  };
  const onTagSelected = (id) => {
    dispatch(searchTag(id));
    dispatch(searchAdminMod(id));
    dispatch(reloadMembers(true));
    document.body.click()
  };
  const popoverSpaceSort = (
    <Popover className="navbar-popover mt-3">
      <Popover.Body className="px-3 py-2">
        <ListGroup as="ol">
          {privateSpaces.length > 0 &&
            privateSpaces.map((space) => (
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => onSpaceSelected(space.id)}
              >
                {space.name}
              </ListGroup.Item>
            ))}
        </ListGroup>
      </Popover.Body>
    </Popover>
  );
  const popoverTagSort = (
    <Popover className="navbar-popover mt-3">
      <Popover.Body className="px-3 py-2">
        <ListGroup as="ol">
          {tags.length > 0 &&
            tags.map((tag) => (
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => onTagSelected(tag.id)}
              >
                {tag.name}
              </ListGroup.Item>
            ))}
        </ListGroup>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <div className="members-search-container-bg uuki-box">
        <Stack className="px-4 py-3" direction="horizontal" gap={2}>
          <span className="community-pages-header">Members</span>
          <Badge bg="light" text="dark">
            {count}
          </Badge>
        </Stack>
        <hr className="post-footer-divider m-0"></hr>

        <Stack gap={3} direction="horizontal" className="px-4 py-3">
          <Image src={searchImage} loading="lazy"></Image>
          <Form.Control
            placeholder="Search"
            className="members-search-input"
            onKeyUp={onFormSubmit}
          />
          <Form.Select
            aria-label="Search"
            className="member-filter-dropdown border-0"
            onChange={onSearchFilterChange}
          >
            <option value="1">Search by name</option>
            <option value="2">Search by bio</option>
            <option value="3">Search by location</option>
          </Form.Select>
        </Stack>
      </div>
      <Stack className="my-4 pe-4" direction="horizontal" gap={3}>
        <div className=" ms-auto d-flex align-items-center flex-nowrap ms-1">
          <span>Sort by Space</span>

          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose
            overlay={popoverSpaceSort}
          >
            <i className="fas fa-sort ms-2"></i>
          </OverlayTrigger>
        </div>
        <div className="d-flex align-items-center flex-nowrap ms-1">
          <span>Sort by Tag</span>

          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose
            overlay={popoverTagSort}
          >
            <i className="fas fa-sort ms-2"></i>
          </OverlayTrigger>
        </div>
      </Stack>
    </>
  );
}
