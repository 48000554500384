import React from "react";
import { Container, Stack } from "react-bootstrap";
import TrendingPostItems from "./TrendingPostItems";
import { useSelector } from "react-redux";

export default function TrendingPosts({ trendingPostData }) {
  const currentLanguages = useSelector(
    (state) => state.languagesReducer.languages
  );
  const currentCommunityLanguage = useSelector(
    (state) => state.communityReducer.communityLanguage
  );
  return (
    <Stack gap={2} className="right-nav-block uuki-box p-3 ">
      <span className="right-block-title mb-1">
        {currentLanguages[69][currentCommunityLanguage]}
      </span>

      {trendingPostData != null
        ? trendingPostData.map((trending_posts) => (
            <TrendingPostItems
              data={trending_posts}
              key={trending_posts.id}
            ></TrendingPostItems>
          ))
        : ""}
    </Stack>
  );
}
