import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Row,
  Col,
  Tab,
  Stack,
  Image,
  Button,
  Overlay,
  Popover,
} from "react-bootstrap";
import NavbarChat from "../components/chat/NavbarChat";
import "../styles/chat.css";
import io from "socket.io-client";
import { useMediaQuery } from "react-responsive";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { communityDetails } from "../redux/slice/communitySlice";
import ChatMessages from "../components/chat/ChatMessages";
import { toast } from "react-toastify";

function Chat() {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1600px)" });
  const [key, setKey] = useState("community");
  const [data, setData] = useState(null);
  const [replyTo, setReplyTo] = useState({id:0,name:''});
  const [sending, setSending] = useState(false)
  const navigate= useNavigate()
  const { communitySlug } = useParams();
  const dispatch = useDispatch();
  const socket = io("https://dev.uuki.live");
  // const socket = io("http://localhost:5000");
  const keyRef = useRef();
  keyRef.current = key
  const [isConnected, setIsConnected] = useState(false);
  const [outline, setOutline] = useState("outline-dark");  
  const currentUserId = useSelector((state) => state.userReducer.userId);
  const currentName = useSelector(
    (state) => state.communityReducer.communityName
  );
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  const [showDelete, setShowDelete] = useState({
    id: 0,
    show: false,
    canBlock: false,
    canDelete: false,
    name:'',
    isSelf: true,
  });
  const [target, setTarget] = useState(null);
  const chatRefs = useRef([]);
  const replyToRef = useRef();
  replyToRef.current = replyTo
  useEffect(() => {
    axios
      .get("/v2/get_chat_info?commSlug=" + communitySlug)
      .then(function (response) {
        var newHost = "";
          if (currentHost == "/") {
            newHost = currentHost;
          } else {
            newHost = "/c/" + response.data.communitySlug;
          }
		  if(response.data.theme == "dark") {
			  setOutline("outline-light");
		  }
          dispatch(
            communityDetails({
              id: response.data.communityId,
              name: response.data.communityName,
              slug: response.data.communitySlug,
              host: newHost,
            })
            );
        if (response.data.enable_chat==1){

          socket.emit("joined", {
            room: response.data.communityId,
            user_id: response.data.userId,
          });
          setData(response.data);
          
          }else{
            navigate(newHost)
          }
        // socket.on('disconnect', () => {
        //         console.log("Left Room")
        //         });
      })
      .catch(function (error) {
        console.log(error);
      });
    // socket.on('status', function(data) {
    //     console.log(data.msg);
    //     console.log(socket.connected)
    // });

    socket.on("message", newCommunityMsg);
    socket.on("private_message", newPrivateMsg);
    // socket.on('message_deleted',deletedMsg)
    return () => {
      // socket.off('connect');
      // socket.off('disconnect');
      // socket.off('status');
      // socket.off('chat_opened');

      socket.off("message");
      socket.off("private_message");
    };
  }, []);

  socket.on("connect", () => {
    setIsConnected(socket.connected);
  });
  const newCommunityMsg = (chatData) => {
    chatRefs.current[0].setNewMessage(
      chatData.id,
      chatData.msg,
      chatData.name,
      chatData.profile_picture,
      chatData.type,
      chatData.replyTo
    );
    scrollToBottom("messages-0");
    setSending(false)
  };
  const newPrivateMsg = (chatData) => {
    if (
      chatData.type == currentUserId ||
      chatData.receiver_id == currentUserId
    ) {
      if (chatData.type == currentUserId) {
        chatRefs.current[chatData.receiver_id].setNewMessage(
          chatData.id,
          chatData.msg,
          chatData.name,
          "",
          chatData.type,
          chatData.replyTo
        );
      } else {
        chatRefs.current[chatData.type].setNewMessage(
          chatData.id,
          chatData.msg,
          chatData.name,
          "",
          chatData.type,
          chatData.replyTo
        );
      }
    }
    scrollToBottom("messages-" + chatData.receiver_id);
    setSending(false)
  };
  const scrollToBottom = (id) => {
    const element = document.querySelector("#" + id);
    element.scrollTop = element.scrollHeight;
  };
  const sendCommunityMessage = (msg) => {
    if(msg == "") {
        return;		
    }	  
    setSending(true)
    if (isConnected == true) {
      socket.emit("text", {
        msg: msg,
        type: data.userId,
        community_id: data.communityId,
        replyTo:replyToRef.current.id
      });
    } else {
      axios
        .get("/v2/send_chat", {
          params: {
            commId: data.communityId,
            msg: msg,
            receiver: keyRef.current,
            replyTo:replyToRef.current.id
          },
        })
        .then(function (response) {
          newCommunityMsg(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    setReplyTo({id:0,name:''})
  };
  const sendPrivateMessage = (msg) => {
    if(msg == "") {
        return;		
    }
    
    setSending(true)
    if (isConnected == true) {
      socket.emit("private_text", {
        msg: msg,
        sender_id: data.userId,
        receiver_id: keyRef.current,
        community_id: data.communityId,
        replyTo:replyToRef.current.id
      });
    } else {
      axios
        .get("/v2/send_chat", {
          params: {
            commId: data.communityId,
            msg: msg,
            receiver: keyRef.current,
            replyTo:replyToRef.current.id
          },
        })
        .then(function (response) {
          newPrivateMsg(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    setReplyTo({id:0,name:''})
  };
  const checkDelete = (userId, id,name, event) => {
    if (showDelete.id == id) {
      setShowDelete({ id: 0, show: false, canDelete: false, canBlock: false,name:'', isSelf: true });
    } else {
      if (userId == currentUserId) {
        setShowDelete({ id: id, show: true, canDelete: true,canBlock:false,name:name, isSelf: true });
        setTarget(event.currentTarget);
      } else{
          if (data.isAdminOrMod == true) {
            setShowDelete({ id: id, show: true, canDelete: true,canBlock:true,name:name, isSelf: false });
          }else{
            setShowDelete({ id: id, show: true, canDelete: false,canBlock:false,name:name, isSelf: false });
          }
        setTarget(event.currentTarget);
      }
    }
  };
  const blockUser = () => {
    setShowDelete({...showDelete,show:false})
    axios
      .get("/v2/block_chat_user", {
        params: {
          id: showDelete.id,
        },
      })
      .then(function (response) {
       toast.success("User Blocked")
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const deleteMessage = () => {
    setShowDelete({...showDelete,show:false})
    axios
      .get("/v2/delete_chat", {
        params: {
          id: showDelete.id,
        },
      })
      .then(function (response) {
        document.getElementById(target.id).innerHTML =
          "<small><i>message deleted<i/></small>";
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  var privateMessages = null;
  document.title = "Chat";
  const setReply = () => {
    let current = document.querySelector('.comment-input.chat-input');
    current.focus()
    setReplyTo({id:showDelete.id,name:showDelete.name});
    setShowDelete({...showDelete,show:false})
  }
 
  return (
    <Tab.Container
      id="dashboard-nav"
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
      {data != null && (
        <Row className="main-row  g-0">
          <NavbarChat
            setKey={setKey}
            community={{ name: data.communityName, slug: data.communitySlug, logo: data.communityLogo, showLogo: data.show_logo }}
            member={{
              name: data.memberName,
              image: data.memberImage,
              id: data.memberId,
              canChat:data.memberCanChat
            }}
            chatWith={data.chatWith}
            chatStatus={data.chatStatus}
          />
          <Col
            className="mt-0 mt-md-5 py-0 py-md-4 ps-0 overflow-hidden"
            md={isBigScreen ? { span: 7, offset: 4 } : { span: 8, offset: 3 }}
          >
            <Overlay
              show={showDelete.show}
              target={target}
              placement={showDelete.isSelf ? "left" : "right"}
            >
              <Popover
                className={
                  showDelete.isSelf
                    ? "border-0 bg-transparent me-4"
                    : "border-0 bg-transparent ms-4"
                }
                id="popover-contained"
              >
                <Popover.Body className="p-0">
                  <Stack direction="horizontal" gap={3}>
                  <Button
                      variant={outline}
                      onClick={setReply}
                      className="rounded-circle px-2 py-1"
                    >
                      <i class="fas fa-reply"></i>
                    </Button>
                    {showDelete.canDelete == true &&
                    <Button
                      variant={outline}
                      onClick={deleteMessage}
                      className="rounded-circle px-2 py-1"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </Button>
                      }
                    {showDelete.canBlock == true &&  
                    <Button
                      variant="outline-danger"
                      onClick={blockUser}
                      className="rounded-circle px-2 py-1"
                    >
                      <i className="fa-solid fa-user-lock"></i>
                    </Button>}
                  </Stack>
                </Popover.Body>
              </Popover>
            </Overlay>
            <Tab.Content
              className="chat-content mt-5  center-container-dashboard uuki-box my-md-2"
            >
              <Tab.Pane className="h-100" eventKey="community">
                <ChatMessages
                  ref={(element) => {
                    chatRefs.current[0] = element;
                  }}
                  receiver={0}
                  commId={data.communityId}
                  receiverName={currentName}
                  receiverImage=""
                  checkDelete={checkDelete}
                  sendMessage={sendCommunityMessage}
                  sending={sending}
                  replyTo={replyTo}
                  setReplyTo={setReplyTo}
                />
              </Tab.Pane>
              {data.chatWith.map((member) => (
                <Tab.Pane className="h-100" eventKey={member.userId}>
                  <ChatMessages
                    ref={(element) => {
                      chatRefs.current[member.userId] = element;
                    }}
                    receiver={member.userId}
                    commId={data.communityId}
                    receiverName={member.memberName}
                    receiverImage={member.memberImage}
                    checkDelete={checkDelete}
                    sendMessage={sendPrivateMessage}
                    sending={sending}
                    replyTo={replyTo}
                    setReplyTo={setReplyTo}
                  />
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      )}
    </Tab.Container>
  );
}

export default Chat;
