import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Image, Stack, Button, Form, ProgressBar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import Avatar from 'react-avatar';
import '../../styles/moderatordashboard.css';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export default function ReportedComments({ slug }) {
	const [data, setData] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		axios
			.get('/v2/moderate/' + slug, { params: { type: 3 } })
			.then((response) => {
				setData(response.data.reportedComments);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteComment = (data) => {
		var type = 'Reported comment deleted';
		axios
			.post('/delete_communitycomment', {
				commentId: data.id,
				type: type,
				userId: data.user_id,
				communityId: data.communityId,
			})
			.then((response) => {
				toast.success('Comment deleted successfully');
				getData();
			})
			.error((err) => {
				console.log(err);
			});
	};

	const ignoreComment = (data) => {
		var type = 'Reported comment ignored';
		axios
			.post('/ignore_communitycomment', {
				commentId: data.id,
				type: type,
				userId: data.user_id,
				communityId: data.communityId,
			})
			.then((response) => {
				toast.success('Comment ignored successfully');
				getData();
			})
			.error((err) => {
				console.log(err);
			});
	};
	return (
		<>
			{data.length > 0 ? (
				data.map((comment) => (
					<div className='uuki-box post px-4 mb-3 pt-3'>
						<Stack gap={3} direction='horizontal'>
							{comment.user.image_url != '' &&
							comment.user.image_url != 'noImage' ? (
								<Image
									src={comment.user.image_url}
									className={'post-author-image'}
									roundedCircle
									style={{ cursor: 'pointer' }}
									onClick={() =>
										navigate(
											'/c/' + slug + '/member?memberId=' + comment.user_id
										)
									}></Image>
							) : (
								<Avatar
									name={comment.user.name}
									style={{ cursor: 'pointer' }}
									size={'48'}
									textSizeRatio={2.5}
									round={true}
									onClick={() =>
										navigate(
											'/c/' + slug + '/member?memberId=' + comment.user_id
										)
									}
								/>
							)}
							<Stack gap={1}>
								{
									<Stack direction='horizontal' gap={2}>
										<span
											className='post-author'
											onClick={() =>
												navigate(
													'/c/' + slug + '/member?memberId=' + comment.user_id
												)
											}
											style={{ cursor: 'pointer' }}>
											{comment.user.name}
										</span>
										{comment.user.is_admin && (
											<div className='leaderboard-rank'>Admin</div>
										)}
										{comment.user.is_mod && (
											<div className='leaderboard-rank'>Moderator</div>
										)}

										<ReactTimeAgo
											date={comment.date}
											locale='en-US'
											timeStyle='mini'
											style={{ opacity: '0.7', fontSize: '0.875rem' }}
										/>

										<div className='d-flex justify-content-end container-delete-approve'>
											<div
												className='me-4'
												style={{
													cursor: 'pointer',
													fontSize: '17px ',
													color: 'green',
												}}
												onClick={(e) => deleteComment(comment)}>
												<i class='fas fa-trash-alt'></i>
											</div>
											<div
												className='me-3'
												style={{
													cursor: 'pointer',
													fontSize: '20px',
													color: 'red',
												}}
												onClick={(e) => ignoreComment(comment)}>
												<i class='fas fa-times'></i>
											</div>
										</div>
									</Stack>
								}
							</Stack>
						</Stack>

						<div className='post-content p-4'>
							<div dangerouslySetInnerHTML={{ __html: comment.comment }} />
						</div>
					</div>
				))
			) : (
				<h4>No reported comment</h4>
			)}
		</>
	);
}
