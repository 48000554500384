import { createSlice } from "@reduxjs/toolkit";

const state = () => {
  const initialState = {
    topicId: 0,
    topicSlug: "",
    topicName: "",
    topicForEvents: false,
    topicForCourses: false,
    topicRequested: null,
    communityId: 0,
    communitySlug: "",
    communityName: "",
    communityHost: "",
	allow_anonymous: true,	
    privacyPolicy: "",
    tos: "",	
	theme: "light",
	deviceId: "",
	cookieUrl: "",
	members: [],
    enable_addon: false,
    default_load_topic: 0,
  };

  return initialState;
};

export const communitySlice = createSlice({
  name: "communitySlice",
  initialState: state,
  reducers: {
    topicDetails: (state, action) => {
      state.topicId = action.payload.id;
      state.topicSlug = action.payload.slug;
      state.topicForEvents = action.payload.forEvents;
      state.topicForCourses = action.payload.forCourses;
      state.topicName = action.payload.name;
      state.topicRequested = action.payload.isRequested;
    },
    communityDetails: (state, action) => {
      state.communityId = action.payload.id;
      state.communitySlug = action.payload.slug;
      state.communityName = action.payload.name;
      state.communityHost = action.payload.host;
      state.communityLanguage = action.payload.language;
      state.allow_anonymous = action.payload.allow_anonymous;
      state.privacyPolicy = action.payload.privacyPolicy;
      state.tos = action.payload.tos;	  
      state.theme = action.payload.theme;
      state.default_load_topic = action.payload.default_load_topic;	  
     
    },
    deviceDetails: (state, action) => {
      state.deviceId = action.payload;
    },
    cookieDetails: (state, action) => {
      state.cookieUrl = action.payload.cookieUrl; 	  
    },
    memberDetails: (state, action) => {
      state.members = action.payload; 	  
    },
    addonDetails: (state, action) => {
      state.enable_addon = action.payload; 	  
    },
  },
});

// Action creators are generated for each case reducer function
export const { topicDetails, communityDetails, deviceDetails, cookieDetails, memberDetails, addonDetails } = communitySlice.actions;

export default communitySlice.reducer;
