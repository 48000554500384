import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import {
  Container,
  Col,
  Nav,
  Navbar,
  Offcanvas,
  Image,
  Stack,
  Button,
  Popover,
  OverlayTrigger,
  Badge,
  ListGroup,
} from "react-bootstrap";
import Avatar from "react-avatar";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import CommonNavbar from "../CommonNavbar";

function NavbarChat({ setKey, community, member, chatWith, chatStatus }) {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  const isBigScreen = useMediaQuery({ query: "(min-width: 1600px)" });
  let [searchParams, setSearchParams] = useSearchParams();
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    if (searchParams.get("newId") != null) {
      let isPresent = chatWith.find(
        (el) => el.userId === searchParams.get("newId")
      );
      if (isPresent == undefined) {
        chatWith.unshift({
          memberImage: searchParams.get("newImage"),
          memberName: searchParams.get("newName"),
          userId: searchParams.get("newId"),
        });
        chatStatus.unshift({
          isSeen: true,
          lastDate: new Date(),
          lastMessage: "",
          userId: searchParams.get("newId"),
        });
        setKey(searchParams.get("newId"));
      } else {
        setKey(searchParams.get("newId"));
      }
    }
  }, [])
  
  
  const logout = (e) => {
    e.preventDefault();
    axios.get("/logout?noRedirect=1").then((res) => navigate("/login"));
  };
  useEffect(() => {
    document.querySelectorAll(".dashboard-icon").forEach((element) => {
      if (element.parentElement.parentElement.classList.contains("active")) {
        element.setAttribute("filter", "url(#white-svg)");
      } else {
        element.setAttribute("filter", "url(#black-svg)");
      }
    });
  });

  var status;

  return (
    <>
      <Navbar fixed="top" className="nav-uuki nav-home" expand={false}>
        <Navbar.Brand className="community-name" href={currentHost}>
        {community.showLogo==false || community.logo == "" ? (
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate(currentHost)}
            >
              {community.name}
            </h4>
          ) : (
            <Image
            fluid
              style={{ cursor: "pointer" }}
              onClick={() => navigate(currentHost)}
              loading="lazy"
              className="brand-logo"
              src={community.logo}
            />
          )}
        </Navbar.Brand>
        {isDesktop ? (
          <CommonNavbar />
        ) : (
          <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={()=>setShowNavbar((prev)=>true)}/>
        )}
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
          show={showNavbar}
          onHide={()=>setShowNavbar((prev)=>false)}
        >
          <Offcanvas.Header closeButton>
            <Navbar.Brand className="community-name">
            {community.logo == "" || community.show_logo == false ? (
              <h4
                style={{ cursor: "pointer" }}
                onClick={() => navigate(currentHost)}
              >
              {community.name}
              </h4>
            ) : (
              <Image
                fluid
                style={{ cursor: "pointer" }}
                onClick={() => navigate(currentHost)}
                loading="lazy"
                className="brand-logo"
                src={community.logo}
              />
            )}
            </Navbar.Brand>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Stack className="h-100" gap={2}>
              <Nav variant="pills" className="flex-column text-nowrap">
                <Stack gap={3}>
                  {member.canChat == 1 && 
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="main-links d-flex align-items-center border-0 px-3"
                      eventKey="community"
                      onClick={() => {setKey("community");setShowNavbar((prev)=>false)}}
                    >
                      Community Chat
                    </Nav.Link>
                  </Nav.Item>}
                  <span className="my-3 ms-3 fw-bold">Members</span>
                  {chatWith != undefined &&
                    chatWith.map((member) => (
                      <Nav.Item key={member.userId} className="home-link">
                        <Nav.Link
                          className=" d-flex align-items-center border-0 px-3"
                          eventKey={member.userId}
                          onClick={() => {setKey(member.userId);setShowNavbar((prev)=>false)}}
                        >
                          <Stack className="w-100" direction="horizontal">
                            {member.memberImage !== "" &&
                            member.memberImage !== undefined ? (
                              <Image
                                src={member.memberImage}
                                className="mobile-profile-image me-2"
                                roundedCircle
                                loading="lazy"
                              ></Image>
                            ) : (
                              <Avatar
                                name={member.name}
                                size="32"
                                className="me-2"
                                style={{ fontSize: "0.5rem" }}
                                // className="mobile-profile-image"
                                round={true}
                              />
                            )}
                            {
                              ((status = chatStatus.filter(
                                (status) => status.userId == member.userId
                              )[0]),
                              (
                                <Stack>
                                  <Stack direction="horizontal">
                                    <span>{member.memberName}</span>
                                    <small className="ms-auto">
                                      {new Date(
                                        status.lastDate
                                      ).toLocaleDateString("en-us", {
                                        month: "short",
                                        day: "2-digit",
                                        year: "numeric",
                                      })}
                                    </small>
                                  </Stack>
                                  <Stack direction="horizontal">
                                    <small
                                      dangerouslySetInnerHTML={{
                                        __html: status.lastMessage,
                                      }}
                                    ></small>
                                    {!status.isSeen && (
                                      <Badge className="ms-auto rounded-circle">
                                        1
                                      </Badge>
                                    )}
                                  </Stack>
                                </Stack>
                              ))
                            }
                          </Stack>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                </Stack>
              </Nav>
            </Stack>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
      {isDesktop && (
        <Col className="position-fixed mt-5 py-4 px-0" md={isBigScreen ? 4 : 3}>
          <div
            style={{ height: "87vh" }}
            className="ms-3 mb-5   pt-2 overflow-scroll home-left-nav nav-scroll"
          >
            <Nav
              variant="pills"
              className=" scroll-nav uuki-box chat-list fixed-nav flex-column flex-nowrap text-nowrap pb-3 h-100"
            >
              <div className="home-link main-links d-flex align-items-center ">
                <Button
                  onClick={() => navigate(-1)}
                  className=" border-0 bg-transparent back-btn"
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </Button>
                <h6 className=" m-0 ms-5 ps-4">Chats</h6>
              </div>
              {member.canChat ==1 && 
              <Nav.Item className="home-link">
                <Nav.Link
                  className="main-links d-flex align-items-center border-0"
                  eventKey="community"
                  onClick={() => setKey("community")}
                >
                  Community Chat
                </Nav.Link>
              </Nav.Item>}
              <span className="my-3 ms-3 fw-bold">Members</span>
              {chatWith != undefined &&
                chatWith.map((member) => (
                  <>
                    <Nav.Item key={member.userId} className="home-link">
                      <Nav.Link
                        className=" d-flex align-items-center border-0"
                        eventKey={member.userId}
                        onClick={() => setKey(member.userId)}
                      >
                        <Stack className="w-100" direction="horizontal">
                          {member.memberImage !== "" &&
                          member.memberImage !== undefined ? (
                            <Image
                              src={member.memberImage}
                              className="mobile-profile-image me-2"
                              roundedCircle
                              loading="lazy"
                            ></Image>
                          ) : (
                            <Avatar
                              name={member.name}
                              size="32"
                              className="me-2"
                              style={{ fontSize: "0.5rem" }}
                              // className="mobile-profile-image"
                              round={true}
                            />
                          )}
                          {
                            ((status = chatStatus.filter(
                              (status) => status.userId == member.userId
                            )[0]),
                            (
                              <Stack>
                                <Stack direction="horizontal">
                                  <span>{member.memberName}</span>
                                  <small className="ms-auto">
                                    {new Date(
                                      status.lastDate
                                    ).toLocaleDateString("en-us", {
                                      month: "short",
                                      day: "2-digit",
                                      year: "numeric",
                                    })}
                                  </small>
                                </Stack>
                                <Stack direction="horizontal">
                                  <small
                                    className="text-truncate"
                                    style={{ maxWidth: "7rem" }}
                                    dangerouslySetInnerHTML={{
                                      __html: status.lastMessage,
                                    }}
                                  ></small>
                                  ...
                                  {!status.isSeen && (
                                    <Badge className="ms-auto rounded-circle">
                                      1
                                    </Badge>
                                  )}
                                </Stack>
                              </Stack>
                            ))
                          }
                        </Stack>
                      </Nav.Link>
                    </Nav.Item>
                  </>
                ))}
            </Nav>
          </div>
        </Col>
      )}
    </>
  );
}

export default NavbarChat;
