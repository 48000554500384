import React from 'react'
import { createReactEditorJS } from 'react-editor-js'
import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import List from '@editorjs/list'
import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
import Image from '@editorjs/image'
import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'
import AttachesTool from '@editorjs/attaches'
import {useRef,useCallback} from 'react'
import { useSelector } from "react-redux";
import {toast} from 'react-toastify'
import axios from "axios";


export default function MemberEditorComponent({editorCore,content,isDisabled}) {
    const ReactEditorJS = createReactEditorJS();
    const communityId = useSelector(
      (state) => state.communityReducer.communityId
    );
    const uploadImage = (file) => {
      return new Promise(function (resolve, reject) {
        axios
        .get("/v2/get_profile_description_image_url", {
          params: {
            commId: communityId,
            filename: file.name,
          },
        })
        .then((res) => {
          res = res.data;
          let url = res.url;
          let fields = res.fields;
          let formData = new FormData();
          for (let key in fields) {
            formData.append(key, fields[key]);
          }
          formData.append("file", file);
          const xhr = new XMLHttpRequest();
          xhr.addEventListener("loadend", uploadComplete, false);
          function uploadComplete(event) {
            resolve("https://communitydata.uuki.live/" + fields["key"]);
          }
          xhr.onerror = reject;
          xhr.open("POST", url, true);
          xhr.send(formData);
          toast.success("Image Uploaded");
        })
        .catch(function (error) {
          toast.error("Image Upload Failed");
        });
      });
    };
    const uploadImageByUrl = (url) => {
      return new Promise(function (resolve, reject) {
        resolve(url);
      });
    };	
    const EDITOR_JS_TOOLS = {
        embed: Embed,
        // table: Table,
        // marker: Marker,
        list: List,
        // warning: Warning,
        // code: Code,
        linkTool: {class:LinkTool,config:{endpoint: '/accept_url'}},
        image:{class:Image,config: {
          uploader: {
            uploadByFile(file){ 	
              return uploadImage(file).then((url) => {
                return {
                  success: 1,
                  file: {
                    url: url,
                  }
                };
              });
            },
            uploadByUrl(url){
              return uploadImageByUrl(url).then((url) => {
                return {
                  success: 1,
                  file: {
                    url: url,
                  }
                };
              });
            }
          }
        }},
        raw: Raw,
        header: Header, 
        quote: Quote,
        checklist: CheckList,
        // delimiter: Delimiter,
        // inlineCode: InlineCode,
        simpleImage: SimpleImage,
        
      }
      const handleInitialize = useCallback((instance) => {
        editorCore.current = instance
      }, [])
  return (
    <ReactEditorJS defaultValue={content}  onInitialize={handleInitialize} tools={EDITOR_JS_TOOLS} readOnly={isDisabled}/>
  )
}
