import React from "react";
import CommentBody from "./CommentBody";
import CommentFooter from "./CommentFooter";
import CommentHeader from "./CommentHeader";

export default function CommentItem({ commentData, communityId, postId }) {
  return (
    <>
      <div
        className="comment-body-container p-3  mb-2"
        id={`comment-` + commentData.id}
      >
        <CommentHeader data={commentData} communityId={communityId} />
        <CommentBody data={commentData} postId={postId} />
      </div>
      <CommentFooter data={commentData} postId={postId}></CommentFooter>
    </>
  );
}
