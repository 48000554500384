import {useState,useEffect,useRef} from 'react'
import EditorJS from '../EditorComponents'
import { default as edjsParser } from "editorjs-parser";
import {Stack,Form,Button} from 'react-bootstrap'
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

function ComposeEmail({emailContent,setNewEmailContent,adminCodes}) {
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [dataSet, setDataSet] = useState(false);  
    const [titleInvalid, setTitleInvalid] = useState(false);
    const editorCore = useRef(null);
    var parser = new edjsParser(undefined);
    const currentCommunityId = useSelector(
        (state) => state.communityReducer.communityId
      );
    const currentTopicId = useSelector((state) => state.communityReducer.topicId);
    
    const uploadImage = (file) => {
        return new Promise(function (resolve, reject) {
            axios
            .get("/v2/get_community_image_url", {
                params: {
                    topicId: currentTopicId,
                    commId: currentCommunityId,
                    filename: file.name,
                },
            })
            .then((res) => {
              res = res.data;
              let url = res.url;
              let fields = res.fields;
              let formData = new FormData();
              for (let key in fields) {
                  formData.append(key, fields[key]);
                }
                formData.append("file", file);
                const xhr = new XMLHttpRequest();
                xhr.addEventListener("loadend", uploadComplete, false);
                function uploadComplete(event) {
                    resolve("https://communitydata.uuki.live/" + fields["key"]);
                }
                xhr.onerror = reject;
                xhr.open("POST", url, true);
                xhr.send(formData);
                toast.success("Image Uploaded");
            })
            .catch(function (error) {
                toast.error("Image Upload Failed");
            });
        });
      };
      const uploadImageByUrl = (url) => {
          return new Promise(function (resolve, reject) {
              resolve(url);
            });
      };
      const customParsers = {
          linkTool: function (data, config) {
              // return `<a href="${data.link}" ></a>`;
              return `<div class="card post-card" >
              <a href="${data.link}">
              <img src="${data.meta.image.url}" class="card-img-top" alt="...">
              <div class="card-body">
              <h5 class="card-title text-dark">${data.meta.title}</h5>
              <p class="card-text text-dark">${data.meta.description}</p>
              </div>
              </a>
              </div>`;
            },
            attaches: function (data, config) {
                var filesize = parseFloat(parseInt(data.file.size) / 1024).toFixed(1);
                return `<div class="cdx-attaches cdx-attaches--with-file">
                <div class="cdx-attaches__file-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40">
                <g fill="#A8ACB8" fill-rule="evenodd">
                <path fill-rule="nonzero" d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"></path>
                <path d="M7 22h18v2H7zm0 4h18v2H7zm0 4h18v2H7z"></path>
                </g></svg></div>
                <div class="cdx-attaches__file-info">
                <div class="cdx-attaches__title" contenteditable="false">${data.title}</div>
                <div class="cdx-attaches__size" data-size="KiB">${filesize}</div></div>
                <a class="cdx-attaches__download-button" href="${data.file.url}" target="_blank" rel="nofollow noindex noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="17pt" height="17pt" viewBox="0 0 17 17"><path d="M9.457 8.945V2.848A.959.959 0 0 0 8.5 1.89a.959.959 0 0 0-.957.957v6.097L4.488 5.891a.952.952 0 0 0-1.351 0 .952.952 0 0 0 0 1.351l4.687 4.688a.955.955 0 0 0 1.352 0l4.687-4.688a.952.952 0 0 0 0-1.351.952.952 0 0 0-1.351 0zM3.59 14.937h9.82a.953.953 0 0 0 .953-.957.952.952 0 0 0-.953-.953H3.59a.952.952 0 0 0-.953.953c0 .532.425.957.953.957zm0 0" fill-rule="evenodd"></path></svg></a></div>`;
            },
        };
        
        parser = new edjsParser(undefined, customParsers);
        
        const saveEmail = async() => {
            const savedData = await editorCore.current.save();
            const markup = parser.parse(savedData);
            setNewEmailContent({title:title,content:markup,jsonContent:savedData.blocks})
        }
        
        useEffect(() => {
            if (emailContent != null) {
                setTitle(emailContent.title);
                if (emailContent.jsonContent != "") {
                    let getDate = Math.floor(new Date().getTime() / 1000);
                    var json_content;
                    try {
                    json_content = JSON.parse(emailContent.jsonContent);
                    } catch (err) {
                    json_content = JSON.parse(emailContent.jsonContent).replace(/\'/g, '"');
                    }
                    setDesc({
                    time: getDate,
                    blocks: json_content,
                    });
                }   
                setDataSet(true)
            }
      }, [emailContent]);
    return (
        <Stack gap={3}>
        <Form.Group controlId="title">
            <Form.Label>Enter Title</Form.Label>
            <Form.Control
            isInvalid={titleInvalid}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
            Please enter valid title.
            </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="desc">
            <Form.Label>Enter Description</Form.Label>
            <EditorJS
            editorCore={editorCore}
            uploadImage={uploadImage}
            uploadImageByUrl={uploadImageByUrl}
            postSet={dataSet}
            content={desc}
            adminCodes={adminCodes}
            selectedTopic={currentTopicId}
            communityId={currentCommunityId}
            />
        </Form.Group>
        <Button
            className="uuki-btn uuki-community-btn align-self-start"
            onClick={saveEmail}
        >
            Save Email
        </Button>
        </Stack>
    )
}

export default ComposeEmail