import { Image, Form, Stack, Button, Spinner, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import '../../styles/members.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Avatar from 'react-avatar';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

export default function Analytics({ getKey }) {
	const [data, setData] = useState(null);
	const { communitySlug } = useParams();
	const navigate = useNavigate();
	const currentSlug = useSelector(
		(state) => state.communityReducer.communitySlug
	);

	useEffect(() => {
		if (getKey == 'analytics') {
			axios
				.get('/v2/get_analytics_info?commSlug=' + communitySlug)
				.then((res) => {
					setData(res.data);
				});
		}
	}, [getKey]);

	return (
		<div className='d-flex justify-content-center'>
			<Stack
				className='center-container uuki-box members-search-container-bg py-3'
				gap={3}>
				<span className='community-pages-header px-4 '>Analytics</span>

				<hr className='post-footer-divider m-0'></hr>
				{data != null && (
					<>
						<Row className='justify-content-center g-0'>
							<Col className='ps-4 pe-2' md={6}>
								<Stack gap={2} className='analytics-block p-3'>
									<Stack direction='horizontal'>
										<b>Top Posts of the Week</b>
										<small className='ms-auto'>Likes</small>
									</Stack>
									{data.weeklyPostData.map((post) => (
										<Stack
											style={{ cursor: 'pointer' }}
											direction='horizontal'
											onClick={() =>
												navigate('/c/' + currentSlug + '/post/' + post.slug)
											}>
											<span>{post.title}</span>
											<small className='ms-auto fw-bold'>{post.likes}</small>
										</Stack>
									))}
								</Stack>
							</Col>
							<Col className='pe-4 ps-2' md={6}>
								<Stack gap={2} className='analytics-block p-3'>
									<Stack direction='horizontal'>
										<b>Top Posts of the Month</b>
										<small className='ms-auto'>Likes</small>
									</Stack>
									{data.monthlyPostData.map((post) => (
										<Stack
											style={{ cursor: 'pointer' }}
											direction='horizontal'
											onClick={() =>
												navigate('/c/' + currentSlug + '/post/' + post.slug)
											}>
											<span>{post.title}</span>
											<small className='ms-auto fw-bold'>{post.likes}</small>
										</Stack>
									))}
								</Stack>
							</Col>
						</Row>
						<Row className='justify-content-center g-0'>
							<Col className='ps-4 pe-2' md={6}>
								<Stack gap={2} className='analytics-block p-3'>
									<Stack direction='horizontal'>
										<b>Top Spaces of the Week</b>
									</Stack>
									{data.weeklyTopicData.map((topic) => (
										<Stack
											style={{ cursor: 'pointer' }}
											direction='horizontal'
											onClick={() =>
												navigate('/c/' + currentSlug + '/' + topic.slug)
											}>
											<span>{topic.name}</span>
										</Stack>
									))}
								</Stack>
							</Col>
							<Col className='pe-4 ps-2' md={6}>
								<Stack gap={2} className='analytics-block p-3'>
									<Stack direction='horizontal'>
										<b>Top Spaces of the Month</b>
									</Stack>
									{data.monthlyTopicData.map((topic) => (
										<Stack
											style={{ cursor: 'pointer' }}
											direction='horizontal'
											onClick={() =>
												navigate('/c/' + currentSlug + '/' + topic.slug)
											}>
											<span>{topic.name}</span>
										</Stack>
									))}
								</Stack>
							</Col>
						</Row>
						<Row className='justify-content-center g-0'>
							<Col className='ps-4 pe-2' md={6}>
								<Stack gap={3} className='analytics-block p-3'>
									<Stack direction='horizontal'>
										<b>Top Members of the Week</b>
										<small className='ms-auto'>Karma</small>
									</Stack>
									{data.weekMembers.map((member) => (
										<Stack
											style={{ cursor: 'pointer' }}
											direction='horizontal'
											onClick={() =>
												navigate(
													'/c/' + currentSlug + '/member?memberId' + member.id
												)
											}>
											{member.image !== '' && member.image !== undefined ? (
												<Image
													src={member.image}
													className='desktop-profile-image me-2'
													roundedCircle
													loading='lazy'></Image>
											) : (
												<Avatar
													name={member.name}
													size='40'
													className='me-2'
													style={{ fontSize: '0.5rem' }}
													// className="mobile-profile-image"
													round={true}
												/>
											)}
											<span>{member.name}</span>
											<small className='ms-auto fw-bold'>{member.karma}</small>
										</Stack>
									))}
								</Stack>
							</Col>
							<Col className='pe-4 ps-2' md={6}>
								<Stack gap={3} className='analytics-block p-3'>
									<Stack direction='horizontal'>
										<b>Top Members of the Month</b>
										<small className='ms-auto'>Karma</small>
									</Stack>
									{data.monthMembers.map((member) => (
										<Stack
											style={{ cursor: 'pointer' }}
											direction='horizontal'
											onClick={() =>
												navigate(
													'/c/' + currentSlug + '/member?memberId' + member.id
												)
											}>
											{member.image !== '' && member.image !== undefined ? (
												<Image
													src={member.image}
													className='desktop-profile-image me-2'
													roundedCircle
													loading='lazy'></Image>
											) : (
												<Avatar
													name={member.name}
													size='40'
													className='me-2'
													style={{ fontSize: '0.5rem' }}
													// className="mobile-profile-image"
													round={true}
												/>
											)}
											<span>{member.name}</span>
											<small className='ms-auto fw-bold'>{member.karma}</small>
										</Stack>
									))}
								</Stack>
							</Col>
						</Row>
						<div class='mx-4 p-3 analytics-block'>
							<b>Top Days to Post</b>
							<Bar
								className=''
								options={{
									responsive: true,
									plugins: {
										legend: {
											position: 'top',
										},
									},
								}}
								data={{
									labels: [
										'Monday',
										'Tuesday',
										'Wednesday',
										'Thursday',
										'Friday',
										'Saturday',
										'Sunday',
									],
									datasets: [
										{
											label: 'Number of Posts',
											data: [
												data.popularDays['Monday'],
												data.popularDays['Tuesday'],
												data.popularDays['Wednesday'],
												data.popularDays['Thursday'],
												data.popularDays['Friday'],
												data.popularDays['Saturday'],
												data.popularDays['Sunday'],
											],
											backgroundColor: getComputedStyle(
												document.body
											).getPropertyValue('--accent'),
										},
									],
								}}
							/>
						</div>
						<div class='mx-4 p-3 analytics-block'>
							<b>Top Times to Post</b>
							<Line
								className='mx-4 p-3 analytics-block'
								options={{
									responsive: true,
									plugins: {
										legend: {
											position: 'top',
										},
									},
								}}
								data={{
									labels: Object.keys(data.popularTimes),
									datasets: [
										{
											label: 'Number of Posts',
											data: Object.keys(data.popularTimes).map(
												(time) => data.popularTimes[time]
											),
											borderColor: getComputedStyle(
												document.body
											).getPropertyValue('--accent'),
											backgroundColor: getComputedStyle(
												document.body
											).getPropertyValue('--accent'),
										},
									],
								}}
							/>
						</div>
						<div class='mx-4 p-3 analytics-block'>
							<b>Daily Active Members</b>
							<Bar
								className='mx-4 p-3 analytics-block'
								options={{
									responsive: true,
									plugins: {
										legend: {
											position: 'top',
										},
									},
								}}
								data={{
									labels: Object.keys(data.activeUsers),
									datasets: [
										{
											label: 'Active Users',
											data: Object.keys(data.activeUsers).map(
												(day) => data.activeUsers[day]
											),
											backgroundColor: getComputedStyle(
												document.body
											).getPropertyValue('--accent'),
										},
									],
								}}
							/>
						</div>
						<div class='mx-4 p-3 analytics-block'>
							<b>New Members</b>
							<Line
								className='mx-4 p-3 analytics-block'
								options={{
									responsive: true,
									plugins: {
										legend: {
											position: 'top',
										},
									},
								}}
								data={{
									labels: Object.keys(data.newUsers),
									datasets: [
										{
											label: 'Number of New Users',
											data: Object.keys(data.newUsers).map(
												(date) => data.newUsers[date]
											),
											borderColor: getComputedStyle(
												document.body
											).getPropertyValue('--accent'),
											backgroundColor: getComputedStyle(
												document.body
											).getPropertyValue('--accent'),
										},
									],
								}}
							/>
						</div>
					</>
				)}

				<div className='p-5' />
			</Stack>
		</div>
	);
}
