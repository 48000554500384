import React from "react";
import AddComment from "./AddComment";
import CommentBody from "./CommentBody";
import CommentFooter from "./CommentFooter";
import CommentHeader from "./CommentHeader";
import CommentItem from "./CommentItem";

export default function Comment({ data, postId, communityId }) {
  return (
    <>
      <hr className="post-footer-divider m-0"></hr>
      <div className="p-4">
        <AddComment postId={postId} replyTo={null}/>
        {data != null
          ? data.map((comment) => (
              <>
                <CommentItem
                  commentData={comment}
                  communityId={communityId}
                  postId={postId}
                  id={comment.id}
                />
                {comment.reply != null && comment.reply !== []
                  ? comment.reply.map((reply) => (
                      <div className="ms-5">
                        <CommentItem
                          commentData={reply}
                          communityId={communityId}
                          postId={postId}
                          id={`comment-` + comment.id}
                        />
                      </div>
                    ))
                  : ""}
              </>
            ))
          : ""}
      </div>
    </>
  );
}
