export default function swDev() {
  function determineAppServerKey() {
    const vapidPublicKey =
      "BJdgn_8znxo4cA5QmF4JeynwbA3PDVqVOC-n1dTJq_bDAgX8xgqWRkPGutnEY_z-z7OvLWLJmXYcIgk0YqsgYKg";
    return urlBase64ToUint8Array(vapidPublicKey);
  }

  function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  let swUrl = `/sw.js`;
  try {
    navigator.serviceWorker.register(swUrl).then((response) => {
      console.warn("response", response);
    });
  } catch(error) {
	console.log("Cannot register serviceworker", error);  
  }
}