import { Stack,Form, Button, InputGroup } from "react-bootstrap";
import {useState,useEffect} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'

function SSOSettings({setApiSettings,settings}) {
    const [settingsSet, setSettingsSet] = useState(false);
    const [google, setGoogle] = useState({enabled:false,id:'',secret:''});
    const [fb, setFb] = useState({enabled:false,id:'',secret:''});
    const [li, setLi] = useState({enabled:false,id:'',secret:''});
    const [wp, setWp] = useState({enabled:false,id:'',secret:'',auth:'',token:'',info:'',msg:''});
    const [jwt, setJwt] = useState({enabled:false,token:'',url:'',msg:''});
    const currentId = useSelector(
        (state) => state.communityReducer.communityId
    )
    useEffect(() => {
        if(Object.entries(settings).length > 0) {		
            setSettingsSet((prev)=>true);
            if(settings != "Not Generated"){
                setGoogle({enabled:settings.googleEnabled,id:settings.googleId,secret:settings.googleSecret})
                setFb({enabled:settings.fbEnabled,id:settings.fbId,secret:settings.fbSecret})
                setLi({enabled:settings.liEnabled,id:settings.liId,secret:settings.liSecret})
                setWp({enabled:settings.wpEnabled,id:settings.wpId,secret:settings.wpSecret,auth:settings.wpAuthUrl,token:settings.wpTokenUrl,info:settings.wpInfoUrl,msg:settings.wpLoginMsg})
                setJwt({enabled:settings.jwtEnabled,token:settings.jwtToken,url:settings.jwtUrl,msg:settings.jwtLoginMsg})
            }
             
         }
    }, [settings])	
    const generateJwtToken = () => {
        axios.post('/generate_jwt_sso_token',{community_id:currentId})
            .then((res) => {
                toast.success('Token Generated')
                setJwt({...jwt,token:res.data})
            
            }).catch(function (error) {
                toast.error('Token Not Generated')
            });
    }
    const saveGoogle = () => {
        axios.post('/save_google_credentials',{commId:currentId,client_id:google.id,client_secret:google.secret,checkbox:google.enabled})
            .then((res) => {
                toast.success('Google Credentials Updated')
            
            }).catch(function (error) {
                toast.error('Google Credentials Not Updated')
            });
    }
    const saveFb = () => {
        axios.post('/save_fb_credentials',{commId:currentId,client_id:fb.id,client_secret:fb.secret,checkbox:fb.enabled})
            .then((res) => {
                toast.success('Facebook Credentials Updated')
            
            }).catch(function (error) {
                toast.error('Facebook Credentials Not Updated')
            });
    }
    const saveLi = () => {
        axios.post('/save_linkedin_credentials',{commId:currentId,client_id:li.id,client_secret:li.secret,checkbox:li.enabled})
            .then((res) => {
                toast.success('LinkedIn Credentials Updated')
            
            }).catch(function (error) {
                toast.error('LinkedIn Credentials Not Updated')
            });
    }
    const saveWp = () => {
        axios.post('/save_wp_credentials',{commId:currentId,authorization_url:wp.auth,token_url:wp.token,user_info_url:wp.info,login_msg:wp.msg,client_id:wp.id,client_secret:wp.secret,checkbox:wp.enabled})
            .then((res) => {
                toast.success('Wordpress Credentials Updated')
            
            }).catch(function (error) {
                toast.error('Wordpress Credentials Not Updated')
            });
    }
    const saveJwt = () => {
        axios.post('/save_jwt_credentials',{commId:currentId,login_msg:jwt.msg,login_url:jwt.url,checkbox:jwt.enabled})
            .then((res) => {
                toast.success('JWT Credentials Updated')
            
            }).catch(function (error) {
                toast.error('JWT Credentials Not Updated')
            });
    }
    
    return (
        <>
            <Stack gap={5}>
                <Stack gap={4}>
                    <Stack direction="horizontal">
                        <h4 className="m-0">Google SSO</h4>
                        <Form.Switch className="ms-auto" checked={google.enabled} onChange={(event)=>setGoogle({...google,enabled:event.target.checked})}/>
                    </Stack>
                    <Form.Group controlId="googleClientID">
                        <Form.Label>Client ID</Form.Label>
                        <Form.Control defaultValue={google.id} onChange={(event)=>setGoogle({...google,id:event.target.value})}/>
                    </Form.Group>
                    <Form.Group controlId="googleClientSecret">
                        <Form.Label>Client Secret</Form.Label>
                        <Form.Control defaultValue={google.secret} onChange={(event)=>setGoogle({...google,secret:event.target.value})}/>
                    </Form.Group>
                    <Button className="uuki-btn uuki-community-btn align-self-start" onClick={saveGoogle}>Save</Button>
                </Stack>
                
                <Stack gap={4}>
                    <Stack direction="horizontal">
                        <h4 className="m-0">Facebook SSO</h4>
                        <Form.Switch className="ms-auto" checked={fb.enabled} onChange={(event)=>setFb({...fb,enabled:event.target.checked})}/>
                    </Stack>
                    <Form.Group controlId="fbClientID">
                        <Form.Label>Client ID</Form.Label>
                        <Form.Control defaultValue={fb.id} onChange={(event)=>setFb({...fb,id:event.target.value})}/>
                    </Form.Group>
                    <Form.Group controlId="fbClientSecret">
                        <Form.Label>Client Secret</Form.Label>
                        <Form.Control defaultValue={fb.secret} onChange={(event)=>setFb({...fb,secret:event.target.value})}/>
                    </Form.Group>
                    <Button className="uuki-btn uuki-community-btn align-self-start" onClick={saveFb}>Save</Button>
                </Stack>

                <Stack gap={4}>
                    <Stack direction="horizontal">
                        <h4 className="m-0">LinkedIn SSO</h4>
                        <Form.Switch className="ms-auto" checked={li.enabled} onChange={(event)=>setLi({...li,enabled:event.target.checked})}/>
                    </Stack>
                    <Form.Group controlId="liClientID">
                        <Form.Label>Client ID</Form.Label>
                        <Form.Control defaultValue={li.id} onChange={(event)=>setLi({...li,id:event.target.value})}/>
                    </Form.Group>
                    <Form.Group controlId="liClientSecret">
                        <Form.Label>Client Secret</Form.Label>
                        <Form.Control defaultValue={li.secret} onChange={(event)=>setLi({...li,secret:event.target.value})}/>
                    </Form.Group>
                    <Button className="uuki-btn uuki-community-btn align-self-start" onClick={saveLi}>Save</Button>
                </Stack>

                <Stack gap={4}>
                    <Stack direction="horizontal">
                        <h4 className="m-0">Wordpress SSO</h4>
                        <Form.Switch className="ms-auto" checked={wp.enabled} onChange={(event)=>setWp({...wp,enabled:event.target.checked})}/>
                    </Stack>
                    <Form.Group controlId="wpAuthUrl">
                        <Form.Label>Authorization URL</Form.Label>
                        <Form.Control defaultValue={wp.auth} onChange={(event)=>setWp({...wp,auth:event.target.value})}/>
                    </Form.Group>
                    <Form.Group controlId="wpTokenUrl">
                        <Form.Label>Token URL</Form.Label>
                        <Form.Control defaultValue={wp.token} onChange={(event)=>setWp({...wp,token:event.target.value})}/>
                    </Form.Group>
                    <Form.Group controlId="wpUserUrl">
                        <Form.Label>User Info URL</Form.Label>
                        <Form.Control defaultValue={wp.info} onChange={(event)=>setWp({...wp,info:event.target.value})}/>
                    </Form.Group>
                    <Form.Group controlId="wpClientID">
                        <Form.Label>Client ID</Form.Label>
                        <Form.Control defaultValue={wp.id} onChange={(event)=>setWp({...wp,id:event.target.value})}/>
                    </Form.Group>
                    <Form.Group controlId="wpClientSecret">
                        <Form.Label>Client Secret</Form.Label>
                        <Form.Control defaultValue={wp.secret} onChange={(event)=>setWp({...wp,secret:event.target.value})}/>
                    </Form.Group>
                    <Form.Group controlId="wpClientMsg">
                        <Form.Label>Login Message</Form.Label>
                        <Form.Control defaultValue={wp.msg} onChange={(event)=>setWp({...wp,msg:event.target.value})}/>
                    </Form.Group>
                    <Button className="uuki-btn uuki-community-btn align-self-start" onClick={saveWp}>Save</Button>
                </Stack>

                <Stack gap={4}>
                    <Stack direction="horizontal">
                        <h4 className="m-0">JWT SSO</h4>
                        <Form.Switch className="ms-auto" checked={jwt.enabled} onChange={(event)=>setJwt({...jwt,enabled:event.target.checked})}/>
                    </Stack>
                    <Form.Group controlId="sitemap">
                        <Stack direction="horizontal">
                            <Form.Label>SSO Token</Form.Label>
                            <Button className="uuki-btn uuki-community-btn ms-auto" onClick={generateJwtToken}>Generate</Button>
                        </Stack>
                        <InputGroup className="mb-3 mt-2 settings-input-group">
                            <Form.Control defaultValue={jwt.token} disabled/>
                            <Button variant="secondary" onClick={(event) => {navigator.clipboard.writeText(event.currentTarget.previousSibling.value)}}>
                                <i className="fa-regular fa-clipboard"></i>
                            </Button>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="jwtClientID">
                        <Form.Label>Login URL</Form.Label>
                        <Form.Control defaultValue={jwt.url} onChange={(event)=>setJwt({...jwt,url:event.target.value})}/>
                    </Form.Group>
                    <Form.Group controlId="jwtClientSecret">
                        <Form.Label>Login Message</Form.Label>
                        <Form.Control defaultValue={jwt.msg} onChange={(event)=>setJwt({...jwt,msg:event.target.value})}/>
                    </Form.Group>
                    <Button className="uuki-btn uuki-community-btn align-self-start" onClick={saveJwt}>Save</Button>
                </Stack>
            </Stack>
        </>
    )
}

export default SSOSettings
