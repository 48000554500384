import { useState, useEffect,useRef } from "react";
import BasicOnboarding from "./BasicOnboarding";
import InteractionOnboarding from "./InteractionOnboarding";
import InviteOnboarding from "./InviteOnboarding";
import PostOnboarding from "./PostOnboarding";
import SettingsOnboarding from "./SettingsOnboarding";
import {
  Tab,
  Nav,
  Stack,
  Row,
  Col,
  Form,
  Image,
  Accordion,
  Button,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import onboardImage from "../../../assets/onboard-cover.png";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import EditMember from "../EditMember";

function OnboardingHome({ setKey, completed, isAdmin, communityName }) {
  const [onboardKey, setOnboardKey] = useState("basic");
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
  let [searchParams, setSearchParams] = useSearchParams();
  const [getCompleted, setGetCompleted] = useState([]);
  const completionRef = useRef()
  completionRef.current = getCompleted
  useEffect(() => {
    if (getCompleted.length == 0) {
      setGetCompleted(completed);
    }
  }, [completed]);
  const navigate = useNavigate()
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  const completeStep = (step) => {
    axios
      .post("/v2/addOnboardStep", {
        memberId: searchParams.get("memberId"),
        step: step,
      })
      .then((res) => {
        toast.success("Step Completed");
        if(Array.isArray(step)){
          step.forEach(getStep => {
            setGetCompleted([...completionRef.current, getStep]);
          });
        }else{
          setGetCompleted([...getCompleted, step]);
        }
        if(res.data==1){
          navigate(currentHost)
          window.location.reload()
        }
      })
      .catch(function (error) {
        toast.error("Step Not Completed");
      });
  };
  
  return (
    <>
      <div className=" justify-content-center pe-md-5">
        <Image fluid src={onboardImage} />
        <div className="view-leaderboard-container p-4  uuki-box mb-4 ">
          {isAdmin ? (
            <Tab.Container
              id="onboarding-nav"
              activeKey={onboardKey}
              onSelect={(k) => setOnboardKey(k)}
            >
              <Nav variant="pills" className="text-nowrap">
                <Stack
                  direction="horizontal"
                  className="w-100 justify-content-center pb-3 border-bottom"
                >
                  <Nav.Item className="ms-auto">
                    <Nav.Link className="p-0 bg-transparent" eventKey="basic">
                     
                      <div className={[0, 1, 2].every((i) => getCompleted.includes(i))?"step-complete step-button":"step-incomplete step-button"}>
                        <b>1</b>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <div className={[0, 1, 2].every((i) => getCompleted.includes(i))?"step-complete step-divider":"step-incomplete step-divider"}/>
                  <Nav.Item>
                    <Nav.Link
                      className="p-0 bg-transparent"
                      eventKey="settings"
                    >
                     
                      <div className={[3, 4, 5].every((i) => getCompleted.includes(i))?"step-complete step-button":"step-incomplete step-button"}>
                        <b>2</b>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <div className={[3, 4, 5].every((i) => getCompleted.includes(i))?"step-complete step-divider":"step-incomplete step-divider"}/>
                  <Nav.Item>
                    <Nav.Link className="p-0 bg-transparent" eventKey="post">
                  
                      <div className={[6].every((i) => getCompleted.includes(i))?"step-complete step-button":"step-incomplete step-button"}>
                        <b>3</b>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <div className={[6].every((i) => getCompleted.includes(i))?"step-complete step-divider":"step-incomplete step-divider"}/>
                  <Nav.Item>
                    <Nav.Link className="p-0 bg-transparent" eventKey="invite">
                     
                      <div className={[7].every((i) => getCompleted.includes(i))?"step-complete step-button":"step-incomplete step-button"}>
                        <b>4</b>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <div className={[7].every((i) => getCompleted.includes(i))?"step-complete step-divider":"step-incomplete step-divider"}/>
                  <Nav.Item>
                    <Nav.Link
                      className="p-0 bg-transparent"
                      eventKey="interaction"
                    >
                    
                      <div className={[8, 9, 10, 11].every((i) => getCompleted.includes(i))?"step-complete step-button":"step-incomplete step-button"}>
                        <b>5</b>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <div className={[8, 9, 10, 11].every((i) => getCompleted.includes(i))?"step-complete step-divider":"step-incomplete step-divider"}/>
                  <Nav.Item>
                    <Nav.Link className="p-0 bg-transparent" eventKey="follow">
                    
                      <div className={[12].every((i) => getCompleted.includes(i))?"step-complete step-button":"step-incomplete step-button"}>
                        <b>6</b>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <a className='ms-auto text-decoration-none' onClick={()=>completeStep([0,1,2,3,4,5,6,7,8,9,10,11,12])}>Skip Onboarding</a>
                </Stack>
              </Nav>
              <Tab.Content className="my-2 pt-3 home-content" def>
                <Tab.Pane className="justify-content-center" eventKey="basic">
                  <BasicOnboarding
                    completeStep={completeStep}
                    completed={getCompleted}
                    setOnboardKey={setOnboardKey}
                  />
                </Tab.Pane>
                <Tab.Pane
                  className="justify-content-center"
                  eventKey="settings"
                >
                  <SettingsOnboarding
                    completeStep={completeStep}
                    completed={getCompleted}
                    setOnboardKey={setOnboardKey}
                  />
                </Tab.Pane>
                <Tab.Pane className="justify-content-center" eventKey="post">
                  <PostOnboarding
                    setKey={setKey}
                    completeStep={completeStep}
                    completed={getCompleted}
                    setOnboardKey={setOnboardKey}
                  />
                </Tab.Pane>
                <Tab.Pane className="justify-content-center" eventKey="invite">
                  <InviteOnboarding
                    completeStep={completeStep}
                    completed={getCompleted}
                    setOnboardKey={setOnboardKey}
                  />
                </Tab.Pane>
                <Tab.Pane
                  className="justify-content-center"
                  eventKey="interaction"
                >
                  <InteractionOnboarding
                    setKey={setKey}
                    completeStep={completeStep}
                    completed={getCompleted}
                    setOnboardKey={setOnboardKey}
                  />
                </Tab.Pane>
                <Tab.Pane className="justify-content-center" eventKey="follow">
                  <Stack gap={3}>
                  <Stack direction="horizontal">
                    <h5>Follow</h5>
                    <a className='ms-auto text-decoration-none' onClick={()=>{completeStep(12)}}>Skip Section</a>
                  </Stack>
                    <span>
                      UUKI is always evolving, make sure you get your voice
                      heard and become a part of the community platform
                    </span>
                    <Accordion flush alwaysOpen>
                      <Accordion.Item >
                        <Accordion.Header>
                          <Form.Check
                            className="onboard-check"
                            inline
                            name="basic"
                            type="checkbox"
                            disabled
                            checked={getCompleted.includes(12) ? true : false}
                          />
                          Follow UUKI for updates
                        </Accordion.Header>
                        <Accordion.Body className="ps-5 mb-3">
                          <Stack gap={3}>
                            <span>
                              Get connected with other community managers and
                              share your thoughts. Like to see a feature
                              implemented in your community? Just let us know.
                            </span>

                            <Stack gap={4} direction="horizontal">
                              <Button
                                className="uuki-community-btn uuki-btn align-self-start"
                                href="https://app.uuki.live/c/tdb3Vsl1"
                                onClick={() => completeStep(12)}
                                target="_blank"
                              >
                                Follow and Continue
                              </Button>

                              <a className="text-decoration-none" onClick={() => completeStep(12)}>Mark Read</a>
                            </Stack>
                          </Stack>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Stack>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          ) : (
            <EditMember
              member={[]}
              setKey={setKey}
              navigate_from="onboarding"			  
              communityName={communityName}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default OnboardingHome;
