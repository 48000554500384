import {
  Button,
  Stack,
  Form,
  Tabs,
  Tab,
  FormControl,
  Image,
  InputGroup,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import placeholderImage from "../../assets/add-image.png";
import { useSearchParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { userRole } from "../../redux/slice/userSlice";

function EditMember({ member, setKey, navigate_from, communityName }) {
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [location, setLocation] = useState("");
  const [tags, setTags] = useState([]);
  const [deletedTags, setDeletedTags] = useState([]);
  const [links, setLinks] = useState([]);
  const [deletedLinks, setDeletedLinks] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const browserLocation = useLocation();
  const [customFieldData,setCustomFieldData]= useState([]);
  const [editedCustomField,setEditedCustomField]= useState([]);  

  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const currentId = useSelector((state) => state.communityReducer.communityId);  
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  const currentRole = useSelector((state) => state.userReducer.userRole);
  useEffect(() => {
    if (Object.entries(member).length > 0) {
      setImage(member.image);
      setName(member.name);
      setBio(member.bio);
      setLocation(member.location);
      setTags(member.tags);
      setLinks(member.links);
      setIsNew(false);
    }
    if (browserLocation.pathname.includes("/welcomeMember")) {
      setIsNew(true);
    }
  }, [member]);
  const removeTag = (id) => {
    let removedNewTags = tags.filter((tag) => tag.id !== id);
    setDeletedTags([...deletedTags, id]);
    setTags(removedNewTags);
  };
  const removeLink = (id) => {
    let removedNewLinks = links.filter((link) => link.id !== id);
    setDeletedLinks([...deletedLinks, id]);
    setLinks(removedNewLinks);
  };
  const addLink = (linkFor) => {
    setLinks([...links, { id: linkFor + "New", link: "", linkFor: linkFor }]);
  };
  const changeLink = (id, link) => {
    let linkIndex = links.findIndex((link) => link.id == id);
    links[linkIndex].link = link;
  };
  const uploadImage = (file) => {
    axios
      .get("/get_member_image_url", {
        params: { id: member.id, filename: file.name },
      })
      .then((res) => {
        res = res.data;
        let url = res.url;
        let fields = res.fields;
        let formData = new FormData();
        for (let key in fields) {
          formData.append(key, fields[key]);
        }
        formData.append("file", file);
        const xhr = new XMLHttpRequest();
        xhr.addEventListener("loadend", uploadComplete, false);
        function uploadComplete(event) {
          updateMemberImage("https://communitydata.uuki.live/" + fields["key"]);
        }
        xhr.open("POST", url, true);
        xhr.send(formData);
        toast.success("Image Uploaded");
      })
      .catch(function (error) {
        toast.error("Image Upload Failed");
      });
  };
  const updateMemberImage = (imageUrl) => {
    axios
      .post("/add_member_image", { id: member.id, url: imageUrl })
      .then((res) => {
        setImage(imageUrl);
        toast.success("Profile Image Updated");
      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }  
  const saveEdit = () => {	  
    let memberId;
    if (isNew) {
      memberId = searchParams.get("memberId");
    } else {
      memberId = member.id;
    }
	if(navigate_from=="onboarding") {
      var custom_ids = []	
      editedCustomField.forEach((x, i) => custom_ids.push(x['id']));
      for (const x of customFieldData) { 
        if(x['show_on_onboarding'] && (!isInArray(x['id'], custom_ids))) {
            toast.error("Please fill "+x['name']);
            return;			
        }			
      }	
	}
    if(name!=null && name!=''){	
      axios
      .post("/v2/editMember", {
        memberId: memberId,
        name: name,
        bio: bio,
        location: location,
        tags: tags,
        links: links,
        deletedTags: deletedTags,
        deletedLinks: deletedLinks,
        customFields : editedCustomField
      })
      .then((res) => {
        toast.success("Profile Edited");
        if (isNew) {
          navigate(currentHost);
        } else {
          navigate("/c/" + currentSlug + "/member?memberId=" + member.id);
        }
      })
      .catch(function (error) {
        toast.error("Profile Edit Failed");
      });
    }else{
        toast.error("Please enter a valid name")
    }	  
  };
  
  const get_custom_fields = ()=>{
        axios.get("/v2/get_custom_field_info",{ params: { communityId: currentId } }).then((response)=>{
            setCustomFieldData(response.data.custom_field_data)
        }).catch((error)=>{
            console.log("Error fetching custom fields")
        })
  }

  const handleCustomFieldEdit=(id,e)=>{
		if(editedCustomField.length<=0){
			setEditedCustomField([...editedCustomField,{'id':id,'value':e.target.value}])
		}else{
			var isFieldPresent = false;
			editedCustomField.forEach(field => {
				if(field.id==id){
					isFieldPresent=true;
				}
			});

			if(isFieldPresent){
				setEditedCustomField(editedCustomField.map((field)=>updateCustomField(field,id,e)))
			}else{
				setEditedCustomField([...editedCustomField,{'id':id,'value':e.target.value}])
			}
			
		}
  }
  
  const updateCustomField = (field,id,e)=>{
		if(field.id==id){
			field.value=e.target.value
		}
		return field
  }

  useEffect(() => {
        get_custom_fields()
  }, [])  
  return (
    <div className={!isNew && "d-flex justify-content-center"}>
      <Stack
        className={
          !isNew ? "uuki-box center-container edit-wrapper py-4 px-5" : "p-4"
        }
        gap={4}
      >
        {!isNew ? (
          <h4 className="mb-3">
            {" "}
            <Button
              onClick={() => setKey("feed")}
              className="text-dark border-0 bg-transparent"
            >
              <i className="fa-solid fa-arrow-left"></i>
            </Button>
            Edit Member
          </h4>
        ) : (
          <div>
            <h4 className="m-0">Welcome to {communityName}</h4>
            <span>Enter your profile details to get started</span>
          </div>
        )}
        <Stack gap={3} direction="horizontal">
          <Image
            roundedCircle
            className="uuki-logo"
            src={image}
            loading="lazy"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = placeholderImage;
            }}
          />
          <label for="file-upload-member" class="custom-file-upload">
            <div className="uuki-btn uuki-community-btn">Change Image</div>
          </label>
          <input
            id="file-upload-member"
            className="d-none"
            type="file"
            onChange={(e) => uploadImage(e.target.files[0])}
          />
        </Stack>
        <Form.Group controlId="memberName">
          <Form.Label>Enter Name</Form.Label>
          <Form.Control
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="memberBio">
          <Form.Label>Enter Bio</Form.Label>
          <Form.Control
            defaultValue={bio}
            onChange={(e) => setBio(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="memberLocation">
          <Form.Label>Enter Location</Form.Label>
          <Form.Control
            defaultValue={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </Form.Group>
        {!isNew && currentRole!=0 && (
          <Form.Group controlId="memberTags">
            <Form.Label>Check Tags</Form.Label>
            <Stack direction="horziontal" gap={2}>
              {tags.map((tag) => (
                <div className="d-flex ">
                  <div className="leaderboard-rank">{tag.name}</div>
                  <i
                    onClick={() => removeTag(tag.id)}
                    className="fa-solid fa-sm ms-1 mt-1 fa-x"
                  ></i>
                </div>
              ))}
            </Stack>
          </Form.Group>
        )}
        {navigate_from=="onboarding" &&
        <>		
        {customFieldData!=null && customFieldData!=[]?customFieldData.map((field)=>(
			<>
			{field.show_on_onboarding?<div key={field.id + `-`+field.name}>

			<Form.Group>
				<Form.Label>{field.name}</Form.Label>
				<Form.Control defaultValue={field.value} onChange={(e)=>{handleCustomFieldEdit(field.id,e)}}/>
			</Form.Group>
		</div>:""}
		</>
			)
		):""}
        </>
        }		
        <Form.Group controlId="memberLinks">
          <Form.Label>{isNew ? "Add" : "Edit"} Links</Form.Label>
          {links.length > 0 &&
            links.map((link) => (
              <>
                {link.linkFor == "fb" ? (
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <i className="px-1 fa-2x fab fa-facebook-square"></i>
                    </InputGroup.Text>
                    <Form.Control
                      defaultValue={link.link}
                      onChange={(e) => changeLink(link.id, e.target.value)}
                    />
                    <i
                      className="fa-solid ms-1  fa-x"
                      onClick={() => removeLink(link.id)}
                    ></i>
                  </InputGroup>
                ) : link.linkFor == "insta" ? (
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <i className="px-1 fa-2x fab fa-instagram-square"></i>
                    </InputGroup.Text>
                    <Form.Control
                      defaultValue={link.link}
                      onChange={(e) => changeLink(link.id, e.target.value)}
                    />
                    <i
                      className="fa-solid ms-1  fa-x"
                      onClick={() => removeLink(link.id)}
                    ></i>
                  </InputGroup>
                ) : link.linkFor == "twit" ? (
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <i className="px-1 fa-2x fab fa-twitter-square"></i>
                    </InputGroup.Text>
                    <Form.Control
                      defaultValue={link.link}
                      onChange={(e) => changeLink(link.id, e.target.value)}
                    />
                    <i
                      className="fa-solid ms-1  fa-x"
                      onClick={() => removeLink(link.id)}
                    ></i>
                  </InputGroup>
                ) : link.linkFor == "li" ? (
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <i className="px-1 fa-2x fab fa-linkedin"></i>
                    </InputGroup.Text>
                    <Form.Control
                      defaultValue={link.link}
                      onChange={(e) => changeLink(link.id, e.target.value)}
                    />
                    <i
                      className="fa-solid ms-1  fa-x"
                      onClick={() => removeLink(link.id)}
                    ></i>
                  </InputGroup>
                ) : (
                  link.linkFor == "web" && (
                    <InputGroup className="mb-3">
                      <InputGroup.Text>
                        <i className="px-1 fa-2x fas fa-globe"></i>
                      </InputGroup.Text>
                      <Form.Control
                        defaultValue={link.link}
                        onChange={(e) => changeLink(link.id, e.target.value)}
                      />
                      <i
                        className="fa-solid ms-1  fa-x"
                        onClick={() => removeLink(link.id)}
                      ></i>
                    </InputGroup>
                  )
                )}
              </>
            ))}
          <Stack gap={2} direction="horizontal">
            <Button
              className="uuki-community-btn border-0 align-self-start"
              onClick={() => addLink("fb")}
            >
              <i className="px-1 fa-xl fab fa-facebook-square"></i>
              <i className="fa-solid fa-plus"></i>
            </Button>
            <Button
              className="uuki-community-btn border-0 align-self-start"
              onClick={() => addLink("insta")}
            >
              <i className="px-1 fa-xl fab fa-instagram-square"></i>
              <i className="fa-solid fa-plus"></i>
            </Button>
            <Button
              className="uuki-community-btn border-0 align-self-start"
              onClick={() => addLink("twit")}
            >
              <i className="px-1 fa-xl fab fa-twitter-square"></i>
              <i className="fa-solid fa-plus"></i>
            </Button>
            <Button
              className="uuki-community-btn border-0 align-self-start"
              onClick={() => addLink("li")}
            >
              <i className="px-1 fa-xl fab fa-linkedin"></i>
              <i className="fa-solid fa-plus"></i>
            </Button>
            <Button
              className="uuki-community-btn border-0 align-self-start"
              onClick={() => addLink("web")}
            >
              <i className="px-1 fa-xl fas fa-globe"></i>
              <i className="fa-solid fa-plus"></i>
            </Button>
          </Stack>
        </Form.Group>
        <Button
          className="uuki-community-btn uuki-btn align-self-start"
          onClick={saveEdit}
        >
          Save and Continue
        </Button>
        <div className="p-5" />
      </Stack>
    </div>
  );
}

export default EditMember;
