import React from "react";
import { Navbar, Image, Stack } from "react-bootstrap";

import AITutorIcon from "../../assets/aitutor-logo.png";

export default function NavbarTutorLogo() {
  return (
    <>
      <Navbar fixed="top" className="nav-uuki nav-home" expand={false}>
        <Stack direction="horizontal" gap={3}>
          <Image
            fluid
            loading="lazy"
            className="brand-logo"
            src={AITutorIcon}
          />

          <h5 className="mt-2">AI Tutor</h5>
        </Stack>
      </Navbar>
    </>
  );
}
