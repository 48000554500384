import { createSlice } from "@reduxjs/toolkit";

const state = () => {
  const initialState = {
    isUserLoggedIn: false,
    username: "",
    userId: "",
    userRole: 0,
    enableBeta: 0,
  };

  return initialState;
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState: state,
  reducers: {
    userLoggedIn: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.isUserLoggedIn = action.payload;
    },
    usernameStatus: (state, action) => {
      state.username = action.payload;
    },
    userId: (state, action) => {
      state.userId = action.payload;
    },
    userRole: (state, action) => {
      state.userRole = action.payload;
    },
    enableBeta: (state, action) => {
      state.enableBeta = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { userLoggedIn, usernameStatus, userId, userRole, enableBeta } =
  userSlice.actions;

export default userSlice.reducer;
