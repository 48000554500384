import { ListGroup, Button, FormSelect } from "react-bootstrap";
import { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ReorderTab({ sections }) {
  const [sectionList, setSectionList] = useState([]);
  const [lessonList, setLessonList] = useState([]);
  const [currentSort,setCurrentSort] = useState([])
  const [order, setOrder] = useState(0)
  useEffect(() => {
    if (Object.entries(sections).length > 0) {
      let sectionGet = sections;
      sectionGet.sort(function (a, b) {
        return a.order - b.order;
      });
      setSectionList(sectionGet);
      let lessons = []
      sections.map((section) => {
        let sortLesson = [];
        sortLesson.push(section.lessons);
        sortLesson.sort(function (a, b) {
          return a.order - b.order;
        });
        lessons.push({sectionId:section.id,lessons:sortLesson[0]});
      });
      setLessonList(lessons)
    }
  }, [sections]);
  useEffect(() => {
    if(sectionList.length>0){
      setCurrentSort((prev)=>sectionList)
    }
  }, [sectionList])
  
  let handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...currentSort];
    const [reorderedGroupItem] = updatedList.splice(
      droppedItem.source.index,
      1
    );
    updatedList.splice(droppedItem.destination.index, 0, reorderedGroupItem);
    setCurrentSort(updatedList);
  }

  const saveOrder = () => {
    axios
      .post("/v2/sort_lessons", { id: order, orderList: currentSort })
      .then((res) => {
        toast.success("Lesson Order Updated");
      })
      .catch(function (error) {
        toast.error("Lesson Order Not Updated");
      });
  };
  useEffect(() => {
    if(order==0){
      setCurrentSort(sectionList)
    }else{
      let sectionIndex = lessonList.find(
        (section) => section.sectionId == order
      );
      setCurrentSort(sectionIndex.lessons)
    }
  }, [order])
  return (
    <>
      {sectionList.length>0 && (
      <>
        <FormSelect
          defaultValue={order}
          onChange={(event) => setOrder(event.target.value)}
          className="mb-3 border-0"
        >
          <option value={0}>All Sections</option>
          {sectionList.map((section)=><option value={section.id}>{section.title}</option>)}
          
        </FormSelect>

        {currentSort.length>0 && 
        <DragDropContext  onDragEnd={handleDrop}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {currentSort.map((item, index) => (
                  <Draggable key={item.id} draggableId={"lesson-"+item.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="p-3 border border-2 d-flex align-items-center rounded m-2"
                      >
                        <i className="fa-solid fa-grip-vertical me-3"></i><h6 className="m-0 wrapword">{item.title}</h6>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>}
      </>
      )}
      <div className="content-border mt-3"></div>
      <Button className="uuki-btn uuki-community-btn mt-4" onClick={saveOrder}>
        Save
      </Button>
    </>
  );
}

export default ReorderTab;
