import { Button,ListGroup } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {useState,useEffect} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'

function SpaceArrangeSettings({settings}) {

    const [spaceGroupList, setSpaceGroupList] = useState(null);
    const currentId = useSelector(
        (state) => state.communityReducer.communityId
    )
    const [spaceList, setSpaceList] = useState(null);
    useEffect(() => {
            if(Object.entries(settings).length > 0){
                settings.space_groups.sort(function(a,b){
                    return a.order - b.order;
                    }
                );
                settings.spaces.sort(function(a,b){
                    return a.order - b.order;
                    }
                );
                setSpaceGroupList(settings.space_groups)
                setSpaceList(settings.spaces)
                
                }
            
        }, [settings])
    let handleGroupDrop = (droppedItem) => {
        if (!droppedItem.destination) return;
        var updatedList = [...spaceGroupList];
        const [reorderedGroupItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedGroupItem);
        setSpaceGroupList(updatedList);
    }
    let handleSpaceDrop = (droppedItem) => {
        if (!droppedItem.destination) return;
        var updatedList = [...spaceList];
        const [reorderedSpaceItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedSpaceItem);
        setSpaceList(updatedList);
    }
    const saveOrder = () => {
        console.log(spaceGroupList,spaceList)
        axios.post('/v2/sort_spaces',{id: currentId,groups:spaceGroupList,spaces:spaceList})
            .then((res) => {
                toast.success('Community Space Order Updated')
            
            }).catch(function (error) {
                toast.error('Community Space Order Not Updated')
            });
    }
    return (
        <>
            {(spaceList != null) && 
                <DragDropContext  onDragEnd={handleGroupDrop}>
                <Droppable droppableId="group-container">
                    {(provided) => (
                        <ListGroup
                        className="group-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        >
                        {spaceGroupList.map((groupItem, groupIndex) => (
                            <Draggable key={groupItem.name} draggableId={groupItem.name} index={groupIndex}>
                            {(provided) => (
                                <ListGroup.Item
                                className="item-container mb-3 border-0"
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                >
                                    <span>{groupItem.name}</span>
                                    <DragDropContext  onDragEnd={handleSpaceDrop}>
                                        <Droppable droppableId="space-container">
                                        {(provided) => (
                                            <ListGroup
                                            className="mt-4 space-container"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            >
                                            {spaceList.map((spaceItem, spaceIndex) => (
                                                (spaceItem.group_id == groupItem.id) &&
                                                <Draggable key={spaceItem.name} draggableId={spaceItem.name} index={spaceIndex}>
                                                {(provided) => (
                                                    <ListGroup.Item
                                                    className="item-container mb-3 border"
                                                    ref={provided.innerRef}
                                                    {...provided.dragHandleProps}
                                                    {...provided.draggableProps}
                                                    >
                                                    {spaceItem.name}
                                                    </ListGroup.Item>
                                                )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </ListGroup>
                                    )}
                                    </Droppable>
                                    </DragDropContext>
                                </ListGroup.Item>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </ListGroup>
                    )}
                    </Droppable>
                </DragDropContext>
            } 
            <div className="content-border"></div>
            <Button className="uuki-btn uuki-community-btn mt-4" onClick={saveOrder}>Save</Button>
        </>
    )
}


export default SpaceArrangeSettings
