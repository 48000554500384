import React from "react";
import PostHeader from "./PostHeader";
import PostContent from "./PostContent";
import PostFooter from "./PostFooter";
import { Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

export default function PostItem({ data, setKey, setEditPost, layout,setPostsUpdated }) {
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  return (
    <>
      {layout == 0 ? (
        <Stack className="uuki-box post ">
          <PostHeader
            data={data}
            setKey={setKey}
            setEditPost={setEditPost}
            layout={layout}
            setPostsUpdated={setPostsUpdated}
          />
          <PostContent data={data} />
          <PostFooter data={data} layout={layout} setPostsUpdated={setPostsUpdated} />
        </Stack>
      ) : layout == 1 ? (
        <Stack direction="horizontal" className="uuki-box post ">
          <PostHeader
            data={data}
            setKey={setKey}
            setEditPost={setEditPost}
            layout={layout}
            setPostsUpdated={setPostsUpdated}
          />
          <PostFooter
            data={data}
            setKey={setKey}
            setEditPost={setEditPost}
            layout={layout}
          />
        </Stack>
      ) : (
        <Stack className="uuki-box post grid-post ">
          <PostHeader
            data={data}
            setKey={setKey}
            setEditPost={setEditPost}
            layout={layout}
            setPostsUpdated={setPostsUpdated}
          />
          <PostFooter
            data={data}
            setKey={setKey}
            setEditPost={setEditPost}
            layout={layout}
          />
        </Stack>
      )}
    </>
  );
}
