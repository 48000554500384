import { createSlice } from "@reduxjs/toolkit";

const state = () => {
  const initialState = {
    topicList : []
  };

  return initialState;
};

export const topicListSlice = createSlice({
  name: "topicListSlice",
  initialState: state,
  reducers: {
    topicList: (state, action) => {
      state.topicList = [...state.topicList,action.payload];
    },
   
  },
});

export const { topicList } = topicListSlice.actions;

export default topicListSlice.reducer;
