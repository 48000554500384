import React, { useEffect, useState } from 'react'
import { useMediaQuery } from "react-responsive";
import NavbarAITutor from '../components/aitutor/NavbarAITutor';
import axios from 'axios';
import { useSearchParams, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AITutorContent from '../components/aitutor/AITutorContent';
import NavbarTutorLogo from '../components/aitutor/NavbarTutorLogo';

export default function AITutor() {
    const { communitySlug, topicSlug } = useParams();
    const [data, setData] = useState(null);
    const currentCommId = useSelector(
        (state) => state.communityReducer.communityId
      );
    useEffect(() => {	
        axios
        .get("/v2/get_ai_tutor_info",{params:{commSlug:communitySlug,topicSlug:topicSlug}})
        .then(function (response) {
          
            setData(response.data)
        })
        .catch(function (error) {
          console.log(error);
        });	
    }, [])
    

  return (
    <>
    <div style={{background:"white"}}>
        {data!=null ?<div>
          <NavbarTutorLogo></NavbarTutorLogo>
            <div style={{marginTop:"4.5rem",height:'100%'}}><AITutorContent data={data}></AITutorContent></div>
            
        </div>:""}

        <a className="tutor-producthunt-banner d-none d-xl-block" href="https://www.producthunt.com/posts/chatgpt-based-ai-tutor-for-uuki?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-chatgpt&#0045;based&#0045;ai&#0045;tutor&#0045;for&#0045;uuki" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=387245&theme=light" alt="ChatGPT&#0032;based&#0032;AI&#0032;tutor&#0032;for&#0032;UUKI - Your&#0032;own&#0032;AI&#0032;coding&#0032;tutor&#0032;based&#0032;on&#0032;course&#0032;created&#0032;on&#0032;UUKI | Product Hunt" style={{maxWidth:'200px'}}/></a>

    </div>

    </>
   
  )
}
