import {
  Button,
  Stack,
  Form,
  Tabs,
  Tab,
  FormControl,
  Image,
  FormCheck,
  FormCheckLabel,
  InputGroup,
} from "react-bootstrap";
// import { createReactEditorJS } from "react-editor-js";
import EditorJS from "../EditorComponents";
import { useRef, useCallback, useState, useEffect } from "react";
import { default as edjsParser } from "editorjs-parser";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DateTimePicker from "react-datetime-picker";
import { Link, useNavigate } from "react-router-dom";
import placeholderImage from "../../assets/add-image.png";

function CreatePost({ setKey, post, spaces, setEditPost, adminCodes }) {
  const editorCore = useRef(null);
  const navigate = useNavigate();
  var parser = new edjsParser(undefined);
  const [options, setOptions] = useState([{id:0,value:""}, {id:1,value:""}]);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [postTabKey, setPostTabKey] = useState("post");
  const [openPoll, setOpenPoll] = useState(false);
  const [updateArray, setUpdateArray] = useState(false);
  const [allowMultiple, setAllowMultiple] = useState(false);
  const [allowMemberOptions, setAllowMemberOptions] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [communityId, setCommunityId] = useState(0);
  const [spacesSet, setSpacesSet] = useState(false);
  const [postSet, setPostSet] = useState(null);
  const [pollSet, setPollSet] = useState(false);
  const [forEvents, setForEvents] = useState(false);
  const [content, setContent] = useState("");
  const [url, setUrl] = useState("");
  const [eventDate, setEventDate] = useState(null);
  const [duration, setDuration] = useState({ hours: 0, minutes: 0 });
  const [zoom, setZoom] = useState(false);
  const [stopRegistration, setStopRegistration] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [titleInvalid, setTitleInvalid] = useState(false);
  const [contentInvalid, setContentInvalid] = useState(false);
  const [pollOptionsInvalid, setPollOptionsInvalid] = useState(false);
  const [joinURLInvalid, setjoinURLInvalid] = useState(false);
  const [eventDateInvalid, setEventDateInvalid] = useState(false);
  const [removedOptions, setRemovedOptions] = useState([]);

  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const currentCommunityId = useSelector(
    (state) => state.communityReducer.communityId
  );
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  const currentTheme = useSelector(
    (state) => state.communityReducer.theme
  );  
  const currentTopic = useSelector((state) => state.communityReducer.topicSlug);
  const currentTopicForEvents = useSelector(
    (state) => state.communityReducer.topicForEvents
  );
  const currentLanguages = useSelector(
    (state) => state.languagesReducer.languages
  );
  const currentCommunityLanguage = useSelector(
    (state) => state.communityReducer.communityLanguage
  );
  const currentTopicId = useSelector((state) => state.communityReducer.topicId);
  const allow_anonymous = useSelector(
    (state) => state.communityReducer.allow_anonymous
  );  

  useEffect(() => {
    setForEvents(currentTopicForEvents);
  }, [currentTopicForEvents]);

  const uploadImage = (file) => {
    return new Promise(function (resolve, reject) {
      axios
        .get("/v2/get_community_image_url", {
          params: {
            topicId: selectedTopic,
            commId: communityId,
            filename: file.name,
          },
        })
        .then((res) => {
          res = res.data;
          let url = res.url;
          let fields = res.fields;
          let formData = new FormData();
          for (let key in fields) {
            formData.append(key, fields[key]);
          }
          formData.append("file", file);
          const xhr = new XMLHttpRequest();
          xhr.addEventListener("loadend", uploadComplete, false);
          function uploadComplete(event) {
            resolve("https://communitydata.uuki.live/" + fields["key"]);
          }
          xhr.onerror = reject;
          xhr.open("POST", url, true);
          xhr.send(formData);
          toast.success("Image Uploaded");
        })
        .catch(function (error) {
          toast.error("Image Upload Failed");
        });
    });
  };
  const uploadImageByUrl = (url) => {
    return new Promise(function (resolve, reject) {
      resolve(url);
    });
  };
  const uploadCover = (file) => {
    uploadImage(file).then((url) => {
      document.querySelector(".post-cover").src = url;
      //document.querySelector('.cover-wrapper').id = url
      setImage(url);
    });
  };
  useEffect(() => {	  
    if (currentTopicId !== 0) {
      setSelectedTopic(currentTopicId);
    } else {
      if (spaces.length > 0) {
        for (let space of spaces) {
          if (space.topics != undefined) {
            let allowed_topics = [];		
            for(let topic of space.topics) {
                if(!topic.for_courses && topic.show && topic.allow_posts == true) {
                    allowed_topics.push(topic);					
                }					
            }
            if(allowed_topics.length!=0) {
                setSelectedTopic(allowed_topics[0].topic_id);
                break;
            }
          }
        }
      }
    }
    setCommunityId(currentCommunityId);
  }, [currentTopicId, spaces]);
  useEffect(() => {
    setCommunityId(currentCommunityId);
  }, [currentCommunityId]);
  useEffect(() => {
    if (spaces != undefined && spaces.length > 0) {
      setSpacesSet(true);
    }
  }, [spaces]);

  useEffect(() => {
    if (Object.entries(post).length > 0) {
      let getDate = Math.floor(new Date().getTime() / 1000);
      if (post.json_content != "") {
        var json_content;
        try {
          json_content = JSON.parse(post.json_content);
        } catch (err) {
          json_content = JSON.parse(post.json_content.replace(/\'/g, '"'));
        }
        setContent({
          time: getDate,
          blocks: json_content,
        });
      }
      if (post.type == "event") {
        setUrl(post.event_info.location);
        let actualHours = post.event_info.duration / 60;
        let getHours = Math.floor(actualHours);
        let getMinutes = Math.round((actualHours - getHours) * 60);
        setDuration({ hours: getHours, minutes: getMinutes });
        let getTime = post.event_info.time.split(":");
        let getEventDate = new Date(post.event_info.date);
        getEventDate.setHours(getTime[0]);
        getEventDate.setMinutes(getTime[1]);
        setEventDate(getEventDate);
        if (post.event_info.registrationStopped == 1) {
          setStopRegistration(true);
        } else {
          setStopRegistration(false);
        }
      }
      if(post.type == "poll"){
        setOptions(post.poll_options);
        setPollSet(true);
        setOpenPoll(true);
        if(post.allow_multi_select ==1){
          setAllowMultiple(true)
        }else{
          setAllowMultiple(false)
        }
        if(post.allow_user_options == 1){
          setAllowMemberOptions(true)
        }else{
          setAllowMemberOptions(false)
        }
      }else{
        setOpenPoll(false);
      }
      setPostSet(true);
      setTitle(post.title);
      setImage(post.post_cover);
    } else {
      resetData()
    }
  }, [post]);
  const customParsers = {
    linkTool: function (data, config) {
      // return `<a href="${data.link}" ></a>`;
      return `<div class="card post-card" >
						<a href="${data.link}">
						<img src="${data.meta.image.url}" class="card-img-top" alt="...">
						<div class="card-body">
							<h5 class="card-title text-dark">${data.meta.title}</h5>
							<p class="card-text text-dark">${data.meta.description}</p>
						</div>
						</a>
					</div>`;
    },
    attaches: function (data, config) {
      var filesize = parseFloat(parseInt(data.file.size) / 1024).toFixed(1);
      return `<div class="cdx-attaches cdx-attaches--with-file">
								<div class="cdx-attaches__file-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="32" height="40">
								<g fill="#A8ACB8" fill-rule="evenodd">
		                        <path fill-rule="nonzero" d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"></path>
								<path d="M7 22h18v2H7zm0 4h18v2H7zm0 4h18v2H7z"></path>
		                        </g></svg></div>
								<div class="cdx-attaches__file-info">
							    <div class="cdx-attaches__title" contenteditable="false">${data.title}</div>
								<div class="cdx-attaches__size" data-size="KiB">${filesize}</div></div>
								<a class="cdx-attaches__download-button" href="${data.file.url}" target="_blank" rel="nofollow noindex noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="17pt" height="17pt" viewBox="0 0 17 17"><path d="M9.457 8.945V2.848A.959.959 0 0 0 8.5 1.89a.959.959 0 0 0-.957.957v6.097L4.488 5.891a.952.952 0 0 0-1.351 0 .952.952 0 0 0 0 1.351l4.687 4.688a.955.955 0 0 0 1.352 0l4.687-4.688a.952.952 0 0 0 0-1.351.952.952 0 0 0-1.351 0zM3.59 14.937h9.82a.953.953 0 0 0 .953-.957.952.952 0 0 0-.953-.953H3.59a.952.952 0 0 0-.953.953c0 .532.425.957.953.957zm0 0" fill-rule="evenodd"></path></svg></a></div>`;
    },
  };

  parser = new edjsParser(undefined, customParsers);
  const addPost = async () => {
    const savedData = await editorCore.current.save();
    const markup = parser.parse(savedData);

    // if (!title) {
    //   setTitleInvalid(true);
    // } else {
    //   setTitleInvalid(false);
    // }

    // if (!openPoll && !markup) {
    //   setContentInvalid(true);
    // } else {
    //   setContentInvalid(false);
    // }

    if (openPoll && !options[0] && !options[1]) {
      setPollOptionsInvalid(true);
    } else {
      setPollOptionsInvalid(false);
    }

    if (
      selectedTopic != 0 &&
      currentSlug != "" &&
      (!openPoll || (openPoll && (options[0] && options[1])))
    ) {
      axios
        .post("/add_post", {
          topicId: selectedTopic,
          title: title,
          commUrl: currentSlug,
          cover_url: image,
          desc: markup,
          jsonData: { jsonData: savedData.blocks },
          options: { options: options },
          postPoll: openPoll,
          isAnon: isAnonymous,
          allowMulti: allowMultiple,
          allowUserOption: allowMemberOptions,
        })
        .then((res) => {
          toast.success("Posted Successfully");
          setLoading(false);
          backToSpace();
        })
        .catch(function (error) {
          if(error.response) {			
              console.log("Post error", error, error.response, error.response.data);	
              toast.error(error.response.data);			  
          } else {
              toast.error("Error adding post");
          }		  
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  const editPost = async () => {
    const savedData = await editorCore.current.save();
    const markup = parser.parse(savedData);

    // if (!title) {
    //   setTitleInvalid(true);
    // } else {
    //   setTitleInvalid(false);
    // }

    // if (!openPoll && !markup) {
    //   setContentInvalid(true);
    // } else {
    //   setContentInvalid(false);
    // }

    if (openPoll && !options[0] && !options[1]) {
      setPollOptionsInvalid(true);
    } else {
      setPollOptionsInvalid(false);
    }

    if (selectedTopic != 0 && currentSlug != "" && (!openPoll || (openPoll && (options[0] && options[1])))) {
        axios
          .post("/edit_post", {
            postId: post.id,
            topicId: selectedTopic,
            title: title,
            commUrl: currentSlug,
            cover_url: image,
            desc: markup,
            jsonData: savedData.blocks,
            isAnon: false,
            postPoll: openPoll,
            options: { options: options },
            removedOptions: removedOptions,
            allowMulti: allowMultiple,
            allowUserOption: allowMemberOptions,
          })
          .then((res) => {
            toast.success("Post Edited");
            setLoading(false);
            backToSpace();
          })
          .catch(function (error) {
            toast.error("Post Edit Failed");
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    
  };
  const addEvent = async () => {
    const savedData = await editorCore.current.save();
    const markup = parser.parse(savedData);
    let minuteDuration = 0;
    if (duration.hours != "" || duration.minutes != "") {
      if (duration.hours == "") {
        minuteDuration = parseInt(duration.minutes);
      } else if (duration.minutes == "") {
        minuteDuration = parseInt(duration.hours) * 60;
      } else {
        minuteDuration =
          parseInt(duration.hours) * 60 + parseInt(duration.minutes);
      }
    }

    if (!title) {
      setTitleInvalid(true);
    } else {
      setTitleInvalid(false);
    }

    if (!url && zoom == false) {
      setjoinURLInvalid(true);
    } else {
      setjoinURLInvalid(false);
    }

    if (eventDate == null) {
      setEventDateInvalid(true);
    } else {
      setEventDateInvalid(false);
    }

    if (
      selectedTopic != 0 &&
      currentSlug != "" &&
      title &&
      eventDate != null &&
      (url || zoom == true)
    ) {
      axios
        .post("/add_event", {
          topicId: selectedTopic,
          title: title,
          commId: currentCommunityId,
          cover: image,
          desc: markup,
          jsonData: { jsonData: savedData.blocks },
          link: url,
          duration: minuteDuration,
          date: eventDate.toUTCString(),
          zoom: zoom,
        })
        .then((res) => {
          toast.success("Posted Successfully");
          setLoading(false);
          backToSpace();
        })
        .catch(function (error) {
          toast.error("Not Posted");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  const editEvent = async () => {
    const savedData = await editorCore.current.save();
    const markup = parser.parse(savedData);
    let minuteDuration = 0;
    if (duration.hours != "" || duration.minutes != "") {
      if (duration.hours == "") {
        minuteDuration = parseInt(duration.minutes);
      } else if (duration.minutes == "") {
        minuteDuration = parseInt(duration.hours) * 60;
      } else {
        minuteDuration =
          parseInt(duration.hours) * 60 + parseInt(duration.minutes);
      }
    }

    if (!title) {
      setTitleInvalid(true);
    } else {
      setTitleInvalid(false);
    }

    if (!url && zoom == false) {
      setjoinURLInvalid(true);
    } else {
      setjoinURLInvalid(false);
    }

    if (eventDate == null) {
      setEventDateInvalid(true);
    } else {
      setEventDateInvalid(false);
    }

    if (
      selectedTopic != 0 &&
      currentSlug != "" &&
      title &&
      eventDate != null &&
      (url || zoom == true)
    ) {
      axios
        .post("/edit_communityevent", {
          id: post.event_info.event_id,
          name: title,
          cover: image,
          description: markup,
          jsonData: { jsonData: savedData.blocks },
          url: url,
          duration: minuteDuration,
          date: eventDate.toUTCString(),
          registration: stopRegistration,
        })
        .then((res) => {
          toast.success("Event Updated Successfully");
          setLoading(false);
          backToSpace();
        })
        .catch(function (error) {
          toast.error("Not Updated");
        });
    } else {
      setLoading(false);
    }
  };

  const changeTopic = (option) => {
    setSelectedTopic(option[option.selectedIndex].value);
    let isTrueSet =
      option[option.selectedIndex].getAttribute("data-forEvents") === "true";
    setForEvents(isTrueSet);
  };
  useEffect(() => {
    if (isLoading) {
      forEvents
        ? postSet
          ? editEvent()
          : addEvent()
        : postSet
        ? editPost()
        : addPost();
    }
  }, [isLoading]);

  const removeOption = (index,id) => {
    options.splice(index,1);
    if(id!=0){
      setRemovedOptions([...removedOptions,id]);
    }
    setUpdateArray((prev)=>!prev)
  }

  const handleSave = () => setLoading(true);
  const resetData = () => {
    setPostSet(null);
    setOptions((prev)=>[{id:0,value:""}, {id:1,value:""}])
    setTitle((prev)=>"")
    setImage((prev)=>"")
    if(editorCore.current!=null){
      editorCore.current._editorJS.clear()
    }
    setOpenPoll((prev)=>false)
    setUpdateArray((prev)=>false)
    setAllowMultiple((prev)=>false)
    setAllowMemberOptions((prev)=>false)
    setPostSet((prev)=>false)
    setPollSet((prev)=>false)
    setContent((prev)=>"")
    setUrl((prev)=>"")
    setEventDate((prev)=>null)
    setDuration({hours: 0, minutes: 0 })
    setZoom((prev)=>false)
    setStopRegistration((prev)=>false)
    setIsAnonymous((prev)=>false)
    setRemovedOptions((prev)=>[])
  }

  const backToSpace = () => {
    if (currentTopic == "" || currentTopic == 0) {
      navigate(currentHost + "?isNew=" + currentTopicId);
    } else {
      navigate(
        "/c/" + currentSlug + "/" + currentTopic + "?isNew=" + currentTopicId
      );
    }
    setEditPost([]);
  };
  return (
    <div className="d-flex justify-content-center">
      <Stack
        className="uuki-box edit-wrapper py-4 px-5 center-container"
        gap={4}
      >
        <h4 className="mb-3">
          {" "}
          <Button
            onClick={() => backToSpace()}
            className="text-dark border-0 bg-transparent"
          >
            <i className="fa-solid fa-arrow-left"></i>
          </Button>
          {forEvents
            ? postSet
              ? "Edit Event"
              : currentLanguages[80][currentCommunityLanguage]
            : postSet
            ? currentLanguages[32][currentCommunityLanguage]
            : currentLanguages[23][currentCommunityLanguage]}
        </h4>
        {(currentTopic == 0 || postSet==true) && (
          <Form.Group controlId="spacesToPost">
            <Form.Label>{postSet ? "Change" : "Enter"} Topic</Form.Label>
            <Form.Select onChange={(e) => changeTopic(e.target)}>
              {spacesSet &&
                spaces.map((space) => (
                  <>
                    {space.topics != null
                      ? space.topics.map((topic) => topic.allow_posts==true && topic.show && !topic.for_courses && (
                          <option
                            selected={post.topic_id!=0 && topic.topic_id == post.topic_id}						  
                            value={topic.topic_id}
                            data-forEvents={topic.for_events}
                          >
                            {topic.topic_name}
                          </option>
                        ))
                      : ""}
                  </>
                ))}
            </Form.Select>
          </Form.Group>
        )}		
        <Stack gap={3} direction="horizontal">
          <Image
            roundedCircle
            className="uuki-logo post-cover"
            src={image}
            loading="lazy"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = placeholderImage;
            }}
          />
          <label for="file-upload-post" class="custom-file-upload">
            <div className="uuki-btn uuki-community-btn">
              {currentLanguages[27][currentCommunityLanguage]}
            </div>
          </label>
          <input
            id="file-upload-post"
            className="d-none"
            type="file"
            onChange={(e) => uploadCover(e.target.files[0])}
          />
        </Stack>				
        <div className="post-edit">
          <Form.Group controlId="postTitle">
            <Form.Control.Feedback type="invalid">
              Please enter valid title.
            </Form.Control.Feedback>
            <Form.Control
              isInvalid={titleInvalid}
              value={title}
              placeholder={currentLanguages[24][currentCommunityLanguage]}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <EditorJS
            editorCore={editorCore}
            uploadImage={uploadImage}
            uploadImageByUrl={uploadImageByUrl}
            postSet={postSet}
            content={content}
            adminCodes={adminCodes}
            selectedTopic={selectedTopic}
            communityId={communityId}
            />
        </div>


        {forEvents && (
          <>
            <Form.Group controlId="joinUrl">
              <Form.Label>
                {currentLanguages[81][currentCommunityLanguage]}
              </Form.Label>
              <Form.Control
                isInvalid={joinURLInvalid}
                defaultValue={url}
                onChange={(e) => setUrl(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid url or select add zoom checkbox.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="eventDate">
              <Form.Label>
                {currentLanguages[82][currentCommunityLanguage]}
              </Form.Label>
              <br />
              <DateTimePicker onChange={setEventDate} value={eventDate} />
              <br />
              {eventDateInvalid ? (
                <>
                  <span style={{ color: "red" }}>
                    Please enter a valid date and time
                  </span>
                </>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group controlId="duration">
              <Form.Label>Enter Duration</Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="Hours"
                  type="number"
                  min="0"
                  max="23"
                  value={duration.hours}
                  onChange={(e) =>
                    setDuration({ ...duration, hours: e.target.value })
                  }
                />
                <Form.Control
                  placeholder="Minutes"
                  type="number"
                  min="0"
                  max="59"
                  value={duration.minutes}
                  onChange={(e) =>
                    setDuration({ ...duration, minutes: e.target.value })
                  }
                />
              </InputGroup>
            </Form.Group>
            {!postSet ? (
              <Form.Group className="d-flex" controlId="zoomCheckbox">
                <Form.Check
                  type="checkbox"
                  checked={zoom}
                  onChange={(event) => setZoom(event.target.checked)}
                />
                <Form.Label className="ms-2">Add Zoom</Form.Label>
              </Form.Group>
            ) : (
              <Form.Group className="d-flex" controlId="registrationCheckbox">
                <Form.Check
                  type="checkbox"
                  checked={stopRegistration}
                  onChange={(event) =>
                    setStopRegistration(event.target.checked)
                  }
                />
                <Form.Label className="ms-2">Stop Registration</Form.Label>
              </Form.Group>
            )}
          </>
        )}
           

          {!pollSet&&<Button className="uuki-btn uuki-community-btn align-self-start" onClick={()=>setOpenPoll((prev)=>!prev)}>{openPoll?"Remove":"Add"} Poll</Button>}
          {(!forEvents && openPoll) && (

              <Stack gap={3}>

                {options.map((option, index) => (
                  <InputGroup className="mb-3">
                    <FormControl
                      defaultValue={option.value}
                      onChange={(e) => {options[index].value = e.target.value;setUpdateArray((prev)=>!prev)}}
                    />
                    <Button variant="outline-secondary" id="button-addon2" onClick={()=>removeOption(index,option.id)}>
                      <i className="fa-solid fa-xmark"></i>
                    </Button>
                  </InputGroup>
                ))}

                {pollOptionsInvalid ? (
                  <>
                    <span style={{ color: "red" }}>
                      Please Enter a valid options
                    </span>
                  </>
                ) : (
                  ""
                )}
                <Button
                  className={"align-self-start p-1 px-2 border-dark"+ (currentTheme=="dark" ? "text-light bg-transparent" : "text-dark bg-dark")}
                  onClick={() => setOptions([...options, {id:0,value:""}])}
                >
                  <i class="fa-solid fa-plus fa-lg"></i>
                </Button>
                <FormCheck
                  checked={allowMultiple}
                  label={currentLanguages[70][currentCommunityLanguage]}
                  onChange={(e) => setAllowMultiple(e.target.checked)}
                />
                <FormCheck
                  checked={allowMemberOptions}
                  label={currentLanguages[71][currentCommunityLanguage]}
                  onChange={(e) => setAllowMemberOptions(e.target.checked)}
                />
              </Stack>
          )}
        {contentInvalid ? (
          <>
            <span style={{ color: "red" }}>Please Enter a valid content</span>
          </>
        ) : (
          ""
        )}
        {allow_anonymous &&		
        <Form.Check
          type="checkbox"
          label={currentLanguages[30][currentCommunityLanguage]}
          className="mb-3"
          onChange={() => setIsAnonymous(!isAnonymous)}
        />
        }		
        <Button
          className="uuki-btn uuki-community-btn align-self-start"
          disabled={isLoading}
          onClick={!isLoading ? handleSave : null}
        >
          {isLoading
            ? "Saving"
            : currentLanguages[115][currentCommunityLanguage]}
        </Button>

        {/* <Button className="uuki-btn uuki-community-btn align-self-start" onClick={forEvents ? (postSet ? editEvent :addEvent) : (postSet ? editPost :addPost) }>{currentLanguages[115][currentCommunityLanguage]}</Button> */}
        <div className="p-5" />
      </Stack>
    </div>
  );
}

export default CreatePost;
