import { useEffect, useState } from 'react';
import { Accordion, Form, Stack, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function InteractionOnboarding({ setKey, completeStep, completed,setOnboardKey }) {
	const navigate = useNavigate();

	const currentSlug = useSelector(
		(state) => state.communityReducer.communitySlug
	);
	return (
		<>
			<Stack gap={3}>
			<Stack direction="horizontal">
				<h5>Interact</h5>
				<a className='ms-auto text-decoration-none' onClick={()=>{completeStep([8,9,10,11]);setOnboardKey('follow')}}>Skip Section</a>
				<Button className="ms-3 uuki-btn uuki-community-btn" onClick={()=>setOnboardKey('follow')}>Next</Button>
			</Stack>
				<span>
					Check out the various interaction options available and foster a
					community that always is open for communication.
				</span>
				<Accordion flush alwaysOpen>
					<Accordion.Item >
						<Accordion.Header>
							<Form.Check
								className='onboard-check'
								inline
								name='basic'
								type='checkbox'
								disabled
								checked={completed.includes(8) ? true : false}
							/>
							Check out Members and assign moderators
						</Accordion.Header>
						<Accordion.Body className='ps-5 mb-3'>
							<Stack gap={3}>
								<span>
									Check out the members in your community in the
									<b>Members Tab</b>. You can filter and search for any
									particular member and keep up with your community growth.
								</span>
								<span>
									Assign moderators to help you out with member and post
									management so you can focus on content and community building.
								</span>

								<Stack gap={4} direction='horizontal'>
									<Button
										className='uuki-community-btn uuki-btn align-self-start'
										onClick={() => {
											completeStep(8);
											setKey('members');
										}}>
										Check Members
									</Button>

									<a className="text-decoration-none" onClick={() => completeStep(8)}>Mark Read</a>
								</Stack>
							</Stack>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item >
						<Accordion.Header>
							<Form.Check
								className='onboard-check'
								inline
								name='basic'
								type='checkbox'
								disabled
								checked={completed.includes(9) ? true : false}
							/>
							Chat with Members
						</Accordion.Header>
						<Accordion.Body className='ps-5 mb-3'>
							<Stack gap={3}>
								<span>
									Use the chat platform for either a community wide discussion
									or a private one with any member. Let's chat with your first
									members.
								</span>

								<Stack gap={4} direction='horizontal'>
									<Button
										className='uuki-community-btn uuki-btn align-self-start'
										onClick={() => {
											completeStep(9);
											navigate('/chat/' + currentSlug);
										}}>
										Check Chat
									</Button>

									<a className="text-decoration-none" onClick={() => completeStep(9)}>Mark Read</a>
								</Stack>
							</Stack>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item >
						<Accordion.Header>
							<Form.Check
								className='onboard-check'
								inline
								name='basic'
								type='checkbox'
								disabled
								checked={completed.includes(10) ? true : false}
							/>
							Host Events
						</Accordion.Header>
						<Accordion.Body className='ps-5 mb-3'>
							<Stack gap={3}>
								<span>
									Events are an integral part for keeping your community up to
									date along with interconnecting and getting to know your
									members. Create a new Space for events or modify the content
									type of an existing Space, by going to
									<b>Settings -> Spaces</b> and choosing the edit option.
								</span>
								<span>
									Use any platform or link your Zoom account through{' '}
									<b>Settings -> Integrations</b> for a better seamless event
									creation process.
								</span>

								<Stack gap={4} direction='horizontal'>
									<Button
										className='uuki-community-btn uuki-btn align-self-start'
										onClick={() => {
											completeStep(10);
											navigate('/settings/' + currentSlug + "?openTab=spaceSettings");
										}}>
										Check Settings
									</Button>

									<a className="text-decoration-none" onClick={() => completeStep(10)}>Mark Read</a>
								</Stack>
							</Stack>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item >
						<Accordion.Header>
							<Form.Check
								className='onboard-check'
								inline
								name='basic'
								type='checkbox'
								disabled
								checked={completed.includes(11) ? true : false}
							/>
							Keep up with your community
						</Accordion.Header>
						<Accordion.Body className='ps-5 mb-3'>
							<Stack gap={3}>
								<span>
									No community fostering is complete without a proper rewards
									for your members. Your members earn Karma by interacting, keep
									up with your community by visiting the Leaderboards and check
									out the top members. Can't think of a reward? Award them
									special tags if you like, by making them in{' '}
									<b>Settings -> Member Tags</b>
								</span>

								<Stack gap={4} direction='horizontal'>
									<Button
										className='uuki-community-btn uuki-btn align-self-start'
										onClick={() => {
											completeStep(11);
											setKey('leaderboard');
										}}>
										Check Leaderboard
									</Button>

									<a className="text-decoration-none" onClick={() => completeStep(11)}>Mark Read</a>
								</Stack>
								<span>
									Want a more refined view of how your community is progressing?
									Check out the detailed analytics for getting informations on
									all interactions that happen daily.
								</span>

								<Stack gap={4} direction='horizontal'>
									<Button
										className='uuki-community-btn uuki-btn align-self-start'
										onClick={() => {
											completeStep(11);
											setKey('analytics');
										}}>
										Check Analytics
									</Button>

									<a className="text-decoration-none" onClick={() => completeStep(11)}>Mark Read</a>
								</Stack>
							</Stack>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Stack>
		</>
	);
}

export default InteractionOnboarding;
