import {Form,Button,Stack,InputGroup,Dropdown} from 'react-bootstrap'
import {useState,useEffect} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce';
import {useSelector} from 'react-redux'


function LinkSettings({setLinkSettings,settings}) {
    const [settingsSet, setSettingsSet] = useState(false);
    const [linkName, setLinkName] = useState('');
    const [link, setLink] = useState('');
    const currentId = useSelector(
        (state) => state.communityReducer.communityId
    )
    useEffect(() => {
            (Object.entries(settings).length > 0) && setSettingsSet((prev)=>true)
        }, [settings])

    const addLink = (event) => {
        event.preventDefault()
        axios.post('/add_communitylink',{id: currentId,link: link,linkName:linkName})
            .then((res) => {
                toast.success('Link Added')
                console.log(res.data)
                setLinkSettings([...settings,{id:res.data,name:linkName,link:link}])
            
            }).catch(function (error) {
                toast.error('Link Not Added')
            });
    }
    const changeLink = useDebouncedCallback(
        (id,value,isLink) => {
            
            axios.post('/v2/change_link',{id: id,value:value,isLink:isLink})
                .then((res) => {
                    toast.success('Link Updated')
                
                }).catch(function (error) {
                    toast.error('Update Failed')
                });
        },
        1000
    );
    const deleteLink = (id) => {
        axios.post('/delete_communitylink',{id: id})
            .then((res) => {
                toast.success('Link Deleted')
                let removedNewLinks = settings.filter(link => link.id !== id);
                setLinkSettings(removedNewLinks)
            
            }).catch(function (error) {
                toast.error('Link Not Deleted')
            });
    }
    return (
        <>
            <Form onSubmit={addLink}>
                <Stack gap={4}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Enter Link</Form.Label>
                        <Form.Control onChange={(event) => setLink(event.target.value)}/>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Enter Link Name</Form.Label>
                        <Form.Control onChange={(event) => setLinkName(event.target.value)}/>
                    </Form.Group>
                    <Button className="uuki-btn uuki-community-btn align-self-start" type="submit">
                        Save
                    </Button>
                </Stack>
            </Form>

            {(settingsSet && settings.length> 0) && <Form.Label className="mt-4">Edit Links</Form.Label>}
            <Stack gap={4}>
                {settingsSet && settings.map((link) =>
                    <Stack key={link.id} direction="horizontal">
                        <InputGroup className="settings-input-group">
                            <Form.Control defaultValue={link.name} onChange={(event)=>changeLink(link.id,event.target.value,0)}/>
                            <Form.Control defaultValue={link.link} onChange={(event)=>changeLink(link.id,event.target.value,1)}/>
                        </InputGroup>
                        <Dropdown  drop='end'>
                            <Dropdown.Toggle className="bg-transparent border-0 text-dark" >
                                <i className="fa-solid fa-ellipsis"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={()=>deleteLink(link.id)}>Delete Link</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Stack>
                )}
          
            </Stack>
            

        </>
    )
}

export default LinkSettings
