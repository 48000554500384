import {
  ListGroup,
  Accordion,
  Button,
  Stack,
  Modal,
  Form,
  Nav,
  ModalFooter,
  Spinner
} from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function CurriculumTab({ sections, setSections, setKey, setLessonId,setQuizId,info }) {
  const [addSectionShow, setAddSectionShow] = useState({
    show: false,
    title: "",
    id: 0,
  });
  const [addContentShow, setAddContentShow] = useState({show: false,id: 0});
  const currentTopicId = useSelector((state) => state.communityReducer.topicId);
  const enableBeta = useSelector((state) => state.userReducer.enableBeta);
 const [type, setType] = useState(0)
 const [showLoadingPopup, setShowLoadingPopup] = useState(false)
  const navigate = useNavigate();
  const aiTutorEnabled = useSelector((state) => state.communityReducer.aiTutorEnabled);
  const communitySlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const topicSlug = useSelector((state) => state.communityReducer.topicSlug);
  const addSection = () => {
    axios
      .post("/v2/add_section", {
        topicId: currentTopicId,
        title: addSectionShow.title,
        id: addSectionShow.id,
      })
      .then((res) => {
        if (res.data.isNew) {
          setSections([
            ...sections,
            { id: res.data.id, title: addSectionShow.title, lessons: [] },
          ]);
        } else {
          let sectionIndex = sections.findIndex(
            (section) => section.id == res.data.id
          );
          sections[sectionIndex].title = addSectionShow.title;
        }
        setAddSectionShow({ show: false, title: "", id: 0 });
      })
      .catch(function (error) {
        console.log("Course Not Found");
      });
  };
  
  const deleteSection = () => {
    axios
      .post("/v2/delete_section", {
        id: addSectionShow.id,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch(function (error) {
        console.log("Section Not Found");
      });
  };  

  const addContent = () => {
    if(type==0){
      setLessonId({ id: 0, sectionId: addContentShow.id });
      setAddContentShow({ show: false, id: 0 });
      setKey("lessonEdit");
    }else if(type==1){
      axios
      .post("/v2/add_quiz", {
        id: addContentShow.id,
      })
      .then((res) => {
        setQuizId({ id: res.data.id, sectionId: addContentShow.id });
        setAddContentShow({ show: false, id: 0 });
        setKey("quizEdit");
      })
      .catch(function (error) {
        console.log("Course Not Found");
      });
    }
  }
  
  const openTutororReferral = () => {
	  if(enableBeta == 0) {
	      window.open("https://upvir.al/145253/lp145253", '_blank');
	  } else {
		  window.open("/course/" + communitySlug + "/" + topicSlug + "/aitutor", '_blank'); 
	  }
  }

  const handleGenerateLesson =(section)=>{
    setShowLoadingPopup(true)
    axios
    .post("/v2/generate_course_lesson", {
      sectionId: section.id,
      commSlug: communitySlug,
    })
    .then((res) => {
      setShowLoadingPopup(false)
      window.location.reload()
    })
    .catch(function (error) {
      setShowLoadingPopup(false)
      toast.error("Error generating lessons. Please try again.")
    });
  }

  return (
    <Stack>
      <Stack className="mb-3" direction="horizontal">
        <h6>Course Lessons</h6>
        <Button
          className="uuki-btn uuki-community-btn ms-auto"
          onClick={() => setAddSectionShow({ show: true, title: "", id: 0 })}
        >
          Add a Section
        </Button>

        {info.length > 0 && info[0].ai_tutor_enabled && <Button className="uuki-btn uuki-community-btn ms-2" onClick={() =>
                      openTutororReferral()
                      }>Train with AI</Button>}
        
      </Stack>
      {sections.map((section) => (
        <Accordion classname="rounded" key={section.id} flush alwaysOpen>
          <Accordion.Item>
            <Accordion.Header className="section-header">
              <Stack className="w-100 text-dark wrapword" direction="horizontal">
                {section.title}
                <div className="ms-auto me-3">
                <i
                  className="fa-solid fa-pen-to-square "
                  onClick={() =>
                    setAddSectionShow({
                      show: true,
                      title: section.title,
                      id: section.id,
                    })
                  }
                ></i>
                <i
                  className="fa-solid fa-bolt ms-3"
                  onClick={() => handleGenerateLesson(section)}
                ></i>
                </div>
                
              </Stack>
            </Accordion.Header>
            <Accordion.Body className="p-0">
              {section.lessons && section.lessons.map((lesson) => (
                <Nav.Item className=" ">
                  <Nav.Link
                    style={{ fontSize: "1rem" }}
                    className="py-4 px-4 space-name text-dark d-flex align-items-center border rounded-0"
                  >
                    <Stack className="w-100" direction="horizontal">
                      <small className="wrapword"
                      onClick={() =>
                        navigate(
                          "/course/" +
                            communitySlug +
                            "/" +
                            topicSlug +
                            "?lessonId=" +
                            lesson.id
                        )
                      }>{lesson.title}</small>
                      <i
                        className="fa-solid fa-pen-to-square ms-auto me-3"
                        onClick={() => {
                          setLessonId({ id: lesson.id, sectionId: section.id });
                          setKey("lessonEdit");
                        }}
                      ></i>
                      <small>{lesson.published == 0 && "Not "}Published</small>
                    </Stack>
                  </Nav.Link>
                </Nav.Item>
              ))}
              {section.quizzes &&section.quizzes.map((quiz) => (
                <Nav.Item className=" ">
                  <Nav.Link
                    style={{ fontSize: "1rem" }}
                    className="py-4 px-4 space-name text-dark d-flex align-items-center border rounded-0"
                  >
                    <Stack className="w-100" direction="horizontal">
                      <small className="wrapword"
                      onClick={() =>
                        navigate(
                          "/course/" +
                            communitySlug +
                            "/" +
                            topicSlug +
                            "?quizId=" +
                            quiz.id
                        )
                      }>{quiz.title}</small>
                      <i
                        className="fa-solid fa-pen-to-square ms-auto me-3"
                        onClick={() => {
                          setQuizId({ id: quiz.id, sectionId: section.id });
                          setKey("quizEdit");
                        }}
                      ></i>
                      <small>{quiz.published == 0 && "Not "}Published</small>
                    </Stack>
                  </Nav.Link>
                </Nav.Item>
              ))}
              <Button
                className="uuki-btn uuki-community-btn m-2"
                onClick={() => {
                  setAddContentShow({show:true,id:section.id})
                  // setLessonId({ id: 0, sectionId: section.id });
                  // setKey("edit");
                }}
              >
                Add Content
              </Button>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}

      <Modal
        show={addSectionShow.show}
        onHide={() => setAddSectionShow({ show: false, title: "", id: 0 })}
        aria-labelledby="edit-section-modal"
        centered
      >
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>
            {addSectionShow.id == 0 ? "Add" : "Edit"} a Section
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Enter Section Title</Form.Label>
          <Form.Control
            value={addSectionShow.title}
            id="input-edit-section"
            onChange={(e) =>
              setAddSectionShow({ ...addSectionShow, title: e.target.value })
            }
          />
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button
            className="mt-4 uuki-btn uuki-community-btn"
            onClick={addSection}
          >
            {addSectionShow.id == 0 ? "Add" : "Edit"} Section
          </Button>
          {addSectionShow.id != 0 &&
          <>
          <Button
            className="mt-4 uuki-btn uuki-delete-btn"
            onClick={() => deleteSection()}
          >
            Delete Section
          </Button>	 		  
          </>
          }		  
        </Modal.Footer>
      </Modal>
      
      <Modal
        show={addContentShow.show}
        onHide={() => setAddContentShow({ show: false, id: 0 })}
        aria-labelledby="edit-section-modal"
        centered
      >
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>
            Choose Content Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack direction="horizontal" gap={4} className="justify-content-center">
            <Form.Group>
              <Form.Check
                inline
                name="content-type"
                type="radio"
                className="poll-option-radio me-2"
                defaultChecked={type==0&&true}
                onChange = {(e)=>e.target.checked==true&&setType(0)}
                />
                <Form.Label><h6 className="m-0">Add a Lesson</h6></Form.Label>
            </Form.Group>
            <Form.Group>
             <Form.Check
              inline
              name="content-type"
              type="radio"
              className="poll-option-radio me-2"
              defaultChecked={type==1&&true}
              onChange = {(e)=>e.target.checked==true&&setType(1)}
              />
              <Form.Label><h6 className="m-0">Add a Quiz</h6></Form.Label>
            </Form.Group>
          
          </Stack>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button className="uuki-btn uuki-community-btn" onClick={addContent}>
              Proceed
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal  centered  backdrop="static" show={showLoadingPopup} closeButton={false}>
          <Modal.Body className="p-5 position-relative">
          <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" role="status">
          </Spinner>
          <span className="ms-3">Generating Lessons.Please wait...</span>
          </div>
          
          </Modal.Body>
      </Modal>
    </Stack>
  );
}

export default CurriculumTab;
