import React from 'react'
import {Form,Stack,InputGroup,Dropdown,Button,Modal} from 'react-bootstrap'
import axios from 'axios'
import {useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import { toast } from "react-toastify";



export default function ProfileSettings() {
    const [customFieldData,setCustomFieldData]= useState([])
    const [showUpdateModal,setShowUpdateModal]= useState(false)
    const [selectedField,setSelectedField] = useState({id:0,name:"",showOnProfile:true,showOnOnboarding:false})
    const communityId = useSelector(
        (state) => state.communityReducer.communityId
    )

    const get_custom_fields = ()=>{
        axios.get("/v2/get_custom_field",{ params: { id: communityId } }).then((response)=>{
            setCustomFieldData(response.data.custom_fields)
        }).catch((error)=>{
            console.log("Error fetching custom fields")
        })
    }

    const delete_custom_field = (fieldId)=>{
        axios.post("/v2/delete_custom_field",{id: fieldId})
        .then((response)=>{
            get_custom_fields()
            toast.success("Custom Field deleted successfully");
        })
        .catch((error)=>{
            console.log("Error deleting custom fields")
            toast.error("Error deleting custom fields");
        })

        
    }
    const add_custom_field = (event)=>{
        event.preventDefault()
        const fieldName = document.getElementById('profileFieldName')
        const fieldType = 0
        const showOnProfile = document.getElementById('switch-profile-add').checked
        const showOnOnboarding = document.getElementById('switch-onboarding-add').checked
        
        
        if(fieldName.value!='' ){
            axios.post("/v2/add_custom_field",{name:fieldName.value,type:fieldType,communityId:communityId,showOnProfile:showOnProfile,showOnOnboarding:showOnOnboarding})
        .then((response)=>{
            get_custom_fields()
            fieldName.value=''
            toast.success("Custom Field added successfully");
        })
        .catch((error)=>{
            console.log("Error adding custom fields")
        })    
        }else{
            toast.error("Error adding the field");
        }
        
    }

    const update_custom_field = (event)=>{
        event.preventDefault()
        if(selectedField!=null){

            axios.post("/v2/update_custom_field",{name:selectedField.name,id:selectedField.id,showOnProfile:selectedField.showOnProfile,showOnOnboarding:selectedField.showOnOnboarding})
            .then((response)=>{
                
                get_custom_fields()
                handleOnHide()
                toast.success("Custom Field updated successfully");
            })
            .catch((error)=>{
                toast.error("Error updating custom field");
            })
        }
        
    }

    const handleUpdateClick = (field)=>{
        setShowUpdateModal(true)
        setSelectedField({id:field.id,name:field.name,showOnProfile:field.show_on_profile,showOnOnboarding:field.show_on_onboarding})
    }

    const handleOnHide = ()=>{
        setShowUpdateModal(false)
        setSelectedField({id:0,name:"",showOnProfile:true,showOnOnboarding:false})
        
    }

    useEffect(() => {
        get_custom_fields()
    }, [])
  return (
    <div>
        <>
            <h4>Custom Profile Fields</h4>

            <div className='mt-4'>
            <Form >
                <Form.Group className="mb-4" controlId="profileFieldName">
                    <Form.Label>Field Name</Form.Label>
                    <Form.Control placeholder="Enter the field name"/>
                    {/* <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>

                {/* <Form.Group className="mb-3" controlId="profileFieldType">
                    <Form.Label>Field Type</Form.Label>
                    <Form.Select aria-label="Default select example">
                        <option value="-1">Select the type of the field</option>
                        <option value="0">Text Field</option>
                        <option value="1">Rich Text Field</option>
                       
                    </Form.Select>
                </Form.Group> */}
                <Form.Check 
                    type="switch"
                    id="switch-profile-add"
                    label="Add to the profile"
                    defaultChecked={true}
                />
                <Form.Check 
                    type="switch"
                    id="switch-onboarding-add"
                    label="Add to the onboarding"
                    className='mb-3'
                />
                <Button variant="primary"  onClick={(event)=>{add_custom_field(event)}}>
                    Add Field
                </Button>
                </Form>
            </div>

            <div className='mt-5'>
                <h5>
                    Created Fields
                </h5>

                <Stack direction='vertical' gap={3} className='mt-3'>
                    {customFieldData!=[]?customFieldData.map((field)=>
                        <div key={field.id + `-`+field.name}>
                        <Stack direction='horizontal' gap={3} >
                        <InputGroup className="settings-input-group">
                            <Form.Control defaultValue={field.name} disabled/>
                            
                        </InputGroup>
                        <Dropdown  drop='bottom'>
                            <Dropdown.Toggle className="bg-transparent border-0 text-dark" >
                                <i className="fa-solid fa-ellipsis"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e)=>{handleUpdateClick(field)}}>Update Field</Dropdown.Item>
                                <Dropdown.Item onClick={(e)=>{delete_custom_field(field.id)}}>Delete Field</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </Stack>
                        </div>
                    ):""}
                  
                
                </Stack>
            </div>
        </>

        {selectedField!=null?
        <>
            <Modal show={showUpdateModal} onHide={handleOnHide} centered>
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Update Field</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <InputGroup className="settings-input-group mb-4">
                <Form.Control defaultValue={selectedField.name} onChange={(event)=>setSelectedField({id:selectedField.id,name:event.target.value,showOnProfile:selectedField.showOnProfile,showOnOnboarding:selectedField.showOnOnboarding})}/>
                            
            </InputGroup>
            <Form.Check 
                    type="switch"
                    id="switch-profile-add"
                    label="Add to the profile"
                   defaultChecked={selectedField.showOnProfile}
                   onChange={(e)=>setSelectedField({id:selectedField.id,name:selectedField.name,showOnProfile:e.target.checked,showOnOnboarding:selectedField.showOnOnboarding})}
                   
                />
                <Form.Check 
                    type="switch"
                    id="switch-onboarding-add"
                    label="Add to the onboarding"
                    defaultChecked={selectedField.showOnOnboarding}
                    onChange={(e)=>setSelectedField({id:selectedField.id,name:selectedField.name,showOnProfile:selectedField.showOnProfile,showOnOnboarding:e.target.checked})}

                />
            <Button className='mt-3' variant="primary" onClick={(event)=>{update_custom_field(event)}}> Save Changes</Button>
        </Modal.Body>
        </Modal>
        </>:""}
        
    </div>
  )
}
