import React, { Component } from "react";
import "../styles/home.css";
import NavbarHome from "../components/home/NavbarHome";
import HomeRightNav from "../components/home/HomeRightNav";
import {
  Col,
  Container,
  Row,
  Tab,
  Spinner,
  Image,
  Stack,
  Button,
} from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { topicDetails, communityDetails, deviceDetails, memberDetails } from "../redux/slice/communitySlice";
import { topicList } from "../redux/slice/topicListSlice";
import { userRole } from "../redux/slice/userSlice";
import Feed from "../components/home/feed/Feed";
import MembersHome from "../components/home/members/MembersHome";
import MemberProfile from "../components/home/MemberProfile";
import CreatePost from "../components/home/CreatePost";
import { useInView } from "react-intersection-observer";
import MetaTags from "react-meta-tags";
import logoImage from "../assets/uuki-logo.png";
import PostItem from "../components/home/feed/post/PostItem";
import { Link, useNavigate } from "react-router-dom";
import Leaderboard from "../components/home/Leaderboard";
import { useMediaQuery } from "react-responsive";
import {toast} from 'react-toastify'
import NotificationDetail from "../components/home/NotificationDetail";
import EditMember from "../components/home/EditMember";
import placeholderImage from "../assets/add-image.png";
import Analytics from "../components/home/Analytics";
import InviteRequest from "../components/home/InviteRequest";
import OnboardingHome from "../components/home/onboarding/OnboardingHome";
import Membership from "../components/home/Membership";
import Courses from "../components/home/course/Courses";
import CourseFeed from "../components/home/CourseFeed";

export default function HomeFeed() {
  const { communitySlug, topicSlug, postSlug, tag } = useParams();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [editPost, setEditPost] = useState([]);
  const [post, setPost] = useState(null);
  const [eventSet, setEventSet] = useState(false);  
  const [isRequested, setIsRequested] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [editMember, setEditMember] = useState([]);
  const [hasCourses, setHasCourses] = useState(false);
  const [key, setKey] = useState("feed");
  const [topicChange, setTopicChange] = useState(false);
  const [dataSet, setDataSet] = useState(false);
  const [widgetScript, setWidgetScript] = useState(null);
  const [linksLocation, setLinksLocation] = useState(0);
  const [meta, setMeta] = useState({ title: "", desc: "" });
  const [embed_script, setEmbedScript] = useState("");
  const [isPublic, setIsPublic] = useState({show:true,theme:'light',join_requested:false});
  const isBigScreen = useMediaQuery({ query: "(min-width: 1600px)" });

  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentTopic = useSelector((state) => state.communityReducer.topicSlug);
  const currentTopicForCourses = useSelector((state) => state.communityReducer.topicForCourses);

  const deviceId = useSelector((state) => state.communityReducer.deviceId);
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  const currentId = useSelector((state) => state.communityReducer.communityId);
  const currentUserId = useSelector((state) => state.userReducer.userId);
  const currentTopicId = useSelector((state) => state.communityReducer.topicId);
  const currentTopicIsRequested = useSelector(
    (state) => state.communityReducer.topicRequested
  );
  const currentTopicList = useSelector(
    (state) => state.topicListReducer.topicList
  );
  const setAnalytics = (id) => {
    var googleIds = ["G-YW5H1H2W85"];
    if(id != ""){
      googleIds.push(id);
    }
    window.dataLayer = window.dataLayer || [];
    window.gtag =
    window.gtag ||
    function() {
        window.dataLayer.push(arguments);
    };
    window.gtag("js", new Date());
    var gtagScriptExists = false;
    for (var i = 0; i < googleIds.length; i++) {
      var gtagId = googleIds[i];
      window.gtag("config", gtagId);
      if (!gtagScriptExists) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = "//www.googletagmanager.com/gtag/js?id=" + gtagId;
        document.getElementsByTagName("head")[0].appendChild(script);
        gtagScriptExists = true;
      }
    }			
  }

  var setInnerHTML = function(elm, html) {
    elm.innerHTML = html;
    Array.from(elm.querySelectorAll("script")).forEach( oldScript => {
      const newScript = document.createElement("script");
      Array.from(oldScript.attributes)
        .forEach( attr => newScript.setAttribute(attr.name, attr.value) );
      newScript.appendChild(document.createTextNode(oldScript.innerHTML));
      oldScript.parentNode.replaceChild(newScript, oldScript);
    });
  }

  useEffect(() => {
	var element = document.getElementById("embed-script");
	console.log("element", element);
	if (element != null) {
	  console.log("setting element", element, embed_script);
	  setInnerHTML(element, embed_script);
	} else {
	  //do nothing
	}	  
  }, [embed_script]);

  const setPage = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let selectedTopic;
    if (Object.entries(data).length > 0) {
      selectedTopic = currentTopicList.filter(
        (topic) => topic.slug == topicSlug
      );
      selectedTopic = selectedTopic[0];
      if (selectedTopic != undefined) {
        dispatch(
          topicDetails({
            id: selectedTopic.topic_id,
            slug: selectedTopic.slug,
            forEvents: selectedTopic.for_events,
            forCourses: selectedTopic.for_courses,
            name: selectedTopic.topic_name,
            isRequested: selectedTopic.isRequested,
          })
        );
        
       
        setKey("feed" + selectedTopic.slug);
        document.title = selectedTopic.topic_name;
        setMeta({
          title: selectedTopic.meta_title,
          desc: selectedTopic.meta_description,
        });
        if (!selectedTopic.show) {
          setKey("privateComm");
        } else if(selectedTopic.for_embed) {
          setKey("embed");
		  setEmbedScript(selectedTopic.embed_script);
		}
      } else if (location.pathname.includes("/post/")) { 
        setKey("post");
        axios
          .get("/v2/loadsinglepost", {
            params: { postSlug: postSlug, commId: data.community_id },
          })
          .then(function (response) {
            let isEvent = false;
            if (response.data.posts[0].type == "event") {
              isEvent = true;
            }
            if(isEvent) {		
               setPost(response.data.posts[0]);			
               setEventSet(isEvent);
            } else {			
               setEventSet(isEvent);
               setPost(response.data.posts[0]);			   
            }				
            setMeta({ title: response.data.posts[0].title, desc: "Community Post" });
            if(response.data.show == false) {
                setKey("privateComm");
            }				


            if (currentTopic == "") {
              dispatch(
                topicDetails({
                  id: response.data.posts[0].topic_id,
                  slug: response.data.posts[0].topic_slug,
                  forEvents: isEvent,
                  forCourses: false,
                  name: response.data.posts[0].topic_name,
                })
              );
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else if (location.pathname.includes("/membership/")) {
        setKey("membership");
      } else if (location.pathname.includes("/members")) {
        dispatch(
          topicDetails({
            id: 0,
            slug: 0,
            forEvents: false,
            forCoures: false,
            name: "Members",
          })
        );
        setKey("members");
        document.title = "Members";
      } else if (location.pathname.includes("/member")) {
        setKey("memberProfile");
      } else if (location.pathname.includes("/welcomeMember")) {
        setKey("welcomeMember");
      } else if (location.pathname.includes("/analytics")) {
        setKey("analytics");
      } else if (location.pathname.includes("/notifications")) {
        setKey("notificationDetail");
      } else if (location.pathname.includes("/invite_requests")) {
        setKey("inviteRequest");
      } else if (location.pathname.includes("/courses")) {
        setKey("courses");
      } else if (location.pathname.includes("/tags/")) {
        dispatch(
          topicDetails({
            id: 0,
            slug: 0,
            forEvents: false,
            forCourses: false,
            name: tag,
          })
        );
        setKey("tagFeed");
        document.title = tag;
        setMeta({ title: data.meta_title, desc: data.meta_description });
      } else {
        dispatch(
          topicDetails({
            id: 0,
            slug: 0,
            forEvents: false,
            forCourses: false,
            name: "Home",
          })
        );
        setKey("feed");
        document.title = "Home";
        setMeta({ title: data.meta_title, desc: data.meta_description });
      }
      document.getElementById("community-favicon").href = data.favicon;
      applyStyles();
      setLinksLocation(data.links_location);
      setDataSet(true);
    }
  };
  useEffect(() => {
    data.length != 0 && setPage();
  }, [location, data]);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    axios
      .get("/v2/c/" + communitySlug)
      .then(function (response) {
        setIsPublic({show:response.data.isPublic,theme:response.data.theme,join_requested:response.data.isRequested});
        if (
          response.data.isPublic ||
          (!response.data.isPublic && response.data.isMember)
        ) {
          response.data.spaces.forEach((space) => {
            space.topics != undefined &&
            space.topics.forEach((topic) => {
                if(topic.for_courses == true){
                  setHasCourses(true)
                }
                dispatch(topicList(topic));
                if (
                  location.pathname.includes("/" + topic.slug) &&
                  topic.show==false
                ) {
                  setKey("privateComm");
                }
              });
          });
          if (response.data.isOnboarded == 0) {
            navigate(
              "/c/" +
                communitySlug +
                "/welcomeMember?memberId=" +
                response.data.memberId
            );
          }
          setData(response.data);
          var newHost = "";
          if (currentHost == "/") {
            newHost = currentHost;
          } else {
            newHost = "/c/" + response.data.community_slug;
          }
          dispatch(
            communityDetails({
              id: response.data.community_id,
              slug: response.data.community_slug,
              name: response.data.community_name,
              host: newHost,
              language: response.data.language,
              allow_anonymous: response.data.allow_anonymous,
              theme: response.data.theme,
              privacyPolicy: response.data.privacy_policy,
              tos: response.data.tos,
			  enable_addon: response.data.enable_addon,
            })
          );
          axios
          .get("/v2/get_member_data?commId=" + response.data.community_id)
          .then(function (response) {
              dispatch(memberDetails(response.data.members));				
          });			  
          axios
            .post('/add_user_device', {
                token: deviceId,
                community_id: response.data.community_id
            })
            .then((response) => {
            });
          setAnalytics(response.data.analytics_id)		  
          if (response.data.isAdmin) {
            dispatch(userRole(2));
          } else if (response.data.isMod) {
            dispatch(userRole(1));
          } else {
            dispatch(userRole(0));
          }
        } else {

          setKey("privateComm");
        }
      })
      .catch(function (error) {
        // handle error
        console.log("Error", error.message);
        if(error.message.includes("reading '4'")) {
            window.location.reload();		
        }			
      });
  };
  // useEffect(() => {
  //   if (post != null) {
  //     setKey("post");
  //   }
  // }, [post]);
  const applyStyles = () => {
    if (data.theme == "light") {
      document.documentElement.style.setProperty("--primary", "#ffffff");
      document.documentElement.style.setProperty("--secondary", "#eff1f5");
      document.documentElement.style.setProperty("--fontColor", "#2d395a");
      document.documentElement.style.setProperty(
        "--border",
        "1px solid #dfdfdf"
      );
    } else {
      document.documentElement.style.setProperty("--primary", "#2B2B2B");
      document.documentElement.style.setProperty("--secondary", "#000000");
      document.documentElement.style.setProperty("--fontColor", "#ffffff");
      document.documentElement.style.setProperty(
        "--border",
        "1px solid #030303"
      );
    }
    document.documentElement.style.setProperty("--accent", data.color);
    document.documentElement.style.setProperty("--fontFamily", data.font);
    document.documentElement.style.setProperty("--themeText", data.textColor);
    document.documentElement.style.setProperty(
      "--hoverColor",
      data.hover_color
    );
  };

  const backToSpace = () => {
    if (currentTopic == "" || currentTopic == 0) {
      navigate(currentHost);
    } else {
      navigate("/c/" + currentSlug + "/" + currentTopic);
    }
    setPost(null);
  };

  const handleRequestToJoin = () => {
    if(currentUserId == "") {
        toast.error("Login to request join");		
    } else {
      axios
      .get("/request_spacejoin", {
        params: {
          id: currentUserId,
          commId: currentId,
          topicId: currentTopicId,
        },
      })
      .then((response) => {		  
        setIsRequested(true)
      })
      .catch((err) => {
        if(err.response.status == 302) {
            window.location.href = err.response.data;		
        }			
        console.log(err);
      });
    }
  };

  const handleCancelRequestToJoin = () => {
    axios
      .get("/cancel_spacejoin", {
        params: {
          id: currentUserId,
          commId: currentId,
          topicId: currentTopicId,
        },
      })
      .then((response) => {
        setIsRequested(false)
      })
      .error((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setIsRequested(currentTopicIsRequested)
  }, [currentTopicIsRequested])
  return (
    <>
      {/* <MetaTags>
				<meta name='title' content={meta.title} />
				<meta name='description' content={meta.desc} />
				<meta property='og:title' content={meta.title} />
				<meta property="og:image" content="path/to/image.jpg" />
			</MetaTags> */}
      { data.show_affiliate == 1 && (
        <div className="d-none d-lg-block powered-by">
          <a
              className="text-decoration-none "
              href={data.affiliate_link}
              target="_blank"
            >
              
                Powered by <b>UUKI</b>
             
            </a>
        </div>
            
          )}
      
      <Tab.Container
        id="settings-nav"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        
        {dataSet ? (
          <Row className="main-row g-0  ">
            {data.show_cover && (
              <Image
                ref={ref}
                className="community-cover "
                fluid
                loading="lazy"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = placeholderImage;
                }}
                src={data.cover}
              />
            )}
            <NavbarHome
              setKey={setKey}
              spaces={data.spaces}
              community={{
                name: data.community_name,
                slug: data.community_slug,
                logo: data.brand_logo,
                show_logo: data.show_logo,
                enable_chat:data.enable_chat,
                has_courses:hasCourses,
                collapse_spaces:data.collapse_spaces
              }}
              member={{
                id: data.memberId,
                name: data.memberName,
                image: data.memberImage,
                isMember: data.isMember,
                isAdmin: data.isAdmin,
                isMod: data.isMod,
                isRequested: data.isRequested,
                isOnboarded: data.isOnboarded,
              }}
              setTopicChange={setTopicChange}
              inView={inView}
              ref={ref}
              linksLocation={linksLocation}
              links={data.community_links}
              notifications={data.notifications}
              counter={{
                notification: data.notifCounter,
                chat: data.chatCounter,
              }}
              affiliate={{
                show: data.show_affiliate,
                link: data.affiliate_link,
              }}
              translate={data.show_translate}
              memberInvite={data.show_member_invite}
              memberList={data.member_list}
            />
           
            <Col
              className="mt-2  ps-0 overflow-hidden"
             
              lg={
                isBigScreen
                  ? !inView
                    ? key == "welcomeMember"
                      ? { span: 8, offset: 4 }
                      : { span: 4, offset: 4 }
                    : key == "welcomeMember"
                    ? { span: 8 }
                    : { span: 4 }
                  : !inView
                  ? key == "welcomeMember"
                    ? { span: 9, offset: 3 }
                    : { span: 6, offset: 3 }
                  : key == "welcomeMember"
                  ? { span: 9 }
                  : { span: 6 }
              }
              // xxl={!inView ? { span: 4, offset: 4 } : { span: 4 }}
            >
              <Tab.Content className="my-md-2 home-content" def>
                <Tab.Pane className="justify-content-center" eventKey="feed">
                  <Feed
                    setKey={setKey}
                    setTopicChange={setTopicChange}
                    setEditPost={setEditPost}
                    tag={tag}
                  />
                   
                </Tab.Pane>
                {dataSet &&
                  data.spaces.map((space) => (
                    <>
                      {space.topics != null
                        ? space.topics.map((topic) => (
                            <Tab.Pane eventKey={"feed" + topic.slug}>
                              {topic.for_courses ? 
                              <CourseFeed/>
                              :
                              <Feed
                                setKey={setKey}
                                setTopicChange={setTopicChange}
                                setEditPost={setEditPost}
                                tag={tag}
                              />}
                            </Tab.Pane>
                          ))
                        : ""}
                    </>
                  ))}
                <Tab.Pane className="justify-content-center" eventKey="tagFeed">
                  <Feed
                    setKey={setKey}
                    setTopicChange={setTopicChange}
                    setEditPost={setEditPost}
                    tag={tag}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="members">
                  <MembersHome
                    getKey={key}
                    count={data.member_count}
                    community_id={data.community_id}
                    enable_chat={data.enable_chat}
                    allow_ranking={data.allow_ranking}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="leaderboard">
                  <Leaderboard getKey={key} communityId={data.community_id} />
                </Tab.Pane>
                <Tab.Pane eventKey="notificationDetail">
                  <NotificationDetail
                    communityId={data.community_id}
                    getKey={key}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="inviteRequest">
                  <InviteRequest slug={currentSlug} getKey={key} />
                </Tab.Pane>
                <Tab.Pane eventKey="membership">
                  <Membership communitySlug={data.community_slug} getKey={key}/>
                </Tab.Pane>
                <Tab.Pane eventKey="post">
                  {post != null && (
                    <>
                      <h4 className="mb-3">
                        {" "}
                        <Button
                          onClick={backToSpace}
						  className={"border-0 bg-transparent " + (isPublic.theme=="dark" ? "text-light" : "text-dark")}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </Button>
                        Go to Space
                      </h4>
                      <div style={{marginBottom:'400px'}}>
                      <PostItem
                        data={post}
                        setKey={setKey}
                        setEditPost={setEditPost}
                        layout={0}
                        setPost={setPost}
                        
                      />
                      </div>
                    </>
                  )}
                  <div className="p-5" />
                </Tab.Pane>
                <Tab.Pane eventKey="createPost">
                  <CreatePost
                    setKey={setKey}
                    post={editPost}
                    spaces={data.spaces}
                    setEditPost={setEditPost}
                    adminCodes={data.admin_codes}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="editMember">
                  <EditMember
                    member={editMember}
                    setKey={setKey}
                    navigate_from="homefeed"					
                    communityName={data.community_name}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="welcomeMember">
                  {/* <EditMember
										member={editMember}
										setKey={setKey}
										communityName={data.community_name}
									/> */}
                  <OnboardingHome
                    setKey={setKey}
                    completed={data.stepsDone}
                    isAdmin={data.isAdmin}
                    communityName={data.community_name}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="memberProfile">
                  <MemberProfile
                    getKey={key}
                    setKey={setKey}
                    selectedMember={selectedMember}
                    setEditMember={setEditMember}
                    enable_chat={data.enable_chat}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="analytics">
                  <Analytics getKey={key} />
                </Tab.Pane>
                <Tab.Pane eventKey="courses">
                  <Courses isAdmin={data.isAdmin}/>
                </Tab.Pane>
                <Tab.Pane eventKey="privateComm">
                  <Stack gap={4} className="vh-100 pt-4 align-items-center">
                      <i className="fa-solid  fa-lock fa-lg"></i>
                    <h5 className="text-center">
                       This Community/Space is Private
                    </h5>
                    <div className="d-flex justify-content-center align-items-center ">
                      {isRequested != null &&
                      isRequested ? (
                        <Button
                          className="uuki-btn uuki-community-btn"
                          onClick={handleCancelRequestToJoin}
                        >
                          Cancel Request
                        </Button>
                      ) : (
                        <Button
                          className="uuki-btn uuki-community-btn"
                          onClick={handleRequestToJoin}
                        >
                          Request to Join
                        </Button>
                      )}
                    </div>
                  </Stack>
                </Tab.Pane>
                <Tab.Pane eventKey="embed">
                  <Stack gap={4} className="vh-100 pt-4">
                    <div id="embed-script" style={{ overflow: "auto" }}></div>
                  </Stack>
                </Tab.Pane>				
              </Tab.Content>
            </Col>
            <Col
              className={
                key == "welcomeMember"
                  ? "d-none"
                  : !inView
                  ? "mt-5 pt-4  position-fixed d-none d-lg-block"
                  : "mt-2  d-none d-lg-block"
              }
              md={
                isBigScreen
                  ? !inView
                    ? { span: 4, offset: 8 }
                    : { span: 4 }
                  : !inView
                  ? { span: 3, offset: 9 }
                  : { span: 3 }
              }
            >
              <HomeRightNav
                navData={data}
                widget={widgetScript}
                linksLocation={linksLocation}
                links={data.community_links}
                notifications={data.notifications}
                event={post}
                eventSet={eventSet}				
                setKey={setKey}
                affiliate={{
                  show: data.show_affiliate,
                  link: data.affiliate_link,
                }}
                allow_ranking={data.allow_ranking}
                show_trending={data.show_trending}				
              />
            </Col>
          </Row>
        ) : (
          <div
            style={isPublic.theme=='light'?{ overflowY: "hidden",backgroundColor:'#eff1f5',color:'#2d395a'}:{ overflowY: "hidden",backgroundColor:'#000000',color:'#fff'}}
            className=" vh-100 d-flex flex-column align-items-center justify-content-center"
          >
            <>
              {!isPublic.show ? (
                <>
                  <div ref={ref}>
                    <h3>
					 {isPublic.join_requested ? (
					  <>
					  Join Request pending activation
					  </>
					  ): (
					  <>
                      This Community is Invite Only. Already a member ?
                      <Link
                        className=" ms-1"
                        to="/login"
                        style={{ color: "#21cd9c", fontWeight: "bold" }}
                      >
                        Login
                      </Link>					  
					  </>
					  )}
                    </h3>
                  </div>
                </>
              ) : (
                <>
                  <div className="p-4 d-flex justify-content-center" ref={ref}>
                    {/* <Spinner animation="border" /> */}
                    <span class="loader-spinner-main" />
                  </div>
                </>
              )}
            </>
          </div>
        )}
      </Tab.Container>
    </>
  );
}
