import { Button, Stack, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as IncludedIcon } from "../../assets/included.svg";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMoralisWeb3Api, useMoralis } from "react-moralis";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function Membership({ communitySlug, getKey }) {
  const { membershipUrl } = useParams();
  const [data, setData] = useState(null);
  const Web3Api = useMoralisWeb3Api();
  const currentId = useSelector((state) => state.communityReducer.communityId);

  const {
    authenticate,
    isAuthenticated,
    isAuthenticating,
    user,
    account,
    logout,
  } = useMoralis();
  const loginMetamask = async () => {
    if (!isAuthenticated) {
      await authenticate({ signingMessage: "Log in using Moralis" })
        .then(function (user) {
          axios
            .post("/connect_metamask", {
              communityId: currentId,
              address: user.get("ethAddress"),
            })
            .then((res) => {
              toast.success("Wallet Connected");
            })
            .catch(function (error) {
              toast.error("Wallet Connect Failed");
            });
          console.log(user);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  async function getNFTS(nftDetails,id) {
    const options = {
      method: "GET",
      headers: { Accept: "application/json", "X-API-Key": "WMYLSj3BqdGX03Taw1F...Dq11RQj2NuduYSh" },
    };

    fetch(
      "https://deep-index.moralis.io/api/v2/nft/" +
        nftDetails.address +
        "/" +
        nftDetails.token_id +
        "/owners?chain=" +
        nftDetails.chain +
        "&format=decimal",
      options
    )
      .then((response) => response.json())
      .then((response) => {
        response.result.forEach((owner) => {
          if (
            owner.owner_of.toUpperCase() == data.memberAddress.toUpperCase()
          ) {
            addMembership(id)
          }
        });
      })
      .catch((err) => console.error(err));
  }
  
  const addMembership = (id) => {
      axios
        .post("/addusertomembership", {
          membership_id: id,
        })
        .then(function (response) {
          toast.success("Membership Added");
		  window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });
      
  }


  useEffect(() => {
    if (getKey == "membership") {
      axios
        .post("/v2/getMembership", {
          commUrl: communitySlug,
          url: membershipUrl,
        })
        .then(function (response) {
          if (response.data != "Invalid URL") {
            setData(response.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [getKey]);
  const payStripe = (id) => {
    window.location.href = "/stripe_payment?membership_id=" + id;
  };
  const payNFT = (id) => {
    if (data.memberAddress == "") {
      loginMetamask();
    } else {
      var nftDetails;
      {
        data.membershipNfts
          .filter((nft) => nft.membership_id == id)
          .forEach((nft) => {
            nftDetails = {
              address: nft.address,
              token_id: nft.tokenId,
              chain: nft.chain,
            };
            getNFTS(nftDetails,id);
          });
      }
    }
  };
  return (
    <Row>
      {data != null ? (
        data.memberships.map((membership) => (
          <Col md={6}>
            <Stack className="p-4 uuki-box text-center right-nav-block" gap={3}>
              <h5>{membership.name}</h5>
              {membership.payType == 0 && (
                <Stack className="mx-5 px-4" gap={3} direction="horizontal">
                  <b>{membership.price} {membership.currency}</b>
                  <span className="ms-auto">
                    {membership.type == 1 ? "Monthly" : "One-Time"}
                  </span>
                </Stack>
              )}
              <span>{membership.desc}</span>
              <small>Access To</small>
              {data.membershipTopics
                .filter((topic) => topic.membership_id == membership.id)
                .map((topic) => (
                  <Stack gap={3} className="mx-5" direction="horizontal">
                    <IncludedIcon />
                    <b>{topic.name}</b>
                  </Stack>
                ))}
              {data.membershipNfts.filter(
                (nft) => nft.membership_id == membership.id
              ).length != 0 && <small>Assets Required</small>}
              {data.membershipNfts
                .filter((nft) => nft.membership_id == membership.id)
                .map((nft, index) => (
                  <a
                    href={
                      nft.chain == "eth"
                        ? "https://opensea.io/assets/ethereum/" +
                          nft.address +
                          "/" +
                          nft.tokenId
                        : "https://opensea.io/assets/" +
                          nft.chain +
                          "/" +
                          nft.address +
                          "/" +
                          nft.tokenId
                    }
                  >
                    NFT #{index + 1}
                  </a>
                ))}

              <div className="content-border"></div>
              <Button
                className="uuki-big-btn uuki-community-btn"
                onClick={() =>
                  membership.payType == 0
                    ? payStripe(membership.id)
                    : payNFT(membership.id)
                }
              >
                Join
              </Button>
            </Stack>
          </Col>
        ))
      ) : (
        <h5 className="text-center py-4">Memberships Unavailable</h5>
      )}
    </Row>
  );
}

export default Membership;
