import React from "react";
import "../../styles/leaderboard.css";
import { Image, ProgressBar, Stack } from "react-bootstrap";
import medalImage from "../../assets/medal.svg";
import { useEffect, useState } from "react";
import placeholderImage from '../../assets/add-image.png'
import Avatar from "react-avatar";

import axios from "axios";

export default function Leaderboard({ getKey,communityId }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if(getKey=='leaderboard' && data == null){
      axios
        .get("/v2/leaderboardData?community=" + communityId)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [getKey]);

  return (
    <div className="d-flex justify-content-center">
      <div className="view-leaderboard-container center-container uuki-box p-3 mb-4">
        <h6 style={{ fontWeight: "700" }}>Community Leaderboard</h6>
        <hr className="mt-3" />
        <span className="mt-2">Progress towards next rank</span>
        <div className="leaderboard-karma-block uuki-box p-4 mt-3">
          <Stack direction="horizontal" gap={2}>
            <Image src={medalImage} loading="lazy"/>
            <h4 style={{ fontSize: "1.125rem" }} className="pt-3 ">
              {data != null && data.userKarma != null
                ? data.userKarma.total_karma
                : 0}{" "}
              karma
            </h4>
            <span className="ms-auto pt-3">
              Level{" "}
              {data != null && data.userKarma != null ? data.userKarma.level : 0}
            </span>
          </Stack>
          <ProgressBar
            style={{ borderRadius: "1rem", height: "0.6rem" }}
            now={
              data != null && data.userKarma != null
                ? data.userKarma.percentage
                : 0
            }
            className="ps-0 mt-4"
          />
          <Stack gap={2} direction="horizontal" className="mt-4 mb-4">
            <div className="leaderboard-rank py-1">
              {data != null && data.userKarma != null
                ? data.userKarma.current_rank
                : 0}
            </div>
            <span className="ms-auto">
              Next Rank:{" "}
              {data != null && data.userKarma != null
                ? data.userKarma.next_rank
                : ""}
            </span>
          </Stack>
        </div>
        <div className="mt-5"> Top Contibutors</div>
        <Stack
          direction="horizontal"
          gap={3}
          className="justify-content-center mt-5"
        >
          {data != null && data.topContributors != null
            ? data.topContributors.map((contributor, index) => (
                <div className="leaderboard-top-contributor-block">
                  <div className="ms-3 mt-3 mb-2">{index + 1}</div>
                  <div className="d-flex flex-column align-items-center">
                    <i class="fas fa-2x top-1-crown fa-crown"></i>
                    {contributor.profile_image != "" ? (
                      <Image
                        src={contributor.profile_image}
                        className="leaderboard-top-contributor-image"
                        roundedCircle
                        loading="lazy"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src=placeholderImage;
                        }}
                      ></Image>
                    ) : (
                      <Avatar
                        name={data.user_name}
                        size="32"
                        textSizeRatio={2.5}
                        round={true}
                      />
                    )}
                    <span className="mb-3 text-truncate w-100 px-2">{contributor.user_name}</span>
                  </div>
                </div>
              ))
            : ""}
        </Stack>
        <Stack
          direction="horizontal"
          gap={3}
          className="mt-5 justify-content-around leaderboard-list-header p-2"
        >
          <span style={{ color: "white" }}>Rank</span>
          <span style={{ color: "white" }}>Member</span>
          <span className="ms-4" style={{ color: "white" }}>
            Karma
          </span>
          <span style={{ color: "white" }}>Level</span>
          <span style={{ color: "white" }}>Rank Tier</span>
        </Stack>
        {data != null && data.topKarma != null
          ? data.topKarma.map((topUser, index) => (
              <Stack
                direction="horizontal"
                gap={3}
                className="mt-4 leaderboard-karma-block justify-content-around p-2"
              >
                <span className="leaderboard-column">{index + 1}</span>
                <span className="text-truncate leaderboard-column">{topUser.user_name}</span>
                <span className="ms-4 leaderboard-column">{topUser.total_karma}</span>
                <span className="leaderboard-column">{topUser.level}</span>
                <span className="leaderboard-column">{topUser.current_rank}</span>
              </Stack>
            ))
          : ""}
          <div className="p-5"/>

      </div>
    </div>
  );
}
