import { createSlice } from "@reduxjs/toolkit";

const state = () => {
  const initialState = {
    showLoginModal: false,
    showSignupModal: false,
  };

  return initialState;
};

export const authenticationSlice = createSlice({
  name: "authenticationSlice",
  initialState: state,
  reducers: {
    showLoginModal: (state, action) => {
      state.showLoginModal = action.payload;
    },
    showSignupModal: (state, action) => {
      state.showSignupModal = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showLoginModal, showSignupModal } = authenticationSlice.actions;

export default authenticationSlice.reducer;
