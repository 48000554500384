import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import {
  Container,
  Col,
  Nav,
  Navbar,
  Offcanvas,
  Image,
  Stack,
  Button,
  Popover,
  OverlayTrigger,
  Badge,
  ListGroup,
} from "react-bootstrap";
import Avatar from "react-avatar";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../../assets/uuki-logo.png";
import { ReactComponent as SettingsIcon } from "../../assets/navbar-settings.svg";
import { ReactComponent as ChatIcon } from "../../assets/navbar-chat.svg";
import { ReactComponent as NotificationIcon } from "../../assets/navbar-notification.svg";
import { useSelector } from "react-redux";
import CommonNavbar from "../CommonNavbar";

function NavbarSettings({ setKey, communityName,communityLogo,showLogo, member }) {
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  const isBigScreen = useMediaQuery({ query: "(min-width: 1600px)" });
  const logout = (e) => {
    e.preventDefault();
    axios.get("/logout?noRedirect=1").then((res) => navigate("/login"));
  };
  useEffect(() => {
    document.querySelectorAll(".dashboard-icon").forEach((element) => {
      if (element.parentElement.parentElement.classList.contains("active")) {
        element.setAttribute("filter", "url(#white-svg)");
      } else {
        element.setAttribute("filter", "url(#black-svg)");
      }
    });
  });

  return (
    <>
      <Navbar fixed="top" className="nav-uuki nav-home" expand={false}>
        <Navbar.Brand className="community-name" href={currentHost}>
        {showLogo==false || communityLogo == "" ? (
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate(currentHost)}
            >
              {communityName}
            </h4>
          ) : (
            <Image
            fluid
              style={{ cursor: "pointer" }}
              onClick={() => navigate(currentHost)}
              loading="lazy"
              className="brand-logo"
              src={communityLogo}
            />
          )}
        </Navbar.Brand>
        {isDesktop ? (
          <CommonNavbar></CommonNavbar>
        ) : (
          <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={()=>setShowNavbar((prev)=>true)}/>
        )}
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
          show={showNavbar}
          onHide={()=>setShowNavbar((prev)=>false)}
        >
          <Offcanvas.Header closeButton>
            <Navbar.Brand className="community-name">
            {showLogo==false || communityLogo == "" ? (
              <h4
                style={{ cursor: "pointer" }}
                onClick={() => navigate(currentHost)}
              >
              {communityName}
              </h4>
            ) : (
              <Image
                fluid
                style={{ cursor: "pointer" }}
                onClick={() => navigate(currentHost)}
                loading="lazy"
                className="brand-logo"
                src={communityLogo}
              />
            )}
            </Navbar.Brand>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Stack className="h-100" gap={2}>
              <Nav variant="pills" className="flex-column text-nowrap">
                <Stack gap={3}>
                  {member.isAdmin && (
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="communitySettings"
                      onClick={() => {setKey("communitySettings");setShowNavbar((prev)=>false)}}
                    >
                      Customization
                    </Nav.Link>
                  </Nav.Item>
                  )}				  
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="notifications"
                      onClick={() => {setKey("notifications");setShowNavbar((prev)=>false)}}
                    >
                      Notifications
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="account"
                      onClick={() => {setKey("account");setShowNavbar((prev)=>false)}}
                    >
                      Account
                    </Nav.Link>
                  </Nav.Item>
                  {member.isAdmin && (
                  <>				  
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="profile"
                      onClick={() => {setKey("profile");setShowNavbar((prev)=>false)}}
                    >
                      Profile
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="spaceSettings"
                      onClick={() => {setKey("spaceSettings");setShowNavbar((prev)=>false)}}
                    >
                      Spaces
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="spaceArrange"
                      onClick={() => {setKey("spaceArrange");setShowNavbar((prev)=>false)}}
                    >
                      Reorder Spaces
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="links"
                      onClick={() => {setKey("links");setShowNavbar((prev)=>false)}}
                    >
                      Links
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="gamification"
                      onClick={() => {setKey("gamification");setShowNavbar((prev)=>false)}}
                    >
                      Gamification
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="api"
                      onClick={() => {setKey("api");setShowNavbar((prev)=>false)}}
                    >
                      API
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="webhooks"
                      onClick={() => {setKey("webhooks");setShowNavbar((prev)=>false)}}
                    >
                      Webhooks
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="sso"
                      onClick={() => {setKey("sso");setShowNavbar((prev)=>false)}}
                    >
                      SSO
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="embeds"
                      onClick={() => {setKey("embeds");setShowNavbar((prev)=>false)}}
                    >
                      Embeds
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="tags"
                      onClick={() => {setKey("tags");setShowNavbar((prev)=>false)}}
                    >
                      Member Tags
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="pwa"
                      onClick={() => {setKey("pwa");setShowNavbar((prev)=>false)}}
                    >
                      PWA
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="smtp"
                      onClick={() => {setKey("smtp");setShowNavbar((prev)=>false)}}
                    >
                      SMTP
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="integrations"
                      onClick={() => {setKey("integrations");setShowNavbar((prev)=>false)}}
                    >
                      Integrations
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="memberships"
                      onClick={() => {setKey("memberships");setShowNavbar((prev)=>false)}}
                    >
                      Memberships
                    </Nav.Link>
                  </Nav.Item>
                  </>				  
                  )}				  
                </Stack>
              </Nav>
            </Stack>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
      {isDesktop && (
        <Col className="position-fixed mt-5 py-4 px-0" md={isBigScreen ? 4 : 3}>
          <div className="ms-3 vh-100 mb-5  py-2 overflow-scroll home-left-nav nav-scroll">
            <Nav
              variant="pills"
              className=" scroll-nav fixed-nav flex-column text-nowrap pb-3"
            >
              {member.isAdmin && (
                <Nav.Item className="home-link">
                  <Nav.Link
                    className="main-links d-flex align-items-center"
                    eventKey="communitySettings"
                    onClick={() => setKey("communitySettings")}
                  >
                    Customization
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item className="home-link">
                <Nav.Link
                  className="main-links d-flex align-items-center"
                  eventKey="notifications"
                  onClick={() => setKey("notifications")}
                >
                  Notifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="home-link">
                <Nav.Link
                  className="main-links d-flex align-items-center"
                  eventKey="account"
                  onClick={() => setKey("account")}
                >
                  Account
                </Nav.Link>
              </Nav.Item>
              {member.isAdmin && (
                <>			  
              <Nav.Item className="home-link">
                    <Nav.Link
                      className="main-links d-flex align-items-center"
                      eventKey="profile"
                      onClick={() => setKey("profile")}
                    >
                      Profile
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="main-links d-flex align-items-center"
                      eventKey="spaceSettings"
                      onClick={() => setKey("spaceSettings")}
                    >
                      Spaces
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="main-links d-flex align-items-center"
                      eventKey="spaceArrange"
                      onClick={() => setKey("spaceArrange")}
                    >
                      Reorder Spaces
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="main-links d-flex align-items-center"
                      eventKey="links"
                      onClick={() => setKey("links")}
                    >
                      Links
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="main-links d-flex align-items-center"
                      eventKey="gamification"
                      onClick={() => setKey("gamification")}
                    >
                      Gamification
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="main-links d-flex align-items-center"
                      eventKey="api"
                      onClick={() => setKey("api")}
                    >
                      API
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="main-links d-flex align-items-center"
                      eventKey="webhooks"
                      onClick={() => setKey("webhooks")}
                    >
                      Webhooks
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="main-links d-flex align-items-center"
                      eventKey="sso"
                      onClick={() => setKey("sso")}
                    >
                      SSO
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="main-links d-flex align-items-center"
                      eventKey="embeds"
                      onClick={() => setKey("embeds")}
                    >
                      Embeds
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="main-links d-flex align-items-center"
                      eventKey="tags"
                      onClick={() => setKey("tags")}
                    >
                      Member Tags
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="main-links d-flex align-items-center"
                      eventKey="pwa"
                      onClick={() => setKey("pwa")}
                    >
                      PWA
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="main-links d-flex align-items-center"
                      eventKey="smtp"
                      onClick={() => setKey("smtp")}
                    >
                      SMTP
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="main-links d-flex align-items-center"
                      eventKey="integrations"
                      onClick={() => setKey("integrations")}
                    >
                      Integrations
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link ">
                    <Nav.Link
                      className="main-links d-flex align-items-center"
                      eventKey="memberships"
                      onClick={() => setKey("memberships")}
                    >
                      Memberships
                    </Nav.Link>
                  </Nav.Item>
                </>
              )}
              <div className="p-5"></div>
            </Nav>
          </div>
        </Col>
      )}
    </>
  );
}

export default NavbarSettings;
