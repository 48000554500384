import { useEffect, useState,useRef } from "react";
import { Tab, Nav, Stack, Button } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import QuestionAnswers from "./QuestionAnswers";
import QuizSettings from "./QuizSettings";
import QuizSubmissions from "./QuizSubmissions";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function QuizDashboard({adminCodes,setKey,quizId}) {
    const [loaded, setLoaded] = useState(false);
    const [quizKey, setQuizKey] = useState("settings");
      const { ref, inView, entry } = useInView({
    threshold: 0,
    });
    const [settings, setSettings] = useState(null)
    const [queAns, setQueAns] = useState([])
    const [submissions, setSubmissions] = useState([])
    const [stats, setStats] = useState(null)
    const [showLoading,setShowLoading] = useState(false)

    const currentCommunityId = useSelector(
        (state) => state.communityReducer.communityId
      );
    const currentTopicId = useSelector((state) => state.communityReducer.topicId);
    const uploadImage = (file) => {
        return new Promise(function (resolve, reject) {
          axios
            .get("/v2/get_community_image_url", {
              params: {
                topicId: currentTopicId,
                commId: currentCommunityId,
                filename: file.name,
              },
            })
            .then((res) => {
              res = res.data;
              let url = res.url;
              let fields = res.fields;
              let formData = new FormData();
              for (let key in fields) {
                formData.append(key, fields[key]);
              }
              formData.append("file", file);
              const xhr = new XMLHttpRequest();
              xhr.addEventListener("loadend", uploadComplete, false);
              function uploadComplete(event) {
                resolve("https://communitydata.uuki.live/" + fields["key"]);
              }
              xhr.onerror = reject;
              xhr.open("POST", url, true);
              xhr.send(formData);
              toast.success("Image Uploaded");
            })
            .catch(function (error) {
              toast.error("Image Upload Failed");
            });
        });
      };
      const uploadImageByUrl = (url) => {
        return new Promise(function (resolve, reject) {
          resolve(url);
        });
      };
      const customParsers = {
        linkTool: function (data, config) {
          // return `<a href="${data.link}" ></a>`;
          return `<div class="card post-card" >
                            <a href="${data.link}">
                            <img src="${data.meta.image.url}" class="card-img-top" alt="...">
                            <div class="card-body">
                                <h5 class="card-title text-dark">${data.meta.title}</h5>
                                <p class="card-text text-dark">${data.meta.description}</p>
                            </div>
                            </a>
                        </div>`;
        },
        attaches: function (data, config) {
          var filesize = parseFloat(parseInt(data.file.size) / 1024).toFixed(1);
          return `<div class="cdx-attaches cdx-attaches--with-file">
                                    <div class="cdx-attaches__file-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40">
                                    <g fill="#A8ACB8" fill-rule="evenodd">
                                    <path fill-rule="nonzero" d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"></path>
                                    <path d="M7 22h18v2H7zm0 4h18v2H7zm0 4h18v2H7z"></path>
                                    </g></svg></div>
                                    <div class="cdx-attaches__file-info">
                                    <div class="cdx-attaches__title" contenteditable="false">${data.title}</div>
                                    <div class="cdx-attaches__size" data-size="KiB">${filesize}</div></div>
                                    <a class="cdx-attaches__download-button" href="${data.file.url}" target="_blank" rel="nofollow noindex noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="17pt" height="17pt" viewBox="0 0 17 17"><path d="M9.457 8.945V2.848A.959.959 0 0 0 8.5 1.89a.959.959 0 0 0-.957.957v6.097L4.488 5.891a.952.952 0 0 0-1.351 0 .952.952 0 0 0 0 1.351l4.687 4.688a.955.955 0 0 0 1.352 0l4.687-4.688a.952.952 0 0 0 0-1.351.952.952 0 0 0-1.351 0zM3.59 14.937h9.82a.953.953 0 0 0 .953-.957.952.952 0 0 0-.953-.953H3.59a.952.952 0 0 0-.953.953c0 .532.425.957.953.957zm0 0" fill-rule="evenodd"></path></svg></a></div>`;
        },
      };
    useEffect(() => {	
        if (quizId.id!=0) {
          axios
            .post("/v2/get_quiz_info", {
              id: quizId.id
            })
            .then((res) => {
             setSettings(res.data.settings)
             setQueAns(res.data.questions)
             setSubmissions(res.data.submissions)
             setStats(res.data.stats)
             setLoaded((prev)=>true)
            })
            .catch(function (error) {
              console.log("Course Not Found");
            });
        }
      }, [quizId,inView]);


      const handleGenerateQuestion = ()=>{
        setShowLoading(true)
        axios
        .post("/v2/generate_quiz_question", {
          id: quizId.id,
          commId:currentCommunityId
        })
        .then((res) => {
		  console.log("Res", res);	
          if(res.data.json_content!=null && res.data.answers!=[]){
            setQueAns([...queAns,{"jsonContent":res.data,"isGenerated":true}])
            setShowLoading(false)
          }else if("error" in res.data){
            toast.error(res.data.error)
            setShowLoading(false)
          }else{
            toast.error("Something went wrong.Please try again")
            setShowLoading(false)
          }
        })
        .catch(function (error) {
          toast.error("Something went wrong.Please try again")
          setShowLoading(false)
        });   

      } 

      return (
        <>
            <Stack className="mb-3" direction="horizontal">
                <Button
                onClick={() => setKey("curriculum")}
                style={{color:'var(--fontColor)'}}
                className=" border-0 bg-transparent "
                >
                <i className="fa-solid fa-arrow-left me-2"></i>Back
                </Button>
            </Stack>
            <div className="d-flex justify-content-center">
            <Stack className=" center-container ">
                {loaded == true ? (
                <Tab.Container
                    id="course-dashboard"
                    activeKey={quizKey}
                    onSelect={(k) => setQuizKey(k)}
                >
                    <Nav
                    variant="pills"
                    className="text-nowrap mb-3 uuki-box  course-block p-3"
                    >
                    <Stack direction="horizontal" gap={2} className="admin-scroll">
                        <Nav.Item className="home-link">
                        <Nav.Link
                            className="d-flex align-items-center"
                            eventKey="settings"
                            style={{ color: "var(--fontColor)" }}
                        >
                            Quiz Settings
                        </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="home-link">
                        <Nav.Link
                            className="d-flex align-items-center"
                            eventKey="queAns"
                            style={{ color: "var(--fontColor)" }}
                        >
                            Questions & Answers
                        </Nav.Link>
                        </Nav.Item>
                       <Nav.Item className="home-link">
                        <Nav.Link
                            className="d-flex align-items-center"
                            eventKey="submissions"
                            style={{ color: "var(--fontColor)" }}
                        >
                            Submissions
                        </Nav.Link>
                        </Nav.Item>
                    </Stack>
                    </Nav>
            
                    <Tab.Content>
                    <Tab.Pane
                        className="uuki-box  course-block p-4"
                        eventKey="queAns"
                    >
                        { queAns.map((question,index)=><QuestionAnswers editorHelpers={{uploadImage:uploadImage,uploadImageByUrl:uploadImageByUrl,customParsers:customParsers,adminCodes:adminCodes}} quizId={quizId.id} getIndex={index} question={question} setQueAns={setQueAns} queAns={queAns}/>)}
                        <Stack className="mt-3" direction="horizontal">
                            <Button className="uuki-btn uuki-community-btn " onClick={()=>setQueAns([...queAns,{}])}>Add Question</Button>
                            {showLoading?<span className="ms-2">Generating question. Please wait...</span>:<Button className="uuki-btn uuki-community-btn ms-2" onClick={()=>handleGenerateQuestion()}>Generate Question</Button>}
                        </Stack>

                    </Tab.Pane>
                    <Tab.Pane
                        className="uuki-box  course-block p-4"
                        eventKey="settings"
                    >
                        <QuizSettings editorHelpers={{uploadImage:uploadImage,uploadImageByUrl:uploadImageByUrl,customParsers:customParsers,adminCodes:adminCodes}} settings={settings} setKey={setKey}/>
                    </Tab.Pane>
                    <Tab.Pane
                        className="uuki-box  course-block p-4"
                        eventKey="submissions"
                    >
                        <QuizSubmissions submissions={submissions} queAns={queAns} stats={stats} quizId={quizId.id}/>
                    </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
                ) : (
                <div className="p-4 d-flex justify-content-center" ref={ref}>
                    {/* <Spinner animation="border" /> */}
                    <span class="loader-spinner" />
                </div>
                )}
            </Stack>
            </div>
        </>
    )
}

export default QuizDashboard
