import React, { useEffect } from "react";
import { Modal, Form, Button, Stack } from "react-bootstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  usernameStatus,
  userLoggedIn,
  userId,
} from "../../redux/slice/userSlice";
import { useState } from "react";
import "../../styles/loginmodal.css";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

import {
  showLoginModal,
  showSignupModal,
} from "../../redux/slice/authenticationSlice";

import "../../styles/loginmodal.css";
import { Link, useNavigate } from "react-router-dom";

export default function LoginModal(props) {
  const navigate = useNavigate();
  const [isPolicyChecked, setIsPolicyChecked] = useState(false);
  const [data, setData] = useState(null);
  const [captchaValue, setCaptchaValue] = useState("");
  const currentTheme = useSelector(
    (state) => state.communityReducer.theme
  );    

  const dispatch = useDispatch();
  const isToShowSigupModal = useSelector(
    (state) => state.authenticationReducer.showSignupModal
  );
  const privacyPolicy = useSelector(
    (state) => state.communityReducer.privacyPolicy
  );
  const tos = useSelector(
    (state) => state.communityReducer.tos
  );  

  useEffect(() => {
    let endpoints = ["/v2/get_community_info?url=" + window.location.href];
    axios
      .all(endpoints.map((endpoint) => axios.get(endpoint)))
      .then((data) => setData(data[0].data));
  }, []);

  const handleGoogleLogin = () => {
    window.location.href = "/google_login";
  };

  const handleFBLogin = () => {
    window.location.href = "/facebook_login";
  };

  const handleWPLogin = () => {
    window.location.href = "/wordpress_login";
  };

  const handleLinkedinLogin = () => {
    window.location.href = "/linkedin_login";
  };

  const handleJWTLogin = () => {
    if (
      data != null &&
      typeof data.jwtUrl != "undefined" &&
      data.jwtUrl != ""
    ) {
      window.location.href = data.jwtUrl;
    } else {
      toast.error("JWT config not setup");		
    }
  };
  
  const handleLogin = (e) => {
    e.preventDefault();

    const email = document.getElementById("loginUserEmail").value;
    const password = document.getElementById("loginUserPassword").value;
    if ((email && email !== "") || (password && password !== "")) {
      axios
        .post("/v2/login", { email: email, password: password })
        .then((res) => {
          // loginUser({id:res.data.id});
          dispatch(usernameStatus(res.data.name));
          dispatch(userLoggedIn(true));
          dispatch(userId(res.data.id));
          dispatch(showLoginModal(false));
          window.location.reload();
        })
        .catch(function (error) {
          // handle error
          toast.error(error.response.data);
          console.log("Error", error.response.data);
        });
    } else {
      toast.error("Incomplete Details.");
    }
  };

  const handleSignup = (e) => {
    e.preventDefault();
    const email = document.getElementById("loginUserEmail").value;
    const password = document.getElementById("loginUserPassword").value;
    const username = document.getElementById("loginUserName").value;

    if (
      (email && email !== "") ||
      (password && password !== "") ||
      (username && username !== "")
    ) {
      if (isPolicyChecked) {
        axios
          .post("/v2/signup", {
            email: email,
            name: username,
            password: password,
            captcha: captchaValue,
          })
          .then((res) => {
            console.log(res);
            toast.success("Signup complete. Please login.");
            dispatch(showSignupModal(false));
          })
          .catch(({ response }) => {
            console.log(response.data);
            if (response != null && response.status == 302 || response.status == 301) {
              toast.success(response.data);
              dispatch(showSignupModal(false));
            } else {
              toast.error("something went wrong");
            }
          });
      } else {
        toast.error("Please accept Privacy policy and TOS.");
      }
    } else {
      toast.error("Incomplete Details.");
    }
  };

  const handleOnHide = () => {
    dispatch(showLoginModal(false));
    if (isToShowSigupModal) {
      dispatch(showSignupModal(false));
    }
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setCaptchaValue(value);
  }
  return (
    <>
      <Modal show={props.show} onHide={handleOnHide} centered>
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>{isToShowSigupModal ? "Signup" : "Login"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Enter your email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                id="loginUserEmail"
              />
            </Form.Group>

            {isToShowSigupModal ? (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Enter Name</Form.Label>
                  <Form.Control placeholder="Enter Name" id="loginUserName" />
                </Form.Group>
              </>
            ) : (
              ""
            )}

            <Form.Group className="mb-3">
              <Form.Label>Enter Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                id="loginUserPassword"
              />
            </Form.Group>

            {!isToShowSigupModal ? (
              <div className="d-flex justify-content-end">
                <Link
                  className="text-decoration-none"
                  to="/forgot_password"
                  style={{ color: "#21cd9c" }}
                >
                  Forgot Password
                </Link>
              </div>
            ) : (
              <>
                <div>
                  <ReCAPTCHA
                    sitekey="6LeBy4YfAAAAAPehRlyT3bEwwWmSN6Iy442S61hq"
                    onChange={onChange}
                  />
                  <span>
                    This site is protected by reCAPTCHA and Google{" "}
                    <a href="https://policies.google.com/privacy">
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a href="https://policies.google.com/terms">
                      Terms of Service
                    </a>{" "}
                    apply
                  </span>
                  <Form.Check
                    label={
                      <span>
                        Accept the community’s{" "}
                        <a href={(privacyPolicy=="" ? "https://www.uuki.live/utility/privacy" : privacyPolicy)}>
                          Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a href={(tos=="" ? "https://www.uuki.live/utility/terms-conditions" : tos)}>
                          Terms of Service
                        </a>
                      </span>
                    }
                    name="group1"
                    type="checkbox"
                    id="checkboxPrivacy"
                    className="mt-3"
                    onChange={() => setIsPolicyChecked(!isPolicyChecked)}
                  />
                </div>
              </>
            )}

            <Stack direction="vertical" gap={3}>
              {!isToShowSigupModal ? (
                <>
                  <div className="d-flex justify-content-center">
                    <Button
                      onClick={handleLogin}
                      className="mt-4 me-4 ms-4 uuki-btn uuki-community-btn action-btn"
                    >
                      Login
                    </Button>
                  </div>

                  <div className="d-flex justify-content-center">
                    <span>
                      New Here?{" "}
                      <Button
                        className={"signup-btn " + (currentTheme=="dark" ? "text-light" : "text-dark")}
                        onClick={() => dispatch(showSignupModal(true))}
                      >
                        SignUp
                      </Button>
                    </span>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-center">
                  <Button
                    variant="primary"
                    onClick={handleSignup}
                    className="mt-2 me-4 ms-4 uuki-btn uuki-community-btn action-btn"
                  >
                    Signup
                  </Button>
                </div>
              )}

              {data != null &&
              ((typeof data.googleEnabled !== "undefined" &&
                data.googleEnabled) ||
                (typeof data.fbEnabled !== "undefined" && data.fbEnabled) ||
                (typeof data.wpEnabled !== "undefined" && data.wpEnabled) ||
                (typeof data.liEnabled !== "undefined" &&
                  data.liEnabled == 1) ||
                (typeof data.jwtEnabled !== "undefined" && data.jwtEnabled)) ? (
                <div className="d-flex justify-content-center mt-3">
                  <hr style={{ width: "30%" }}></hr>
                </div>
              ) : (
                ""
              )}

              {data != null &&
              typeof data.googleEnabled !== "undefined" &&
              data.googleEnabled ? (
                <div className="d-flex justify-content-center ms-5 me-5 ps-4 pe-4">
                  <Button
                    className="my-1 w-50 uuki-btn uuki-community-btn"
                    onClick={handleGoogleLogin}
                  >
                    Login with Google
                  </Button>
                </div>
              ) : (
                ""
              )}

              {data != null &&
              typeof data.fbEnabled !== "undefined" &&
              data.fbEnabled ? (
                <div className="d-flex justify-content-center ms-5 me-5 ps-4 pe-4">
                  <Button
                    className="my-1 w-50 uuki-btn uuki-community-btn"
                    onClick={handleFBLogin}
                  >
                    Login with Facebook
                  </Button>
                </div>
              ) : (
                ""
              )}

              {data != null &&
              typeof data.wpEnabled !== "undefined" &&
              data.wpEnabled ? (
                <div className="d-flex justify-content-center ms-5 me-5 ps-4 pe-4">
                  <Button
                    className="my-1 w-50 uuki-btn uuki-community-btn"
                    onClick={handleWPLogin}
                  >
                    {data.wpLoginMsg}
                  </Button>
                </div>
              ) : (
                ""
              )}

              {data != null &&
              typeof data.liEnabled !== "undefined" &&
              data.liEnabled == 1 ? (
                <div className="d-flex justify-content-center ms-5 me-5 ps-4 pe-4">
                  <Button
                    className="my-1 w-50 uuki-btn uuki-community-btn"
                    onClick={handleLinkedinLogin}
                  >
                    Login with Linkedin
                  </Button>
                </div>
              ) : (
                ""
              )}

              {data != null &&
              typeof data.jwtEnabled !== "undefined" &&
              data.jwtEnabled ? (
                <div className="d-flex justify-content-center ms-5 me-5 ps-4 pe-4">
                  <Button
                    className="my-1 w-50 uuki-btn uuki-community-btn"
                    onClick={handleJWTLogin}
                  >
                    {data.jwtLoginMsg}
                  </Button>
                </div>
              ) : (
                ""
              )}
            </Stack>

            {/* <div className=" mt-5 d-flex justify-content-center flex-column">
              <Button
                variant="primary"
                type="submit"
                className="mt-2 me-4 ms-4"
              >
                Login
              </Button>
              <span>
                New Here? <a href="#">SignUp</a>
              </span>
            </div> */}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
