import React from "react";
import {
  Image,
  Stack,
  Modal,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Button,
  Spinner 
} from "react-bootstrap";
import { ReactComponent as ShareImage } from "../../../../assets/share.svg";
import { ReactComponent as CommentImage } from "../../../../assets/comments.svg";
import Avatar from "react-avatar";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import PostOptions from "./PostOptions";
import Comment from "../comment/Comment";
import {
  updateComments,
  jumpToComment,
} from "../../../../redux/slice/commentSlice";
import { showLoginModal } from "../../../../redux/slice/authenticationSlice";
import { toast } from "react-toastify";
import LoginModal from "../../../authentication/LoginModal";
import { Link, useNavigate } from "react-router-dom";

export default function PostFooter({ data, layout, setKey, setEditPost,setPostsUpdated}) {
  const [isLiked, setIsLiked] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [postCommentData, setPostCommentData] = useState([]);
  const [commentPostId, setcommentPostId] = useState(0);
  const [showDelete, setShowDelete] = useState({ id: 0, show: false });
  const [showLikesModal, setShowLikesModal] = useState(false);
  const [likesData, setLikesData] = useState([]);
  const [likeProgress, setLikeProgress] = useState(true);
  const navigate = useNavigate();

  const currentLanguages = useSelector(
    (state) => state.languagesReducer.languages
  );
  const currentCommunityLanguage = useSelector(
    (state) => state.communityReducer.communityLanguage
  );
  const dispatch = useDispatch();
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const isLoggedIn = useSelector((state) => {
    return state.userReducer.isUserLoggedIn;
  });
  const jumpToComment = useSelector(
    (state) => state.commentReducer.jumpToComment
  );
  const isToShowLoginModal = useSelector(
    (state) => state.authenticationReducer.showLoginModal
  );
  const postOptions = (
    <Popover className="navbar-popover ">
      <Popover.Body className="px-3 py-2">
        <PostOptions
          data={data}
          setKey={setKey}
          setEditPost={setEditPost}
          setShowDelete={setShowDelete}
          setPostsUpdated = {setPostsUpdated}
        />
      </Popover.Body>
    </Popover>
  );
  const deletePost = () => {
    axios
      .post("/delete_communitypost", { postId: showDelete.id })
      .then((res) => {
        toast.success("Post Deleted");
        setShowDelete({ id: 0, show: false });
      })
      .catch(function (error) {
        toast.success("Post Delete Failed");
      });
  };
  const isToUpdateComments = useSelector(
    (state) => state.commentReducer.updateComments
  );
  const likePost = () => {
    if (isLoggedIn) {
      axios
        .post("/add_like", { check: !isLiked, postId: data.id })
        .then((res) => {
          setIsLiked((prev) => !prev);
        })
        .catch(function (error) {});
    } else {
      dispatch(showLoginModal(true));
    }
  };

  const sharePost = () => {
    if (isLoggedIn) {
      setShowShare(true);
    } else {
      dispatch(showLoginModal(true));
    }
  };
  useEffect(() => {
    if (Object.entries(data).length > 0) {
      setIsLiked(data.is_liked);
    }

    if (isToUpdateComments && commentPostId === data.id) {
      axios
        .get("/v2/loadcomments", {
          params: {
            postId: data.id,
          },
        })
        .then(function (response) {
          dispatch(updateComments(false));
          setPostCommentData(response.data.result);
          setShowComments(true);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          dispatch(updateComments(false));
        });
    }
  }, [data, isToUpdateComments]);

  const toggleCommentSection = () => {
    setcommentPostId(data.id);
    if (!showComments) {
      axios
        .get("/v2/loadcomments", {
          params: {
            postId: data.id,
          },
        })
        .then(function (response) {
          console.log(response.data.result);
          setPostCommentData(response.data.result);
          setShowComments(true);
          if (jumpToComment != null && jumpToComment !== -1) {
            console.log("jumpToCall");
            if (document.querySelector("#comment-" + jumpToComment)) {
              document
                .querySelector("#comment-" + jumpToComment)
                .scrollIntoView();
              dispatch(jumpToComment(-1));
            }
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    } else {
      setShowComments(false);
    }
  };

  const showMemberDetails = (memberId)=>{
    setShowLikesModal(false)
    navigate("/c/" +currentSlug +"/member?memberId=" +memberId)
  }

  const getLikesData=(postId)=>{
    setShowLikesModal(true)
    axios.get("/v2/get_likes_data?postId="+postId).then((response)=>{
      if(response!=null && response.data.likes!=null){
        setLikesData(response.data.likes)
        setLikeProgress(false)
      }
    }).catch((err)=>{
      
    })
  }

  const hideLikesModal = ()=>{
    setShowLikesModal(false)
    setLikeProgress(true)
    setLikesData([])
  }

  return (
    <>
      <hr className="post-footer-divider m-0"></hr>
      <Stack
        className={layout == 1 ? "ms-auto py-3 px-4" : "py-3 px-4"}
        direction="horizontal"
      >
        <Stack direction="horizontal" gap={4}>
          {layout == 0 && data.likes > 0 && (
            <div className="position-relative" onClick={(e)=>getLikesData(data.id)} style={{cursor:'pointer'}}>
              {data.liked_by != null &&
                data.liked_by != [] &&
                data.liked_by.map((liked) =>
                  liked.image ? (
                    <Image
                      src={liked.image}
                      className=" cascade-image"
                      roundedCircle
                      loading="lazy"
                    ></Image>
                  ) : (
                    liked.name != null && (
                      <Avatar
                        className="cascade-image"
                        name={liked.name}
                        size="22"
                        textSizeRatio={2.5}
                        round={true}
                      />
                    )
                  )
                )}
              <div className="position-absolute text-center like-badge rounded-circle " >
                {data.likes}
              </div>
            </div>
          )}

          <div
            style={
              isLiked
                ? { cursor: "pointer", color: "#21cd9c" }
                : { cursor: "pointer" }
            }
            onClick={likePost}
          >
            <i class="fa-regular fa-thumbs-up fa-xl me-2"></i>
            {layout == 0 ? (
              <small className="post-item-like-comment-text">
                {isLiked
                  ? "Liked"
                  : currentLanguages[65][currentCommunityLanguage]}
              </small>
            ) : (
              <span className="post-item-like-comment-text">{data.likes}</span>
            )}
          </div>
          <Stack direction="horizontal" gap={2}>
            <ShareImage style={{ cursor: "pointer" }} onClick={sharePost} />
            {/* <small>Share</small> */}
          </Stack>
        </Stack>
        {data.commenting?<Stack
          gap={2}
          className={layout == 0 || layout == 2 ? "ms-auto" : "ms-4"}
          direction="horizontal"
          style={{ cursor: "pointer" }}
          onClick={toggleCommentSection}
        >
          <CommentImage />
          <small className="post-item-like-comment-text">
            {data.comment_count}{" "}
            {layout == 0 && currentLanguages[66][currentCommunityLanguage]}
          </small>
        </Stack>:""}
        
        {layout == 1 && (
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            overlay={postOptions}
          >
            <i
              style={{ cursor: "pointer" }}
              className="ms-auto fa-solid fa-ellipsis p-3"
            ></i>
          </OverlayTrigger>
        )}
      </Stack>
      {/* <hr className="post-footer-divider m-0"></hr> */}
      {showComments && commentPostId === data.id ? (
        <Comment
          data={postCommentData}
          postId={data.id}
          communityId={data.community_id}
        ></Comment>
      ) : (
        ""
      )}
      <Modal show={showShare} onHide={() => setShowShare(false)} centered>
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Share Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Stack
                className="justify-content-center"
                onClick={() => {
                  window.open(
                    "http://www.facebook.com/sharer.php?u=https://" +
                      window.location.host +
                      "/c/" +
                      currentSlug +
                      "/post/" +
                      data.slug,
                    "sharer",
                    "toolbar=0,status=0,width=580,height=325"
                  );
                  setShowShare(false);
                }}
              >
                <i className="fa-brands fa-facebook fa-3x align-self-center"></i>
                <span className="text-center">Facebook</span>
              </Stack>
            </Col>
            <Col>
              <Stack
                className="justify-content-center"
                onClick={(event) => {
                  {
                    navigator.clipboard.writeText(
                      "https://" +
                        window.location.host +
                        "/c/" +
                        currentSlug +
                        "/post/" +
                        data.slug
                    );
                    toast.success("Link Copied");
                    setShowShare(false);
                  }
                }}
              >
                <i className="fa-solid fa-link fa-3x align-self-center"></i>
                <span className="text-center">Direct</span>
              </Stack>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDelete.show}
        backdrop="static"
        onHide={() => setShowDelete({ ...showDelete, show: false })}
        centered
      >
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deleting the post will remove all likes comments and interactions.
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button className="uuki-delete-btn" onClick={deletePost}>
            Delete Post
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showLikesModal}
       
        onHide={() => hideLikesModal()}
        centered
        rootClose
      >
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>{data.likes} Likes</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height:"300px",overflow:"auto"}}>
          {likeProgress!=null && likeProgress==true?<div className="d-flex align-items-center justify-content-center"><Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner></div>:""}
   
    
          {likesData!=null && likesData.length>0?<div className="position-relative" >
              {likesData != null &&
                likesData != [] &&
                likesData.map((liked) =>
                  liked.image ? (
                    <Stack  direction='horizontal' gap={3} className="d-flex mb-3" style={{cursor:'pointer' }} onClick={() =>
                      showMemberDetails(liked.id)
                    }>
                     <Image
                      src={liked.image}
                      className="likes-images"
                      roundedCircle
                      loading="lazy">
                      </Image>
                      <div style={{fontSize:"16px"}}>{liked.name}</div>
                    </Stack>
                   
                    
                  ) : (
                    liked.name != null && (
                      <Stack  direction='horizontal' gap={3} className="d-flex mb-3"  style={{cursor:'pointer' }} onClick={() =>
                        showMemberDetails(liked.id)
                      }>
                       <Avatar
                        className="likes-images"
                        name={liked.name}
                        size="35"
                        textSizeRatio={2.5}
                        round={true}
                      />
                      <div style={{fontSize:"16px"}}>{liked.name}</div>
                      </Stack>
                     
                    )
                  )
                )}
              
            </div>:""}
        </Modal.Body>
        
      </Modal>
      {/* <LoginModal
        show={isToShowLoginModal}
        onHide={() => dispatch(showLoginModal(false))}
      /> */}
    </>

    
  );
}
