import { useState, useEffect } from "react";
import {
  Image,
  Stack,
  Popover,
  OverlayTrigger,
  ListGroup,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import profilePic from "../../../../assets/blank-profile-pic.png";
import ReactTimeAgo from "react-time-ago";
import Avatar from "react-avatar";
import axios from "axios";
import { useSelector } from "react-redux";
import PostOptions from "./PostOptions";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";

export default function PostHeader({ data, setKey, setEditPost, layout,setPostsUpdated }) {
  const [isPinned, setIsPinned] = useState(0);
  const [showDelete, setShowDelete] = useState({ id: 0, show: false });
  const [showEditPostSettings, setShowEditPostSettings] = useState({ id: 0, show: false });  
  const currentRole = useSelector((state) => state.userReducer.userRole);
  const currentLanguages = useSelector(
    (state) => state.languagesReducer.languages
  );
  const members = useSelector(
    (state) => state.communityReducer.members
  );  
  const currentCommunityLanguage = useSelector(
    (state) => state.communityReducer.communityLanguage
  );
  const navigate = useNavigate();

  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const postOptions = (
    <Popover className="navbar-popover ">
      <Popover.Body className="px-3 py-2">
        <PostOptions
          data={data}
          setKey={setKey}
          setEditPost={setEditPost}
          setShowDelete={setShowDelete}
          setShowEditPostSettings={setShowEditPostSettings}		  
        />
      </Popover.Body>
    </Popover>
  );
  const deletePost = () => {
    axios
      .post("/delete_communitypost", { postId: showDelete.id })
      .then((res) => {
        toast.success("Post Deleted");
        setShowDelete({ id: 0, show: false });
        setPostsUpdated(true)
       
      })
      .catch(function (error) {
        toast.success("Post Delete Failed");
      });
  };
  
  const changePostSlug = useDebouncedCallback((newSlug) => {
    axios
      .post("/change_post_slug", { postId: data.id, newSlug: newSlug })
      .then((res) => {
        toast.success("Post Slug updated");
        setShowEditPostSettings({ id: 0, show: false });
		setTimeout(function() {setPostsUpdated(true)}, 1000);
      })
      .catch(function (error) {
        toast.success("Slug update failed, try a different one");
      });
  }, 1000);
  
  const changePostAuthor = useDebouncedCallback((newAuthor) => {
    axios
      .post("/change_post_author", { postId: data.id, newAuthor: newAuthor })
      .then((res) => {
        toast.success("Post Author updated");
        setShowEditPostSettings({ id: 0, show: false });
		setTimeout(function() {setPostsUpdated(true)}, 1000);
      })
      .catch(function (error) {
        toast.success("Post Author update failed");
      });
  }, 1000);

  const changePostPublishDate = useDebouncedCallback((newDate) => {
    axios
      .post("/change_post_date", { postId: data.id, newDate: newDate })
      .then((res) => {
        toast.success("Post Publish Date updated");
        setShowEditPostSettings({ id: 0, show: false });
		setTimeout(function() {setPostsUpdated(true)}, 1000);
      })
      .catch(function (error) {
        toast.success("Post Publish Date update failed");
      });
  }, 3000);  
  
  useEffect(() => {
    if (Object.entries(data).length > 0) {
      setIsPinned(data.pinnedTo);
    }
  }, [data]);
  return (
    <>
      {data.post_cover != "" && layout!=1 && (
        <Image
          className="post-cover-image-grid"
          loading="lazy"
          fluid
          src={data.post_cover}
        />
      )}
      <Stack className="p-4 pb-3" direction="horizontal">
        <Stack gap={3} direction="horizontal">
          {currentRole === 0 && data.isAnonymous ? (
            <Avatar
              name="A"
              style={{ cursor: "pointer" }}
              size={layout == 0 ? "48" : "42"}
              textSizeRatio={layout == 0 ? 2.5 : 1.5}
              round={true}
            />
          ) : (
            <>
              {data.user.image_url != "" && data.user.image_url != "noImage" ? (
                <Image
                  src={data.user.image_url}
                  className={
                    layout == 0 ? "post-author-image" : "post-author-image-list"
                  }
                  roundedCircle
                  style={{ cursor: "pointer" }}
                  loading="lazy"
                  onClick={() =>
                    navigate(
                      "/c/" +
                        currentSlug +
                        "/member?memberId=" +
                        data.user.memberId
                    )
                  }
                ></Image>
              ) : (
                <Avatar
                  name={data.user.name}
                  style={{ cursor: "pointer" }}
                  size={layout == 0 ? "48" : "42"}
                  textSizeRatio={layout == 0 ? 2.5 : 1.5}
                  round={true}
                  onClick={() =>
                    navigate(
                      "/c/" +
                        currentSlug +
                        "/member?memberId=" +
                        data.user.memberId
                    )
                  }
                />
              )}
            </>
          )}

          <Stack gap={1}>
            {layout == 1 ? (
              <span
                style={{ cursor: "pointer" }}
                className="m-0 post-title"
                onClick={() =>
                  navigate("/c/" + currentSlug + "/post/" + data.slug)
                }
              >
                {data.title}
              </span>
            ) : layout == 2 ? (
              <>
                {currentRole == 0 && data.isAnonymous ? (
                  <>
                    <span className="post-author">Anonymous</span>
                  </>
                ) : (
                  <>
                    <span
                      className="post-author"
                      onClick={() =>
                        navigate(
                          "/c/" +
                            currentSlug +
                            "/member?memberId=" +
                            data.user.memberId
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {data.user.name}
                    </span>
                  </>
                )}
              </>
            ) : (
              <Stack className="flex-wrap" direction="horizontal" gap={2}>
                <>
                  {currentRole == 0 && data.isAnonymous ? (
                    <>
                      <span className="post-author">Anonymous</span>
                    </>
                  ) : (
                    <>
                      <span
                        className="post-author"
                        onClick={() =>
                          navigate(
                            "/c/" +
                              currentSlug +
                              "/member?memberId=" +
                              data.user.memberId
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {data.user.name}
                      </span>
                    </>
                  )}
                </>
                {currentRole == 0 && data.isAnonymous ? (
                  ""
                ) : (
                  <>
                    {data.user.is_admin && (
                      <div className="leaderboard-rank">Admin</div>
                    )}
                    {data.user.is_mod && (
                      <div className="leaderboard-rank">Moderator</div>
                    )}
                    {data.user.tags.length > 0 &&
                      data.user.tags.map((tag) => (
                        <div className="leaderboard-rank">{tag}</div>
                      ))}
                    <ReactTimeAgo
                      date={data.date}
                      locale="en-US"
                      timeStyle="round"
                      style={{ opacity: "0.7", fontSize: "12px" }}
                    />					  
                  </>
                )}
              </Stack>
            )}

            {layout == 1 ? (
              <Stack gap={1} direction="horizontal">
                {currentRole === 0 && data.isAnonymous ? (
                  <>
                    <small className="text-muted">Anonymous posted</small>
                  </>
                ) : (
                  <>
                    <small
                      className="text-muted"
                      onClick={() =>
                        navigate(
                          "/c/" +
                            currentSlug +
                            "/member?memberId=" +
                            data.user.memberId
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {data.user.name} posted
                    </small>
                  </>
                )}
                <ReactTimeAgo
                  date={data.date}
                  locale="en-US"
                  timeStyle="round"
                  style={{ opacity: "0.8", fontSize: "12px" }}
                />
              </Stack>
            ) : layout == 2 ? (
              <Stack gap={1} direction="horizontal">
                <ReactTimeAgo
                  date={data.date}
                  locale="en-US"
                  timeStyle="round"
                  style={{ opacity: "0.8", fontSize: "12px" }}
                />
              </Stack>
            ) : (
              <Stack direction="horizontal" gap={2}>
                <small className="text-muted" style={{ opacity: "0.7" }}>
                  {currentLanguages[50][currentCommunityLanguage]}{" "}
                  {data.topic_name}
                </small>
                {isPinned != 0 && 
				  <>
                  <i className="fa-solid fa-thumbtack "></i>
                  </>
                }
              </Stack>
            )}
          </Stack>
        </Stack>
        {layout != 1 && (
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            overlay={postOptions}
          >
            <i
              style={{ cursor: "pointer" }}
              className="ms-auto fa-solid fa-ellipsis p-3"
            ></i>
          </OverlayTrigger>
        )}
      </Stack>
      {layout == 2 && (
        <span
          style={{ cursor: "pointer" }}
          className="m-0 px-4 py-3 post-title"
          onClick={() => navigate("/c/" + currentSlug + "/post/" + data.slug)}
        >
          {data.title}
        </span>
      )}
      <Modal
        show={showDelete.show}
        onHide={() => setShowDelete({ ...showDelete, show: false })}
        centered
      >
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deleting the post will remove all likes comments and interactions.
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button className="uuki-delete-btn" onClick={deletePost}>
            Delete Post
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showEditPostSettings.show}
        onHide={() => setShowEditPostSettings({ ...showEditPostSettings, show: false })}
        centered
      >
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Edit Post Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Change Post Slug</Form.Label>
              <Form.Control
                type="text"
                defaultValue={data.slug}			
                placeholder="Enter new slug"
                id="postSlug"
				onChange={(event) => changePostSlug(event.target.value)}
              />
			</Form.Group>
            {(currentRole!=0) && (
            <>			
			<Form.Group className="mb-3">
              <Form.Label>Change Post Author</Form.Label>
              <Form.Select
                onChange={(event) => changePostAuthor(event.target.value)}
              >
			  {members.map(m => <option key={m.user_id} value={m.user_id}>{m.name}</option>)}	  
              </Form.Select>
            </Form.Group>
			<Form.Group className="mb-3">
              <Form.Label>Change Post Publish Date</Form.Label>
			  <Form.Control 
                type="date"
				id="postDate"
                onChange={(e) => changePostPublishDate(e.target.value)}
              />
            </Form.Group>
            </>			
            )}			
          </Form>			
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
        </Modal.Footer>
      </Modal>	  
    </>
  );
}
