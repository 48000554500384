import { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ListGroup,
  Stack,
  Spinner,
  Modal  
} from "react-bootstrap";
import "../../styles/aitutor.css";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ReactComponent as DiscordIcon } from "../../assets/discord.svg";
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";
import { ReactComponent as SendIcon } from "../../assets/send.svg";

export default function AITutorContent({ data }) {
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [selectedLesson, setSelectedLesson] = useState("-1");
  const [sessionId, setSessionId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const { communitySlug } = useParams();
  const messagesRef = useRef(null);
  const chatRef = useRef(null);
  chatRef.current = messages;
  const currentLesson = useRef(null);
  currentLesson.current = selectedLesson;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (messageInput!=''){
      setMessages([
        ...chatRef.current,
        { text: messageInput, fromUser: true, isDefault: false },
      ]);
      setMessageInput("");
      askTutor(messageInput);
    }
    
  };

  const handleKeyDown= (event)=> {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  }

  const handleSectionSelection = (e, section) => {
    e.preventDefault(); 
    
    if(currentLesson.current!="-1"){
      handleLessonAndTopicChange()
    }else{
      const categories1 = (
        <Stack gap={2}>
          <span>Please select the lesson you want help with</span>
          <div class="d-flex flex-wrap gap-2">
            {section.lessons != null &&
              section.lessons.map((lesson) => (
                <Button
                  onClick={(e) => {
                    handleLessonSelection(e, lesson);
                  }}
                  key={`lesson-` + lesson.id}
                  className="uuki-btn uuki-community-btn"
                >
                  {lesson.title}
                </Button>
              ))}
          </div>
        </Stack>
      );
      setMessages([
        ...chatRef.current,
        { fromUser: false, text: categories1, isDefault: true },
      ]);
    } 
  };

  const handleLessonSelection = (e, lesson) => {
    e.preventDefault();
      if(currentLesson.current!="-1"){
        handleLessonAndTopicChange()
      }else{
        setSelectedLesson(lesson.id);
        setIsLoading(true)
        setMessageInput("");
        currentLesson.current = lesson.id;
        axios
          .post("/v2/ask_ai_tutor", {
            lessonId: lesson.id,
            commSlug: communitySlug,
            answer: "Hello let's start",
            isFirstTime : true,
            sessionId:sessionId
          })
          .then((res) => {
            setSessionId(res.data.session_id)
            setIsLoading(false)
            if(res.data.output!=null && res.data.output.length>0){
              setMessages([
                ...chatRef.current,
                {
                  fromUser: false,
                  text: `<pre>` + res.data.output + `<pre>`,
                  isDefault: false,
                },
              ]);
            }else if("error" in res.data){
              setIsLoading(false)
              toast.error(res.data.error)
            }else{
              setIsLoading(false)
              toast.error("Something went wrong. Please try again")
            }
            
          })
          .catch((err) => {
            setIsLoading(false)
            console.log("Failed to respond " + err.response.data);
            toast.error("Something went wrong.Please try again")
          });
      }
      
    
    
    
  };

  const askTutor = (answer) => {
    setIsLoading(true)
    if (selectedLesson != "-1") {
      axios
        .post("/v2/ask_ai_tutor", {
          lessonId: selectedLesson,
          commSlug: communitySlug,
          answer: answer,
          isFirstTime:false,
          sessionId:sessionId
        })
        .then((res) => {
          setSessionId(res.data.session_id)
          setIsLoading(false)
          if(res.data.output!=null && res.data.output.length>0){
            setMessages([
              ...chatRef.current,
              {
                fromUser: false,
                text: `<pre>` + res.data.output + `<pre>`,
                isDefault: false,
              },
            ]);
          }else if("error" in res.data){
            setIsLoading(false)
            toast.error(res.data.error)
          }else{
            setIsLoading(false)
            toast.error("Something went wrong. Please try again")
          }
        })
        .catch((err) => {
          setIsLoading(false)
          console.log("Failed to respond " + err.response.data);
          toast.error("Something went wrong. Please try again")
        });
    } else {
      setIsLoading(false)
      console.log("Selected lesson is null");
      toast.error("Selected lesson is null")
    }
  };

  useEffect(() => {
  
      loadInitialData()
  }, []);

  useEffect(() => {
    let timeout;
    const showPopup = () => {
      setShowBanner(true);
      console.log("Timer")
    };
  
    timeout = setTimeout(showPopup, 30000);
  
    // Cleanup function
    const cleanup = () => {
      clearTimeout(timeout);
    };
  
    // Cleanup on unmount
    return cleanup;
  }, []);


  useEffect(() => {
    messagesRef.current.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const loadInitialData = ()=>{
    const courseDetail = data.course_detail;
    if (courseDetail != null) {
      const courseSections = courseDetail.sections;
      console.log(courseSections);
      const categories = (
        <Stack gap={2}>
          <span>Hi</span>
          <span>Please select the topic you want help with</span>
          <div class="d-flex flex-wrap gap-2">
            {courseSections != null &&
              courseSections.map((section) => (
                <Button
                  key={`section-` + section.id}
                  onClick={(e) => {
                    handleSectionSelection(e, section);
                  }}
                  className="uuki-btn uuki-community-btn"
                >
                  {section.title}
                </Button>
              ))}
          </div>
        </Stack>
      );
      setMessages([
        ...chatRef.current,
        { fromUser: false, text: categories, isDefault: true },
      ]);
    }
  }
  const handleLessonAndTopicChange = ()=>{
        chatRef.current = []
        setMessages([])
        setMessageInput("")
        setSelectedLesson("-1")
        currentLesson.current="-1"
        loadInitialData()   
  }

  return (
    <>
      <div className="tutor-bg" style={{ height: "100%" }}>
        
              <Stack
                direction="vertical"
                className="tutor-chat-container pt-3 pb-3 "
              >
                
                {messages.map((message, index) => (
                  <>
                    {message.fromUser ? (
                      <div
                        key={index}
                        className="tutor-msg mt-3  me-2 p-3 user-msg"
                      >
                        {message.text}
                      </div>
                    ) : (
                      <>
                      {message.isDefault?<div
                        key={index}
                        className="tutor-msg mt-3  me-2 p-3 bot-msg"
                      >
                        {message.text}
                      </div>:<div
                        key={index}
                        className="tutor-msg mt-3  me-2 p-3 bot-msg"
                        dangerouslySetInnerHTML={{ __html: message.text }}
                      ></div>}
                      </>
                    )}
                     
                  </>
                ))}
                <div ref={messagesRef} />
                {isLoading && <Stack className="ms-3 mt-3" direction="horizontal" gap={2}>
                    <Spinner size="sm" animation="grow" />
                    <Spinner size="sm" animation="grow" />
                    <Spinner size="sm" animation="grow" />
                    
                </Stack>}
              </Stack>

             
             <div className="d-flex tutor-input-container justify-content-center align-items-center" >
              <div className="d-flex align-items-center justify-content-between tutor-input ms-2 mb-2 p-1">
              <Form onSubmit={handleSubmit} style={{width:"95%"}}>
                <Form.Group controlId="messageInput" >
                      <Form.Control
                        as="textarea"
                        rows={1}
                        placeholder="Type your message here"
                        value={messageInput}
                        onChange={(event) => setMessageInput(event.target.value)} 
                        onKeyDown={(event)=>handleKeyDown(event)}
                        style={{border:"0px"}}
                        
                      />
                </Form.Group>
               </Form> 
			 <SendIcon className="icon m-2" onClick={handleSubmit}/>
              </div>
              <Button className="uuki-btn uuki-community-btn ms-3 me-3" onClick={(e)=>handleLessonAndTopicChange()}>Start Again</Button>
             </div>
			  
			  <div className= "d-flex justify-content-end " >
                <div className='ps-3 pe-3 tutor-powered-by'><a  className="text-decoration-none d-flex align-items-center" href="https://app.uuki.live/signup" target="_blank">Create your own AI tutor</a></div>
              </div>
             
       
      </div>
	  
	  <Modal  centered show={showBanner} onHide={()=>setShowBanner(false)}>
          <Modal.Body className="p-5 position-relative">
                <b style={{cursor:'pointer'}} onClick={()=>setShowBanner(false)} className="position-absolute top-0 end-0 me-4 mt-2">X</b>
                <Stack className="align-items-center" gap={3}>
                    <a href="https://www.producthunt.com/posts/chatgpt-based-ai-tutor-for-uuki?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-chatgpt&#0045;based&#0045;ai&#0045;tutor&#0045;for&#0045;uuki" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=387245&theme=light" alt="ChatGPT&#0032;based&#0032;AI&#0032;tutor&#0032;for&#0032;UUKI - Your&#0032;own&#0032;AI&#0032;coding&#0032;tutor&#0032;based&#0032;on&#0032;course&#0032;created&#0032;on&#0032;UUKI | Product Hunt"/></a>
                    <a className="discord-invite px-3 py-3 ms-3" target="_blank" href="https://discord.gg/KZSUEjr4TM"><DiscordIcon className="icon me-1"/> Join Our Discord</a>
                    <a className="discord-invite px-3 py-3 ms-3" target="_blank" href="http://twitter.com/share?text=Free and faster ChatGPT pro with prompt support, Check out MyGPT by SamurAI&url=https://mygpt.thesamur.ai&hashtags=ai,gpt,chatgpt"><TwitterIcon className="icon me-1"/> Share on Twitter</a>
					                    <a className="discord-invite px-3 py-3 ms-3" target="_blank" href="https://www.youtube.com/watch?v=_bv6S9un-rA&ab_channel=Vadootv"><TwitterIcon className="icon me-1"/> Watch Tutorial</a>

                </Stack>
          </Modal.Body>
      </Modal>
    </>
  );
}