import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyC7rIkB4ydAN8WFjkuuWD_WwHtLEnDvwgo",
  authDomain: "uuki-b132f.firebaseapp.com",
  projectId: "uuki-b132f",
  storageBucket: "uuki-b132f.appspot.com",
  messagingSenderId: "529675399416",
  appId: "1:529675399416:web:dc05fcb0f9973770c94b0d",
  measurementId: "G-DP4TE2JK0W",
};

var messaging = null;
try {
   const app = initializeApp(firebaseConfig);	
   messaging = getMessaging(app);
} catch(error) {
   console.log("Firebase not supported", error);	
}
export default messaging;