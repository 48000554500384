import { useState, useEffect } from "react";
import RecentActivityHeader from "./RecentActivityHeader";
import PostItem from "./post/PostItem";
import { Spinner, Stack, Image } from "react-bootstrap";
import axios from "axios";
import { useInView } from "react-intersection-observer";
import { useSelector, useDispatch } from "react-redux";
import {
  topicDetails,
  communityDetails,
} from "../../../redux/slice/communitySlice";
import { useSearchParams } from "react-router-dom";

import { ReactComponent as CreateNew } from "../../../assets/create-new.svg";

export default function Feed({ setKey, setTopicChange, setEditPost, tag }) {
  const [pageCounter, setPageCounter] = useState(1);
  const [postsFor, setPostsFor] = useState(0);
  const [postsUpdated, setPostsUpdated] = useState(false);
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  // const [topicChange, setTopicChange] = useState(false);
  const [methodChange, setMethodChange] = useState(false);
  const [hasPosts, setHasPosts] = useState(true);
  const [noPosts, setNoPosts] = useState(false);
  const [postData, setPostData] = useState([]);
  const [method, setMethod] = useState(0);
  const [layout, setLayout] = useState(0);
  const [allowPosts, setAllowPosts] = useState(true);
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  const currentTopic = useSelector((state) => state.communityReducer.topicSlug);
  const currentTopicId = useSelector((state) => state.communityReducer.topicId);

  const currentTopicName = useSelector(
    (state) => state.communityReducer.topicName
  );
  const currentId = useSelector((state) => state.communityReducer.communityId);
  useEffect(() => {
    // if (topicChange == true) {
    //   setPageCounter((prev) => 0);
    //   setPostsFor((prev) => 0);
    //   setPostData((prev) => []);
    //   setHasPosts((prev) => true);
    //   setTopicChange((prev) => false);
    // }
    if (currentId != 0) {
      if (methodChange == true) {
        setPageCounter((prev) => 1);
        setPostsFor((prev) => 0);
        setPostData((prev) => []);
        setHasPosts((prev) => true);
        setMethodChange((prev) => false);
      }

      if (inView == true) {
        setHasPosts((prev) => false);
        loadContent();
      }
    }
  }, [currentId, inView, methodChange]);
  useEffect(() => {
    if (searchParams.get("isNew") == currentTopicId) {
      setMethodChange((prev)=>true);
      searchParams.delete('isNew')
      setSearchParams(searchParams);
      // setPostsUpdated(true);
    }
  }, [searchParams]);

  useEffect(() => {

    if(postsUpdated==true){
      setPageCounter(1)
      setPostsFor(0)
      setMethod(0)
      setPostData((prev) => []);
      setHasPosts((prev) => true);
      setNoPosts((prev)=>false)
     
    }else{
     // do nothing
    }
    
  }, [postsUpdated]);


  const loadContent = () => {
    axios
      .post("/v2/loadposts", {
        topicSlug: currentTopic,
        page: pageCounter,
        id: currentId,
        method: method,
        postFor: postsFor,
        tag: tag,
      })
      .then(function (response) {
        if (pageCounter==1 && response.data.posts.length == 0) {
          setNoPosts((prev) => true);
        }else{
          setNoPosts((prev) => false);
        }
        if ((postsFor==0 && response.data.posts.length != 0) || (postsFor==1 && response.data.hasNext== true)) {
          setHasPosts((prev) => true);
        }
        setPageCounter((prev) => prev + 1);
        setAllowPosts(response.data.topicAllow);
        setLayout(response.data.topicLayout);
        setPostsUpdated(false)
        if (response.data.topicSlug != "") {
          dispatch(
            topicDetails({
              id: response.data.topicId,
              slug: response.data.topicSlug,
              forEvents: response.data.isEventTopic,
              forCourses: false,
              name: response.data.topicName,
              isRequested: response.data.isRequestedToJoin,
            })
          );
        }
        if (response.data.show == true) {
          setPostData((prev) => [...postData, response.data.posts]);
          if (postsFor == 0) {
            setPostsFor((prev) => 1);
          }
        } else {
          setKey("privateComm");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className="d-flex justify-content-center">
      <Stack className="center-container" gap={3}>
        {tag == undefined ? (
          <RecentActivityHeader
            setMethod={setMethod}
            currentMethod={method}
            setMethodChange={setMethodChange}
            setKey={setKey}
            setEditPost={setEditPost}
            topicAllow={allowPosts}
          ></RecentActivityHeader>
        ) : (
          <h4>
            <b>#{tag}</b>
          </h4>
        )}

        <Stack
          className={layout == 2 ? "flex-wrap justify-content-center" : ""}
          direction={layout == 2 ? "horizontal" : "vertical"}
          gap={layout == 1 ? 1 : 3}
        >
          {postData != null && postData != []
            ? postData.map((page) =>
                page.map((post) => (
                  <PostItem
                    data={post}
                    setKey={setKey}
                    setEditPost={setEditPost}
                    layout={layout}
                    setPostsUpdated={setPostsUpdated}
                  />
                ))
              )
            : ""}
        </Stack>
        {hasPosts && (
          <div className="p-4 d-flex justify-content-center" ref={ref}>
            {/* <Spinner animation="border" /> */}
            <span class="loader-spinner" />
          </div>
        )}
        {noPosts && (
          <Stack gap={2}>
            <CreateNew className="align-self-center" />
            <h3 className="text-center">
              <b>Welcome to {currentTopicName}</b>
            </h3>
            <h6 className="text-center">Be the first one to post!</h6>
          </Stack>
        )}
         <div style={{marginBottom:'400px'}} />
      </Stack>
    </div>
  );
}
