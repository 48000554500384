import thunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import userSlice from "../redux/slice/userSlice";
import communitySlice from "../redux/slice/communitySlice";
import membersSlice from "../redux/slice/membersSlice";
import commentSlice from "../redux/slice/commentSlice";
import authenticationSlice from "../redux/slice/authenticationSlice";
import topicListSlice from "../redux/slice/topicListSlice";
import languagesSlice from "../redux/slice/languagesSlice";

// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'

// const persistConfig = {
//     key: 'uuki-store',
//     storage,
// }

// const persistedReducer = persistReducer(persistConfig, reducers)

export default configureStore({
  reducer: {
    userReducer: userSlice,
    communityReducer: communitySlice,
    membersReducer: membersSlice,
    commentReducer: commentSlice,
    authenticationReducer: authenticationSlice,
    topicListReducer: topicListSlice,
    languagesReducer: languagesSlice,
  },
  middleware: [thunk],
});

// const persistor = persistStore(store)

// export {store,persistor}
