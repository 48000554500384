import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  Image,
  Stack,
  Button,
  Form,
  ProgressBar,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import Avatar from "react-avatar";
import "../../styles/moderatordashboard.css";
import { toast } from "react-toastify";
import trash from "../../assets/trash.svg";

export default function BanWords({ slug }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const deleteWord = (wordData) => {
    var type = "Banned Word Removed";
    axios
      .post("/delete_banned_word", {
        word: wordData.banned_word,
        url: slug,
        type: type,
        userId: wordData.user_id,
        communityId: wordData.community_id,
      })
      .then((response) => {
        toast.success("Word deleted successfully");
        getData();
      })
      .error((err) => {
        console.log(err);
      });
  };

  const banWord = () => {
    var word = document.getElementById("ban-word-input").value;
    var type = "Word Banned";
    axios
      .post("/ban_word", {
        word: word,
        url: slug,
        type: type,
        userId: data.user_id,
        communityId: data.community_id,
      })
      .then((response) => {
        toast.success("Word banned successfully");
        document.getElementById("ban-word-input").value = "";
        getData();
      })
      .error((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    axios
      .get("/v2/moderate/" + slug, { params: { type: 4 } })
      .then((response) => {
        console.log(response);
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="banword-form-block d-flex justify-content-between">
        <div className="flex-grow-1">
          <Form.Control
            id="ban-word-input"
            style={{ width: "100%" }}
            className="border-0"
          />
        </div>
        <div>
          <Button
            style={{ background: "red", border: "0px", height: "38px" }}
            onClick={(e) => banWord()}
          >
            Ban Word
          </Button>
        </div>
      </div>

      <Stack gap={3} direction="horizontal" className="flex-wrap mb-3">
        {data != null && data.bannedWords.length > 0
          ? data.bannedWords.map((word) => (
              <div className="mt-3 me-3 p-4 d-flex ban-word-container justify-content-around align-items-center">
                <span>{word.banned_word}</span>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => deleteWord(word)}
                >
                  <Image src={trash}></Image>
                </div>
              </div>
            ))
          : ""}
      </Stack>
    </>
  );
}
