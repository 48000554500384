import { Button, Image, Stack, Form,FormGroup,FormLabel,FormControl } from "react-bootstrap";
import {useState,useRef} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import placeholderImage from '../../assets/add-image.png'

function SettingsDashboard({user}) {
    const userState = useSelector(state => state.userReducer)
    const [userName, setUserName] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const file = useRef(null)

    const changeSettings = (e) => {
        e.preventDefault()
        axios.post('/change_user',{id: userState.userId,name: userName})
        
    }
    const changePassword = (e) => {
        e.preventDefault()
        axios.post('/change_password',{id: userState.userId,current: currentPassword,new:newPassword})
        .then((res) => {
        })
        
    }

    const uploadFile = (e) => {
        console.log();
        axios.get('get_profile_image_url?userId='+userState.userId+'&filename='+e.target.files[0].name)
        .then((res) => {
            res = res.data;			
            let url = res.url;
            let fields = res.fields;
            var formData = new FormData();
            for(var key in fields) {
                formData.append(key, fields[key]);
            }
            formData.append('file', e.target.files[0]);
            const xhr = new XMLHttpRequest();
            xhr.addEventListener('loadend', uploadComplete, false);
            function uploadComplete(event) {
                let newUrl = "https://profile.uuki.live/"+fields['key']
                axios.post('/add_profile_image',{id:userState.userId,url:newUrl})
                .then((res) => file.src=newUrl)
            }
            xhr.open("POST", url, true);
            xhr.send(formData);
        })
    }

    return (
        <div className="">
            <h4 className="mb-3">Account Details</h4>
            <Stack gap={2} className="content-border-bottom ">
                
                <FormGroup>
                    <FormLabel htmlFor="fileUpload" style={{ cursor: "pointer" }}>
                        <FormControl
                            id="fileUpload"
                            type="file"
                            onChange={uploadFile}
                            style={{ display: "none" }}
                        />
                        <Image ref={file} className="uuki-logo" src={user.profile_pic} id="profileImage" roundedCircle loading="lazy"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src=placeholderImage;
                            }}/>
                    </FormLabel>
                </FormGroup>
                
            </Stack>
            <Form className="content-border-bottom " onSubmit={changeSettings}>
                <Form.Group className="mb-3" >
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder={user.email} readOnly/>
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Name</Form.Label>
                    <Form.Control defaultValue={user.name} onChange={e => setUserName(e.target.value)} required/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid name.
                    </Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" className="uuki-btn uuki-dashboard-btn" >Save Changes</Button>
            </Form>
            <h4 className="mb-3">Change Password</h4>
            <Form onSubmit={changePassword}>
                <Form.Group className="mb-3" >
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control type="password" onChange={e => setCurrentPassword(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>New Password</Form.Label>
                    <Form.Control type="password" onChange={e => setNewPassword(e.target.value)}/>
                </Form.Group>
                <Button type="submit" className=" uuki-btn uuki-dashboard-btn mb-4" >Save Changes</Button>
            </Form>
        </div>
    )
}

export default SettingsDashboard
