import { useState, useEffect, useRef } from "react";
import {
  Stack,
  Button,
  FormControl,
  Form,
  InputGroup,
  FormLabel,
  Accordion,
} from "react-bootstrap";
import EditorJS from "../../../../EditorComponents";
import { default as edjsParser } from "editorjs-parser";
import { useSelector } from "react-redux";
import axios from 'axios'
import {toast} from 'react-toastify'
import InnerHTML from 'dangerously-set-html-content';

function QuestionAnswers({ editorHelpers,quizId,getIndex, question,setQueAns,handleRegenerateQues }) {
  const editorCore = useRef(null);
  var parser = new edjsParser(undefined);
  parser = new edjsParser(undefined, editorHelpers.customParsers);
  const currentCommunityId = useSelector(
    (state) => state.communityReducer.communityId
  );
  const [options, setOptions] = useState(
    [
      { id: 0, value: "",correctAnswer:false },
      { id: 0, value: "",correctAnswer:false },
      { id: 0, value: "",correctAnswer:false },
      { id: 0, value: "",correctAnswer:false }
    ],
  );
  const [FIBAnswer, setFIBAnswer] = useState("");
  const [updateArray, setUpdateArray] = useState(false);
  const [points, setPoints] = useState({correct:1,incorrect:0});
  const [removedOptions, setRemovedOptions] = useState([]);
  const [questionInvalid, setQuestionInvalid] = useState(false);
  const [pollOptionsInvalid, setPollOptionsInvalid] = useState(false);
  // const [answerInvalid, setAnswerInvalid] = useState(false);
  const [dataSet, setDataSet] = useState(false);
  const [desc, setDesc] = useState(null);
  const [isFIB, setIsFIB] = useState(false);
  const currentTopicId = useSelector((state) => state.communityReducer.topicId);
  const currentTheme = useSelector((state) => state.communityReducer.theme);
  const removeOption = (index, id) => {
    options.splice(index, 1);
    if (id != 0) {
      setRemovedOptions([...removedOptions, id]);
    }
    setUpdateArray((prev) => !prev);
  };
  const saveQueAns = async() => {
    const savedData = await editorCore.current.save();
    const markup = parser.parse(savedData);
    if(markup==""){
      setQuestionInvalid((prev)=>true)
    }else{
      setQuestionInvalid((prev)=>false)
    }
    
    let validOption = true
    if(!isFIB){
      options.forEach(option => {
        if (option.value=="") {validOption=false}
      });
      if(validOption==true && options.length>=2){
        setPollOptionsInvalid((prev)=>false)
      }else{
        setPollOptionsInvalid((prev)=>true)
      }
    }
    let getId =question.id || 0
    
    if (markup != ""  && validOption==true && options.length>=2) {
      axios
        .post("/v2/add_question", {
          id: getId,
          quizId: quizId,
          question: markup,
          jsonContent: savedData.blocks,
          answers: options,
          FIBAnswer: FIBAnswer,
          isFIB:isFIB,
          points: points,
          removedOptions: removedOptions,

        })
        .then((res) => {
          toast.success("Question Saved");
          if(!question.id){
            question.id = res.data.id
            setOptions(res.data.newOptions)
          }
        })
        .catch(function (error) {
          toast.error("Not Saved");
        });
    }
  }
  const removeQuestion = () => {
    if(Object.entries(question).length > 0){
      var questionId= question.id || 0
     
    axios
    .post("/v2/remove_question", {
      id: questionId,
    })
    .then((res) => {
      toast.success("Question Removed");
      setQueAns((current) =>
        current.filter((getQuestion) => getQuestion.id !== question.id)
      );
    })
    .catch(function (error) {
      toast.error("Not Saved");
    });
    }else{
      setQueAns((current) =>
      current.filter((question,index) => index !== getIndex)
      );
    }
  }
  useEffect(() => {
    console.log(question)
    if (Object.entries(question).length > 0) {
      let getDate = Math.floor(new Date().getTime() / 1000);
      console.log("Gen", question.isGenerated, question.jsonContent);
      if(question.isGenerated!=null && question.isGenerated){
        if(question.jsonContent!=""){
          setDesc({
            time: getDate,            
            blocks: [
              {
                type: 'paragraph',
                data: {
                  text: question.jsonContent.question
                }
              }
            ]
          });
          setDataSet(true);
		    console.log("Setting options", question.jsonContent.answers);
            setOptions(question.jsonContent.answers)
            setIsFIB(false)
        }
      }else if(question.isGenerated==null || (question.isGenerated!=null && question.isGenerated==false)){
        if (question.jsonContent != "") {
          var json_content;
          try {
            json_content = JSON.parse(question.jsonContent);
          } catch (err) {
            json_content = JSON.parse(question.jsonContent.replace(/\'/g, '"'));
          }
          setDesc({
            time: getDate,
            blocks: json_content,
          });
          setDataSet(true);
        }
        setPoints(question.points);
        if(question.isFIB==true){
          setIsFIB(true)
          setFIBAnswer(question.answers[0].value)
        }else{
          setOptions(question.answers)
          setIsFIB(false)
        }
      }
    }
  }, []);

  return (
    <Accordion >
      <Accordion.Item eventKey={question.id}>
        <Accordion.Header className="d-flex flex-row nav-header">
          <i className="fa-solid fa-lg ms-auto pointer-cursor fa-xmark" onClick={removeQuestion}></i>
          {question.content?<div class="d-flex pt-3"><span>{(getIndex+1)+"."}</span> 
          {question.content!="" &&
            <>
              <InnerHTML html={question.content} />
            </>
          }
          </div>:"New Question"}

        </Accordion.Header>
      <Accordion.Body>
        <Stack className="border-bottom py-3" gap={3}>
          <div className="question-edit">
            <Stack direction="horizontal">
            <FormLabel>Add Question</FormLabel>
            </Stack>
            <EditorJS
              editorCore={editorCore}
              uploadImage={editorHelpers.uploadImage}
              uploadImageByUrl={editorHelpers.uploadImageByUrl}
              postSet={dataSet}
              content={desc}
              adminCodes={editorHelpers.adminCodes}
              selectedTopic={currentTopicId}
              communityId={currentCommunityId}
            />
            {questionInvalid &&
                <span style={{ color: "red" }}>Please enter valid question</span>
            }
          </div>
          <div>
            <Stack direction="horizontal">
              <FormLabel>Add Answers</FormLabel>
              <div class="d-flex ms-auto">
                <Form.Check
                  inline
                  label="MCQs"
                  name={"type-"+question.id}
                  type="radio"
                  defaultChecked={(isFIB==true)?false:true}
                  onChange={(e)=>e.target.checked==true&&setIsFIB(false)}
                />
                {question.isGenerated ?<Form.Check
                  inline
                  disabled
                  label="Fill in the Blank"
                  name={"type-"+question.id}
                  type="radio"
                  defaultChecked={(isFIB==true)?true:false}
                  onChange={(e)=>e.target.checked==true&&setIsFIB(true)}
                />:<Form.Check
                inline
                label="Fill in the Blank"
                name={"type-"+question.id}
                type="radio"
                defaultChecked={(isFIB==true)?true:false}
                onChange={(e)=>e.target.checked==true&&setIsFIB(true)}
              />}
                
              </div>
            </Stack>
            {isFIB?
              <FormControl
              defaultValue={FIBAnswer}
              onChange={(e) => setFIBAnswer(e.target.value)}
              />
            : <>
              {options.map((option, index) => (
                <InputGroup className="mb-3">
                  <InputGroup.Checkbox  checked={option.correctAnswer} onChange={(e) => {
                      options[index].correctAnswer = e.target.checked;
                      setUpdateArray((prev) => !prev);
                    }}/>
                  <FormControl
                    defaultValue={option.value}
                    onChange={(e) => {
                      options[index].value = e.target.value;
                      setUpdateArray((prev) => !prev);
                    }}
                  />
                  <Button
                    variant="outline-secondary"
                    id="button-addon2"
                    onClick={() => removeOption(index, option.id)}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </Button>
                </InputGroup>
              ))}
              <Button
              className={
                  "align-self-start p-1 px-2 border-dark" +
                  (currentTheme == "dark"
                    ? "text-light bg-transparent"
                    : "text-dark bg-dark")
                }
                onClick={() => setOptions([...options, { id: 0, value: "",correctAnswer:false }])}
              >
              <i class="fa-solid fa-plus fa-lg"></i></Button>
            </>}
            <Stack>
              {pollOptionsInvalid &&
                  <span style={{ color: "red" }}>Please enter valid options</span>
              }
              {/* {answerInvalid &&
                  <span style={{ color: "red" }}>Please select the correct answer(s)</span>
              } */}
            </Stack>
        
            
        
          </div>
            <Stack gap={4}  direction="horizontal">
              <Form.Group >
                <Form.Label>Add Correct Points</Form.Label>
                <Form.Control type="number" onChange={(e)=>setPoints({...points,correct:e.target.value})} value={points.correct}/>
              </Form.Group>
              <Form.Group >
                <Form.Label>Add Incorrect Points</Form.Label>
                <InputGroup >
                <InputGroup.Text>-</InputGroup.Text>
                <Form.Control type="number" onChange={(e)=>setPoints({...points,incorrect:e.target.value})} value={points.incorrect}/>
                </InputGroup>
              </Form.Group>
            </Stack>
            <Stack direction="horizontal" gap={4}>
            <Button className="uuki-btn uuki-community-btn align-self-start" onClick={saveQueAns}>Save</Button>
            {/* {question.isGenerated!=null && question.isGenerated ?<Button className="uuki-btn uuki-community-btn align-self-start" onClick={(e)=>handleRegenerateQuestion()}>Regenerate Question</Button>:""} */}
            
            </Stack>
           
        </Stack>
      </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default QuestionAnswers;
