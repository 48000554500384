import { useState, useEffect, useRef } from "react";
import { Stack, Form, Button, InputGroup, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import EditorJS from "../../../../EditorComponents";
import { default as edjsParser } from "editorjs-parser";

function QuizSettings({ editorHelpers, settings, setKey }) {
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [dataSet, setDataSet] = useState(false);
  const [duration, setDuration] = useState({ hours: 0, minutes: 0 });
  const [passing, setPassing] = useState(0);
  const [allowResults, setAllowResults] = useState(1);
  const [allowRetake, setAllowRetake] = useState(1);
  const [titleInvalid, setTitleInvalid] = useState(false);
  const editorCore = useRef(null);

  var parser = new edjsParser(undefined);
  const currentCommunityId = useSelector(
    (state) => state.communityReducer.communityId
  );
  const currentTopicId = useSelector((state) => state.communityReducer.topicId);

  parser = new edjsParser(undefined, editorHelpers.customParsers);

  useEffect(() => {
    if (settings != null) {
      setTitle(settings.title);
      let getDate = Math.floor(new Date().getTime() / 1000);
      if (settings.jsonContent != "") {
        var json_content;
        try {
          json_content = JSON.parse(settings.jsonContent);
        } catch (err) {
          json_content = JSON.parse(settings.jsonContent.replace(/\'/g, '"'));
        }
        setDesc({
          time: getDate,
          blocks: json_content,
        });
        setDataSet(true);
      }
      setDuration({
        hours: settings.durationHours,
        minutes: settings.durationMinutes,
      });
      setPassing(settings.passingPercent);
      setAllowResults(settings.allowResultView);
      setAllowRetake(settings.allowRetake);
    }
  }, [settings]);

  const saveCustomize = async (draftType) => {
    const savedData = await editorCore.current.save();
    const markup = parser.parse(savedData);
    if (title != "") {
      axios
        .post("/v2/customize_quiz", {
          id: settings.id,
          topicId: currentTopicId,
          title: title,
          content: markup,
          jsonContent: savedData.blocks,
          draftType: draftType,
          duration: duration,
          passing: passing,
          allowResults: allowResults,
          allowRetake: allowRetake,
        })
        .then((res) => {
          toast.success("Quiz Saved");
        })
        .catch(function (error) {
          toast.error("Not Posted");
        });
    }
  };

  const deleteQuiz = () => {
      axios
        .post("/v2/delete_quiz", {
          id: settings.id,
         
        })
        .then((res) => {
          toast.success("Quiz Deleted");
          setKey("curriculum")
        })
        .catch(function (error) {
          toast.error("Not Deleted");
        });
    
  };

  return (
    <Stack gap={3}>
      <Form.Group controlId="title">
        <Form.Label>Enter Title</Form.Label>
        <Form.Control
          isInvalid={titleInvalid}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Please enter valid title.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="desc">
        <Form.Label>Enter Description</Form.Label>
        {/* <Form.Control
            value={desc}
            as="textarea"
            onChange={(e) => setDesc(e.target.value)}
        /> */}
        <EditorJS
          editorCore={editorCore}
          uploadImage={editorHelpers.uploadImage}
          uploadImageByUrl={editorHelpers.uploadImageByUrl}
          postSet={dataSet}
          content={desc}
          adminCodes={editorHelpers.adminCodes}
          selectedTopic={currentTopicId}
          communityId={currentCommunityId}
        />
      </Form.Group>
      <Form.Group controlId="duration">
        <Form.Label>Enter Duration</Form.Label>
        <InputGroup>
          <Form.Control
            placeholder="Hours"
            type="number"
            min="0"
            max="23"
            value={duration.hours}
            onChange={(e) =>
              setDuration({ ...duration, hours: e.target.value })
            }
          />
          <Form.Control
            placeholder="Minutes"
            type="number"
            min="0"
            max="59"
            value={duration.minutes}
            onChange={(e) =>
              setDuration({ ...duration, minutes: e.target.value })
            }
          />
        </InputGroup>
      </Form.Group>

      <Form.Group controlId="perc">
        <Form.Label>Enter Passing Percentage</Form.Label>
        <InputGroup className="mb-3 w-50">
          <Form.Control
            value={passing}
            onChange={(e) => setPassing(e.target.value)}
          />
          <InputGroup.Text>%</InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <Form.Switch
        className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 "
        label="Allow Students to Retake Quiz"
        checked={allowResults == 1 ? true : false}
        onChange={(event) =>
          event.target.checked == true ? setAllowResults(1) : setAllowResults(0)
        }
      />
      <Form.Switch
        className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 "
        label="Allow Students to View Results"
        checked={allowRetake == 1 ? true : false}
        onChange={(event) =>
          event.target.checked == true ? setAllowRetake(1) : setAllowRetake(0)
        }
      />

      <div class="d-flex">
        <Button
          className="uuki-btn uuki-community-btn align-self-start"
          onClick={() => saveCustomize(1)}
        >
          Save & Publish Quiz
        </Button>
        <a className="ms-3 align-self-center" onClick={() => saveCustomize(0)}>
          Save Quiz as Draft
        </a>
      <Button
          className="ms-auto p-2 uuki-btn uuki-delete-btn align-self-start"
          style={{fontSize:'0.5rem'}}
          onClick={deleteQuiz}
        >
          Delete Quiz
        </Button>	 		 
      </div> 
    </Stack>
  );
}

export default QuizSettings;
