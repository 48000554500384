import React from 'react';
import { Image, Stack } from 'react-bootstrap';
import profilePic from '../../../assets/blank-profile-pic.png';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

export default function TrendingPostItems({ data }) {
	const navigate = useNavigate();
	const currentSlug = useSelector(
		(state) => state.communityReducer.communitySlug
	);
	return (
		<Stack
			style={{cursor:'pointer'}}		
			onClick={() => navigate('/c/' + currentSlug + '/post/' + data.slug)}
			gap={2}
			direction='horizontal'>
			{data.profileImg != '' ? (
				<Image
					src={data.profileImg}
					className='trending-post-image'
					roundedCircle				
					loading='lazy'></Image>
			) : (
				<Avatar
					name={data.profileName}
					size='28'
					textSizeRatio={2.5}				
					round={true}
				/>
			)}

			<Stack>
				<span style={{ fontWeight: '500' }} >{data.title}</span>
				<small className='text-muted' >{data.profileName}</small>
			</Stack>
		</Stack>
	);
}
