import {useState,useEffect} from "react";
import { Stack,Form,Modal,Button } from "react-bootstrap";
import axios from 'axios'
import { useSelector } from "react-redux";

export default function EventInfo({ title,event }) {
  const [isAttending, setIsAttending] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [email, setEmail] = useState(false);
  const isUserLoggedIn = useSelector(
    (state) => state.userReducer.isUserLoggedIn
  );
  const currentCommunityId = useSelector(
    (state) => state.communityReducer.communityId
  ); 
  const attendEvent = () => {
    axios
      .post("/attend_event", {
        eventId: event.event_id,
        value: !isAttending,
        isNew: isUserLoggedIn,
        newEmail: email,
        communityId: currentCommunityId,
      })
      .then(function (response) {
        setIsAttending((prev) => !prev);
        setShowEmail(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    if (event.type == "event") {
      setIsAttending(event.attending);
    }
  }, [event]);
  return (
    <>
      <Stack gap={2} className="right-nav-block uuki-box  py-3">
        <b className="px-3 ">Event Status</b>
          <Button
          className="uuki-btn uuki-community-btn mx-3"
            onClick={() =>
              isUserLoggedIn ? attendEvent() : setShowEmail(true)
            }
          >
            {isAttending ? "Cancel" : "Attend"}
          </Button>
          <span className="px-3 ">
            {event.going} Attending
          </span>
          <a className="px-3 text-decoration-none" href={"http://www.google.com/calendar/render?action=TEMPLATE&text="+title+"&dates="+event.year+event.month+event.day+"T"+event.time+"00/"+event.year+event.month+event.day+"T"+event.time+"00&location="+event.location+"&trp=false&sprop=&sprop=name:"}
            target="_blank" rel="nofollow"><i className="fas fa-calendar-plus me-1"></i>Add to my calendar</a>
          <hr class="post-footer-divider m-0"></hr>
          <b className="px-3 ">Date and Time</b>
          <span className="px-3 ">{event.date}</span>
          <span className="px-3 ">Starts at {event.time} {event.duration != 0 && "for "+event.duration + " minutes"}</span>
          <hr class="post-footer-divider m-0"></hr>
          <b className="px-3 ">Location</b>
          <a className="px-3 " href={event.location}><small class="overflow-text">{event.location}</small></a>

      </Stack>
      <Modal show={showEmail} onHide={() => setShowEmail(false)} centered>
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Attend Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="lvl">
            <Form.Label>Enter Email</Form.Label>
            <Form.Control
              autoFocus
              onChange={(event) => setEmail(event.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button className="uuki-btn uuki-community-btn" onClick={attendEvent}>
            Attend
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
