import { useState, useEffect} from "react";
import {
  Button,
  Form,
  ProgressBar,
  Stack,
  Modal,
  Image,
} from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import InnerHTML from 'dangerously-set-html-content'

export default function PostContent({ data }) {
  const [isAttending, setIsAttending] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [isImageSet, setIsImageSet] = useState(false);
  const [showPDFPreview, setShowPDFPreview] = useState(false);
  const [isPDFSet, setIsPDFSet] = useState(false);
  const [PDFSrc, setPDFSrc] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [newOption, setNewOption] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState(false);
  const [postData, setPostData] = useState(data);
  const onImageClickHandling = (src) => {
    setImageSrc(src);
    setShowImagePreview(true);
  };
  const currentTheme = useSelector(
    (state) => state.communityReducer.theme
  );  
  const onPDFClickHandling = (src) => {
    setPDFSrc(src);
    setShowPDFPreview(true);
  };
  useEffect(() => {
    document.querySelectorAll("#item-" + data.id).forEach((content) => {
      content.querySelectorAll("img").forEach((tag) => {
        if (isImageSet === false) {
          tag.style.cursor = "zoom-in";
          tag.addEventListener("click", (e) => onImageClickHandling(tag.src));
          setIsImageSet(true);
        }
      });
    });

    document.querySelectorAll("#item-" + data.id).forEach((content) => {
      content
        .querySelectorAll(".cdx-attaches__download-button")
        .forEach((dfile) => {
          if (isPDFSet === false) {
            dfile.addEventListener("click", (e) =>
              onPDFClickHandling(
                dfile.href + "#toolbar=0&navpanes=0&scrollbar=0"
              )
            );
			dfile.onclick = function() { 
               return false;
            };
            setIsPDFSet(true);
          }
        });
    });

    document.querySelectorAll(".embed-youtube").forEach((element) => {
      var parent = element.parentElement;
      if (!parent.classList.contains("responsive-embed")) {
        parent.classList.add("responsive-embed");
      }
      if (!element.classList.contains("responsive-embed-youtube")) {
        element.classList.add("responsive-embed-youtube");
      }
    });

    document
      .querySelectorAll('iframe[src^="https://player.twitch.tv"]')
      .forEach((element) => {
        if (!element.src.includes("parent")) {
          element.src =
            element.src + "&parent=" + "{{get_url_host(request.url)}}";
          var parent = element.parentElement;
          if (!parent.classList.contains("responsive-embed")) {
            parent.classList.add("responsive-embed");
          }
          if (!element.classList.contains("responsive-embed-youtube")) {
            element.classList.add("responsive-embed-youtube");
          }
        }
      });

    setInterval(function () {
      document.querySelectorAll("#item-" + data.id).forEach((element) => {
        if (element.scrollHeight > element.clientHeight) {
          const id = data.id;
          document.querySelectorAll("#see-more-" + id).forEach((item)=>{
item.classList.remove("d-none")
          });
        }
      });
    }, 1000);
  }, [data.content]);
  useEffect(() => {
    if (data.type == "event") {
      setIsAttending(data.event_info.attending);
    }
    setPostData(data);
  }, [data]);
  const isUserLoggedIn = useSelector(
    (state) => state.userReducer.isUserLoggedIn
  );
  const userId = useSelector((state) => state.userReducer.userId);
  const currentCommunityId = useSelector(
    (state) => state.communityReducer.communityId
  );
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const attendEvent = () => {
    axios
      .post("/attend_event", {
        eventId: data.event_info.event_id,
        value: !isAttending,
        isNew: isUserLoggedIn,
        newEmail: email,
        communityId: currentCommunityId,
      })
      .then(function (response) {
        setIsAttending((prev) => !prev);
        setShowEmail(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const sendPollHit = (e) => {
  //   e.preventDefault();
  //   axios
  //     .post("/add_hit", {
  //       postId: data.id,
  //       optionId: optionId,
  //       checked: checked,
  //       userId: userId,
  //     })
  //     .then((res) => console.log(res))
  //     .catch((err) => console.log(err));
  // };

  const handlePollOptionsChecked = (optionId, e) => {
    e.preventDefault();
    axios
      .post("/v2/add_hit", {
        postId: postData.id,
        optionId: optionId,
        checked: e.target.checked,
        userId: userId,
      })
      .then((res) => {
        setPostData(res.data.posts[0]);
      })
      .catch((err) => console.log(err));
  };

  const addOption = (e) => {
	e.target.value="";  
    axios
      .post("/v2/add_poll_option", {
        postId: postData.id,
        option: newOption
      })
      .then((res) => {
        setPostData(res.data.posts[0]);
      })
      .catch((err) => console.log(err));
  };

  // const handleContentOnClick = (content) => {
  //   if (content.includes("<img")) {
  //     const imageLink = content.split("<");
  //     imageLink.forEach((item) => {});
  //     console.log(imageLink);
  //   } else {
  //     console.log("no image found");
  //   }
  // };

  const seeMoreClick = (id) => {
    document.querySelectorAll("#item-" + id).forEach((post) => {
      post.style.maxHeight = "100%";
    });
    document.querySelectorAll("#see-more-" + id).forEach((item)=>{
      item.classList.add("d-none")
    });
  };

  return (
    <>
      <Stack
        className="py-3 px-4 pt-0 post-content overflow-hidden"
        gap={4}
        id={`item-` + data.id}
      >
        <Stack direction="horizontal">
          <span
            className="post-title"
            onClick={() =>
              navigate("/c/" + currentSlug + "/post/" + postData.slug)
            }
          >
            {postData.title}
          </span>
          {postData.type == "event" && (
            <div className="ms-auto">
              <small className="me-3">
                {postData.event_info.going} Attending
              </small>
              <Button
                onClick={() =>
                  isUserLoggedIn ? attendEvent() : setShowEmail(true)
                }
                className="uuki-btn uuki-community-btn"
              >
                {isAttending ? "Cancel" : "Attend"}
              </Button>
            </div>
          )}
        </Stack>
        {data.content!="" &&
        <div
          id={`item-` + data.id}
          key={data.id}
          className="post-content  overflow-hidden"
        ><InnerHTML html={data.content} /></div>
        }
        {postData.type === "poll" &&
        postData.hasOwnProperty("poll_options") != null
          && <Stack gap={2}> {postData.poll_options
              .map((item) => (
                  <div
                    className="post-item-poll-options-bg position-relative border rounded poll-option d-flex align-items-center"
                    
                    key={item.id}
                  >
                      {item.is_option_voted ? (
                        <Form.Check
                          inline
                          name="group1"
                          type="checkbox"
                          className="poll-option-radio ps-3 position-absolute"
                          checked={true}
                          onChange={(e) => handlePollOptionsChecked(item.id, e)}
                        />
                      ) : (
                        <Form.Check
                          inline
                          name="group1"
                          type="checkbox"
                          className="poll-option-radio ps-3 position-absolute"
                          checked={false}
                          onChange={(e) => handlePollOptionsChecked(item.id, e)}
                        />
                      )}

                      <ProgressBar
                        now={item.percentage}
                        className="post-item-poll-options-progress bg-transparent h-100"
                      ></ProgressBar>

                    <span
                      className="option-text text-truncate text-dark"

                    >
                      {item.value}
                    </span>
                    <small className="me-3 position-absolute end-0">{item.percentage}%</small>
                  </div>
              ))}
              </Stack>
          }

        {postData.type === "poll" && postData.allow_user_options === 1 &&
          <div className="post-item-option-input-bg">
            <Form.Control type="text" placeholder="Add your option" onChange={(e)=>setNewOption(e.target.value)} onKeyDown={(e)=>{e.keyCode==13&&addOption(e)}}/>
          </div>
        }

        {postData.type === "poll" &&
        postData.hasOwnProperty("poll_options") != null ? (
          <div className="post-total-votes-text">
            {postData.total_votes} Vote
          </div>
        ) : (
          ""
        )}
      </Stack>
      <Button
        id={`see-more-` + data.id}
        className="w-100 d-none p-1 see-more fw-bold d-flex justify-content-center"
        onClick={(e) => seeMoreClick(data.id)}
      >
        <small style={{ color: "var(--fontcolor) !important" }}>See More</small>
      </Button>
      <Modal show={showEmail} onHide={() => setShowEmail(false)} centered>
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Attend Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="lvl">
            <Form.Label>Enter Email</Form.Label>
            <Form.Control
              autoFocus
              onChange={(event) => setEmail(event.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button className="uuki-btn uuki-community-btn" onClick={attendEvent}>
            Attend
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showImagePreview}
        onHide={() => setShowImagePreview(false)}
        id="preview-image-modal"
        centered
      >
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Image
              src={imageSrc}
              style={{ height: "100%", width: "100%" }}
            ></Image>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showPDFPreview}
        onHide={() => setShowPDFPreview(false)}
        id="preview-pdf-modal"
		centered
      >
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <iframe
              src={PDFSrc}
              type="application/pdf"
              id="pdfViewer"
              frameBorder="0"
              scrolling="auto"
              height="100%"
              width="100%"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
