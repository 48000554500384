import { Button, Stack,Row,Col } from "react-bootstrap";
import {Link,useNavigate} from 'react-router-dom';
import {ReactComponent as IncludedIcon} from '../../assets/included.svg'
import {ReactComponent as NotIncludedIcon} from '../../assets/not-included.svg'

function PlanDashboard({user,setKey}) {
    const navigate = useNavigate();
    return (
        <Row>
            <Col md={6}>
                <h4 className="mb-3">My Plan</h4>
                <Stack className=" p-4 border rounded" gap={3}>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>{user.limits.members} Members</b></Stack>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>{user.limits.communities} Communitites</b></Stack>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>{user.limits.moderators} Moderators</b></Stack>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>{user.limits.storage} Storage</b></Stack>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>{user.limits.spaces} Spaces</b></Stack>
                    <Stack gap={3} direction="horizontal">{(user.limits.removeBrand === 0) ? <NotIncludedIcon/> : <IncludedIcon/>}<b>Remove Brand</b></Stack>
                    <Stack gap={3} direction="horizontal">{(user.limits.cname === 0) ? <NotIncludedIcon/> : <IncludedIcon/>}<b>C-Name</b></Stack>
                    {(user.codes>=3) &&
                        <>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Custom CSS/JavaScript</b> </Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Advanced Analytics and Moderation</b></Stack>
                        </>
                    }
                    {(user.codes>=5) &&
                        <>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>API</b> </Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Webhooks</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Wordpress SSO</b></Stack>
                        </>
                    }
                    {(user.enable_addon == 1) &&
                        <>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Custom SMTP</b> </Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Custom PWA</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Monetization Fee</b></Stack>
                        </>
                    }
                    <div className="content-border"></div>
                    {((user.plan!=0 || user.userCodes!=0) && user.recur_plan==0) ?
                        <Button className="uuki-big-btn" onClick={() => setKey('pricing')} >Upgrade</Button>
                        :
                        <Button className="uuki-big-btn" onClick={() => setKey('upgrade')} >Upgrade</Button>
                    }
                
                </Stack>
                
            </Col>
           
            {(user.enable_addon == 1) && 
                <Col>
                    <h4 className=" mt-3 mt-md-0 mb-3">Premium Add-on</h4>
                    <Stack className=" p-4 border rounded" gap={3}>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Custom SMTP</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Custom PWA</b></Stack>
                        <Stack className="" gap={3} direction="horizontal"><IncludedIcon/><b>Monetization Fee of 1%</b></Stack>
                        <div className="content-border"></div>

                        <Button className="uuki-big-btn" href={"https://payments.pabbly.com/subscribe/6219fb0318cd581b431c10bc/premium-addon?email="+user.email+"&first_name="+user.name}  target="_blank">Buy Monthly</Button>
                        <Button className="uuki-big-outline-btn" href={"https://payments.pabbly.com/subscribe/6219fbbf18cd581b431c10e5/premium-addon-yearly?email="+user.email+"&first_name="+user.name}  target="_blank">Buy Yearly</Button>
                    </Stack>
                   
                </Col>
            }
        </Row>

    )
}

export default PlanDashboard
