import { useMediaQuery } from "react-responsive";
import { useEffect, useRef, useState } from "react";
import {
  Container,
  Col,
  Nav,
  Navbar,
  Offcanvas,
  Image,
  Stack,
  Button,
  Popover,
  OverlayTrigger,
  Badge,
  ListGroup,
} from "react-bootstrap";
import Avatar from "react-avatar";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../assets/uuki-logo.png";
import { ReactComponent as SettingsIcon } from "../assets/navbar-settings.svg";
import { ReactComponent as ChatIcon } from "../assets/navbar-chat.svg";
import { ReactComponent as NotificationIcon } from "../assets/navbar-notification.svg";
import ReactTimeAgo from "react-time-ago";
import { jumpToComment } from "../redux/slice/commentSlice";
import { useDispatch, useSelector } from "react-redux";
import { userId } from "../redux/slice/userSlice";
import { InView } from 'react-intersection-observer';


export default function CommonNavbar() {
  const [navbarData, setNavbarData] = useState(null);
  const [notifSeen, setNotifSeen] = useState([]);
  const [isToRefresh, setIsToRefresh] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  const currentCommId = useSelector(
    (state) => state.communityReducer.communityId
  );
  const logout = (e) => {
    e.preventDefault();
    axios.get("/logout?noRedirect=1").then((res) => navigate("/login"));
  };

  const handleNotificationClick = (notification) => {
    if (notification.post_id != null && notification.post_slug != "") {
      if (notification.comment_id != null || notification.comment_id != "") {
        dispatch(jumpToComment(notification.comment_id));
      }
      navigate("/c/" + currentSlug + "/post/" + notification.post_slug);
    } else if (notification.type.includes("request")) {
      navigate("/invite_requests/" + currentSlug);
    } else if(notification.lesson_id != null && notification.lesson_slug != ""){
      navigate("/course/" + currentSlug + "/" + notification.lesson_slug+"?lessonId="+notification.lesson_id);
    } else if(notification.chat_id != null && notification.chat_id != ""){
      navigate("/chat/" + currentSlug);
    }
    document.body.click();
  };
  useEffect(() => {
    if (isToRefresh && currentCommId != 0) {
      axios
        .get("/v2/get_navbar_info", { params: { communityId: currentCommId } })
        .then((response) => {
          setNavbarData(response.data);
          setIsToRefresh(false);
          applyStyles(response.data);
          dispatch(userId(response.data.user_id));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [currentCommId]);

  const applyStyles = (data) => {
    if (data.theme == "light") {
      document.documentElement.style.setProperty("--primary", "#ffffff");
      document.documentElement.style.setProperty("--secondary", "#eff1f5");
      document.documentElement.style.setProperty("--fontColor", "#2d395a");
      document.documentElement.style.setProperty(
        "--border",
        "1px solid #dfdfdf"
      );
    } else {
      document.documentElement.style.setProperty("--primary", "#2B2B2B");
      document.documentElement.style.setProperty("--secondary", "#000000");
      document.documentElement.style.setProperty("--fontColor", "#ffffff");
      document.documentElement.style.setProperty(
        "--border",
        "1px solid #030303"
      );
    }
    document.documentElement.style.setProperty("--accent", data.color);
    document.documentElement.style.setProperty("--fontFamily", data.font);
    document.documentElement.style.setProperty("--themeText", data.textColor);
    document.documentElement.style.setProperty(
      "--hoverColor",
      data.hover_color
    );
  };

  const markRead = (index) => {
    if(!notifSeen.includes(index)){
      setNotifSeen([...notifSeen,index])
      var sendNotifs
      if(index==0){
        sendNotifs = navbarData.notifications.slice(0,5)
      }else{
        sendNotifs = navbarData.notifications.slice(5,10)
      }
      axios
        .post("/v2/mark_read", {ids: sendNotifs})
        .then((response) => {
            console.log(response)
          })
          .catch((err) => {
              console.error(err);
            });
        }
      };

  const popoverProfile = (
    <Popover className="navbar-popover">
      {navbarData != null ? (
        <>
          <Popover.Header as="h5" className="navbar-popover-header">
            {navbarData != null &&
            navbarData.profile_pic !== "" &&
            navbarData.profile_pic !== undefined ? (
              <Image
                src={navbarData.image}
                className="desktop-profile-image me-2"
                roundedCircle
                loading="lazy"
              ></Image>
            ) : (
              <Avatar
                name={navbarData.name}
                size="40"
                className="me-2"
                style={{ fontSize: "0.5rem" }}
                // className="mobile-profile-image"
                round={true}
              />
            )}
            {navbarData.name}
          </Popover.Header>
          <Popover.Body className="px-3 py-2">
            <ListGroup as="ol">
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start border-0"
                onClick={() =>
                  navigate(
                    "/c/" +
                      currentSlug +
                      "/member?memberId=" +
                      navbarData.memberId
                  )
                }
              >
                Profile
              </ListGroup.Item>
           {navbarData.isAdmin &&   <ListGroup.Item
                as="button"
                className="d-flex justify-content-start border-0"
                onClick={() =>
                  currentHost == "/"
                    ? (window.location.href = "https://app.uuki.live/")
                    : navigate("/")
                }
              >
                Dashboard
              </ListGroup.Item>}
              <div className="content-border"></div>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start border-0"
                onClick={logout}
              >
                Logout
              </ListGroup.Item>
            </ListGroup>
          </Popover.Body>
        </>
      ) : (
        ""
      )}
    </Popover>
  );

  const popoverSettings = (
    <Popover className="navbar-popover mt-3">
      <Popover.Body className="px-3 py-2">
        <ListGroup as="ol">
          {navbarData != null && navbarData.isAdmin && (
            <>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => {
                  document.body.click();
                  navigate("/settings/" + currentSlug);
                }}
              >
                <i className="fa-solid fa-sliders me-2"></i> Community Settings
              </ListGroup.Item>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => {
                  document.body.click();
                  navigate("/c/" + currentSlug + "/analytics");
                }}
              >
                <i className="fa-solid fa-chart-line me-2"></i> Community
                Analytics
              </ListGroup.Item>
            </>
          )}
          {navbarData != null && (navbarData.isAdmin || navbarData.isMod) && (
            <>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => {
                  document.body.click();
                  navigate("/moderate/" + currentSlug);
                }}
              >
                <i className="fa-solid fa-eye me-2"></i> Moderator Dashboard
              </ListGroup.Item>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => {
                  document.body.click();
                  navigate("/invite_requests/" + currentSlug);
                }}
              >
                <i className="fa-solid fa-table-list me-2"></i> Member Requests
              </ListGroup.Item>
            </>
          )}
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  const popoverNotifications = (
    <Popover className="navbar-popover mt-3 " style={{ minWidth: "20rem" }}>
      <Popover.Header as="h5" className="navbar-popover-header">
        Notifications
      </Popover.Header>
      <Popover.Body className="position-relative p-0">
        <ListGroup className="navbar-notif-list px-3 py-2" as="ol">
          {navbarData != null ? (
            navbarData.notifications.map((item,index) => (
              <>
                {(index==0||index==5) && 
                  <InView
                    as='div'
                    onChange={(inView, entry) => {
                      inView == true && markRead(index);
                    }}>
                   
                  </InView>}
                <ListGroup.Item
                  as="li"
                  className="d-flex flex-column  py-2 px-1 border-0 border-bottom"
                  key={item.id}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleNotificationClick(item)}
                >
                  <div className="d-flex align-items-center">
                    {item.doneByImage !== "" &&
                    item.doneByImage !== undefined ? (
                      <Image
                        src={item.doneByImage}
                        className="member-image me-3"
                        roundedCircle
                        loading="lazy"
                      ></Image>
                    ) : (
                      <Avatar
                        name={item.doneByName}
                        size="32"
                        style={{ fontSize: "0.5rem" }}
                        className="me-3"
                        round={true}
                      />
                    )}
                    <span
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    ></span>
                  </div>

                  <small className="text-muted ms-5">
                    <ReactTimeAgo
                      date={Date.parse(item.notifyTime)}
                      locale="en-US"
                    />
                  </small>
                </ListGroup.Item>
              </>
            ))
          ) : (
            <>
              <ListGroup.Item
                as="li"
                className="d-flex flex-column  py-2 px-1 border-0 border-bottom"
              >
                <div className="d-flex align-items-center">
                  No new notification.
                </div>
              </ListGroup.Item>
            </>
          )}
            <ListGroup.Item
            as="li"
            className="py-3 border-0"/>
        </ListGroup>
        <div
            onClick={() => {
              navigate("/c/" + currentSlug + "/notifications");
            }}
            className="d-flex justify-content-center border-0 rounded-4 p-2 position-absolute bottom-0 view-all-button mx-1"
          >
            <div>
              <span className="view-all-text">View All Notifications</span>
            </div>
          </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      {navbarData != null ? (
        <>
          <Stack direction="horizontal" style={{ gap: "2rem" }}>
            <div className="position-relative">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={popoverNotifications}
                rootClose
              >
                <NotificationIcon style={{ cursor: "pointer" }} />
              </OverlayTrigger>
              <Badge
                pill
                className="notification-badge position-absolute top-0 start-100 translate-middle"
              >
                {navbarData.notifCounter}
              </Badge>
            </div>
            {navbarData.enable_chat==1 && <div className="position-relative">
              <ChatIcon
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/chat/" + currentSlug)}
              />
              <Badge
                pill
                className="notification-badge position-absolute top-0 start-100 translate-middle"
              >
                {navbarData.chatCounter}
              </Badge>
            </div>}
            {navbarData != null && (navbarData.isAdmin || navbarData.isMod) ? (
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={popoverSettings}
                rootClose
              >
                <SettingsIcon style={{ cursor: "pointer" }} />
              </OverlayTrigger>
            ) : (
              <SettingsIcon
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/settings/" + currentSlug)}
              />
            )}

            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popoverProfile}
              rootClose
            >
              {navbarData != null &&
              navbarData.profile_pic !== "" &&
              navbarData.profile_pic !== undefined ? (
                <Image
                  src={navbarData.profile_pic}
                  className="desktop-profile-image"
                  roundedCircle
                  loading="lazy"
                ></Image>
              ) : (
                <Avatar
                  name={navbarData.name}
                  size="40"
                  style={{ fontSize: "0.5rem" }}
                  // className="mobile-profile-image"
                  round={true}
                />
              )}
            </OverlayTrigger>
          </Stack>
        </>
      ) : (
        ""
      )}
    </>
  );
}
