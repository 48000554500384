import { createSlice } from "@reduxjs/toolkit";

const state = () => {
  const initialState = {
    searchName: "",
    searchBio: "",
    searchLoc: "",
    searchSpace: "",
    searchTag: "",
    searchAdminMod: "",
    reloadMembers: false,
  };

  return initialState;
};

export const membersSlice = createSlice({
  name: "membersSlice",
  initialState: state,
  reducers: {
    searchName: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.searchName = action.payload;
    },
    searchBio: (state, action) => {
      state.searchBio = action.payload;
    },
    searchLoc: (state, action) => {
      state.searchLoc = action.payload;
    },
    searchSpace: (state, action) => {
      state.searchSpace = action.payload;
    },
    searchTag: (state, action) => {
      state.searchTag = action.payload;
    },
    searchAdminMod: (state, action) => {
      state.searchAdminMod = action.payload;
    },
    reloadMembers: (state, action) => {
      state.reloadMembers = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  searchName,
  searchBio,
  searchLoc,
  searchSpace,
  searchTag,
  searchAdminMod,
  reloadMembers,
} = membersSlice.actions;

export default membersSlice.reducer;
