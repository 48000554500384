import { useState,useEffect } from "react";
import { Stack, Button } from "react-bootstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import InnerHTML from 'dangerously-set-html-content';
import Comment from "../home/feed/comment/Comment";
import { useSelector, useDispatch } from "react-redux";
import {updateComments} from "../../redux/slice/commentSlice"
import { useMediaQuery } from "react-responsive";
import {ReactComponent as CommentImage} from '../../assets/comments.svg'
function LessonContent({
  communitySlug,
  topicSlug,
  setNext,
  lesson,
  setCompleted,
  completed,
  total,
  setCompleteShow
}) {
  const currentCommId = useSelector(
    (state) => state.communityReducer.communityId
  );
  const [showComments, setShowComments] = useState(false)
  const [discussionData, setDiscussionData] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isToUpdateComments = useSelector(
    (state) => state.commentReducer.updateComments
  );
  useEffect(() => {
    if (isToUpdateComments) {
      axios
        .get("/v2/loadcomments", {
          params: {
            lessonId: lesson.id,
            commId: currentCommId,

          },
        })
        .then(function (response) {
          dispatch(updateComments(false));
          setDiscussionData(response.data.result);
          setShowComments(true);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          dispatch(updateComments(false));
        });
    }
  }, [isToUpdateComments]);
  
  const markCompleted = () => {
    axios
      .post("/v2/mark_lesson_completed", {
        lessonId: lesson.id,
        commId: currentCommId,
      })
      .then((res) => {
        setNext(lesson.page);
        if(Math.round(((completed+1) / total) * 100)==100){
          setCompleteShow(true)
        }
        setCompleted((prev) => prev + 1);
      })
      .catch(function (error) {
        console.log("Mark Complete Failed");
      });
  };
  const toggleCommentSection = () => {
    if (!showComments) {
      axios
        .get("/v2/loadcomments", {
          params: {
            lessonId: lesson.id,
            commId: currentCommId
          },
        })
        .then(function (response) {
          setDiscussionData(response.data.result);
          setShowComments(true);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    } else {
      setShowComments(false);
    }
  };
  return (
    lesson.showLesson ? 
    <div className="ms-md-3 ms-xxl-5 me-xxl-3">
      <Stack className="" direction="horizontal">
        <Button
          onClick={() => navigate("/c/" + communitySlug + "/" + topicSlug)}
          style={{color:'var(--fontColor)'}}
          className=" border-0 bg-transparent "
        >
          <i className="fa-solid fa-arrow-left me-2"></i>Back
        </Button>
      </Stack>
      <Stack className="course-block py-3  uuki-box my-md-4" gap={2}>
	    <Stack className="" direction="horizontal">
          <h3 className={isMobile ? "px-3" : "px-5"}><b>{lesson.title}</b></h3>
		  <Button
            onClick={markCompleted}
            className="mx-3 ms-auto uuki-btn uuki-community-btn align-self-start"
          >
            Mark Completed
          </Button>
		</Stack>
        {lesson.video!="" &&
        <>	   
        <div
        ><InnerHTML className="lesson-video" html={lesson.video} /></div>
        </>
        }		

        {lesson.content!="" &&
        <>	   
        <div className={isMobile ? "px-3" : "px-5"}><InnerHTML html={lesson.content} /></div>
        </>
        }		
        {/* <Button onClick={markCompleted} className="uuki-btn uuki-community-btn align-self-start">Mark Completed</Button> */}
      </Stack>
      {showComments ? 
        <div  className="course-block uuki-box ">
          <Comment
            data={discussionData}
            postId={lesson.id}
            communityId={currentCommId}
          ></Comment>
        </div>
      :
      <Stack
          gap={2}
          className="course-block uuki-box p-4"
          direction="horizontal"
          style={{ cursor: "pointer" }}
          onClick={toggleCommentSection}
        >
          <div class="d-flex">
            <CommentImage />
            <small className="post-item-like-comment-text ms-3">
              View Discussions
            </small>
          </div>
          <small className="ms-auto">{lesson.commentCount} Comments</small>
        </Stack>
    }
    </div>
    :
    <div className="ms-md-3 ms-xxl-5 me-xxl-3">
      <Stack className={"course-block py-5 text-center  uuki-box my-md-4"+(isMobile ? "px-3" : "px-5")} gap={2}>
        <i className="fa-solid fa-lock fa-3x"></i>
        <h5>Complete previous lessons to access content</h5>
        
      </Stack>
    </div>
  );
}

export default LessonContent;
