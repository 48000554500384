import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  Stack,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../styles/signin.css";
import { usernameStatus, userLoggedIn, userId } from "../redux/slice/userSlice";
import uukiLogo from "../assets/uuki-logo.png";
import { toast } from "react-toastify";
import {getCookie, setCookie} from '../Utils.js'

function CommunityCode() {
  const [commLogo, setCommLogo] = useState("");
  const [commName, setCommName] = useState("");
  const [data, setData] = useState(null);
  const [code, setCode] = useState("");
  const [codeInvalid, setCodeInvalid] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUsername = useSelector((state) => state.userReducer.username);

  useEffect(() => {
    let endpoints = ["/v2/get_community_info?url=" + window.location.href];
    axios
      .all(endpoints.map((endpoint) => axios.get(endpoint)))
      .then(
        (data) => (
          setCommName((commName) => (commName = data[0].data.name)),
          (document.title = data[0].data.name + " Login"),
          setCommLogo((commLogo) => (commLogo = data[0].data.logo)),
          setData(data[0].data)
        )
      );
  }, []);

    

  const joinCommunity = (e) => {
    e.preventDefault();

    if (!code) {
      setCodeInvalid(true);
    } else {
      setCodeInvalid(false);
    }

    if (code) {
      axios
        .post("/get_url_from_code", { code: code })
        .then((res) => {
          console.log("Response", res.data);			
          // loginUser({id:res.data.id});
		  setCookie("app_url", "/c/"+res.data, 1000000);
		  navigate("/c/"+res.data);
        })
        .catch(({ response }) => {
          console.log(response.data);
          if (
            response != null &&
            response.data != null &&
            response.data != "" &&
            response.status == 400
          ) {
            toast.error(response.data);
          } else {
            toast.error("something went wrong");
          }
        });
    }
  };

  return (
    <>
      <div
        className="d-flex justify-content-center"
        style={{ background: "#F8F9FB" }}
      >
        <div className="form-container">
          <div
            className="d-flex flex-column  align-items-center justify-content-center"
            style={{ width: "100%" }}
          >
            <h4 className="pt-5">Join Community</h4>
            {typeof commLogo !== "undefined" && commLogo != "" ? (
              <Image
                className="brand-image-mobile py-3 m-4 m-lg-5"
                src={commLogo}
                id="brand_logo"
              />
            ) : (
              <Image
                className="mt-4"
                fluid
                style={{ maxHeight: "7rem", maxWidth: "15rem" }}
                src={uukiLogo}
                id="brand_logo"
              />
            )}
          </div>

          <Form onSubmit={joinCommunity} className="ms-5 me-5 ps-4 pe-4 mt-5">
            <Form.Group className="mb-3">
              <Form.Label>Enter community code</Form.Label>

              <Form.Control
                isInvalid={codeInvalid}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                id="code"
                type="text"
                className="input-login-signup"
                placeholder="Enter code"
              />
              <Form.Control.Feedback type="invalid">
                Please enter valid code.
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex justify-content-center">
              <Button className="my-4  w-50 uuki-btn" type="submit">
                Enter
              </Button>
            </div>
          </Form>

        </div>
      </div>
    </>
  );
}

export default CommunityCode;
