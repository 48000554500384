import {
  Form,
  InputGroup,
  FormControl,
  Button,
  Overlay,
  OverlayTrigger,
  Popover,
  ListGroup,
  Image,
} from "react-bootstrap";
import "../../../../styles/comments.css";
import Picker from "emoji-picker-react";
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateComments } from "../../../../redux/slice/commentSlice";
import { showLoginModal } from "../../../../redux/slice/authenticationSlice";
import LoginModal from "../../../authentication/LoginModal";
import ReactGiphySearchbox from "react-giphy-searchbox";
import { useDebouncedCallback } from "use-debounce";
import { TrixEditor } from "react-trix";
import Avatar from "react-avatar";
import { useLocation,useParams } from "react-router-dom";
import {ReactComponent as GifIcon} from "../../../../assets/gif.svg"
import { useMediaQuery } from "react-responsive";


export default function AddComment({ postId,replyTo }) {
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isLesson, setIsLesson] = useState(false)
  const [show, setShow] = useState(false);
  const [searchMembers, setSearchMembers] = useState([]);
  const [comment, setComment] = useState("");
  const [currentDoc, setCurrentDoc] = useState(null);
  const [isCommentInvalid, setIsCommentInvalid] = useState(false);
  const commentRef = useRef(null);
  const commentContainer = useRef(null);
  const currentUserId = useSelector((state) => state.userReducer.userId);
  const isLoggedIn = useSelector((state) => {
    return state.userReducer.isUserLoggedIn;
  });
  const allow_anonymous = useSelector(
    (state) => state.communityReducer.allow_anonymous
  );	  
  const isDesktop = useMediaQuery({ query: "(min-width: 900px)" });

  const location = useLocation();
  const newCommentRef = useRef();
  newCommentRef.current = comment
  const dispatch = useDispatch();

  const onEmojiClick = (event, emojiObject) => {
    document.body.click();
    setChosenEmoji(emojiObject);
    commentRef.current.editor.insertString(emojiObject.emoji);
  };
  useEffect(() => {
    if(location.pathname.includes("/course/")){
      setIsLesson(true)
    }else{
      setIsLesson(false)
    }
    commentRef.current.d.style = "flex:1;"
    if(commentRef.current!=null){
      commentRef.current.container.addEventListener("keydown", event => {
      if (event.key == "Enter") {
        event.preventDefault()
        postComment()
      }
      })
    }
  }, [])

  const addGif = (url) => {
    document.body.click();
    commentRef.current.editor.insertHTML("<img src='" + url + "'/>");
    // commentRef.value = comment + "<img src='" + url + "'/>";
    setComment(comment + "<img src='" + url + "'/>");
  };
  const currentCommunityId = useSelector(
    (state) => state.communityReducer.communityId
  );
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const { topicSlug } = useParams();

  const emojiPopover = (
    <Popover style={{ maxWidth: null }}>
      <Picker onEmojiClick={onEmojiClick} />
    </Popover>
  );
  const gifPopover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <ReactGiphySearchbox
          apiKey="O2RO1H2qZ0uPSzMohb9uj14IibhIdXHz"
          onSelect={(item) => addGif(item.images.original.url)}
        />
      </Popover.Body>
    </Popover>
  );

  const postComment = () => {
    let newComment = newCommentRef.current
    if(newComment==""){
      setIsCommentInvalid(true)
    }else{
      setIsCommentInvalid(false)
    }
    if (!isLoggedIn) {
      dispatch(showLoginModal(true));
    } else if(newComment) {
      if(location.pathname.includes("/course/")){
        axios
          .post("/v2/add_lesson_comment", {
            lessonId: postId,
            comment: newComment,
            replyTo: replyTo,
            commId: currentCommunityId,
            topicSlug: topicSlug
          })
          .then((response) => {
            if (response != null && response.data != null) {
              dispatch(updateComments(true));
              document.getElementById("editor-" + commentRef.current.id).value =
                "";
                setTimeout(()=>document.getElementById("comment-"+response.data.commentId).scrollIntoView(),500)

            }
          })
          .catch((error) => {
            console.log(error);
          });
      }else{
        axios
          .post("/add_comment", {
            postId: postId,
            userId: currentUserId,
            comment: newComment,
            isAnon: isAnonymous,
            replyTo: replyTo,
          })
          .then((response) => {
            if (response != null && response.data != null) {
              dispatch(updateComments(true));
              document.getElementById("editor-" + commentRef.current.id).value =
                "";
              setTimeout(()=>{
                let yOffset = -300; 
                let element = document.getElementById("comment-"+response.data.commentId);
                let y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'})
              },500)
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const addComment = (value) => {
    if (value.includes("@")) {
      let mention = value.substring(value.indexOf("@"), value.length);
      mention = mention.substring(1);
      mention = mention.substring(0, mention.indexOf("<"));
      if (mention != "") {
        setShow(true);
        axios
          .post("/search_mention", {
            search: mention,
            communityId: currentCommunityId,
          })
          .then((response) => {
            response.data.results.forEach((element) => {
              if (searchMembers != null && searchMembers.length > 0) {
                searchMembers.forEach((item) => {
                  if (item.id !== JSON.parse(element).id) {
                    setSearchMembers([...searchMembers, JSON.parse(element)]);
                  }
                });
              } else {
                setSearchMembers([...searchMembers, JSON.parse(element)]);
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setSearchMembers([]);
        setShow(false);
      }
      setCurrentDoc(commentRef.current.editor.getDocument());
    }
    setComment(value);
  };

  const handleMentionClick = (e) => {
    const editor = document.getElementById("editor-" + commentRef.current.id);
    const editorValue = editor.innerHTML;
    if (!editorValue.includes("<a")) {
      const editedValue = editorValue
        .replace(
          editorValue.slice(
            editorValue.indexOf("@"),
            editorValue.indexOf("</")
          ),
          ""
        )
        .toString()
        .trim();
      editor.value = editedValue;

      var link =
        "<a href='" +
        window.location.protocol +
        "//" +
        window.location.host +
        "/c/" +
        currentSlug +
        "/member?memberId=" +
        e.target.id +
        "'>" +
        e.target.textContent +
        "</a>";

        commentRef.current.editor.insertHTML(link.toString().trim());
        editor.value = editor.value.replace("<br>","")
      commentRef.current.editor.setSelectedRange((commentRef.current.editor.getDocument().toString().length)-1);
    }
    // console.log(commentRef.current.editor.getDocument());
    // const mentionComment =
    //   "<a href=" +
    //   "localhost:5000/test/member/" +
    //   e.target.id +
    //   ">" +
    //   e.target.textContent +
    //   "</a>";
    // commentRef.current.editor.insertHTML(mentionComment.toString());
    setShow(false);
  };
  return (
    <>
      <Overlay
        show={show}
        target={commentRef}
        placement="bottom"
        container={commentContainer}
        containerPadding={20}
        className="mt-5"
      >
        <Popover className="mt-5" id="popover-basic">
          <Popover.Body style={{ minWidth: "15rem" }} className="p-0 ">
            <ListGroup>
              {searchMembers.map((member) => (
                <ListGroup.Item onClick={handleMentionClick} id={member.id}>
                  {member.image != "" ? (
                    <Image
                      roundedCircle
                      className="member-image me-3"
                      src={member.image}
                      loading="lazy"
                    />
                  ) : (
                    <Avatar
                      className="me-2"
                      name={member.name}
                      size="30"
                      textSizeRatio={2.5}
                      round={true}
                    />
                  )}
                  {member.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Popover.Body>
        </Popover>
      </Overlay>
      
      <InputGroup
      
        ref={commentContainer}
        className="flex-nowrap"
        id="comment-input"
      >
        <TrixEditor
          ref={commentRef}
          className="p-2 comment-input"
          
          placeholder="Add a comment"
          onChange={(html, txt) => addComment(html)}
        />
        
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          rootClose
          overlay={emojiPopover}
        >
          <Button className="comment-reaction-button emoji-btn">
            <i className="fa-regular fa-face-smile-beam"></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          rootClose
          overlay={gifPopover}
        >
          <Button className=" comment-reaction-button p-1 gif-btn">
            <GifIcon className="gif-icon"/>
          </Button>
        </OverlayTrigger>
        {!isDesktop && 
        <Button className=" comment-reaction-button px-2 py-1 gif-btn" onClick={postComment}>
          <i className="fa-solid fa-paper-plane"></i>
        </Button>}
      </InputGroup>

        {/* <Button onClick={postComment} className="uuki-btn uuki-community-btn mt-2 mt-md-0 align-self-start" style={{ minWidth:replyTo!=null && "5rem"}}>
          {replyTo==null ? "Send" : "Reply"}
        </Button> */}
      {isCommentInvalid?<span style={{color:"red"}}>Please enter a valid comment</span>:""}
      {allow_anonymous &&
      <Form.Check
        type="checkbox"
        label="Post Anonymously"
        className="mb-3"
        style={{ fontSize: "12px" }}
        onChange={() => setIsAnonymous(!isAnonymous)}
      />
	  }
    </>
  );
}
