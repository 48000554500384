import React from "react";
import { Container, ListGroup, Stack, Row } from "react-bootstrap";

import { useSelector } from "react-redux";

export default function Links({ linkData }) {
  const currentLanguages = useSelector(
    (state) => state.languagesReducer.languages
  );
  const currentCommunityLanguage = useSelector(
    (state) => state.communityReducer.communityLanguage
  );
  const currentTheme = useSelector(
    (state) => state.communityReducer.theme
  );      
  return (
    <Stack gap={2} className="right-nav-block uuki-box p-3 ">
      <span className="right-block-title mb-1">
        {currentLanguages[10][currentCommunityLanguage]}
      </span>
      <ListGroup variant="flush">
        {linkData != null
          ? linkData.map((link) => (
              <ListGroup.Item
                className="border-0 px-0 right-link"
                key={link.id}
              >
                <a className={"text-decoration-none " + (currentTheme=="dark" ? "text-light" : "text-dark")} href={link.link}>
                  {link.linkName}
                </a>
              </ListGroup.Item>
            ))
          : ""}
      </ListGroup>
    </Stack>
  );
}
