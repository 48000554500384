import {useState,useEffect} from 'react'
import {Accordion,Stack,Form,Tabs,Tab,InputGroup, Button,Modal} from 'react-bootstrap'
import DateTimePicker from "react-datetime-picker";
import axios from 'axios'
import {toast} from 'react-toastify'
import { useSelector } from "react-redux";
import ComposeEmail from '../../ComposeEmail';

function DripTab({sections,adminCodes}) {
    const [dateInvalid, setDateInvalid] = useState(false);
    const [update,setUpdate] = useState(false)
    const [emailContent,setEmailContent] = useState(null)
    const [newEmailContent,setNewEmailContent] = useState(null)
    const [showEmailModal,setShowEmailModal] = useState(false);

    const communityId = useSelector(
        (state) => state.communityReducer.communityId
    );
    const changeDate = (newDate,sectionId) => {
        let getSection = sections.find(
            (el) => el.id === sectionId
            );
        getSection.date = newDate 
        setUpdate((prev)=>!prev)
    }
    const changeDay = (newDay,sectionId) => {
        let getSection = sections.find(
            (el) => el.id === sectionId
            );
        getSection.daysAfter = newDay
    }
    const saveDate = (type,sectionId) => {
        let getSection = sections.find(
            (el) => el.id === sectionId
            );
        let value
        if(type==0){
            value = getSection.date
        }else{
            value= getSection.daysAfter
        }
        axios
        .post("/v2/save_section_date", { id: sectionId, type: type, value: value, commId:communityId})
        .then((res) => {
            toast.success("Release Drip Updated");
        })
        .catch(function (error) {
            toast.error("Release Drip Not Updated");
        });
    }
    useEffect(() => {
      if(newEmailContent!=null){
        axios
        .post("/v2/save_section_email", { id: emailContent.id,commId:communityId, subject: newEmailContent.title, content: newEmailContent.content, jsonContent:newEmailContent.jsonContent})
        .then((res) => {
            toast.success("Email Updated");
            setEmailContent(null)
            setShowEmailModal(false)
        })
        .catch(function (error) {
            toast.error("Email Not Updated");
        });
      }
    }, [newEmailContent])
    
    return (
        <div style={{minHeight:"80vh"}}>
        {sections!=null && sections.map((section,index) => (
            <Accordion classname="rounded" key={section.id} flush alwaysOpen>
            <Accordion.Item>
                <Accordion.Header className="section-header">
                <Stack className="w-100 text-dark wrapword" direction="horizontal">
                    {section.title}
                </Stack>
                </Accordion.Header>
                <Accordion.Body className="p-4 border">
                
                    <Tabs
                        id="date-type"
                    >
                        <Tab
                        eventKey="dateWise"
                        title="Publish on a Date"
                        className="my-3"
                        >
                            <Stack gap={4} direction="horizontal">
                                <DateTimePicker onChange={(e)=>changeDate(e,section.id)} value={section.date} />
                                <Button className="uuki-btn uuki-community-btn" onClick={()=>saveDate(0,section.id)}>Save</Button>
                            </Stack>
                        </Tab>
                        {index!=0 &&
                        <Tab
                        eventKey="dayWise"
                        title="Publish on a Day"
                        className="my-3"
                        >
                            <Stack gap={4} direction="horizontal">
                                <InputGroup >
                                    <Form.Control type="number" onChange={(e)=>changeDay(e.target.value,section.id)} defaultValue={section.daysAfter} />
                                    <InputGroup.Text id="basic-addon3">
                                    days after student enrolled
                                    </InputGroup.Text>
                                </InputGroup>
                                <Button className="uuki-btn uuki-community-btn" onClick={()=>saveDate(1,section.id)}>Save</Button>
                            </Stack>
                        </Tab>
                        }
                    </Tabs> 
                    <a onClick={()=>{setEmailContent({id:section.id,title:section.emailSubject,content:section.emailContent,jsonContent:section.emailJsonContent});setShowEmailModal(true)}} className="text-decoration-none">Customize Email</a>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
        ))}
         <Modal show={showEmailModal} onHide={()=>setShowEmailModal(false)} centered>
            <Modal.Header closeVariant="white" closeButton>
                <Modal.Title>Customize Reminder Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ComposeEmail emailContent={emailContent} setNewEmailContent={setNewEmailContent} adminCodes={adminCodes}/>
            </Modal.Body>

        </Modal>
        </div>
    )
}

export default DripTab