import React from 'react';
import { Row, Tab, Col } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import NavbarModerator from '../components/moderatordashboard/NavbarModerator';
import { useParams } from 'react-router-dom';
import ManageInvites from '../components/moderatordashboard/ManageInvites';
import ReportedComments from '../components/moderatordashboard/ReportedComments';
import ReportedPosts from '../components/moderatordashboard/ReportedPosts';
import UnapprovedPosts from '../components/moderatordashboard/UnapprovedPosts';
import BanWords from '../components/moderatordashboard/BanWords';
import ModerationLogs from '../components/moderatordashboard/ModerationLogs';
import { useMediaQuery } from 'react-responsive';

export default function ModeratorDashboard() {
	const [key, setKey] = useState('manageinvites');
	const { communitySlug } = useParams();
	const isBigScreen = useMediaQuery({ query: '(min-width: 1600px)' });

	return (
		<>
			<Tab.Container
				id='moderator-nav'
				activeKey={key}
				onSelect={(k) => setKey(k)}>
				<Row className='settings-wrapper min-vh-100 me-0'>
					<NavbarModerator setKey={setKey} communitySlug={communitySlug} />
					<Col
						className='mt-5 py-4'
						md={isBigScreen ? { span: 7, offset: 4 } : { span: 7, offset: 3 }}>
						<Tab.Content className='settings-content center-container-dashboard uuki-box p-3 p-md-4 my-md-2'>
							<Tab.Pane eventKey='manageinvites'>
								<ManageInvites slug={communitySlug} />
							</Tab.Pane>
							<Tab.Pane eventKey='reportedposts'>
								<ReportedPosts slug={communitySlug} />
							</Tab.Pane>
							<Tab.Pane eventKey='reportedcomments'>
								<ReportedComments slug={communitySlug} />
							</Tab.Pane>
							<Tab.Pane eventKey='unapprovedposts'>
								<UnapprovedPosts slug={communitySlug} />
							</Tab.Pane>
							<Tab.Pane eventKey='banwords'>
								<BanWords slug={communitySlug} />
							</Tab.Pane>
							<Tab.Pane eventKey='moderationlog'>
								<ModerationLogs slug={communitySlug} />
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		</>
	);
}
