import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Image, Stack, Accordion, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import InnerHTML from 'dangerously-set-html-content';

function CoursePreview({ courseData }) {
  const currentTopicId = useSelector((state) => state.communityReducer.topicId);
  const isLoggedIn = useSelector((state) => {
    return state.userReducer.isUserLoggedIn;
  });
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(null);
  useEffect(() => {
    if (courseData != null) {

          setData(courseData);
          setLoaded(true);
    }
  }, [courseData]);

  const joinCourse = () => {
      axios
        .post("/v2/direct_join_course", {
          topicId: currentTopicId,
          membershipId: data.id,
        })
        .then((res) => {
          window.location.reload();
        })
        .catch(function (error) {
          console.log("Course Not Joined");
        });
    
  };

  return (

    <div class="d-flex justify-content-center">
      <Stack gap={4} className=" uuki-box  course-block center-container">
        {loaded == true ? (
          <>
            {data.cover != "" ? (
              <Image style={{ maxHeight: "15rem" }} fluid src={data.cover} />
            ) : 
            <div></div>}
            <Stack className="px-4" direction="horizontal">
              <h5 className="m-0">{data.title}</h5>
              <small className="ms-auto">{data.lessonCount} Lessons</small>
            </Stack>
            <Stack gap={5} className="px-4" direction="horizontal">
              <Stack className="align-items-start" gap={3}>
                {isLoggedIn ? (
                  <>
                    {data.isMember ?
                      <Button
                      className="uuki-btn uuki-community-btn"
                      onClick={joinCourse}
                    >
                      Join
                    </Button> :
                    <small>Join the community to get started</small>}
                  </>
                ) : (
                  <small>Log In to Join</small>
                )}
                <small className="text-center">
                  {data.studentCount} Students Enrolled
                </small>
              </Stack>
            </Stack>
            <hr className="post-footer-divider m-0"></hr>
            <div className="px-4" >
            {data.desc!="" &&
            <>
              <InnerHTML html={data.desc} />
            </>
            }
            </div>
            <hr className="post-footer-divider m-0"></hr>

            <Stack className=" px-4 pb-4">
              <span className="pb-3">Course Curriculum</span>
              {data.sections.map((section) => (
                <Accordion
                  classname="rounded"
                  key={section.id}
                  flush
                  alwaysOpen
                >
                  <Accordion.Item>
                    <Accordion.Header className="section-header">
                      <Stack className="w-100 text-dark wrapword" direction="horizontal">
                        {section.title}
                      </Stack>
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                      {section.lessons.map((lesson) => (
                        <Nav.Item className=" ">
                          <Nav.Link
                            style={{ fontSize: "1rem" }}
                            className="py-4 px-4 space-name text-dark d-flex align-items-center border rounded-0"
                          >
                            <small className="wrapword">{lesson.title}</small>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                       {section.quizzes.map((quiz) => (
                        <Nav.Item className=" ">
                          <Nav.Link
                            style={{ fontSize: "1rem" }}
                            className="py-4 px-4 space-name text-dark d-flex align-items-center border rounded-0"
                          >
                            <small className="wrapword">{quiz.title}</small>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </Stack>
          </>
        ) : (
          <div className="p-4 d-flex justify-content-center" ref={ref}>
            {/* <Spinner animation="border" /> */}
            <span class="loader-spinner" />
          </div>
        )}

        <div className="p-4"></div>
      </Stack>
    </div>
  );
}

export default CoursePreview;
