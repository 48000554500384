import {
  Button,
  Stack,
  Popover,
  OverlayTrigger,
  ListGroup,
} from "react-bootstrap";
import React from "react";
import CreatePostPopup from "./post/CreatePostPopup";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function RecentActivityHeader({
  setMethod,
  currentMethod,
  setMethodChange,
  setKey,
  setEditPost,
  topicAllow,
}) {
  const [modalShow, setModalShow] = useState(false);
  const currentTopicForEvents = useSelector(
    (state) => state.communityReducer.topicForEvents
  );
  const isLoggedIn = useSelector((state) => {
    return state.userReducer.isUserLoggedIn;
  });
  const currentLanguages = useSelector(
    (state) => state.languagesReducer.languages
  );
  const currentCommunityLanguage = useSelector(
    (state) => state.communityReducer.communityLanguage
  );
  const popoverSort = (
    <Popover className="navbar-popover mt-3">
      <Popover.Body className="px-3 py-2">
        <ListGroup as="ol">
          {!currentTopicForEvents ? (
            <>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => changeMethod(0)}
              >
                <i className="fas fa-broadcast-tower me-2"></i>
                {currentLanguages[18][currentCommunityLanguage]}
              </ListGroup.Item>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => changeMethod(1)}
              >
                <i className="fas fa-stopwatch me-2"></i>
                {currentLanguages[19][currentCommunityLanguage]}
              </ListGroup.Item>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => changeMethod(2)}
              >
                <i className="fas fa-calendar-day me-2"></i>
                {currentLanguages[20][currentCommunityLanguage]}
              </ListGroup.Item>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => changeMethod(3)}
              >
                <i className="fas fa-calendar-week me-2"></i>
                {currentLanguages[21][currentCommunityLanguage]}
              </ListGroup.Item>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => changeMethod(4)}
              >
                <i className="fas fa-calendar me-2"></i>
                {currentLanguages[22][currentCommunityLanguage]}
              </ListGroup.Item>
            </>
          ) : (
            <>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => changeMethod(0)}
              >
                <i className="fas fa-broadcast-tower me-2"></i>
                {currentLanguages[84][currentCommunityLanguage]}
              </ListGroup.Item>
              <ListGroup.Item
                as="button"
                className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                onClick={() => changeMethod(1)}
              >
                <i className="fas fa-stopwatch me-2"></i>
                {currentLanguages[85][currentCommunityLanguage]}
              </ListGroup.Item>
            </>
          )}
        </ListGroup>
      </Popover.Body>
    </Popover>
  );
  const changeMethod = (newMethod) => {
    if (currentMethod != newMethod) {
      setMethod((prev) => newMethod);
      setMethodChange((prev) => true);
      document.body.click();
    }
  };
  return (
    <>
      <Stack
        gap={3}
        direction="horizontal"
        className="recent-activity-bar uuki-box py-2 px-4 d-flex justify-content-between"
      >
        <div className="d-flex align-items-center flex-nowrap ms-1">
          {currentTopicForEvents ? (
            <span className="text-nowrap">
              {currentMethod == 0
                ? currentLanguages[84][currentCommunityLanguage]
                : currentMethod == 1 &&
                  currentLanguages[85][currentCommunityLanguage]}
            </span>
          ) : (
            <span className="text-nowrap">
              {currentMethod == 0
                ? currentLanguages[18][currentCommunityLanguage]
                : currentMethod == 1
                ? currentLanguages[19][currentCommunityLanguage]
                : currentMethod == 2
                ? currentLanguages[20][currentCommunityLanguage]
                : currentMethod == 3
                ? currentLanguages[21][currentCommunityLanguage]
                : currentMethod == 4 &&
                  currentLanguages[22][currentCommunityLanguage]}
            </span>
          )}
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose
            overlay={popoverSort}
          >
            <i className="fas fa-sort ms-2"></i>
          </OverlayTrigger>
        </div>
        {topicAllow && isLoggedIn && (
          <Button
            className="uuki-btn uuki-community-btn me-1"
            onClick={() => {setEditPost((prev)=>[]);setKey("createPost")}}
          >
            <i className="fas fa-plus me-2" />{" "}
            {currentTopicForEvents
              ? "Create Event"
              : currentLanguages[23][currentCommunityLanguage]}
          </Button>
        )}
      </Stack>

      {/* <CreatePostPopup show={modalShow} onHide={() => setModalShow(false)} /> */}
    </>
  );
}
