import { Stack, Form, Button, ListGroup, Modal } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useMoralis } from "react-moralis";
import { useSelector, useDispatch } from "react-redux";
import Settings from "../../pages/Settings";

function AccountSettings({ settings,setCommunitySettings, memberships }) {
  const [showLeave, setShowLeave] = useState(false);
  const dispatch = useDispatch();
  const {
    authenticate,
    isAuthenticated,
    isAuthenticating,
    user,
    account,
    logout,
  } = useMoralis();
  const currentId = useSelector((state) => state.communityReducer.communityId);
  const currentUserId = useSelector((state) => state.userReducer.userId);
  const changeTheme = (changeFor, theme) => {
    axios
      .post("/change_theme", {
        communityId: currentId,
        changeFor: changeFor,
        theme: theme,
      })
      .then((res) => {
        toast.success("Theme Updated");
        setCommunitySettings({...settings,theme:theme})

      })
      .catch(function (error) {
        toast.error("Update Failed");
      });
  };
  const loginMetamask = async () => {
    if (!isAuthenticated) {
      await authenticate({ signingMessage: "Log in using Moralis" })
        .then(function (user) {
          console.log(user);
          axios
            .post("/connect_metamask", {
              communityId: currentId,
              address: user.get("ethAddress"),
            })
            .then((res) => {
              toast.success("Wallet Connected");
            })
            .catch(function (error) {
              toast.error("Wallet Connect Failed");
            });
          console.log(user);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const leaveComm = () => {
    axios
      .post("/remove_member", { userId: currentUserId, communityId: currentId})
      .then((res) => {
        toast.success("Left Community");
        setShowLeave(false)
      })
      .catch(function (error) {
        toast.error("Delete Failed");
      });
  };
  return (
    <>
      <Stack gap={4}>
        <Form.Group controlId="changeTheme">
          <Form.Label>Theme</Form.Label>
          <Stack direction="horizontal" gap={2}>
            <Button
              variant="light"
              style={{ border: "1px solid #c6c6c6" }}
              className="uuki-square-btn"
              onClick={() => changeTheme(1, "light")}
            >
              Light
            </Button>
            <Button
              style={{ backgroundColor: "#2d395a", border: 0 }}
              className="uuki-square-btn"
              onClick={() => changeTheme(1, "dark")}
            >
              Dark
            </Button>
          </Stack>
        </Form.Group>

        <Form.Group controlId="memberEmails">
          <Form.Label>Connect MetaMask</Form.Label>
          <Stack gap={3} direction="horizontal">
            {isAuthenticated && user != null ? (
              <>
                <Stack gap={3} direction="vertical">
                  <span>Connected </span>
                  <span>Eth Address: {user.get("ethAddress")}</span>
                </Stack>
              </>
            ) : (
              <Button
                className="uuki-btn uuki-community-btn"
                onClick={loginMetamask}
              >
                Connect MetaMask
              </Button>
            )}
          </Stack>
        </Form.Group>

        {memberships.length > 0 && (
          <ListGroup className=" content-border-bottom" as="ol">
            <Form.Label>Memberships</Form.Label>
            {memberships.map((membership) => (
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <b>{membership.name}</b>${membership.price} -{" "}
                {membership.type == 1 ? "Monthly" : "One-time"}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}

        <Form.Group controlId="leaveComm">
          <Form.Label>Leave Community</Form.Label>
          <Stack gap={3} direction="horizontal">
            <Button
              className="uuki-delete-btn"
              onClick={() => setShowLeave(true)}
            >
              Leave
            </Button>
          </Stack>
        </Form.Group>
      </Stack>

      <Modal show={showLeave} onHide={() => setShowLeave(false)} centered>
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Are you Sure?</Modal.Title>
        </Modal.Header>
        <Modal.Footer className="border-0 justify-content-center">
          <Button className="uuki-delete-btn" onClick={leaveComm}>
            Leave Community
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AccountSettings;
