import React from "react";

export default function CommentBody({ data, postId }) {
  return (
    <>
      <div
        className="comment-content wrapword ms-5 ps-2"
        dangerouslySetInnerHTML={{ __html: data.comment }}
      ></div>
    </>
  );
}
