import {
  Form,
  InputGroup,
  FormControl,
  Button,
  Overlay,
  OverlayTrigger,
  Popover,
  ListGroup,
  Image,
} from "react-bootstrap";
import "../../styles/comments.css";
import Picker from "emoji-picker-react";
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateComments } from "../../redux/slice/commentSlice";
import { showLoginModal } from "../../redux/slice/authenticationSlice";
import ReactGiphySearchbox from "react-giphy-searchbox";
import { useDebouncedCallback } from "use-debounce";
import { TrixEditor } from "react-trix";
import Avatar from "react-avatar";
import {ReactComponent as GifIcon} from "../../assets/gif.svg"
import { useMediaQuery } from "react-responsive";

export default function AddChat({ sendMessage }) {
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [show, setShow] = useState(false);
  const [searchMembers, setSearchMembers] = useState([]);
  const [comment, setComment] = useState("");
  const [currentDoc, setCurrentDoc] = useState(null);
  const commentRef = useRef(null);
  const commentContainer = useRef(null);
  const currentUserId = useSelector((state) => state.userReducer.userId);
  const isLoggedIn = useSelector((state) => {
    return state.userReducer.isUserLoggedIn;
  });
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const isDesktop = useMediaQuery({ query: "(min-width: 900px)" });
  const dispatch = useDispatch();
  const newCommentRef = useRef();
  newCommentRef.current = comment
  const onEmojiClick = (event, emojiObject) => {
    document.body.click();
    setChosenEmoji(emojiObject);
    commentRef.current.editor.insertString(emojiObject.emoji);
  };

  const addGif = (url) => {
    commentRef.current.editor.insertHTML("<img src='" + url + "'/>");
    // commentRef.value = comment + "<img src='" + url + "'/>";
    setComment(comment + "<img src='" + url + "'/>");
    document.body.click();
  };
  const currentCommunityId = useSelector(
    (state) => state.communityReducer.communityId
  );

  const emojiPopover = (
    <Popover style={{ maxWidth: null }}>
      <Picker onEmojiClick={onEmojiClick} />
    </Popover>
  );
  const gifPopover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <ReactGiphySearchbox
          apiKey="O2RO1H2qZ0uPSzMohb9uj14IibhIdXHz"
          onSelect={(item) => addGif(item.images.original.url)}
        />
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    commentRef.current.d.style = "flex:1;"
    if(commentRef.current!=null){
      commentRef.current.container.addEventListener("keydown", event => {
      if (event.key == "Enter") {
        event.preventDefault()
        sendMessage(newCommentRef.current);
        document.getElementById("editor-" + commentRef.current.id).value ="";
      }
      })
    }
    
  }, [])

  const addComment = (value) => {
    if (value.includes("@")) {
      let mention = value.substring(value.indexOf("@"), value.length);
      mention = mention.substring(1);
      mention = mention.substring(0, mention.indexOf("<"));
      if (mention != "") {
        console.log("inside Mention");
        setShow(true);
        axios
          .post("/search_mention", {
            search: mention,
            communityId: currentCommunityId,
          })
          .then((response) => {
            response.data.results.forEach((element) => {
              if (searchMembers != null && searchMembers.length > 0) {
                searchMembers.forEach((item) => {
                  if (item.id !== JSON.parse(element).id) {
                    setSearchMembers([...searchMembers, JSON.parse(element)]);
                  }
                });
              } else {
                setSearchMembers([...searchMembers, JSON.parse(element)]);
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setSearchMembers([]);
        setShow(false);
      }
      setCurrentDoc(commentRef.current.editor.getDocument());
    }
    setComment(value);
  };

  const handleMentionClick = (e) => {
    const editor = document.getElementById("editor-" + commentRef.current.id);
    const editorValue = editor.value;
    if (!editorValue.includes("<a")) {
      const editedValue = editorValue
        .replace(
          editorValue.slice(
            editorValue.indexOf("@"),
            editorValue.indexOf("</")
          ),
          ""
        )
        .toString()
        .trim();
      editor.value = editedValue;
      if (e.target.id == 0) {
        var link =
          "<a href='" +
          window.location.protocol +
          "//" +
          window.location.host +
          "/members/" +
          currentSlug +
          "'>" +
          e.target.textContent +
          "</a>";
      } else {
        var link =
          "<a href='" +
          window.location.protocol +
          "//" +
          window.location.host +
          "/c/" +
          currentSlug +
          "/member?memberId=" +
          e.target.id +
          "'>" +
          e.target.textContent +
          "</a>";
      }
      console.log(link);

      commentRef.current.editor.insertHTML(link.toString().trim());
      commentRef.current.editor.setSelectedRange(0);
    }
    // console.log(commentRef.current.editor.getDocument());
    // const mentionComment =
    //   "<a href=" +
    //   "localhost:5000/test/member/" +
    //   e.target.id +
    //   ">" +
    //   e.target.textContent +
    //   "</a>";
    // commentRef.current.editor.insertHTML(mentionComment.toString());
    setShow(false);
  };
  return (
    <>
      <Overlay
        show={show}
        target={commentRef}
        placement="top"
        container={commentContainer}
        containerPadding={20}
        className="mb-5"
      >
        <Popover className="mb-5" id="popover-basic">
          <Popover.Body
            style={{ minWidth: "15rem", marginTop: "-7rem" }}
            className="p-0 "
          >
            <ListGroup>
              <ListGroup.Item onClick={handleMentionClick} id={0}>
                Everyone
              </ListGroup.Item>
              {searchMembers.map((member) => (
                <ListGroup.Item onClick={handleMentionClick} id={member.id}>
                  {member.image != "" ? (
                    <Image
                      roundedCircle
                      className="member-image me-3"
                      src={member.image}
                      loading="lazy"
                    />
                  ) : (
                    <Avatar
                      className="me-2"
                      name={member.name}
                      size="30"
                      textSizeRatio={2.5}
                      round={true}
                    />
                  )}
                  {member.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Popover.Body>
        </Popover>
      </Overlay>
        <InputGroup
          ref={commentContainer}
          className="flex-nowrap pt-0"
          id="comment-input"
        >
          <TrixEditor
            ref={commentRef}
            className="p-2 chat-input comment-input text-wrap"
            placeholder="Message"
            onChange={(html, txt) => addComment(html)}
          />
          <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose
            overlay={emojiPopover}
          >
            <Button className="comment-reaction-button emoji-btn">
              <i className="fa-regular fa-face-smile-beam"></i>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose
            overlay={gifPopover}
          >
            <Button className=" comment-reaction-button p-1 gif-btn">
              <GifIcon className="gif-icon"/>
            </Button>
          </OverlayTrigger>
          {!isDesktop && 
          <Button className=" comment-reaction-button px-2 py-1 gif-btn" onClick={() => {sendMessage(newCommentRef.current);document.getElementById("editor-" + commentRef.current.id).value ="";}}>
            <i className="fa-solid fa-paper-plane"></i>
          </Button>}
        </InputGroup>

        {/* <Button
          className="uuki-btn uuki-community-btn mt-2 mt-lg-0 align-self-start"
          onClick={() => {sendMessage(comment);document.getElementById("editor-" + commentRef.current.id).value ="";}}
        >
          Send
        </Button> */}
    </>
  );
}
