import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import {
	Image,
	Stack,
	Button,
	Form,
	ProgressBar,
	Modal,
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import Avatar from 'react-avatar';
import '../../styles/moderatordashboard.css';
import { toast } from 'react-toastify';

export default function ManageInvites({ slug }) {
	const [communityData, setCommunityData] = useState(null);
	const [selectedTopic, setSelectedTopic] = useState(-1);
	const [selectedMember, setSelectedMember] = useState(-1);
	const [selectedFilename, setSelectedFilename] = useState(null);
	useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		axios
			.get('/v2/moderate/' + slug, { params: { type: 6 } })
			.then((response) => {
				setCommunityData(response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const addMemberClickHandler = () => {
		axios
			.post('/accept_space_join', {
				userId: selectedMember,
				topicId: selectedTopic,
			})
			.then((response) => {
				toast.success('Member Added Successfully');
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const uploadCSV = (target) => {
		if (target != null) {
			var filename = target.value.replace(/.*[\/\\]/, '');
			setSelectedFilename(filename);
		}
	};

	const sendInviteClick = () => {
		var file = document.getElementById('file-upload-csv');

		if (selectedFilename.includes('.csv')) {
			var bodyFormData = new FormData();
			bodyFormData.append('file', file.files[0]);
			bodyFormData.append('community', communityData.community_id);
			axios
				.post('/send_csv_invite', bodyFormData)
				.then((response) => {
					toast.success('Invited');
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			toast.error('Incorrect file type selected');
		}
	};

	return (
		<>
			<div className=''>
				<h5 className='mt-3 '>Invite Members to Space</h5>

				<div className='mt-4'>
					<Form.Label>Space</Form.Label>
					<Form.Select
						aria-label='Default select example'
						onChange={(e) =>
							setSelectedTopic(e.target[e.target.selectedIndex].id)
						}>
						<option key='-1' id='-1'>
							Select Space
						</option>
						{communityData != null && communityData.spaces != null
							? communityData.spaces.map((space) => (
									<>
										{space.topics != null && space.topics.length > 0
											? space.topics.map((topic) => (
											        <>
													{topic.privacy==2 &&
													<option key={topic.topic_id} id={topic.topic_id}>
														{topic.topic_name}
													</option>
													}
													</>
											  ))
											: ''}
									</>
							  ))
							: ''}
					</Form.Select>
				</div>

				<div className='mt-4 d-flex justify-content-between align-items-end'>
					{' '}
					<div className='flex-grow-1'>
						<Form.Label>Invite existing member:</Form.Label>
						<Form.Select
							aria-label='Default select example'
							onChange={(e) =>
								setSelectedMember(e.target[e.target.selectedIndex].id)
							}>
							<option key='-1' id='-1'>
								Select a Member
							</option>
							{communityData != null && communityData.members != null
								? communityData.members.map((member) => (
										<option key={`member-` + member.id} id={member.user_id}>
											{member.name}
										</option>
								  ))
								: ''}
						</Form.Select>
					</div>
					<div>
						<Button
							className='uuki-btn uuki-community-btn text-center'
							onClick={(e) => addMemberClickHandler()}>
							Add member
						</Button>
					</div>
				</div>

				<div className='mt-4'>
					{' '}
					<h5>Upload Email List CSV (Max:50)</h5>
					<div className='d-flex justify-content-between'>
						<div className='d-flex align-items-center'>
							<label for='file-upload-csv' class='custom-file-upload'>
								<div className='uuki-btn uuki-community-btn text-center'>
									Upload
								</div>
							</label>
							<input
								id='file-upload-csv'
								className='d-none'
								type='file'
								onChange={(e) => uploadCSV(e.target)}
							/>

							{selectedFilename != null ? (
								<div className='ms-4'>{selectedFilename}</div>
							) : (
								''
							)}
						</div>

						<Button
							className='uuki-btn uuki-community-btn text-center'
							onClick={(e) => sendInviteClick()}>
							Send Invites
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}
