import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import List from '@editorjs/list'
import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
import Image from '@editorjs/image'
import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import SimpleImage from '@editorjs/simple-image'
import AttachesTool from '@editorjs/attaches'
import Paragraph from '@editorjs/paragraph'
import { createReactEditorJS } from "react-editor-js";
import {useState, useEffect, useRef,useCallback} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'


function EditorJS({editorCore,uploadImage,uploadImageByUrl,postSet,content,adminCodes,selectedTopic,communityId}) {
  const ReactEditorJS = createReactEditorJS();
  // const editorCore = useRef(null)
  const [editorKey, setEditorKey] = useState(Date.now());
  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance
  }, [editorCore])

  useEffect(() => {
    setEditorKey(Date.now()); // Update the key to current timestamp or another unique value
  }, [content]);

  const uploadFile = (file) => {
    return new Promise(function(resolve, reject) {
      if(file.name.split('.').pop() != 'pdf') {
        reject([0, null, "Non-pdf file not supported", null]);
      }
      if(adminCodes>3) {
        var file_size_limit = 100000000;    
      } else {
        var file_size_limit = 10000000;    
      }
      if(file.size>file_size_limit) {
          reject([0, null, "File upload size limit exceeded", null]);
      }
        axios.get('/v2/get_community_file_url',{params:{topicId:selectedTopic,commId:communityId,filename:file.name}})
        .then((res) => {
            res = res.data;
            let url = res.url;
            let fields = res.fields;
            let formData = new FormData();
            for(let key in fields) {
            formData.append(key, fields[key]);
            }
            formData.append('file', file);
            const xhr = new XMLHttpRequest();
            xhr.addEventListener('loadend', uploadComplete, false);
            function uploadComplete(event) {
                resolve([1,"https://communitydata.uuki.live/"+fields['key'],file.name,file.size])
            }
            xhr.onerror = reject;
            xhr.open("POST", url, true);
            xhr.send(formData);
            toast.success('Image Uploaded')
        
        }).catch(function (error) {
            toast.error('Image Upload Failed')
        });
    })
  }
  const EDITOR_JS_TOOLS = {
    embed: Embed,
    paragraph:{config:{placeholder:"Click on + to choose a block"}},
    // table: Table,
    // marker: Marker,
    list: List,
    // warning: Warning,
    // code: Code,
    linkTool: {class:LinkTool,config:{endpoint: '/accept_url'}},
    image:{class:Image,config: {
      uploader: {
        uploadByFile(file){ 	
          return uploadImage(file).then((url) => {
            return {
              success: 1,
              file: {
                url: url,
              }
            };
          });
        },
        uploadByUrl(url){
          return uploadImageByUrl(url).then((url) => {
            return {
              success: 1,
              file: {
                url: url,
              }
            };
          });
        }
      }
    }},
    raw: Raw,
    header: Header, 
    // quote: Quote,
    // checklist: CheckList,
    // delimiter: Delimiter,
    // inlineCode: InlineCode,
    simpleImage: SimpleImage,
    attaches: {
      class: AttachesTool,
      config: {
        uploader: {
          uploadByFile(file){
            return uploadFile(file).then(([status, url, title, size]) => {
              if(status == 1) {	
                return {
                  success: 1,
                  file: {
                    url: url,
                    title: title,
                    size: size,    
                  }
                };
              } else {
              return Promise.reject({error : title});	  
              }
            });
          },
        }
      }
    }
  }
  return(
    <>
      {postSet==true &&  <ReactEditorJS key={editorKey} defaultValue={content} onInitialize={handleInitialize}  tools={EDITOR_JS_TOOLS}/>}
      {postSet==false && <ReactEditorJS key={editorKey} onInitialize={handleInitialize}  tools={EDITOR_JS_TOOLS}/>}
    </>
  )
}

export default EditorJS
