import {Form,InputGroup,Button,Stack} from 'react-bootstrap'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'

function APISettings({setApiSettings,settings}) {
    const currentId = useSelector(
        (state) => state.communityReducer.communityId
        )
    const generateAPI = () => {
        axios.post('/generate_communityapi',{commId: currentId})
            .then((res) => {
                toast.success('API Generated')
                setApiSettings({...settings,apiId:res.data.id,token:res.data.token})
            }).catch(function (error) {
                toast.error('API Not Generated')
            });
    }
    return (
        <>
            <Stack gap={4}>
                <Form.Group  controlId="apiId">
                    <Form.Label>API/Merchant ID</Form.Label>
                    <InputGroup className="settings-input-group">
                        <Form.Control defaultValue={(settings != "Not Generated") ? settings.apiId:""} disabled/>
                        <Button variant="secondary" >
                            <i className="fa-regular fa-clipboard"></i>
                        </Button>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="apiId">
                    <Form.Label>API Secret/Token</Form.Label>
                    <InputGroup>
                        <Form.Control defaultValue={(settings != "Not Generated") ? settings.token:""} disabled/>
                        <Button variant="secondary" >
                            <i className="fa-regular fa-clipboard"></i>
                        </Button>
                    </InputGroup>
                </Form.Group>
                <div className="content-border"></div>
                <Button className="uuki-btn uuki-community-btn align-self-start" onClick={generateAPI}>Generate</Button>
            </Stack>
        </>
    )
}

export default APISettings
