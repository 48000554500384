import {Stack,Form,Button} from 'react-bootstrap'
import {useState,useEffect} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'

function SMTPSettings({settings}) {
    const [settingsSet, setSettingsSet] = useState(false);
    const [smtp, setSmtp] = useState({email:'',username:'',password:'',enabled:false,host:'',port:''});
	const enableAddon = useSelector((state) => state.communityReducer.enable_addon);
    const currentId = useSelector(
        (state) => state.communityReducer.communityId
    )
    useEffect(() => {
        if(Object.entries(settings).length > 0) {
            setSettingsSet((prev)=>true);
            setSmtp({email:settings.email,username:settings.username,password:settings.password,enabled:settings.enabled,host:settings.host,port:settings.port})
        }
    },[settings])
    const changeSmtp = () => {
		axios.post('/save_smtp_credentials',{commId: currentId,email: smtp.email,username: smtp.username,password: smtp.password,port: smtp.port,host: smtp.host})
			.then((res) => {
				toast.success('SMTP details updated')
			
			}).catch(function (error) {
				toast.error('Community SMTP Not Updated')
			});
    }
    const enableSmtp = (enabled) => {
        axios.post('/change_enable_smtp',{id: currentId,value: enabled})
            .then((res) => {
				setSmtp({...smtp,enabled:enabled})
				toast.success('SMTP enabled')
            }).catch(function (error) {
                toast.error('Error')
            });
    }	
	const upgradeAddon = () => {
        window.open("https://payments.pabbly.com/subscribe/6219fb0318cd581b431c10bc/premium-addon", '_blank');		
	}
    return (
        <>
		{enableAddon == 1 ? (    
           <Stack gap={4}>
                <Stack direction="horizontal">
                    <h4 className="m-0">Enable SMTP</h4>
                    <Form.Switch className="ms-auto" checked={smtp.enabled} onChange={(event)=>enableSmtp(event.target.checked)}/>
                </Stack>
                <Form.Group controlId="senderEmail">
                    <Form.Label>Sender Email</Form.Label>
                    <Form.Control defaultValue={smtp.email} onChange={(event)=>setSmtp({...smtp,email:event.target.value})}/>
                </Form.Group>
                <Form.Group controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control defaultValue={smtp.username} onChange={(event)=>setSmtp({...smtp,username:event.target.value})}/>
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control defaultValue={smtp.password} onChange={(event)=>setSmtp({...smtp,password:event.target.value})}/>
                </Form.Group>
                <Form.Group controlId="host">
                    <Form.Label>Host</Form.Label>
                    <Form.Control defaultValue={smtp.host} onChange={(event)=>setSmtp({...smtp,host:event.target.value})}/>
                </Form.Group>
                <Form.Group controlId="port">
                    <Form.Label>Port</Form.Label>
                    <Form.Select value={smtp.port} onChange={(event)=>setSmtp({...smtp,port:event.target.value})}>
                        <option >Select a Port to use</option>
                        <option value="465">SSL-465</option>
                        <option value="587">TLS-587</option>
                    </Form.Select>
                </Form.Group>
                <div className="content-border"></div>
                <Button className="uuki-btn uuki-community-btn align-self-start" onClick={changeSmtp}>Save</Button>
            </Stack> 
			):(
			<Button className="uuki-btn uuki-community-btn align-self-start" onClick={upgradeAddon}>Upgrade</Button>
			)
		}
        </>
    )
}

export default SMTPSettings
