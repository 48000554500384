import { Container, Row, Col, Image, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import uukiLogo from "../assets/uuki-logo.png";

import "../styles/signin.css";

function AppsumoSignup() {
  const [commLogo, setCommLogo] = useState("");
  const [commName, setCommName] = useState("");
  const [commPrivPol, setCommPrivPol] = useState("");
  const [commTOS, setCommTOS] = useState("");
  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");  
  const [isChecked, setIsChecked] = useState(false);

  const applyStyles = (theme) => {
    if (theme == "light") {
      document.documentElement.style.setProperty("--primary", "#ffffff");
      document.documentElement.style.setProperty("--secondary", "#eff1f5");
      document.documentElement.style.setProperty("--fontColor", "#2d395a");
      document.documentElement.style.setProperty(
        "--border",
        "1px solid #dfdfdf"
      );
    } else {
      document.documentElement.style.setProperty("--primary", "#2B2B2B");
      document.documentElement.style.setProperty("--secondary", "#000000");
      document.documentElement.style.setProperty("--fontColor", "#ffffff");
      document.documentElement.style.setProperty(
        "--border",
        "1px solid #030303"
      );
    }
 
  };

  useEffect(() => {
    let endpoints = ["/v2/get_community_info?url=" + window.location.href];
    axios
      .all(endpoints.map((endpoint) => axios.get(endpoint)))
      .then(
        (data) => (
          setCommName((commName) => (commName = data[0].data.name)),
          setCommPrivPol(
            (commPrivPol) => (commPrivPol = data[0].data.privacy_policy)
          ),
          setCommTOS((commTOS) => (commTOS = data[0].data.tos)),
          (document.title = data[0].data.name + " Signup"),
          setCommLogo((commLogo) => (commLogo = data[0].data.logo)),
          setData(data[0].data),
          data[0].data.theme!=undefined && applyStyles(data[0].data.theme)

        )
      );
  }, []);

  const signUp = (e) => {
    e.preventDefault();

    if (
      password != null &&
      password !== "" &&
      code != null &&
      code !== "" &&	  
      email != null &&
      email !== "" &&
      name != null &&
      name != ""
    ) {
      axios
        .post("/appsumo_signup", {
          email: email,
          name: name,
          password: password,
          code: code,		  
        })
        .then((res) => {
			console.log(res)
            toast.success("Signup success");			
			setTimeout(() => {
              window.location.href = "/redeem_appsumo"
            }, 2000);
		})
        .catch(({ response }) => {
          console.log(response.data);
          if (
            response != null &&
            response.data != null &&
            response.data != ""
          ) {
            toast.error(response.data);
          } else {
            toast.error("something went wrong");
          }
        });
    } else {
      if (email == null || email === "") {
        toast.error("Please enter a valid email");
      }

      if (password != null && password === "") {
        toast.error("Password can't be blank");
      }
	  
      if (code != null && code === "") {
        toast.error("Code can't be blank");
      }	  

      if (name != null && name === "") {
        toast.error("Name can't be blank");
      }
    }
  };

  const handlePrivacyPolicyCheck = (e) => {
    console.log(e.target.checked);
    setIsChecked(e.target.checked);
  };

  return (
    <>
      <div
        className="d-flex justify-content-center"
        // style={{ background: "#F8F9FB" }}
      >
        <div className="form-container">
          <div
            className="d-flex flex-column  align-items-center justify-content-center"
            style={{ width: "100%" }}
          >
            <h4 className="pt-5">Signup</h4>
            {typeof commLogo !== "undefined" && commLogo != "" ? (
              <Image
                className="brand-image-mobile py-3 m-4 m-lg-5"
                src={commLogo}
                id="brand_logo"
              />
            ) : (
              <Image
                className="mt-4"
                fluid
                style={{ maxHeight: "7rem", maxWidth: "15rem" }}
                src={uukiLogo}
                id="brand_logo"
              />
            )}
          </div>

          <Form onSubmit={signUp} className="ms-5 me-5 ps-4 pe-4 mt-5">
            <Form.Group className="mb-3">
              <Form.Label>Enter your email</Form.Label>

              <Form.Control
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                required
                type="email"
                className="input-login-signup"
                placeholder="Enter email"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Enter your name</Form.Label>
              <Form.Control
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                id="name"
                type="text"
                placeholder="Enter name"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Enter password</Form.Label>
              <Form.Control
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                type="password"
                placeholder="Enter password"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Enter AppSumo code</Form.Label>
              <Form.Control
                value={code}
                required
                onChange={(e) => setCode(e.target.value)}
                id="code"
                type="text"
                placeholder="Enter code"
              />
            </Form.Group>
            {((typeof commPrivPol !== "undefined" && commPrivPol !== "") ||
              (typeof commTOS !== "undefined" && commTOS !== "")) && (
              <Form.Group className="mb-3 d-flex" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  onChange={(e) => handlePrivacyPolicyCheck(e)}
                />
                <Form.Label className="ms-2">
                  {" "}
                  Accept the community's{" "}
                  {commPrivPol !== "" && (
                    <a href={commPrivPol} target="_blank">
                      Privacy Policy
                    </a>
                  )}{" "}
                  {commTOS !== "" && (
                    <a href={commTOS} target="_blank">
                      Terms of Service
                    </a>
                  )}
                </Form.Label>
              </Form.Group>
            )}

            {(typeof commPrivPol !== "undefined" && commPrivPol !== "") ||
            (typeof commTOS !== "undefined" && commTOS !== "") ? (
              <>
                {isChecked ? (
                  <div className="d-flex justify-content-center">
                    <Button className="my-4  w-50 uuki-btn" type="submit">
                      Sign Up
                    </Button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Button
                      className="my-4  w-50 uuki-btn"
                      type="submit"
                      disabled
                    >
                      Sign Up
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <Button className="my-4  w-50 uuki-btn" type="submit">
                  Sign Up
                </Button>
              </div>
            )}
          </Form>
          <div className="d-flex justify-content-center">
            <span>Have an account?</span>
            <Link
              className="text-decoration-none ms-1"
              to="/login"
              style={{ color: "#21cd9c", fontWeight: "bold" }}
            >
              Sign In
            </Link>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default AppsumoSignup;
