import { useEffect, useState } from "react";
import { Tab, Nav, Stack } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import CurriculumTab from "./adminDashboard/CurriculumTab";
import StudentTab from "./adminDashboard/StudentTab";
import CustomizeTab from "./adminDashboard/CustomizeTab";
import { useInView } from "react-intersection-observer";
import LessonEdit from "./adminDashboard/LessonEdit";
import ReorderTab from "./adminDashboard/ReorderTab";
import DripTab from "./adminDashboard/DripTab";
import QuizDashboard from "./adminDashboard/quizDashboard/QuizDashboard";

function CourseDashboard({ courseData }) {
  const [sections, setSections] = useState(null);
  const [info, setInfo] = useState(null);
  const [students, setStudents] = useState(null);
  const [stats, setStats] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [key, setKey] = useState("curriculum");
  const [lessonId, setLessonId] = useState({ id: 0, sectionId: 0 });
  const [quizId, setQuizId] = useState({ id: 0, sectionId: 0 });
  const currentTopicId = useSelector((state) => state.communityReducer.topicId);
  const communityId = useSelector(
    (state) => state.communityReducer.communityId
  );
  const currentTopicForCourses = useSelector(
    (state) => state.communityReducer.topicForCourses
  );
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if(courseData!=null){
      setSections(courseData.sections);
      setInfo(courseData.info);
      setStudents(courseData.students);
      setStats(courseData.stats)
      setLoaded((prev) => true);
    }
  }, [courseData]);

  return (
    <div className="d-flex justify-content-center">
      <Stack className=" center-container ">
        {loaded == true ? (
          <Tab.Container
            id="course-dashboard"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            {(key != "lessonEdit" && key != "quizEdit") && (
              <Nav
                variant="pills"
                className="text-nowrap mb-3 uuki-box  course-block p-3"
              >
                <Stack direction="horizontal" gap={2} className="admin-scroll">
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="d-flex align-items-center"
                      eventKey="curriculum"
                      style={{ color: "var(--fontColor)" }}
                    >
                      Curriculum
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="d-flex align-items-center"
                      eventKey="students"
                      style={{ color: "var(--fontColor)" }}
                    >
                      Students
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="d-flex align-items-center"
                      eventKey="customize"
                      style={{ color: "var(--fontColor)" }}
                    >
                      Customize
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="d-flex align-items-center"
                      eventKey="reorder"
                      style={{ color: "var(--fontColor)" }}
                    >
                      Reorder
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="d-flex align-items-center"
                      eventKey="drip"
                      style={{ color: "var(--fontColor)" }}
                    >
                      Release Drip
                    </Nav.Link>
                  </Nav.Item>
                </Stack>
              </Nav>
            )}
            <Tab.Content>
              <Tab.Pane
                className="uuki-box  course-block p-4"
                eventKey="curriculum"
              >
                <Stack gap={3}>
                  <CurriculumTab
                    sections={sections}
                    setSections={setSections}
                    setKey={setKey}
                    setLessonId={setLessonId}
                    setQuizId={setQuizId}
                    info={info}

                  />
                </Stack>
              </Tab.Pane>
              <Tab.Pane
                className="uuki-box  course-block p-4"
                eventKey="students"
              >
                <StudentTab students={students} stats={stats} />
              </Tab.Pane>
              <Tab.Pane
                className="uuki-box  course-block p-4"
                eventKey="customize"
              >
                <CustomizeTab info={info} adminCodes={courseData.adminCodes}/>
              </Tab.Pane>
              <Tab.Pane className="uuki-box  course-block p-4" eventKey="lessonEdit">
                <LessonEdit
                  adminCodes={courseData.adminCodes}
                  setKey={setKey}
                  lessonId={lessonId}
                  setLoaded={setLoaded}
                />
              </Tab.Pane>
              <Tab.Pane className="" eventKey="quizEdit">
                <QuizDashboard adminCodes={courseData.adminCodes} setKey={setKey} quizId={quizId}/>
              </Tab.Pane>
              <Tab.Pane className="uuki-box  course-block p-4" eventKey="reorder">
                <ReorderTab sections={sections}/>
              </Tab.Pane>
              <Tab.Pane
                className="uuki-box  course-block p-4"
                eventKey="drip"
              >
                <DripTab sections={sections} adminCodes={courseData.adminCodes}/>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        ) : (
          <div className="p-4 d-flex justify-content-center" ref={ref}>
            {/* <Spinner animation="border" /> */}
            <span class="loader-spinner" />
          </div>
        )}
      </Stack>
    </div>
  );
}

export default CourseDashboard;
