import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Button, Form, Accordion, Stack } from 'react-bootstrap';

function InviteOnboarding({ completeStep, completed,setOnboardKey }) {
	const [selectedFilename, setSelectedFilename] = useState(null);
	const [invites, setInvites] = useState(['']);
	const uploadCSV = (target) => {
		if (target != null) {
			var filename = target.value.replace(/.*[\/\\]/, '');
			setSelectedFilename(filename);
		}
	};
	const currentId = useSelector((state) => state.communityReducer.communityId);

	const sendInviteClick = () => {
		var file = document.getElementById('file-upload-csv');

		if (selectedFilename.includes('.csv')) {
			var bodyFormData = new FormData();
			bodyFormData.append('file', file.files[0]);
			bodyFormData.append('community', currentId);
			axios
				.post('/send_csv_invite', bodyFormData)
				.then((response) => {
					// toast.success('Invited');
					completeStep(7);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			toast.error('Incorrect file type selected');
		}
	};
	const sendInvites = () => {
		axios
			.post('/v2/sendInvites', {
				commId: currentId,
				invites: invites,
			})
			.then((res) => {
				// toast.success('Invites Sent');
				completeStep(7);
				// if (isNew) {
				// 	navigate('/c/' + currentSlug);
				// } else {
				// 	navigate('/c/' + currentSlug + '/member?memberId=' + member.id);
				// }
			})
			.catch(function (error) {
				toast.error('Brand Update Failed');
			});
	};
	return (
		<>
			<Stack gap={3}>
			<Stack direction="horizontal">
				<h5>Invite</h5>
				<a className='ms-auto text-decoration-none' onClick={()=>{completeStep(7);setOnboardKey('interaction')}}>Skip Section</a>
				<Button className="ms-3 uuki-btn uuki-community-btn" onClick={()=>setOnboardKey('interaction')}>Next</Button>
			</Stack>
				<span>
					Get started on growing your community, invite by individual emails or
					add a file of a list.
				</span>
				<Accordion flush alwaysOpen>
					<Accordion.Item>
						<Accordion.Header>
							<Form.Check
								className='onboard-check'
								inline
								name='basic'
								type='checkbox'
								disabled
								checked={completed.includes(7) ? true : false}
							/>
							Invite Members
						</Accordion.Header>
						<Accordion.Body className='ps-5 mb-3'>
							<Stack gap={3}>
								{invites.map((invite, index) => (
									<Form.Control
										className='justify-self-start'
										onChange={(e) => (invites[index] = e.target.value)}
									/>
								))}
								<Button
									className='uuki-btn uuki-community-btn align-self-start'
									onClick={() => setInvites([...invites, ''])}>
									Add Another Invite
								</Button>
								<Button
									className='uuki-btn uuki-community-btn align-self-start'
									onClick={sendInvites}>
									Send and Continue
								</Button>
								<div className='text-center'>OR</div>
								<Stack gap={3} className='mt-4'>
									<span>Upload Email List CSV (Max 50)</span>
									<div className='d-flex justify-content-between'>
										<div className='d-flex align-items-center'>
											<label for='file-upload-csv' class='custom-file-upload'>
												<div className='uuki-btn uuki-community-btn text-center'>
													Upload
												</div>
											</label>
											<input
												id='file-upload-csv'
												className='d-none'
												type='file'
												onChange={(e) => uploadCSV(e.target)}
											/>

											{selectedFilename != null ? (
												<div className='ms-4'>{selectedFilename}</div>
											) : (
												''
											)}
										</div>

										<Button
											className='uuki-btn uuki-community-btn text-center'
											onClick={(e) => sendInviteClick()}>
											Send Invites
										</Button>
									</div>
								</Stack>
							</Stack>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Stack>
		</>
	);
}

export default InviteOnboarding;
