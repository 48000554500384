import {Stack,Button,Form,ToggleButtonGroup,ToggleButton, Row,Popover,ListGroup,InputGroup,OverlayTrigger,Badge,Tabs,Tab,Image} from  'react-bootstrap'
import {useState,useEffect} from 'react'
import Avatar from 'react-avatar'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'

function MembershipEdit({setKey,editMembership,setEditMembership,spaces,members}) {
    const [settingsSet, setSettingsSet] = useState(0);
    const [searchSpaces, setSearchSpaces] = useState({});
    const [searchMembers, setSearchMembers] = useState({});
    const [newSpaces, setNewSpaces] = useState([]);
    const [newMembers, setNewMembers] = useState([]);
    const [newNfts, setNewNfts] = useState([{address:'',id:'',chain:'eth'}]);
    const [membership, setMembership] = useState({name:'',desc:'',type:0,price:0,id:0,currency:"usd"});
    const [payKey, setPayKey] = useState("payment");
    const currentId = useSelector(
        (state) => state.communityReducer.communityId
    )
    useEffect(() => {
        if(Object.entries(editMembership).length > 0) {
            setSettingsSet(1)
            setSearchSpaces(spaces)
            setSearchMembers(members)
            setNewSpaces(editMembership.topics)
            setNewMembers(editMembership.subscribers)
            setNewNfts(editMembership.nfts)
            setMembership({name:editMembership.membership.name,desc:editMembership.membership.desc,price:editMembership.membership.price,currency:editMembership.currency,type:editMembership.membership.type,id:editMembership.membership.id})
        }else{
            setSettingsSet(2)
            setSearchSpaces(spaces)
            setSearchMembers(members)
        }
    },[editMembership])	
    const searchSpace = (spaceName) => {
        if(spaceName == ''){
            setSearchSpaces(spaces) 
        }else{
            let newSearchSpaces = searchSpaces.spaces.filter(user => user.name.includes(spaceName));
            setSearchSpaces({...searchSpaces,spaces:newSearchSpaces})  
        }  
    }

    const searchMember = (memberName) => {
        if(memberName == ''){
            setSearchMembers(members) 
        }else{
            let newSearchMembers = searchMembers.filter(user => user.name.includes(memberName));
            setSearchMembers(newSearchMembers)  
        }  
    }

    const addMembership = () => {
        
        axios.post('/v2/add_membership',{id: currentId,name: membership.name,price: membership.price,currency: membership.currency,type: membership.type,desc: membership.desc,spaces:newSpaces,members:newMembers,payType:payKey,nfts:newNfts})
            .then((res) => {
                toast.success('Community Membership Added')
                window.location.reload();
            }).catch(function (error) {
                toast.error('Community Membership Not Added')
            });
    }

    const editCommunityMembership = () => {
        
        axios.post('/v2/edit_membership',{id: membership.id,name: membership.name,desc: membership.desc,spaces:newSpaces,members:newMembers})
            .then((res) => {
                toast.success('Community Membership Edited')
                window.location.reload();      
            }).catch(function (error) {
                toast.error('Community Membership Not Edited')
            });
    }

    const popoverSpace = (
        <Popover>
          <Popover.Body style={{minWidth:'15rem'}} className="p-0">
            <ListGroup>
                {(Object.entries(searchSpaces).length > 0) && searchSpaces.spaces.map(space => 
                    <ListGroup.Item onClick={()=>{document.body.click();setNewSpaces([...newSpaces,space])}}>{space.name}</ListGroup.Item>
                )}
            </ListGroup>
          </Popover.Body>
        </Popover>
    )
    const popoverMember = (
        <Popover>
          <Popover.Body style={{minWidth:'15rem'}} className="p-0">
            <ListGroup>
                {(Object.entries(searchMembers).length > 0) && searchMembers.map(member => 
                    <ListGroup.Item onClick={()=>{document.body.click();setNewMembers([...newMembers,member])}}>
                    {(member.profilePic != "")?<Image roundedCircle className="member-image me-3" loading="lazy" src={member.profilePic}/>:<Avatar className="me-2" name={member.name} size="30" textSizeRatio={2.5} round={true}/>}{member.name}
                    </ListGroup.Item>
                )}
            </ListGroup>
          </Popover.Body>
        </Popover>
    )

    const addNft = (index,nftKey,value) => {
        var newToken = newNfts
        newToken[index][nftKey] = value
        setNewNfts(newToken)
    }
    return (
        
        <>
            {(settingsSet != 0) &&
            <Stack gap={4}>
                <Stack gap={3} direction="horizontal">
                    <Button onClick={() => {setKey('memberships');setEditMembership({});setSettingsSet(false)}} className="text-dark border-0 bg-transparent" >
                        <i  className="fa-solid fa-arrow-left"></i>
                    </Button>
                    <h4> {(settingsSet==1)?"Edit":"Create"} Membership</h4>
                </Stack>
                <Form.Group controlId="name">
                    <Form.Label>Enter Membership Name</Form.Label>
                    <Form.Control defaultValue={membership.name} onChange={(event) => setMembership({...membership,name:event.target.value})}/>
                </Form.Group>
                <Form.Group controlId="description">
                    <Form.Label>Enter Membership Description</Form.Label>
                    <Form.Control as="textarea" defaultValue={membership.desc} onChange={(event) => setMembership({...membership,desc:event.target.value})}/>
                </Form.Group>
                <div>
                    <Form.Label>Add/Remove Spaces to Access</Form.Label>
                    <Form.Group controlId="spaces">
                        <InputGroup className="mb-3 settings-input-group">
                            <InputGroup.Text ><i className="fa-solid fa-magnifying-glass"></i></InputGroup.Text>
                            <OverlayTrigger trigger="click" placement="bottom" overlay={popoverSpace} rootClose>
                                <Form.Control placeholder="Add new space" onChange={(event)=>searchSpace(event.target.value)}/>
                            </OverlayTrigger>
                        </InputGroup>
                    </Form.Group>
                    <Stack className="flex-wrap" direction="horizontal" gap={3}>
                        {(Object.entries(newSpaces).length > 0) && newSpaces.map(space => 
                            <Badge className="p-2">{space.name}<i className=" ms-2 fa-sm fa-solid fa-x" onClick={()=>{let removedSpaces = newSpaces.filter(user => user.id !== space.id);setNewSpaces(removedSpaces)}}></i></Badge>
                        )}

                    </Stack>
                </div>
                <div>
                    <Form.Label>Add/Remove Members</Form.Label>
                    <Form.Group controlId="spaces">
                        <InputGroup className="mb-3 settings-input-group">
                            <InputGroup.Text ><i className="fa-solid fa-magnifying-glass"></i></InputGroup.Text>
                            <OverlayTrigger trigger="click" placement="bottom" overlay={popoverMember} rootClose>
                                <Form.Control placeholder="Add new member" onChange={(event)=>searchMember(event.target.value)}/>
                            </OverlayTrigger>
                        </InputGroup>
                    </Form.Group>
                    <Stack className="flex-wrap" direction="horizontal" gap={3}>
                        {(Object.entries(newMembers).length > 0) && newMembers.map(subscriber => 
                            <Badge className="p-2">{subscriber.name}<i className=" ms-2 fa-sm fa-solid fa-x" onClick={()=>{let removedMembers = newMembers.filter(user => user.id !== subscriber.id);setNewMembers(removedMembers)}}></i></Badge>
                        )}
                        
                    </Stack>
                </div>
                {settingsSet!=1 &&
                <div>
                    <Form.Label>Choose Payment Method</Form.Label>
                    <Tabs className="border-0 " activeKey={payKey}
        onSelect={(k) => setPayKey(k)}>
                        <Tab eventKey="payment" title="Payment">
                            <Stack className="mt-3" gap={4}>
                                <Form.Group controlId="spaces">
                                    <Form.Label>Enter Membership Price</Form.Label>
                                    <InputGroup className="settings-input-group">
                                            <Form.Control defaultValue={membership.price} onChange={(event) => setMembership({...membership,price:event.target.value})}/>
                                            <Form.Select defaultValue="usd" onChange={(event) => setMembership({...membership,currency:event.target.value})}>
                                              <option>Select currency</option>
                                              <option value="usd">USD</option>
											  <option value="inr">INR</option>
                                              <option value="sgd">SGD</option>
                                              <option value="eur">EUR</option>
											  <option value="gbp">GBP</option>
                                            </Form.Select>											
                                    </InputGroup>
                                </Form.Group>
                                <Row className="align-self-start">
                                    <Form.Label>Payment Type</Form.Label>
                                    <ToggleButtonGroup type="radio" name="paymentOptions" defaultValue={membership.type} onChange={(val) => setMembership({...membership,type:val})}>
                                        <ToggleButton style={{borderTopLeftRadius: '1.8rem',borderBottomLeftRadius: '1.8rem'}} className="p-3" variant='outline-dark' id="payment-1" value={0}>
                                            One-Time
                                        </ToggleButton>
                                        <ToggleButton style={{borderTopRightRadius: '1.8rem',borderBottomRightRadius: '1.8rem'}} className="p-3" variant='outline-dark' id="payment-2" value={1}>
                                            Monthly
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Row>
                            </Stack>
                        </Tab>
                        <Tab eventKey="nft" title="NFT">
                            {newNfts.map((nft,index)=><Stack className="my-3" gap={4}>
                                <Form.Group controlId="tokenAddress">
                                    <Form.Label>Enter NFT Token Address</Form.Label>
                                    <Form.Control defaultValue={nft.address} onChange={(e)=>addNft(index,"address",e.target.value)}/>
                                </Form.Group>
                                <Form.Group controlId="tokenID">
                                    <Form.Label>Enter NFT Token Id</Form.Label>
                                    <Form.Control defaultValue={nft.tokenId} onChange={(e)=>addNft(index,"id",e.target.value)}/>
                                </Form.Group>
                                <Form.Group controlId="chain">
                                    <Form.Label>Select NFT Chain</Form.Label>
                                    <Form.Select id="nft_chain" defaultValue={nft.chain} onChange={(e)=>addNft(index,"chain",e.target.value)}>
                                        <option value="eth">Ethereum</option>
                                        <option value="matic">Polygon</option>
                                        <option value="rinkeby">Rinkeby</option>

                                    </Form.Select>
                                </Form.Group>
                                
                            </Stack>)}
                            <Button className="uuki-btn mt-3 uuki-community-btn align-self-start" onClick={()=>setNewNfts([...newNfts,{address:'',id:'',chain:'eth'}])}>Add Another NFT</Button>

                        </Tab>
                    </Tabs>
                </div>
				}
                <div className="content-border"></div>
                <Button className="uuki-btn uuki-community-btn align-self-start" onClick={(settingsSet==1)?editCommunityMembership:addMembership}>Save</Button>
           
            </Stack>
            }
        </>
    )
}

export default MembershipEdit
