import { Button,FormLabel,ListGroup,Stack,Dropdown,Modal,Form } from "react-bootstrap";
import {useState,useEffect} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useSelector} from 'react-redux'

function WebhookSettings({setWebhookSettings,settings}) {
    const [showEdit, setShowEdit] = useState({id:0,show:false,endpoint:""});
    const [showDelete, setShowDelete] = useState({id:0,show:false});
    const [settingsSet, setSettingsSet] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [newEndpoint, setNewEndpoint] = useState('');
    const [newWebhook, setNewWebhook] = useState(1);
    const currentId = useSelector(
        (state) => state.communityReducer.communityId
    )
    useEffect(() => {
            (Object.entries(settings).length > 0) && setSettingsSet((prev)=>true)
        }, [settings])
    const addHook = () => {
        axios.post('/add_webhook',{commId: currentId,endpoint: newEndpoint,hook_for:newWebhook})
            .then((res) => {
                toast.success('Webhook Added')
                setShowAdd(false)
                setWebhookSettings([...settings,{endpoint:newEndpoint,hook:newWebhook,id:res.data}])
            }).catch(function (error) {
                toast.error('Webhook Not Added')
            });
    }
    const editHook = () => {
        axios.post('/edit_webhook',{commId: currentId,endpoint: showEdit.endpoint,id:showEdit.id})
            .then((res) => {
                toast.success('Webhook Updated')
                setShowEdit(false)
            
            }).catch(function (error) {
                toast.error('Webhook Not Updated')
            });
    }
    const deleteHook = () => {
        axios.post('/remove_webhook',{id:showDelete.id})
            .then((res) => {
                toast.success('Webhook Deleted')
                let removedNewWebhooks = settings.filter(webhook => webhook.id !== showDelete.id);
                setWebhookSettings(removedNewWebhooks)
                setShowDelete(false)
            }).catch(function (error) {
                toast.error('Webhook Not Deleted')
            });
    }
    return (
        <>
            <Stack gap={3}>
                <Button className="uuki-btn uuki-community-btn align-self-start" onClick={()=>setShowAdd(true)}>Add a Webhook</Button>

                <ListGroup>
                    <FormLabel>Current Running Webhooks</FormLabel>
                    <Stack gap={3}>
                        {settingsSet && settings.map((webhook) =>
                        <ListGroup.Item className="border rounded">
                            <Stack direction="horizontal">
                                <span>{(webhook.hook == 1)?"New User":(webhook.hook == 2)?"Left/Removed User":(webhook.hook == 3)?"New Post":(webhook.hook == 4)?"New Join Request":(webhook.hook == 5)?"New Post Like":(webhook.hook == 6)?"New Comment Like":(webhook.hook == 7)?"New Comment":(webhook.hook == 8)?"New User Mention":(webhook.hook == 9)?"New Event RSVP":(webhook.hook == 10)?"New Course Join":(webhook.hook == 11)?"Student Lesson Complete":(webhook.hook == 12)?"Student Section Complete":(webhook.hook == 13)&&"Student Course Complete"}</span>
                                <Dropdown className="ms-auto">
                                    <Dropdown.Toggle  className="bg-transparent border-0 text-dark">
                                        <i className="fa-solid fa-ellipsis"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={()=>setShowEdit({id:webhook.id,show:true,endpoint:webhook.endpoint})}>Edit Webhook</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={()=>setShowDelete({id:webhook.id,show:true})}>Delete Webhook</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Stack>
                        </ListGroup.Item>
                       )}
                    </Stack>
                
                </ListGroup>
            </Stack>
            <Modal show={showEdit.show} onHide={() => setShowEdit(false)} centered>
                <Modal.Header closeVariant="white" closeButton>
                    <Modal.Title>Edit Webhook</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack gap={4}>
                        <Form.Group controlId="lvl">
                            <Form.Label>Enter New Endpoint URL</Form.Label>
                            <Form.Control autoFocus defaultValue={showEdit.endpoint} onChange={(event)=>setShowEdit({...showEdit,endpoint:event.target.value})}/>
                        </Form.Group>
                       
                    </Stack>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-center">
                    <Button className="uuki-btn uuki-community-btn" onClick={editHook}>Save</Button>
                </Modal.Footer>
            </Modal> 
            <Modal show={showAdd} onHide={() => setShowAdd(false)} centered>
                <Modal.Header closeVariant="white" closeButton>
                    <Modal.Title>Add Webhook</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack gap={4}>
                        <Form.Group controlId="newEndpoint">
                            <Form.Label>Enter Endpoint</Form.Label>
                            <Form.Control autoFocus onChange={(event)=>setNewEndpoint(event.target.value)}/>
                        </Form.Group>
                        <Form.Group controlId="newHook">
                            <Form.Label>Choose Webhook</Form.Label>
                            <Form.Select defaultValue={0} onChange={(event) => setNewWebhook(event.target.value)}>
                                <option value="1">New User</option>
                                <option value="2">Removed/Left User</option>
                                <option value="3">New Post</option>
                                <option value="4">New Join Request</option>
                                <option value="5">New Post Like</option>
                                <option value="6">New Comment Like</option>
                                <option value="7">New Comment</option>
                                <option value="8">New User Mention</option>
                                <option value="9">New Event RSVP</option>
                                <option value="10">New Join on Course</option>								
                                <option value="11">Student Lesson Complete</option>								
                                <option value="12">Student Section Complete</option>	
                                <option value="13">Student Course Complete</option>															
								
                            </Form.Select>
                        </Form.Group>
                       
                    </Stack>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-center">
                    <Button className="uuki-btn uuki-community-btn" onClick={addHook}>Save</Button>
                </Modal.Footer>
            </Modal> 

            <Modal show={showDelete.show} onHide={() => setShowDelete(false)} centered>
                <Modal.Header closeVariant="white" closeButton>
                    <Modal.Title>Are you Sure?</Modal.Title>
                </Modal.Header>
                
                <Modal.Footer className="border-0 justify-content-center">
                    <Button className="uuki-delete-btn" onClick={deleteHook}>Delete Webhook</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default WebhookSettings
