import {Stack,Button,Form,ToggleButtonGroup,ToggleButton, Row,Spinner} from  'react-bootstrap'
import {useState,useEffect} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce';


function SpaceEdit({setKey,editSpace,setSpace}) {
    const [settingsSet, setSettingsSet] = useState(false);
    const [approveSwitch, setApproveSwitch] = useState(false);
    const [allowSwitch, setAllowSwitch] = useState(false);
    const [hideSwitch, setHideSwitch] = useState(false);
    const [discordSwitch, setDiscordSwitch] = useState(false);
    const [telegramSwitch, setTelegramSwitch] = useState(false);
    const [twitterSwitch, setTwitterSwitch] = useState(false);
    const [privacySwitch, setPrivacySwitch] = useState(0);
    const [layoutSwitch, setLayoutSwitch] = useState(0);
    const [allowTrainingSwitch, setAllowTrainingSwitch] = useState(0);
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
	const [embed_script, setEmbedScript] = useState('');
    useEffect(() => {
           if(Object.entries(editSpace).length > 0) {
                setSettingsSet((prev)=>true);
                setApproveSwitch(editSpace.approve_required)
                setAllowSwitch(editSpace.allow_member_posts)
                setHideSwitch(editSpace.hidden)
                setDiscordSwitch(editSpace.discord_post)
                setTelegramSwitch(editSpace.telegram_post)
                setTwitterSwitch(editSpace.twitter_post)
                setPrivacySwitch(editSpace.privacy)
                setLayoutSwitch(editSpace.layout)
                setTitle(editSpace.meta_name)
                setDesc(editSpace.meta_desc)
                setAllowTrainingSwitch(editSpace.training_allowed)
            }
       }, [editSpace])

    const changeApproval = (isSet) => {
        setApproveSwitch((prev)=>!prev)
        axios.post('/require_approval',{topicId: editSpace.id,check: isSet})
            .then((res) => {
                toast.success('Space Post Approval Updated')
            
            }).catch(function (error) {
                toast.error('Space Post Approval Not Updated')
            });
    }
    const changeAllow = (isSet) => {
        setAllowSwitch((prev)=>!prev)
        axios.post('/change_allow_member_posts',{id: editSpace.id,value: isSet})
            .then((res) => {
                toast.success('Space Post Allowance Updated')
            
            }).catch(function (error) {
                toast.error('Space Post Allowance Not Updated')
            });
    }
    const changeHidden = (isSet) => {
        setHideSwitch((prev)=>!prev)
        axios.post('/change_hide_space',{id: editSpace.id,value: isSet})
            .then((res) => {
                toast.success('Space Navigation Visibility Updated')
            
            }).catch(function (error) {
                toast.error('Space Navigation Visibility Not Updated')
            });
    }
    const changeDiscord = (isSet) => {
        setDiscordSwitch((prev)=>!prev)
        axios.post('/change_discord_post',{id: editSpace.id,value: isSet})
            .then((res) => {
                toast.success('Space Discord Post Updated')
            
            }).catch(function (error) {
                toast.error('Space Discord Post Not Updated')
            });
    }
    const changeTelegram = (isSet) => {
        setTelegramSwitch((prev)=>!prev)
        axios.post('/change_telegram_post',{id: editSpace.id,value: isSet})
            .then((res) => {
                toast.success('Space Telegram Post Updated')
            
            }).catch(function (error) {
                toast.error('Space Telegram Post Not Updated')
            });
    }
    const changeTwitter = (isSet) => {
        setTwitterSwitch((prev)=>!prev)
        axios.post('/change_twitter_post',{id: editSpace.id,value: isSet})
            .then((res) => {
                toast.success('Space Twitter Post Updated')
            
            }).catch(function (error) {
                toast.error('Space Twitter Post Not Updated')
            });
    }
    const changePrivacy = (isSet) => {
        setPrivacySwitch(isSet)
        axios.post('/change_space_privacy',{id: editSpace.id,value: isSet})
            .then((res) => {
                toast.success('Space Privacy Updated')
            
            }).catch(function (error) {
                toast.error('Space Privacy Not Updated')
            });
    }
    const changeLayout = (isSet) => {
        setLayoutSwitch(isSet)
        axios.post('/change_layout',{id: editSpace.id,layout: isSet})
            .then((res) => {
                toast.success('Space Layout Updated')
            
            }).catch(function (error) {
                toast.error('Space Layout Not Updated')
            });
    }
    const changeTitle = useDebouncedCallback(
        (value) => {
            
            axios.post('/change_metaname',{id: editSpace.id,name:value})
                .then((res) => {
                    toast.success('Space Meta Title Updated')
                
                }).catch(function (error) {
                    toast.error('Update Failed')
                });
        },
        1000
    );
    const changeDesc = useDebouncedCallback(
        (value) => {
            
            axios.post('/change_metadesc',{id: editSpace.id,desc:value})
                .then((res) => {
                    toast.success('Space Meta Description Updated')
                
                }).catch(function (error) {
                    toast.error('Update Failed')
                });
        },
        1000
    );
    const changeEmbedScript = useDebouncedCallback(
        (value) => {
            
            axios.post('/change_space_embed_script',{id: editSpace.id,embed_script:value})
                .then((res) => {
                    toast.success('Space Embed Script Updated')
                
                }).catch(function (error) {
                    toast.error('Update Failed')
                });
        },
        1000
    );	

    const toggleTrainingSwitch = useDebouncedCallback(
        (value) => {
            setAllowTrainingSwitch(value)
            axios.post('/v2/toggle_ai_training',{id: editSpace.id,value:value})
                .then((res) => {
                    toast.success('Allow in training changes saved successfully')
                
                }).catch(function (error) {
                    toast.error('Update Failed')
                });
        },
        1000
    );

    return (
        <>
            {settingsSet  ?
            <Stack gap={4}>
                <Stack gap={3} direction="horizontal">
                    <Button onClick={() => {setKey('spaceSettings');setSpace({})}} className="text-dark border-0 bg-transparent" >
                        <i  className="fa-solid fa-arrow-left"></i>
                    </Button>
                    <h4> Edit Space</h4>
                </Stack>
                <Form.Switch className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 " id="edit-hidden" checked={hideSwitch} onChange={(event) => changeHidden(event.target.checked)} label="Hide Space in Navigation"/>
                {editSpace.forCourses==0 && 
                <>
                    <Form.Switch className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 " id="edit-approval" checked={approveSwitch} onChange={(event) => changeApproval(event.target.checked)} label="Require Post Approval"/>
                    <Form.Switch className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 " id="edit-member-post" checked={allowSwitch} onChange={(event) => changeAllow(event.target.checked)} label="Allow Member Posts"/>
                    <Form.Switch className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 " id="edit-discord" checked={discordSwitch} onChange={(event) => changeDiscord(event.target.checked)} label="Auto-Post in Discord"/>
                    <Form.Switch className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 " id="edit-telegram" checked={telegramSwitch} onChange={(event) => changeTelegram(event.target.checked)} label="Auto-Post in Telegram"/>
                    <Form.Switch className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 " id="edit-twitter" checked={twitterSwitch} onChange={(event) => changeTwitter(event.target.checked)} label="Auto-Post in Twitter"/>
                    <Form.Switch className="d-flex flex-row-reverse align-items-center justify-content-between ps-0 " id="include-training"  checked={allowTrainingSwitch} onChange={(event)=>toggleTrainingSwitch(event.target.checked)} label="Allow AI Training"/>
                </>}
                
                <Row className="align-self-start">
                    <Form.Label>Change Space Privacy</Form.Label>
                    <ToggleButtonGroup type="radio" name="editPrivacyOptions" value={privacySwitch} onChange={(val)=>changePrivacy(val)}>
                        <ToggleButton style={{borderTopLeftRadius: '1.8rem',borderBottomLeftRadius: '1.8rem'}} className="p-3" variant='outline-dark' id="privacy-1" value={0}>
                            <i className="fa-solid fa-bullhorn me-2"></i>Public
                        </ToggleButton>
                        <ToggleButton className="p-3" variant='outline-dark' id="privacy-2" value={1}>
                            <i className="fa-solid fa-lock me-2"></i>Private
                        </ToggleButton>
                        <ToggleButton style={{borderTopRightRadius: '1.8rem',borderBottomRightRadius: '1.8rem'}} className="p-3" variant='outline-dark' id="privacy-3" value={2}>
                            <i className="fa-solid fa-key me-2"></i>Invite Only
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Row>
                {editSpace.forCourses==0 && 
                <Row className="align-self-start">
                    <Form.Label>Change Space Layout</Form.Label>
                    <ToggleButtonGroup type="radio" name="editLayoutOptions" value={layoutSwitch} onChange={(val)=>changeLayout(val)}>
                        <ToggleButton style={{borderTopLeftRadius: '1.8rem',borderBottomLeftRadius: '1.8rem'}} className="p-3" variant='outline-dark' id="layout-1" value={0}>
                            <i className="fa-solid fa-square me-2"></i>Default
                        </ToggleButton>
                        <ToggleButton className="p-3" variant='outline-dark' id="layout-2" value={1}>
                            <i className="fa-solid fa-bars me-2"></i>List
                        </ToggleButton>
                        <ToggleButton style={{borderTopRightRadius: '1.8rem',borderBottomRightRadius: '1.8rem'}} className="p-3" variant='outline-dark' id="layout-3" value={2}>
                            <i className="fa-solid fa-grip me-2"></i>Grid
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Row>
                }
				{editSpace.forEmbed==1 &&
                    <Form.Group controlId="embed_script">
                    <Form.Label>Embed Script</Form.Label>
                    <Form.Control
                      as="textarea"
                      defaultValue={editSpace.embed_script}
                      onChange={(event) => {setEmbedScript(event.target.value);changeEmbedScript(event.target.value)}}
                    />
                    </Form.Group>				
				}
                <Form.Group controlId="editMetaName">
                    <Form.Label>Meta Title</Form.Label>
                    <Form.Control value={title} onChange={(event) => {setTitle(event.target.value);changeTitle(event.target.value)}}/>
                </Form.Group>

                <Form.Group controlId="editMetaDesc">
                    <Form.Label>Meta Description</Form.Label>
                    <Form.Control value={desc} onChange={(event) => {setDesc(event.target.value);changeDesc(event.target.value)}}/>
                </Form.Group>
           
            </Stack>
            :
            <div className="d-flex justify-content-center">
                {/* <Spinner animation="border" /> */}
                <span class="loader-spinner"/>
            </div>
            }
        </>
    )
}

export default SpaceEdit
