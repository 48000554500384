import {useState} from 'react'
import {Stack,ListGroup,Accordion,Image,Button,Row,Col} from 'react-bootstrap'
import Avatar from "react-avatar"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import QuizCompleted from "../../../../lesson/QuizCompleted";
import InnerHTML from 'dangerously-set-html-content';

function QuizSubmissions({submissions,queAns,stats,quizId}) {
  const navigate = useNavigate();
  const [updateArray, setUpdateArray] = useState(false)
  const communitySlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  return (
    <Stack gap={3}>
       { stats!=null &&
        <>
        <Row className="g-0">
          <Col className="d-flex flex-column uuki-box p-3" >
            <span>Total Students Submitted</span>
            <h6><b>{stats.quizTakers}</b></h6>
          </Col>
          <Col className="d-flex flex-column uuki-box mx-2 p-3" >
            <span>Average Time Taken</span>
            <h6><b>{stats.avgTime}</b></h6>
          </Col>
          <Col className="d-flex flex-column uuki-box p-3" >
            <span>Total Submissions</span>
            <h6><b>{stats.submissionCount}</b></h6>
          </Col>
        </Row>
        <Row className="g-0">
          <Col className="d-flex flex-column uuki-box p-3" >
            <span>Highest Score</span>
            <h6><b>{stats.highestScore}</b></h6>
          </Col>
          <Col className="d-flex flex-column uuki-box mx-2 p-3" >
            <span>Lowest Score</span>
            <h6><b>{stats.lowestScore}</b></h6>
          </Col>
          <Col className="d-flex flex-column uuki-box p-3" >
            <span>Average Score</span>
            <h6><b>{Math.round(stats.avgScore)} </b></h6>
          </Col>
        </Row>
        </>}
        <Accordion>
            <Accordion.Item eventKey={0}>
              <Accordion.Header>Question Wise Averages</Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                      {queAns.map((question,index) => (
                        <ListGroup.Item className="d-flex">
                            <div class="d-flex pt-3"><span>{(index+1)+"."}</span> 
                            {question.content !== "" &&
                            <div dangerouslySetInnerHTML={{ __html: question.content }} />
                            }
                            </div>
                            <div className="ms-auto d-flex align-items-center">
                                <span ><b>{Math.round(question.averageScore)}%</b> of students answered correctly</span>
                            </div>
                        </ListGroup.Item>
                      ))}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <hr/>
        <h6 className="m-0">Member Submissions</h6>
        {submissions.length>0 &&
          submissions.map((student) => (
            <Accordion>
              <Accordion.Item eventKey={student.id}>
                <Accordion.Header>
                  <Stack className="w-100 me-4" direction="horizontal">
                    <Stack onClick={()=>navigate('/c/'+communitySlug+'/member?memberId='+student.id)} direction="horizontal" gap={3}>
                      {student.image != '' ? (
                        <Image
                          src={student.image}
                          className='trending-post-image '
                          roundedCircle
                          loading='lazy'></Image>
                      ) : (
                        <Avatar
                          name={student.name}
                          size='28'
                          textSizeRatio={2.5}
                          round={true}
                        />
                      )}
                      {student.name}
                    </Stack>
                    <span className="ms-auto">{student.count} submissions</span>
                  </Stack>
                </Accordion.Header>
                <Accordion.Body>
                  <Stack gap={3}>
                    <ListGroup>
                      {student.submissions.map((submission) => (
                        <ListGroup.Item className="d-flex">
						    {submission.timeTaken && submission.timeTaken!=="None" &&
							<>
                            <span className="">Submitted on {submission.date}</span>
							</>
							}
                            <div className="ms-auto d-flex flex-column align-items-end">
                                <span >{submission.score} Points</span>
									{submission.timeTaken!=="None" &&
									<>
                                <span >Completed in {submission.timeTaken}</span>
								    </>
									}
                            </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                    <hr/>
                    <Button className="uuki-btn uuki-community-btn align-self-start" onClick={()=>{student.view=true;setUpdateArray((prev)=>!prev)}}>Check Last Answers</Button>
                    {student.view==true && <QuizCompleted quizId={quizId} memberId={student.id}/>}
                  </Stack>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
    </Stack>
  )
}

export default QuizSubmissions
