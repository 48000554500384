import React from "react";
import {
  Container,
  Col,
  Nav,
  Navbar,
  Offcanvas,
  Image,
  Stack,
  Button,
  Popover,
  OverlayTrigger,
  Badge,
  ListGroup,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import axios from "axios";

import CommonNavbar from "../CommonNavbar";

export default function NavbarModerator({ setKey, communitySlug }) {
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1600px)" });
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  const communityName = useSelector(
    (state) => state.communityReducer.communityName
  );
  const userName = useSelector(
    (state) => state.userReducer.username
  );  
  const logout = (e) => {
    e.preventDefault();
    axios.get("/logout?noRedirect=1").then((res) => navigate("/login"));
  };
  useEffect(() => {
    document.querySelectorAll(".dashboard-icon").forEach((element) => {
      if (element.parentElement.parentElement.classList.contains("active")) {
        element.setAttribute("filter", "url(#white-svg)");
      } else {
        element.setAttribute("filter", "url(#black-svg)");
      }
    });
  });

  return (
    <>
      <Navbar fixed="top" className="nav-uuki nav-home" expand={false}>
        <Navbar.Brand className="community-name" href={currentHost}>
          <h4>{communityName}</h4>
        </Navbar.Brand>
        {isDesktop ? (
          <CommonNavbar></CommonNavbar>
        ) : (
          <Navbar.Toggle aria-controls="offcanvasNavbar"  onClick={()=>setShowNavbar((prev)=>true)}/>
        )}
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
          show={showNavbar}
          onHide={()=>setShowNavbar((prev)=>false)}
        >
          <Offcanvas.Header closeButton>
            <Navbar.Brand className="community-name" href={currentHost}>
              <h4>{communityName}</h4>
            </Navbar.Brand>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Stack className="h-100" gap={2}>
              <Nav variant="pills" className="flex-column text-nowrap">
                <Stack gap={3}>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="manageinvites"
                      onClick={() => {setKey("manageinvites");setShowNavbar((prev)=>false)}}
                    >
                      Manage Invites
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="reportedposts"
                      onClick={() => {setKey("reportedposts");setShowNavbar((prev)=>false)}}
                    >
                      Reported Posts
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="reportedcomments"
                      onClick={() => {setKey("reportedcomments");setShowNavbar((prev)=>false)}}
                    >
                      Reported Comments
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="unapprovedposts"
                      onClick={() => {setKey("unapprovedposts");setShowNavbar((prev)=>false)}}
                    >
                      Unapproved Posts
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="banwords"
                      onClick={() => {setKey("banwords");setShowNavbar((prev)=>false)}}
                    >
                      Ban Words
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="home-link">
                    <Nav.Link
                      className="px-2"
                      eventKey="moderationlog"
                      onClick={() => {setKey("moderationlog");setShowNavbar((prev)=>false)}}
                    >
                      Moderation Log
                    </Nav.Link>
                  </Nav.Item>
                </Stack>
              </Nav>
              <Stack
                className="text-nowrap mt-auto"
                direction="horizontal"
                gap={2}
              >
                <Avatar
                  name={userName}
                  size="30"
                  textSizeRatio={2.5}
                  round={true}
                />
                <span className="mx">{userName}</span>
                <Button onClick={logout} variant="outline-danger">
                  Logout
                </Button>
              </Stack>
            </Stack>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
      {isDesktop && (
        <Col className="position-fixed mt-5 py-4 px-0" md={isBigScreen ? 4 : 3}>
          <div className="ms-4 vh-100 mb-5  py-2 overflow-scroll home-left-nav nav-scroll">
            <Nav
              variant="pills"
              className=" scroll-nav flex-column text-nowrap overflow-scroll pb-3"
            >
              <Nav.Item className="home-link">
                <Nav.Link
                  className="main-links d-flex align-items-center"
                  eventKey="manageinvites"
                  onClick={() => setKey("manageinvites")}
                >
                  Manage Invites
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="home-link">
                <Nav.Link
                  className="main-links d-flex align-items-center"
                  eventKey="reportedposts"
                  onClick={() => setKey("reportedposts")}
                >
                  Reported Posts
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="home-link">
                <Nav.Link
                  className="main-links d-flex align-items-center"
                  eventKey="reportedcomments"
                  onClick={() => setKey("reportedcomments")}
                >
                  Reported Comments
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="home-link">
                <Nav.Link
                  className="main-links d-flex align-items-center"
                  eventKey="unapprovedposts"
                  onClick={() => setKey("unapprovedposts")}
                >
                  Unapproved Posts
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="home-link">
                <Nav.Link
                  className="main-links d-flex align-items-center"
                  eventKey="banwords"
                  onClick={() => setKey("banwords")}
                >
                  Ban Words
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="home-link">
                <Nav.Link
                  className="main-links d-flex align-items-center"
                  eventKey="moderationlog"
                  onClick={() => setKey("moderationlog")}
                >
                  Moderation Log
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </Col>
      )}
    </>
  );
}
