import React from "react";
import {
  Stack,
  Image,
  OverlayTrigger,
  Popover,
  ListGroup,
  Modal,
  Form,
  Button,
} from "react-bootstrap";
import Avatar from "react-avatar";
import ReactTimeAgo from "react-time-ago";
import "../../../../styles/comments.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateComments } from "../../../../redux/slice/commentSlice";
import { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";


export default function CommentHeader({ data, communityId }) {
  const currentUserId = useSelector((state) => state.userReducer.userId);
  const [editModalShow, setEditModalShow] = useState(false);
  const [popoverShow, setpopoverShow] = useState(false);
  const [editedComment, setEditedComment] = useState("");
  const dispatch = useDispatch();
  const currentRole = useSelector((state) => state.userReducer.userRole);
    const navigate = useNavigate();

  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );

  const reportComment = () => {
    axios
      .post("/report_comment", {
        commentId: data.id,
        communityId: communityId,
      })
      .then((response) => {
        toast.success("Comment Reported");
        dispatch(updateComments(true));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editComment = () => {
    const commentValue = document.getElementById("input-edit-comment").value;
    axios
      .post("/edit_comment", {
        commentId: data.id,
        comment: commentValue,
      })
      .then((response) => {
        setEditModalShow(false);
        dispatch(updateComments(true));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteComment = () => {
    axios
      .post("/delete_comment", {
        commentId: data.id,
      })
      .then((response) => {
        dispatch(updateComments(true));
        document.body.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const popoverCommentSettings = (
    <Popover id="popover-basic" className="navbar-popover">
      <Popover.Body className="px-3 py-2">
        <ListGroup as="ol">
          {data.viewer_role !== "none" ? (
            <ListGroup.Item
              as="button"
              className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
              onClick={deleteComment}
            >
              Delete
            </ListGroup.Item>
          ) : (
            ""
          )}
          {data.viewer_role === "publisher" ? (
            <ListGroup.Item
              as="button"
              className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
              onClick={() => {
                document.body.click();
                setEditModalShow(true);
              }}
            >
              Edit Comment
            </ListGroup.Item>
          ) : (
            ""
          )}

          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
            onClick={reportComment}
          >
            Report
          </ListGroup.Item>
        </ListGroup>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <Modal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        aria-labelledby="edit-comment-modal"
        centered
      >
        <Modal.Header closeVariant="white" closeButton>
          <Modal.Title>Edit Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control defaultValue="" id="input-edit-comment" />
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button className="mt-4" onClick={editComment}>
            Edit Comment
          </Button>
        </Modal.Footer>
      </Modal>
      <Stack direction="horizontal">
        <Stack gap={3} direction="horizontal">
          {data != null && data.isAnonymous && currentRole === 0 ? (
            <Avatar
              name="A"
              size="40"
              textSizeRatio={2}
              className="comment-author-image"
              round={true}
            />
          ) : (
            <>
              {data.user.image_url !== "" &&
              data.user.image_url !== "noImage" ? (
                <Image
                  src={data.user.image_url}
                  className="comment-author-image"
                  roundedCircle
                  loading="lazy"
                  style={{cursor:'pointer'}}
                  onClick={() =>
                    navigate(
                      "/c/" +
                        currentSlug +
                        "/member?memberId=" +
                        data.user.member_id
                    )
                  }
                ></Image>
              ) : (
                <Avatar
                  name={data.user.name}
                  size="40"
                  textSizeRatio={2}
                  className="comment-author-image"
                  round={true}
                  style={{cursor:'pointer'}}
                  onClick={() =>
                    navigate(
                      "/c/" +
                        currentSlug +
                        "/member?memberId=" +
                        data.user.member_id
                    )
                  }
                />
              )}
            </>
          )}

          <Stack gap={0}>
            <Stack direction="horizontal" gap={3}>
              {currentRole == 0 && data.isAnonymous ? (
                <span className="m-0 comment-author-text">Anonymous</span>
              ) : (
                <>
                  <span className="m-0 comment-author-text" style={{cursor:'pointer'}} onClick={() =>
                    navigate(
                      "/c/" +
                        currentSlug +
                        "/member?memberId=" +
                        data.user.member_id
                    )
                  }>
                    {data.user.name}
                  </span>
                  {data.user.is_admin ? (
                    <div className="leaderboard-rank">Admin</div>
                  ) : (
                    ""
                  )}
                </>
              )}

              <ReactTimeAgo
                date={Date.parse(data.date)}
                locale="en-US"
                timeStyle="mini"
                className="comment-timestamp-text"
              />
            </Stack>

            <Stack direction="horizontal" gap={3}>
              {currentRole == 0 && data.isAnonymous ? (
                ""
              ) : (
                <small className="text-muted" style={{ opacity: "0.7" }}>
                  {data.user.bio}
                </small>
              )}
            </Stack>
          </Stack>
        </Stack>
        <OverlayTrigger
          trigger="click"
          delayShow={200}
          rootClose
          placement="top"
          overlay={popoverCommentSettings}
        >
          <i
            style={{ cursor: "pointer" }}
            className="ms-auto fa-solid fa-ellipsis p-3"
          ></i>
        </OverlayTrigger>
      </Stack>
    </>
  );
}
