import {Button,InputGroup,FormControl, Row,Col,Stack,ToggleButtonGroup,ToggleButton} from 'react-bootstrap'
import {useState} from 'react'
import axios from 'axios'
import {ReactComponent as IncludedIcon} from '../../assets/included.svg'


function PricingDashboard({user}) {
    const [code, setCode] = useState(1)
    const [planOption, setPlanOption] = useState(0)

    const buyPlan = (type) => {
        axios.get('/get_recurring_payment_url?plan='+type+'&period='+planOption)
        .then((res) => {
            window.location.href=res.data
        })		
	}

    const buyUpgrade = () => {
        axios.get('/get_payment_url?codes='+code)
        .then((res) => {
            window.location.href=res.data
        })
    }
    return (
        <div className="">
            <h4 className="mb-3">Pricing</h4>
            {(user.codes==0 && user.plan==0)? 
            <>
                <ToggleButtonGroup  type="radio" name="planOptions" value={planOption} onChange={(val)=>setPlanOption(val)}>
                    <ToggleButton style={{borderTopLeftRadius: '1.8rem',borderBottomLeftRadius: '1.8rem'}} className="p-2 px-3" variant='outline-dark' id="privacy-1" value={0}>
                        Monthly
                    </ToggleButton>
                    <ToggleButton style={{borderTopRightRadius: '1.8rem',borderBottomRightRadius: '1.8rem'}} className="p-2 px-3" variant='outline-dark' id="privacy-3" value={1}>
                        Yearly
                    </ToggleButton>
                </ToggleButtonGroup>
                <Row className="mt-3">
                    <Col md={4}>
                        <Stack className=" p-4 border rounded" gap={3}>
                            <b>Starter</b>
                            <h3 className='m-0 text-wrap'>{planOption == 1 ? "$15" :"$19"} /month</h3>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap">Posts and Discussions</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap">Member Profiles</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap">Group chats & private messaging</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap">Moderation</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap">Mobile App</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap">Custom domain</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap">Payments</b></Stack>
                            <Button  className="uuki-btn uuki-dashboard-btn" onClick={()=>buyPlan(0)}>Buy</Button>
                        </Stack>
                    </Col>
                    <Col md={4}>
                        <Stack className=" p-4 border rounded" gap={3}>
                            <b className="text-wrap">Pro</b>
                            <h3 className='m-0 text-wrap'>{planOption == 1 ? "$65" :"$79"} /month</h3>
                            <small>Everything in Starter +</small>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap ms-2">White Labelled Community</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap ms-2">Advanced Reporting</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap ms-2">API Access</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap ms-2">CSS Customization</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap ms-2">Custom SSO</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap ms-2">Webhooks</b></Stack>
                            <Button  className="uuki-btn uuki-dashboard-btn" onClick={()=>buyPlan(1)}>Buy</Button>
                        </Stack>
                    </Col>
                    <Col md={4}>
                        <Stack className=" p-4 border rounded" gap={3}>
                            <b className="text-wrap">Advance</b>
                            <h3 className='m-0 text-wrap'>{planOption == 1 ? "$205" :"$259"} /month</h3>
                            <small>Everything in Pro +</small>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap ms-2">Dedicated Account Manager</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap ms-2">Priority Support</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap ms-2">Email White labelling</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap ms-2">Custom OAuth</b></Stack>
                            <Stack gap={2} direction="horizontal"><IncludedIcon/><b className="text-wrap ms-2">White label Apps</b></Stack>
                            <Button  className="uuki-btn uuki-dashboard-btn" onClick={()=>buyPlan(2)}>Buy</Button>
                        </Stack>
                    </Col>
                </Row>
            </> :
            <Row>
                <Col md={6}>
                <Stack className=" p-4 border rounded" gap={3}>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b className="text-wrap"><span id="price" className="plan-price">${99*code}</span><span className="plan-type"></span></b></Stack>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Unlimited members</b></Stack>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>{code} Community(s)</b></Stack>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>{(code==1)?3:(code==2)?3:(code==3)?5:(code==4)?10:20} Mod/Admin</b></Stack>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Unlimited storage</b></Stack>
                    <Stack gap={3} direction="horizontal"><IncludedIcon/><b>{(code==1)?3:(code==2||code==3)?5:(code==4)?10:20} Private Spaces</b></Stack>
                    {(code>=5) &&
                        <>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>API</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>Webhooks</b></Stack>
                        <Stack gap={3} direction="horizontal"><IncludedIcon/><b>SSO</b></Stack>
                        </>
                    }
                   
                
                </Stack>
                
                </Col>
                
                <Col className="mt-md-5" md="6">
                    <h5>Add more codes</h5>
                    <InputGroup  className="settings-input-group mb-3">
                        <Button className="add-code-left-btn" onClick={() => setCode((code>1)?code-1:code)}>
                            -
                        </Button>
                        <FormControl
                            placeholder={code}
                            readOnly
                            className="add-code-box text-center"
                        />
                        <Button className="add-code-right-btn" onClick={() => setCode((code<user.maxCodes)?code+1:code)}>
                            +
                        </Button>
                    </InputGroup>
                    <Button style={{width:'10rem'}} className="uuki-btn uuki-dashboard-btn" onClick={buyUpgrade}>Buy</Button>
                </Col>
            </Row>
            }
        </div>
    )
}

export default PricingDashboard
