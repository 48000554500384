import { createSlice } from "@reduxjs/toolkit";

const state = () => {
  const initialState = {
    updateComments: false,
    jumpToComment: -1,
  };

  return initialState;
};

export const commentSlice = createSlice({
  name: "commentSlice",
  initialState: state,
  reducers: {
    updateComments: (state, action) => {
      state.updateComments = action.payload;
    },
    jumpToComment: (state, action) => {
      state.jumpToComment = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateComments, jumpToComment } = commentSlice.actions;

export default commentSlice.reducer;
