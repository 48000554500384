import React from "react";
import {
  Stack,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Button,
  Form,
  Overlay,
  Popover,
  ListGroup,
  Image,
} from "react-bootstrap";
import "../../../../styles/comments.css";
import Trix from "trix";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateComments } from "../../../../redux/slice/commentSlice";
import { showLoginModal } from "../../../../redux/slice/authenticationSlice";
import LoginModal from "../../../authentication/LoginModal";
import ReactGiphySearchbox from "react-giphy-searchbox";
import Picker from "emoji-picker-react";
import { TrixEditor } from "react-trix";
import Avatar from "react-avatar";
import AddComment from "./AddComment";

export default function CommentFooter({ data, postId }) {
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [defaultReplyValue, setdefaultReplyValue] = useState(null);
  const [showReplyInput, setshowReplyInput] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [show, setShow] = useState(false);
  const [searchMembers, setSearchMembers] = useState([]);
  const [comment, setComment] = useState("");
  const [isCommentInvalid, setIsCommentInvalid] = useState(false);

  const commentRef = useRef(null);
  const commentContainer = useRef(null);
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.userReducer.userId);
  const isLoggedIn = useSelector((state) => {
    return state.userReducer.isUserLoggedIn;
  });
  const isToShowLoginModal = useSelector(
    (state) => state.authenticationReducer.showLoginModal
  );

  useEffect(() => {
    setIsLiked(data.is_liked);
    setLikeCount(data.likes);
  }, []);
  const onEmojiClick = (event, emojiObject) => {
    document.body.click()
    commentRef.current.editor.insertString(emojiObject.emoji);
  };

  const addGif = (url) => {
    commentRef.current.editor.insertHTML("<img src='" + url + "'/>");
    // commentRef.value = comment + "<img src='" + url + "'/>";
    setComment(comment + "<img src='" + url + "'/>");
    document.body.click()
  };
  const currentCommunityId = useSelector(
    (state) => state.communityReducer.communityId
  );

  const emojiPopover = (
    <Popover style={{ maxWidth: null }}>
      <Picker onEmojiClick={onEmojiClick} />
    </Popover>
  );
  const gifPopover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <ReactGiphySearchbox
          apiKey="O2RO1H2qZ0uPSzMohb9uj14IibhIdXHz"
          onSelect={(item) => addGif(item.images.original.url)}
        />
      </Popover.Body>
    </Popover>
  );
  const postComment = () => {
    if(!comment){
      setIsCommentInvalid(true)
    }else{
      setIsCommentInvalid(false)
    }

    if (!isLoggedIn) {
      dispatch(showLoginModal(true));
    } else if(comment){
      axios
        .post("/add_comment", {
          postId: postId,
          userId: userId,
          comment: comment,
          isAnon: isAnonymous,
          replyTo: data.id,
        })
        .then((response) => {
          if (response != null && response.data != null) {
            setshowReplyInput(false);
            dispatch(updateComments(true));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const showReplyLayout = () => {
    setshowReplyInput(!showReplyInput);
    // if (!showReplyInput) {
    //   const replyValue =
    //     "<a href={window.location.host}>" + data.user.name + "</a>";
    //   setdefaultReplyValue(replyValue);
    // }
  };
  const likePost = () => {
    if (!isLoggedIn) {
      dispatch(showLoginModal(true));
    } else {
      axios
        .post("/add_comment_like", {
          check: !isLiked,
          commentId: data.id,
          userId: userId,
          communityId: currentCommunityId,
        })
        .then((res) => {
          if (isLiked) {
            if (likeCount > 0) {
              setLikeCount((prev) => prev - 1);
            }
          } else {
            setLikeCount((prev) => prev + 1);
          }
          setIsLiked((prev) => !prev);
        })
        .catch(function (error) {});
    }
  };

  const addComment = (value) => {
    console.log(commentRef.current.id);
    if (value.includes("@")) {
      let mention = value.substring(value.indexOf("@"), value.length);
      mention = mention.substring(1);
      mention = mention.substring(0, mention.indexOf("<"));
      if (mention != "") {
        setShow(true);
        axios
          .post("/search_mention", {
            search: mention,
            communityId: currentCommunityId,
          })
          .then((response) => {
            response.data.results.forEach((element) => {
              if (searchMembers != null && searchMembers.length > 0) {
                searchMembers.forEach((item) => {
                  if (item.id !== JSON.parse(element).id) {
                    setSearchMembers([...searchMembers, JSON.parse(element)]);
                  }
                });
              } else {
                setSearchMembers([...searchMembers, JSON.parse(element)]);
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setSearchMembers([]);
        setShow(false);
      }
    }
    setComment(value);
  };

  const handleMentionClick = (e) => {
    const editor = document.getElementById("editor-" + commentRef.current.id);
    const editorValue = editor.value;
    if (!editorValue.includes("<a")) {
      const editedValue = editorValue
        .replace(
          editorValue.slice(
            editorValue.indexOf("@"),
            editorValue.indexOf("</")
          ),
          ""
        )
        .toString()
        .trim();
      console.log("editedValue:" + editedValue);
      editor.value = editedValue;

      var link =
        "<a href='" +
        window.location.protocol +
        "//" +
        window.location.host +
        "/members/test/" +
        e.target.id +
        "/'>" +
        e.target.textContent +
        "</a>";

      commentRef.current.editor.insertHTML(link.toString().trim());
      commentRef.current.editor.setSelectedRange(0);
    }
    // console.log(commentRef.current.editor.getDocument());
    // const mentionComment =
    //   "<a href=" +
    //   "localhost:5000/test/member/" +
    //   e.target.id +
    //   ">" +
    //   e.target.textContent +
    //   "</a>";
    // commentRef.current.editor.insertHTML(mentionComment.toString());
    setShow(false);
  };
  return (
    <div className="mb-3">
      <Stack className="comment-footer" gap={1} direction="horizontal">
        {likeCount != null && likeCount > 0 ? (
          <>
            <div
              style={
                isLiked
                  ? { cursor: "pointer", color: "#21cd9c" }
                  : { cursor: "pointer" }
              }
              className="mt-1"
              onClick={likePost}
            >
              <span className="comment-like-share-text mt-2">{likeCount}</span>
              <i class="fa-regular fa-thumbs-up fa-lg me-1 ms-1"></i>
              <span className="comment-like-share-text mt-3">Like</span>
            </div>
          </>
        ) : (
          <>
            <div
              className="mt-1"
              style={
                isLiked
                  ? { cursor: "pointer", color: "#21cd9c" }
                  : { cursor: "pointer" }
              }
              onClick={likePost}
            >
              <i class="fa-regular fa-thumbs-up fa-lg me-1 ms-1"></i>
              <span className="comment-like-share-text mt-3">Like</span>
            </div>
          </>
        )}

        <div
          style={{ cursor: "pointer" }}
          className="ms-3 mt-1"
          onClick={showReplyLayout}
        >
          <span className="comment-like-share-text ">Reply</span>
        </div>
      </Stack>

      {showReplyInput && 
        <div className="mt-3">
          <AddComment postId={postId} replyTo={data.id}/>
        </div>
      }

    </div>
  );
}
