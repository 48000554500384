import React from 'react';
import { useEffect, useState } from 'react';
import {
	Image,
	ListGroup,
	Stack,
	OverlayTrigger,
	Popover,
} from 'react-bootstrap';
import Avatar from 'react-avatar';
import ReactTimeAgo from 'react-time-ago';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { jumpToComment } from '../../redux/slice/commentSlice';
import { Link, useNavigate } from 'react-router-dom';
import placeholderImage from '../../assets/add-image.png';
import { useInView } from "react-intersection-observer";


export default function NotificationDetail({ communityId, getKey }) {
	const [data, setData] = useState([]);
	const [hasNotifs, setHasNotifs] = useState(true);
	const [noNotifs, setNoNotifs] = useState(false);
	const [pageCounter, setPageCounter] = useState(1);

	const currentUserId = useSelector((state) => state.userReducer.userId);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { ref, inView, entry } = useInView({
    threshold: 0,
  });
	const [selectedNotification, setSelectedNotification] = useState(-1);

	const currentSlug = useSelector(
		(state) => state.communityReducer.communitySlug
	);

	const getNotificationData = () => {
		axios
			.get('/v2/getAllNotifications/' + communityId,{params:{page:pageCounter}})
			.then((response) => {
				if (
					response.data.notifications != null &&
					response.data.notifications.length > 0
				) {
					setHasNotifs((prev)=>true)
					setPageCounter((prev)=>prev+1)
					setData([...data,response.data.notifications]);
				}else{
					if(pageCounter==1){

						setNoNotifs((prev)=>true)
					}
					setHasNotifs((prev)=>false)
				}
			})
			.catch((err) => console.log(err));
	};

	const handleNotificationClick = (notification) => {
		if (notification.post_id != null && notification.post_slug != "") {
			if (notification.comment_id != null || notification.comment_id != "") {
			  dispatch(jumpToComment(notification.comment_id));
			}
			navigate("/c/" + currentSlug + "/post/" + notification.post_slug);
		  } else if (notification.type.includes("request")) {
			navigate("/invite_requests/" + currentSlug);
		  } else if(notification.lesson_id != null && notification.lesson_slug != ""){
			navigate("/course/" + currentSlug + "/" + notification.lesson_slug+"?lessonId="+notification.lesson_id);
		  } else if(notification.chat_id != null && notification.chat_id != ""){
			navigate("/chat/" + currentSlug);
		  }
	};

	const deleteNotification = () => {
		axios
			.post('/delete_notification', {
				notifyId: selectedNotification,
			})
			.then((response) => {
				getNotificationData();
				document.body.click()
			})
			.catch((err) => console.log(err));
	};
	const deleteAll = () => {
		axios
			.post('/delete_all_notifications')
			.then((response) => {
				setNoNotifs((true))
			})
			.catch((err) => console.log(err));
	};
	const popoverNotificationOptions = (
		<Popover>
			<Popover.Body className='px-3 py-2'>
				<ListGroup as='ol'>
					<ListGroup.Item
						as='button'
						className='d-flex justify-content-start border-0'
						onClick={deleteNotification}>
						Delete Notification
					</ListGroup.Item>
				</ListGroup>
			</Popover.Body>
		</Popover>
	);
	useEffect(() => {
		if (getKey == 'notificationDetail' && inView == true) {
			getNotificationData();
		}
	}, [inView,getKey])
	

	return (
		<div className='d-flex justify-content-center'>
			<Stack  className='view-leaderboard-container center-container uuki-box mb-4'>
				<Stack className="m-4" direction="horizontal">
					<h5 className='mb-0'>Notifications</h5>
					<a className="text-decoration-none ms-auto" onClick={deleteAll}>Clear All</a>
				</Stack>
				<hr></hr>
				{!noNotifs ? (
					<>
						<ListGroup as='ol'>
							{data != null && data.length > 0
								? data.map((page) =>  page.map((item) => (
										<>
											<ListGroup.Item
												as='button'
												style={{ textAlign: 'start' }}
												className=' d-flex mx-3 border-0'
												key={item.id}
												>
												<Stack direction='horizontal' gap={3} onClick={(e) => handleNotificationClick(item)}>
													{item.doneByImage !== '' &&
													item.doneByImage !== 'noImage' ? (
														<Image
															roundedCircle
															className='mobile-profile-image'
															src={item.doneByImage}
															loading='lazy'
															onError={({ currentTarget }) => {
																currentTarget.onerror = null;
																currentTarget.src = placeholderImage;
															}}></Image>
													) : (
														<Avatar
															name={item.doneByName}
															size='32'
															textSizeRatio={2.5}
															round={true}
														/>
													)}

													<div className='d-flex flex-column justify-content-start'>
														<div
															dangerouslySetInnerHTML={{
																__html: item.text,
															}}></div>

														<ReactTimeAgo
															date={Date.parse(item.notifyTime)}
															locale='en-US'
															style={{ fontSize: '13px' }}
														/>
													</div>
													</Stack>
													<OverlayTrigger
														trigger='click'
														rootClose
														placement='bottom'
														overlay={popoverNotificationOptions}>
														<i
															style={{ cursor: 'pointer' }}
															className='ms-auto fa-solid fa-ellipsis p-3'
															onClick={() =>
																setSelectedNotification(item.id)
															}></i>
													</OverlayTrigger>
												{/* </Stack> */}
											</ListGroup.Item>
											<hr></hr>
										</>
								  )))
								: ''}
						</ListGroup>
					
					{hasNotifs && <div className="p-4 d-flex justify-content-center" ref={ref}>
						{/* <Spinner animation="border" /> */}
						<span class="loader-spinner" />
					</div>}
					</>
				) : (
					<div className='p-4'>No new notification</div>
				)}
			</Stack>
			<div style={{ paddingBottom: '550px' }}></div>
		</div>
	);
}
