import { useMediaQuery } from "react-responsive";
import { useEffect, useRef } from "react";
import {
  Container,
  Col,
  Nav,
  Navbar,
  Offcanvas,
  Image,
  Stack,
  Accordion,
  ListGroup,
  ProgressBar,
} from "react-bootstrap";
import Avatar from "react-avatar";
import axios from "axios";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import CommonNavbar from "../CommonNavbar";

function NavbarLesson({
  setKey,
  community,
  member,
  sections,
  completed,
  total,
}) {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  const isBigScreen = useMediaQuery({ query: "(min-width: 1600px)" });
  let [searchParams, setSearchParams] = useSearchParams();
  const { communitySlug, topicSlug } = useParams();

  const logout = (e) => {
    e.preventDefault();
    axios.get("/logout?noRedirect=1").then((res) => navigate("/login"));
  };
  useEffect(() => {
    document.querySelectorAll(".dashboard-icon").forEach((element) => {
      if (element.parentElement.parentElement.classList.contains("active")) {
        element.setAttribute("filter", "url(#white-svg)");
      } else {
        element.setAttribute("filter", "url(#black-svg)");
      }
    });
  });
  return (
    <>
      <Navbar fixed="top" className="nav-uuki nav-home" expand={false}>
        <Navbar.Brand className="community-name" href={currentHost}>
          {community.showLogo == false || community.logo == "" ? (
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate(currentHost)}
            >
              {community.name}
            </h4>
          ) : (
            <Image
              fluid
              style={{ cursor: "pointer" }}
              onClick={() => navigate(currentHost)}
              loading="lazy"
              className="brand-logo"
              src={community.logo}
            />
          )}
        </Navbar.Brand>
        {isDesktop ? (
          <CommonNavbar />
        ) : (
          <Navbar.Toggle aria-controls="offcanvasNavbar" />
        )}
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">
              {community.name}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Stack className="h-100" gap={2}>
              <Nav variant="pills" className="flex-column text-nowrap">
                {sections.map((section) => (
                  <Accordion key={section.id} flush alwaysOpen>
                    <Accordion.Item>
                      <Accordion.Header className="section-header">
                        <Stack className="w-100 text-dark wrapword" direction="horizontal">
                          {section.title}
                        </Stack>
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        {section.lessons.map((lesson) => (
                          <Nav.Item className=" ">
                            <Nav.Link
                              style={{ fontSize: "1rem" }}
                              className="py-4 px-4 space-name text-dark d-flex align-items-center border rounded-0"
                              eventKey={"lesson-"+lesson.id}
                              onClick={() => {
                                setKey(lesson.id);
                                navigate(
                                  "/course/" +
                                    communitySlug +
                                    "/" +
                                    topicSlug +
                                    "?lessonId=" +
                                    lesson.id
                                );
                              }}
                            >
                              {lesson.completed == true ? (
                                <i
                                  className="fa-solid  fa-lg me-2 fa-circle-check"
                                  style={{ color: "green" }}
                                ></i>
                              ) : (
                                <i className="fa-regular fa-lg me-2 fa-circle"></i>
                              )}
                              <small className="wrapword">{lesson.title}</small>
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                         {section.quizzes.map((quiz) => (
                          <Nav.Item className=" ">
                            <Nav.Link
                              style={{ fontSize: "1rem" }}
                              className="py-4 px-4 space-name text-dark d-flex align-items-center border rounded-0"
                              eventKey={"quiz-"+quiz.id}
                              onClick={() => {
                                setKey(quiz.id);
                                navigate(
                                  "/course/" +
                                    communitySlug +
                                    "/" +
                                    topicSlug +
                                    "?quizId=" +
                                    quiz.id
                                );
                              }}
                            >
                              {/* {quiz.completed == true ? (
                                <i
                                  className="fa-solid  fa-lg me-2 fa-circle-check"
                                  style={{ color: "green" }}
                                ></i>
                              ) : (
                                <i className="fa-regular fa-lg me-2 fa-circle"></i>
                              )} */}
                              <small className="wrapword">{quiz.title}</small>
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </Nav>
            </Stack>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
      {isDesktop && (
        <Col
          className="position-fixed mt-5 py-4 "
          md={isBigScreen ? { span: 2, offset: 8 } : { span: 3, offset: 9 }}
        >
          <div
            // style={{ height: "70vh" }}		
            className=" ms-4 ms-xxl-0 mb-5 me-2 me-xxl-5 overflow-auto  nav-scroll d-flex flex-column align-items-end"
          >
            <div className="right-nav-block  uuki-box px-4 py-3 w-100 mb-3">
              <small>Completed {Math.round((completed / total) * 100)}%</small>
              <ProgressBar
                style={{ height: "0.2rem" }}
                className="mt-3"
                now={(completed / total) * 100}
              />
            </div>
            <Nav
              variant="pills"
              className=" scroll-nav uuki-box lesson-list w-100 flex-column flex-nowrap text-nowrap  "
            >
              {sections.map((section) => (
                <Accordion key={section.id} flush alwaysOpen>
                  <Accordion.Item>
                    <Accordion.Header className="section-header">
                      <Stack className="w-100 text-dark wrapword" direction="horizontal">
                        {section.title}
                      </Stack>
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                      {section.lessons.map((lesson) => (
                        <Nav.Item className=" ">
                          <Nav.Link
                            style={{ fontSize: "1rem" }}
                            className="py-4 px-4 space-name text-dark d-flex align-items-center border rounded-0"
                            eventKey={"lesson-"+lesson.id}
                            onClick={() =>
                              navigate(
                                "/course/" +
                                  communitySlug +
                                  "/" +
                                  topicSlug +
                                  "?lessonId=" +
                                  lesson.id
                              )
                            }
                          >
                            {lesson.completed == true ? (
                              <i
                                className="fa-solid  fa-lg me-2 fa-circle-check"
                                style={{ color: "green" }}
                              ></i>
                            ) : (
                              <i className="fa-regular fa-lg me-2 fa-circle"></i>
                            )}
                            <small className="wrapword">{lesson.title}</small>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                       {section.quizzes.map((quiz) => (
                        <Nav.Item className=" ">
                          <Nav.Link
                            style={{ fontSize: "1rem" }}
                            className="py-4 px-4 space-name text-dark d-flex align-items-center border rounded-0"
                            eventKey={"quiz-"+quiz.id}
                            onClick={() =>
                              navigate(
                                "/course/" +
                                  communitySlug +
                                  "/" +
                                  topicSlug +
                                  "?quizId=" +
                                  quiz.id
                              )
                            }
                          >
                            {quiz.submitted == true ? (
                              <i
                                className="fa-solid  fa-lg me-2 fa-circle-check"
                                style={{ color: "green" }}
                              ></i>
                            ) : (
                              <i className="fa-regular fa-lg me-2 fa-circle"></i>
                            )}
                            <small className="wrapword">{quiz.title}</small>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </Nav>
          </div>
        </Col>
      )}
    </>
  );
}

export default NavbarLesson;
