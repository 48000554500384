import React from "react";
import { Image, Form, Stack, Button, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import "../../../styles/members.css";
import { useState, useEffect } from "react";
import axios from "axios";
import MembersPageHeader from "./MembersPageHeader";
import MemberDetailCard from "./MemberDetailCard";
import { useInView } from "react-intersection-observer";
import { reloadMembers } from "../../../redux/slice/membersSlice";

export default function MembersHome({
  getKey,
  count,
  community_id,
  enable_chat,
  allow_ranking
}) {
  const [membersData, setMembersData] = useState([]);
  const [privateSpaceData, setPrivateSpaceData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [pageCounter, setPageCounter] = useState(1);
  const [showProgress, setShowProgress] = useState(true);
  const searchName = useSelector((state) => state.membersReducer.searchName);
  const searchBio = useSelector((state) => state.membersReducer.searchBio);
  const searchLoc = useSelector((state) => state.membersReducer.searchLoc);
  const searchSpace = useSelector((state) => state.membersReducer.searchSpace);
  const searchTag = useSelector((state) => state.membersReducer.searchTag);
  const { communitySlug } = useParams();

  const dispatch = useDispatch();

  const searchAdminMod = useSelector(
    (state) => state.membersReducer.searchAdminMod
  );
  const isToReloadMembers = useSelector(
    (state) => state.membersReducer.reloadMembers
  );

  const currentCommunityId = useSelector(
    (state) => state.communityReducer.communityId
  );

  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  useEffect(() => {
    setShowProgress(true);
    if (getKey == "members" && inView == true) {
      axios
        .get("/v2/loadmembers", {
          params: {
            commSlug: communitySlug,
            page: pageCounter,
            searchName: searchName,
            searchBio: searchBio,
            searchLoc: searchLoc,
            searchSpace: searchSpace,
            searchTag: searchTag,
            searchAdminMod: searchAdminMod,
          },
        })
        .then(function (response) {
          setMembersData((prev) => [...prev, ...response.data.members]);
          setPageCounter(pageCounter + 1);
          setShowProgress(false);
          setPrivateSpaceData(response.data.privateSpaces);
          setTagData(response.data.tags);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }, [getKey, inView]);

  // useEffect(() => {
  //   if (inView === true) {
  //     console.log(inView);
  //     setShowProgress(true);
  //     axios
  //       .get("/v2/loadmembers", {
  //         params: {
  //           commSlug: communitySlug,
  //           page: pageCounter,
  //           searchName: searchName,
  //           searchBio: searchBio,
  //           searchLoc: searchLoc,
  //           searchSpace: searchSpace,
  //           searchTag: searchTag,
  //           searchAdminMod: searchAdminMod,
  //         },
  //       })
  //       .then(function (response) {
  //         console.log(response.data);

  //         if (response.data != null && response.data.members !== []) {
  //           const newMembers = membersData.concat(response.data.members);
  //           setMembersData(newMembers);
  //           console.log(newMembers);
  //           setPageCounter((prev) => prev + 1);
  //         }

  //         setShowProgress(false);
  //       })
  //       .catch(function (error) {
  //         // handle error
  //         console.log(error);
  //       });
  //   }
  // }, [inView, membersData]);

  if (isToReloadMembers) {
    console.log("inside isToReload");
    axios
      .get("/v2/loadmembers", {
        params: {
          commSlug: communitySlug,
          page: 1,
          searchName: searchName,
          searchBio: searchBio,
          searchLoc: searchLoc,
          searchSpace: searchSpace,
          searchTag: searchTag,
          searchAdminMod: searchAdminMod,
        },
      })
      .then(function (response) {
       
        dispatch(reloadMembers(false));
        if (response.data != null && response.data.members !== []) {
          setMembersData(response.data.members);
        } else {
          setMembersData([]);
        }

        setShowProgress(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  return (
    <div className="d-flex justify-content-center">
      <Stack className="center-container" >
        <MembersPageHeader
          privateSpaces={privateSpaceData}
          tags={tagData}
          count={count}
        />
        <Stack gap={3}>
          {membersData != null && membersData.length > 0
            ? membersData.map((member) => (
                <MemberDetailCard
                  data={member}
                  key={member.id}
                  enable_chat={enable_chat}
                  allow_ranking={allow_ranking}
                />
              ))
            : ""}
        </Stack>
        <div ref={ref}>
          {showProgress ? (
            <div className="p-4 d-flex justify-content-center">
              <Spinner animation="border" />
            </div>
          ) : (
            ""
          )}
        </div>
        <div style={{marginBottom:'400px'}}/>
      </Stack>
    </div>
  );
}
