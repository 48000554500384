import { Stack,Form,Button,OverlayTrigger,Tooltip,InputGroup,Modal } from "react-bootstrap";
import {useState,useEffect} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce';
import { useSelector } from 'react-redux'


function IntegrationSettings({settings}) {
    const [showLink, setShowLink] = useState(false);
    const [settingsSet, setSettingsSet] = useState(false);
    const [discord, setDiscord] = useState('');
    const [telegram, setTelegram] = useState({id:0,title:''});

    const currentSlug = useSelector(
        (state) => state.communityReducer.communitySlug
    )
    const currentId = useSelector(
        (state) => state.communityReducer.communityId
    )
    useEffect(() => {
        if(Object.entries(settings).length > 0) {
            setSettingsSet((prev)=>true);
            setDiscord(settings.discordHook)
        }
    },[settings])
    const changeDiscord = useDebouncedCallback(
        (value) => {
            
            axios.post('/change_discord_webhook',{id: currentId,url:value})
                .then((res) => {
                    toast.success('Discord Webhook Updated')
                    setDiscord({id:res.data.id,title:res.data.title})
                
                }).catch(function (error) {
                    toast.error('Update Failed')
                });
        },
        1000
    );
    const getGroup = () => {
            
        axios.post('/get_telegram_group',{url:currentSlug})
            .then((res) => {
                toast.success('Group Recieved')
                setTelegram({id:res.data.id,title:res.data.title})				
            
            }).catch(function (error) {
                toast.error('Group Not Recieved')
            });
    }
    const linkTelegram = () => {
            
        axios.post('/link_telegram_group',{commId:currentId,chatId:telegram.id})
            .then((res) => {
                toast.success('Group Linked')
                setShowLink(false)
            
            }).catch(function (error) {
                toast.error('Group Not Linked')
            });
    }
    const twitterLogin = () => {
        window.location.href = "/twitter_login?commId="+currentId;
    }
    const zoomLogin= () => {
        window.location.href = "/zoom_login?commId="+currentId;
    }
    return (
        <>
            {settingsSet && 
            <Stack gap={4}>
                <Form.Group controlId="discord">
                    <Form.Label>Discord Webhook URL 
                        <OverlayTrigger
                            placement='right'
                            rootClose
                            overlay={
                                <Tooltip >
                                    Create a Webhook in your Discord Server Settings
                                </Tooltip>
                            }
                            >
                            <i className="ms-2 fa-solid fa-circle-info"></i>
                        </OverlayTrigger>
                    </Form.Label>
                    <Form.Control defaultValue={discord} onChange={(event)=>changeDiscord(event.target.value)}/>
                </Form.Group>
                <Form.Group controlId="telegram">
                    <Form.Label>Connect Telegram
                        <OverlayTrigger
                        rootClose
                            placement='right'
                            overlay={
                                <Tooltip >
                                    Add the bot and promote it to admin
                                </Tooltip>
                            }
                            >
                            <i className="ms-2 fa-solid fa-circle-info"></i>
                        </OverlayTrigger>
                    </Form.Label>
                    <InputGroup className="mb-3 settings-input-group">
                        <Form.Control disabled defaultValue="https://t.me/uuki_community_bot"/>
                        <Button variant="secondary" onClick={(event) => {navigator.clipboard.writeText(event.currentTarget.previousSibling.value)}}>
                            <i className="fa-regular fa-clipboard"></i>
                        </Button>
                    </InputGroup>
                    <Stack gap={3} direction="horizontal">
                        <Button className="uuki-btn uuki-community-btn" onClick={()=>setShowLink(true)}>{(settings.telegramId!=0)?"Connected":"Connect Telegram"}</Button>
                    </Stack>
                </Form.Group>
                <Form.Group controlId="twitter">
                    <Form.Label>Connect Twitter</Form.Label>
                    <Stack gap={3} direction="horizontal">
                        <Button className="uuki-btn uuki-community-btn" onClick={()=>twitterLogin()}>{(settings.twitterToken!="")?"Connected":"Connect Twitter"}</Button>
                    </Stack>
                </Form.Group>
                <Form.Group controlId="zoom">
                    <Form.Label>Connect Zoom</Form.Label>
                    <Stack gap={3} direction="horizontal">
                        <Button className="uuki-btn uuki-community-btn" onClick={()=>zoomLogin()}>{(settings.zoomToken!="")?"Connected":"Connect Zoom"}</Button>
                    </Stack>
                </Form.Group>
            </Stack>
            }
            <Modal show={showLink} onHide={() => setShowLink(false)} centered>
                <Modal.Header closeVariant="white" closeButton>
                    <Modal.Title>Connect Telegram</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack gap={4}>
                        <Form.Group controlId="lvl">
                            <Form.Label>Message in Group Chat</Form.Label>
                            <InputGroup className="settings-input-group">
                                <Form.Control disabled defaultValue={"@uuki_community_bot link"+currentSlug}/>
                                <Button variant="secondary" onClick={(event) => {navigator.clipboard.writeText(event.currentTarget.previousSibling.value)}}>
                                    <i className="fa-regular fa-clipboard"></i>
                                </Button>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="title">
                            <Form.Label>Confirm Group Chat Name</Form.Label>
                            <InputGroup className="settings-input-group">
                                <Button variant="outline-secondary" onClick={getGroup}>
                                    Get Group Name
                                </Button>
                                <Form.Control disabled value={telegram.title}/>
                                
                            </InputGroup>
                        </Form.Group>
                    </Stack>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-center">
                    <Button className="uuki-btn uuki-community-btn\" onClick={linkTelegram}>Confirm</Button>
                </Modal.Footer>
            </Modal> 
        </>
    )
}

export default IntegrationSettings
