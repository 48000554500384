import { useState, useEffect, useRef } from "react";
import LeaderboardBlock from "./rightNav/LeaderboardBlock";
import LinksBlock from "./rightNav/Links";
import TrendingPosts from "./rightNav/TrendingPosts";
import { Stack, Col } from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import EventInfo from "./rightNav/EventInfo";
import { useSelector } from "react-redux";

export default function HomeRightNav({
  navData,
  widget,
  linksLocation,
  event,
  eventSet, 
  setKey,
  affiliate,
  allow_ranking,
  show_trending  
}) {
  const [widgetSet, setWidgetSet] = useState(false);
  const isLoggedIn = useSelector((state) => {
    return state.userReducer.isUserLoggedIn;
  });
  const { postSlug } = useParams();
  useEffect(() => {
    setWidgetSet((prev) => true);
  }, [widget]);
  var setInnerHTML = function(elm, html) {
    elm.innerHTML = html;
    Array.from(elm.querySelectorAll("script")).forEach( oldScript => {
      const newScript = document.createElement("script");
      Array.from(oldScript.attributes)
        .forEach( attr => newScript.setAttribute(attr.name, attr.value) );
      newScript.appendChild(document.createTextNode(oldScript.innerHTML));
      oldScript.parentNode.replaceChild(newScript, oldScript);
    });
  }
  useEffect(() => {
    if (navData.widget_script != null) {
      var element = document.getElementById("custom-widget-script");
      if (element != null) {
        setInnerHTML(element, navData.widget_script);
      } else {
       //do nothing
      }
    }
  });
  return (
    <Col className="my-2 nav-scroll home-right-nav overflow-scroll vh-100 d-flex justify-content-end g-0">
      <div className="mb-5 pb-5 fixed-nav me-2">
        <Stack gap={3}>
         
          {navData.widget_script !== "" ? (
            <div
              id="custom-widget-script"
              style={{ overflow: "auto" }}
              className="right-nav-block uuki-box p-3"
            ></div>
          ) : (
            ""
          )}

          {eventSet && (
            <EventInfo
              eventSet={eventSet}
              title={event != null && event.title}
              event={event != null && event.event_info}
            />
          )}
          {(isLoggedIn && allow_ranking) && (
            <LeaderboardBlock
              karmaData={navData ? navData.karma : null}
              setKey={setKey}
            ></LeaderboardBlock>
          )}
          {widgetSet && linksLocation == 1 && (
            <LinksBlock linkData={navData ? navData.community_links : null} />
          )}
          {show_trending && (		  
          <TrendingPosts
            trendingPostData={navData ? navData.trending_posts : null}
          ></TrendingPosts>
          )}		  
          {widgetSet && widget != null && (
            <Stack
              gap={3}
              className="right-nav-block uuki-box p-3 "
              dangerouslySetInnerHTML={{ __html: widget }}
            ></Stack>
          )}
          <div style={{ height: "200px" }}></div>
        </Stack>
      </div>
    </Col>
  );
}
