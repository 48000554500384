import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import store from "./states/store";
import { MoralisProvider } from "react-moralis";
// import {store,persistor} from './states/store'
// import { PersistGate } from 'redux-persist/integration/react'
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en.json";
import swDev from "./swDev";

TimeAgo.addDefaultLocale(en);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <MoralisProvider
        serverUrl="https://jqndt2luvdux.usemoralis.com:2053/server"
        appId="JMiKl0eBCEA4zrg1Lq6oLH7IZM4K7QfkFg9cG6EU"
      >
        <App />
      </MoralisProvider>
      {/* </PersistGate> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);


swDev();
