import { Popover, ListGroup, Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDebouncedCallback } from "use-debounce";

function PostOptions({ data, setKey, setEditPost, setShowDelete, setPostsUpdated, setShowEditPostSettings  }) {
  const [isPinned, setIsPinned] = useState(0);
  const [commenting, setCommenting] = useState(true);
  const [followed, setFollowed] = useState(false);
  const currentLanguages = useSelector(
    (state) => state.languagesReducer.languages
  );
  const currentCommunityLanguage = useSelector(
    (state) => state.communityReducer.communityLanguage
  );
  useEffect(() => {
    if (Object.entries(data).length > 0) {
      setIsPinned(data.pinnedTo);
      setCommenting(data.commenting);
      setFollowed(data.followed);
    }
  }, [data]);

  const pinPost = () => {
    let pinnedTo;
    if (isPinned == 0) {
      if (currentTopic == 0) {
        pinnedTo = 2;
      } else {
        pinnedTo = 1;
      }
    } else {
      pinnedTo = 0;
    }
    axios
      .post("/pin_post", { pin: pinnedTo, postId: data.id })
      .then((res) => {
        setIsPinned((prev) => pinnedTo);
        pinnedTo != 0
          ? toast.success("Post Pinned")
          : toast.success("Post Unpinned");
        document.body.click();
      })
      .catch(function (error) {});
  };
  const toggleCommenting = () => {
    axios
      .post("/toggle_commenting", { toggle: !commenting, postId: data.id })
      .then((res) => {
        !commenting
          ? toast.success("Commenting Turned On")
          : toast.success("Commenting Turned Off");
          setCommenting((prev) => !prev);
        document.body.click();
        
      })
      .catch(function (error) {});
  };
  const notifyPost = () => {
    axios
      .post("/notify_post", { postId: data.id })
      .then((res) => {
        toast.success("Post Notification Sent to Members");
        document.body.click();
      })
      .catch(function (error) {});
  };
  const reportPost = () => {
    axios
      .post("/report_post", { postId: data.id })
      .then((res) => {
        toast.success("Post Reported");
        document.body.click();
      })
      .catch(function (error) {});
  };
  const followPost = () => {
    axios
      .post("/follow_post", { postId: data.id, value: !followed })
      .then((res) => {
        !followed
          ? toast.success("Post Followed")
          : toast.success("Post Unfollowed");

        setFollowed((prev) => !prev);
        document.body.click();
      })
      .catch(function (error) {});
  };

  const currentTopic = useSelector((state) => state.communityReducer.topicSlug);
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );

  return (
    <>
      <ListGroup as="ol">
        {data.viewer_role != -1 && (
          <>
            {data.viewer_role != 0 && (
              <>
                {(data.viewer_role == 2 || data.viewer_role == 3) && (
                  <>
                    <ListGroup.Item
                      as="button"
                      className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                      onClick={pinPost}
                    >
                      {isPinned
                        ? currentLanguages[54][currentCommunityLanguage]
                        : currentLanguages[53][currentCommunityLanguage]}
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="button"
                      className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                      onClick={toggleCommenting}
                    >
                      {commenting
                        ? currentLanguages[56][currentCommunityLanguage]
                        : currentLanguages[55][currentCommunityLanguage]}
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="button"
                      className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                      onClick={notifyPost}
                    >
                      {currentLanguages[57][currentCommunityLanguage]}
                    </ListGroup.Item>
                  </>
                )}
                <ListGroup.Item
                  as="button"
                  className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                  onClick={() => {
                    document.body.click();
                    setShowDelete({ id: data.id, show: true });
                  }}
                >
                  {currentLanguages[41][currentCommunityLanguage]}
                </ListGroup.Item>
                <ListGroup.Item
                  as="button"
                  className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                  onClick={() => {
                    setEditPost(data);
                    setKey("createPost");
                    document.body.click();
                  }}
                >
                  {currentLanguages[32][currentCommunityLanguage]}
                </ListGroup.Item>
                <ListGroup.Item
                  as="button"
                  className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
                  onClick={() => {
                    document.body.click();
                    setShowEditPostSettings({ id: data.id, show: true });
                  }}
                >
                  Edit Post Settings
                </ListGroup.Item>				
              </>
            )}
            <ListGroup.Item
              as="button"
              className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
              onClick={reportPost}
            >
              {currentLanguages[58][currentCommunityLanguage]}
            </ListGroup.Item>
            <ListGroup.Item
              as="button"
              className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
              onClick={() => {
                navigator.clipboard.writeText(
                  '<iframe style="border: 0; box-shadow: none; width: 100%; height: 100vh;" src="' +
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    "/c/" +
                    currentSlug +
                    "/post/" +
                    data.slug +
                    '?iframe=true"></iframe>'
                );
                toast.success("Post Embed Copied");
                document.body.click();
              }}
            >
              {currentLanguages[59][currentCommunityLanguage]}
            </ListGroup.Item>
            <ListGroup.Item
              as="button"
              className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
              onClick={followPost}
            >
              {followed
                ? currentLanguages[60][currentCommunityLanguage]
                : currentLanguages[61][currentCommunityLanguage]}
            </ListGroup.Item>
          </>
        )}

        {/* <ListGroup.Item
          as="button"
          className="d-flex justify-content-start align-items-center py-2 px-1 border-0"
          onClick={() =>
            navigator.clipboard.writeText(
              '<iframe style="border: 0; box-shadow: none; width: 100%; height: 100vh;" src="' +
                window.location.protocol +
                "//" +
                window.location.host +
                "/c/" +
                currentSlug +
                "/post/" +
                data.slug +
                '?iframe=true"></iframe>'
            )
          }
        >
          {currentLanguages[59][currentCommunityLanguage]}
        </ListGroup.Item> */}
      </ListGroup>
    </>
  );
}

export default PostOptions;
