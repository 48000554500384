import React from 'react';
import { useEffect, useState } from 'react';
import {
	Image,
	ListGroup,
	Stack,
	OverlayTrigger,
	Popover,
	Button,
} from 'react-bootstrap';
import Avatar from 'react-avatar';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function InviteRequest({ slug, getKey }) {
	const [requestData, setRequestData] = useState([]);
	const [selectedRequest, setSelectedRequest] = useState(null);
	const navigate = useNavigate();

	const currentSlug = useSelector(
		(state) => state.communityReducer.communitySlug
	);

	const communityId = useSelector(
		(state) => state.communityReducer.communityId
	);
	useEffect(() => {
		if (getKey === 'inviteRequest') {
			console.log(slug);
			getData();
		}
	}, [getKey]);

	const getData = () => {
		axios
			.get('/v2/member_requests/' + currentSlug)
			.then(function (response) {
				setRequestData(response.data.requests);
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const handleAddMember = () => {
		axios
			.post('/add_member', { id: selectedRequest.id })
			.then((response) => {
				toast.success('Member Added Successfully');
				getData();
				document.body.click()
			})
			.catch((err) => {
				toast.error('Something went wrong');
				console.error(err);
			});
	};

	const handleRejectMember = () => {
		axios
			.post('/reject_member', { id: selectedRequest.id, way: 0 })
			.then((response) => {
				toast.success('Request Rejected');
				getData();
				document.body.click()
			})
			.catch((err) => {
				toast.error('Something went wrong');
				console.error(err);
			});
	};

	const handleDeleteRequest = () => {
		axios
			.post('/reject_member', { id: selectedRequest.id, way: 1 })
			.then((response) => {
				toast.success('Request Rejected');
				getData();
				document.body.click()
			})
			.catch((err) => {
				toast.error('Something went wrong');
				console.error(err);
			});
	};

	const handleAcceptRequest = () => {
		axios
			.post('/accept_space_join', {
				userId: selectedRequest.user.user_id,
				topicId: selectedRequest.topic_id,
				removeRequest: true,
			})
			.then((response) => {
				toast.success('Request Accepted');
				getData();
				document.body.click()
			})
			.catch((err) => {
				toast.error('Something went wrong');
				console.error(err);
			});
	};

	const handleRejectRequest = () => {
		axios
			.post('/reject_space_join', {
				userId: selectedRequest.user.user_id,
				topicId: selectedRequest.topic_id,
				removeRequest: true,
				way: 0,
			})
			.then((response) => {
				toast.success('Request Rejected');
				getData();
				document.body.click()
			})
			.catch((err) => {
				toast.error('Something went wrong');
				console.error(err);
			});
	};

	const handleDeleteSpaceRequest = () => {
		axios
			.post('/reject_space_join', {
				userId: selectedRequest.user.user_id,
				topicId: selectedRequest.topic_id,
				removeRequest: true,
				way: 1,
			})
			.then((response) => {
				toast.success('Request Deleted');
				getData();
				document.body.click()
			})
			.catch((err) => {
				toast.error('Something went wrong');
				console.error(err);
			});
	};

	const handleApproveAllRequest = () => {
		axios
			.post('/approve_all', {
				id: communityId,
			})
			.then((response) => {
				toast.success('All requests accepted');
				getData();
			})
			.catch((err) => {
				toast.error('Something went wrong');
				console.error(err);
			});
	};

	const popoverRequestOptions = (
		<Popover>
			<Popover.Body className='px-3 py-2'>
				<ListGroup as='ol'>
					<ListGroup.Item
						as='button'
						className='d-flex justify-content-start border-0'
						onClick={handleAddMember}>
						Add Member
					</ListGroup.Item>
					<ListGroup.Item
						as='button'
						className='d-flex justify-content-start border-0'
						onClick={handleRejectMember}>
						Reject Member
					</ListGroup.Item>
					<ListGroup.Item
						as='button'
						className='d-flex justify-content-start border-0'
						onClick={handleDeleteRequest}>
						Delete Request
					</ListGroup.Item>
				</ListGroup>
			</Popover.Body>
		</Popover>
	);
	const popoverSpaceRequestOptions = (
		<Popover>
			<Popover.Body className='px-3 py-2'>
				<ListGroup as='ol'>
					<ListGroup.Item
						as='button'
						className='d-flex justify-content-start border-0'
						onClick={handleAcceptRequest}>
						Accept Request
					</ListGroup.Item>
					<ListGroup.Item
						as='button'
						className='d-flex justify-content-start border-0'
						onClick={handleRejectRequest}>
						Reject Request
					</ListGroup.Item>
					<ListGroup.Item
						as='button'
						className='d-flex justify-content-start border-0'
						onClick={handleDeleteSpaceRequest}>
						Delete Request
					</ListGroup.Item>
				</ListGroup>
			</Popover.Body>
		</Popover>
	);
	return (
		<>
			<div className=' center-container mb-4'>
				<div className='d-flex justify-content-between p-4'>
					<div>
						<h4>Requests</h4>
					</div>
					{requestData != null && requestData.length > 0 ? (
						<div>
							<Button
								onClick={handleApproveAllRequest}
								className='uuki-btn uuki-community-btn'>
								Approve All
							</Button>
						</div>
					) : (
						''
					)}
				</div>

				{(requestData != null) & (requestData.length > 0) ? (
					requestData.map((item) => (
						<div className='uuki-box post p-4 mb-3'>
							<Stack gap={3} direction='horizontal'>
								{item.user.image_url != '' &&
								item.user.image_url != 'noImage' ? (
									<Image
										src={item.user.image_url}
										className={'post-author-image'}
										roundedCircle
										style={{ cursor: 'pointer' }}
										onClick={() =>
											navigate(
												'/c/' + slug + '/member?memberId=' + item.user.user_id
											)
										}></Image>
								) : (
									<Avatar
										name={item.user.username}
										style={{ cursor: 'pointer' }}
										size={'48'}
										textSizeRatio={2.5}
										round={true}
										onClick={() =>
											navigate(
												'/c/' + slug + '/member?memberId=' + item.user.user_id
											)
										}
									/>
								)}
								<div>
									<div>
										<span
											className='post-author'
											onClick={() =>
												navigate(
													'/c/' + slug + '/member?memberId=' + item.user.user_id
												)
											}
											style={{ cursor: 'pointer' }}>
											{item.user.username}
										</span>
									</div>
									<div>{item.user.email}</div>
									{item.community_id == null ? (
										<div>Requests to join {item.topic_name}</div>
									) : (
										''
									)}
								</div>

								{item.community_id != null ? (
									<OverlayTrigger
										trigger='click'
										rootClose
										placement='bottom'
										overlay={popoverRequestOptions}>
										<i
											style={{ cursor: 'pointer' }}
											className='ms-auto fa-solid fa-ellipsis p-3'
											onClick={(e) => setSelectedRequest(item)}></i>
									</OverlayTrigger>
								) : (
									<OverlayTrigger
										trigger='click'
										rootClose
										placement='bottom'
										overlay={popoverSpaceRequestOptions}>
										<i
											style={{ cursor: 'pointer' }}
											className='ms-auto fa-solid fa-ellipsis p-3'
											onClick={(e) => setSelectedRequest(item)}></i>
									</OverlayTrigger>
								)}
							</Stack>
						</div>
					))
				) : (
					<>
						<h5 className='p-4'>No Pending Requests</h5>
					</>
				)}
			</div>
		</>
	);
}
