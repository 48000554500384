import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Stack } from "react-bootstrap";
import "../../styles/moderatordashboard.css";

export default function ModerationLogs({ slug }) {
  const [moderationData, setModerationData] = useState(null);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get("/v2/moderate/" + slug, { params: { type: 5 } })
      .then((response) => {
        console.log(response);
        setModerationData(response.data.moderationLogs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Stack gap={0} direction="horizontal" className="flex-wrap">
        {moderationData != null && moderationData.length > 0
          ? moderationData.map((item) => (
              <div className="mt-3 me-3 p-4 d-flex ban-word-container justify-content-around align-items-center">
                {item.type} by {item.user.name}
              </div>
            ))
          : "No Moderation logs available"}
      </Stack>
    </>
  );
}
