import { Stack, Button, Form,Modal,Spinner } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import EditorJS from "../../../EditorComponents";
import { default as edjsParser } from "editorjs-parser";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

function LessonEdit({ adminCodes, setKey, lessonId }) {
  const [title, setTitle] = useState("");
  const [embed, setEmbed] = useState("");
  const [content, setContent] = useState("");
  const [lessonSet, setLessonSet] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false)
  var parser = new edjsParser(undefined);
  const editorCore = useRef(null);
  const [titleInvalid, setTitleInvalid] = useState(false);
  const [lessonKey, setLessonKey] = useState(0);
  const currentCommunityId = useSelector(
    (state) => state.communityReducer.communityId
  );
  const currentTopicId = useSelector((state) => state.communityReducer.topicId);
  useEffect(() => {
    if (lessonId.id != 0) {
      axios
        .post("/v2/get_lesson_data", {
          lessonId: lessonId.id,
        })
        .then((res) => {
          setTitle(res.data.title);
          setEmbed(res.data.embed);
          if (res.data.content != "") {
            let getDate = Math.floor(new Date().getTime() / 1000);
            var json_content;
            try {
              json_content = JSON.parse(res.data.content);
            } catch (err) {
              json_content = JSON.parse(res.data.content.replace(/\'/g, '"'));
            }
            setContent({
              time: getDate,
              blocks: json_content,
            });
            setLessonSet(true);
          }
        })
        .catch(function (error) {
          console.log("Lesson Not Found");
        });
    } else {
      setTitle("");
      setEmbed("");
      setLessonKey(Math.floor(Math.random() * (1000000)));
    }
  }, [lessonId]);

  const uploadImage = (file) => {
    return new Promise(function (resolve, reject) {
      axios
        .get("/v2/get_community_image_url", {
          params: {
            topicId: currentTopicId,
            commId: currentCommunityId,
            filename: file.name,
          },
        })
        .then((res) => {
          res = res.data;
          let url = res.url;
          let fields = res.fields;
          let formData = new FormData();
          for (let key in fields) {
            formData.append(key, fields[key]);
          }
          formData.append("file", file);
          const xhr = new XMLHttpRequest();
          xhr.addEventListener("loadend", uploadComplete, false);
          function uploadComplete(event) {
            resolve("https://communitydata.uuki.live/" + fields["key"]);
          }
          xhr.onerror = reject;
          xhr.open("POST", url, true);
          xhr.send(formData);
          toast.success("Image Uploaded");
        })
        .catch(function (error) {
          toast.error("Image Upload Failed");
        });
    });
  };
  const uploadImageByUrl = (url) => {
    return new Promise(function (resolve, reject) {
      resolve(url);
    });
  };
  const customParsers = {
    linkTool: function (data, config) {
      // return `<a href="${data.link}" ></a>`;
      return `<div class="card post-card" >
                            <a href="${data.link}">
                            <img src="${data.meta.image.url}" class="card-img-top" alt="...">
                            <div class="card-body">
                                <h5 class="card-title text-dark">${data.meta.title}</h5>
                                <p class="card-text text-dark">${data.meta.description}</p>
                            </div>
                            </a>
                        </div>`;
    },
    attaches: function (data, config) {
      var filesize = parseFloat(parseInt(data.file.size) / 1024).toFixed(1);
      return `<div class="cdx-attaches cdx-attaches--with-file">
                                    <div class="cdx-attaches__file-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40">
                                    <g fill="#A8ACB8" fill-rule="evenodd">
                                    <path fill-rule="nonzero" d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"></path>
                                    <path d="M7 22h18v2H7zm0 4h18v2H7zm0 4h18v2H7z"></path>
                                    </g></svg></div>
                                    <div class="cdx-attaches__file-info">
                                    <div class="cdx-attaches__title" contenteditable="false">${data.title}</div>
                                    <div class="cdx-attaches__size" data-size="KiB">${filesize}</div></div>
                                    <a class="cdx-attaches__download-button" href="${data.file.url}" target="_blank" rel="nofollow noindex noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="17pt" height="17pt" viewBox="0 0 17 17"><path d="M9.457 8.945V2.848A.959.959 0 0 0 8.5 1.89a.959.959 0 0 0-.957.957v6.097L4.488 5.891a.952.952 0 0 0-1.351 0 .952.952 0 0 0 0 1.351l4.687 4.688a.955.955 0 0 0 1.352 0l4.687-4.688a.952.952 0 0 0 0-1.351.952.952 0 0 0-1.351 0zM3.59 14.937h9.82a.953.953 0 0 0 .953-.957.952.952 0 0 0-.953-.953H3.59a.952.952 0 0 0-.953.953c0 .532.425.957.953.957zm0 0" fill-rule="evenodd"></path></svg></a></div>`;
    },
  };
  parser = new edjsParser(undefined, customParsers);

  const addLesson = async (draftType) => {
    const savedData = await editorCore.current.save();
    const markup = parser.parse(savedData);

    if (!title) {
      setTitleInvalid(true);
    } else {
      setTitleInvalid(false);
    }

    if (currentTopicId != 0 && title) {
      axios
        .post("/v2/add_lesson", {
          sectionId: lessonId.sectionId,
          title: title,
          desc: markup,
          embed: embed,
          jsonData: savedData.blocks,
          draftType: draftType,
        })
        .then((res) => {
          toast.success("Lesson Saved");
          setLessonSet(false);
          window.location.reload()
        })
        .catch(function (error) {
          toast.error("Not Posted");
        });
    } else {
        if(!title) {
            toast.error("Lesson title cannot be empty");
        }		
        console.log("Info", currentTopicId, title, markup);		
	}
  };

  const editLesson = async (draftType) => {
    const savedData = await editorCore.current.save();
    const markup = parser.parse(savedData);

    if (!title) {
      setTitleInvalid(true);
    } else {
      setTitleInvalid(false);
    }

    if (currentTopicId != 0 && title) {
      axios
        .post("/v2/edit_lesson", {
          lessonId: lessonId.id,
          title: title,
          desc: markup,
          embed: embed,
          jsonData: savedData.blocks,
          draftType: draftType,
        })
        .then((res) => {
          toast.success("Lesson Saved");
          setLessonSet(false);
          window.location.reload()
        })
        .catch(function (error) {
          toast.error("Not Posted");
        });
    } else {
        if(!title) {
            toast.error("Lesson title cannot be empty");
        }
        console.log("Info", currentTopicId, title, markup);		
	}
  };
  
  const deleteLesson = async (draftType) => {
      axios
        .post("/v2/delete_lesson", {
          lessonId: lessonId.id,
        })
        .then((res) => {
          toast.success("Lesson Deleted");
          setLessonSet(false);
          window.location.reload()
        })
        .catch(function (error) {
          toast.error("Failed to delete");
        });
  };  

  const handleGenerateContent =()=>{
    setShowLoadingPopup(true)
    setLessonSet(false)

    axios
        .post("/v2/generate_lesson_content", {
          lessonId: lessonId.id,
          commId:currentCommunityId 
        })
        .then((res) => {
         console.log(res.data)
         if("output" in res.data && res.data.output!=null && res.data.output!=""){
          let getDate = Math.floor(new Date().getTime() / 1000);
          
          setContent({
            time: getDate,
            blocks: [
              {
                type: 'paragraph',
                data: {
                  text: res.data.output
                }
              }
            ],
          });
          setLessonSet(true);
          setShowLoadingPopup(false)
         }else if("error" in res.data){
          setShowLoadingPopup(false)
          toast.error(res.data.error)
        }else{
          setShowLoadingPopup(false)
          toast.error("Something went wrong. Please try again")
        }
         
        })
        .catch(function (error) {
          setShowLoadingPopup(false)
          toast.error("Something went wrong.Please try again.");
        });
  }

  return (
    <Stack gap={3}>
      <h5>
        <Button
          onClick={() => {
            setLessonSet(false);
            setKey("curriculum");
          }}
          className="text-dark border-0 bg-transparent"
        >
          <i className="fa-solid fa-arrow-left"></i>
        </Button>
        {lessonId.id == 0 ? "Add" : "Edit"} Lesson
      </h5>
      <Form.Group controlId="title">
        <Form.Label>Enter Title</Form.Label>
        <Form.Control
          isInvalid={titleInvalid}
          defaultValue={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          Please enter valid title.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="embed">
        <Form.Label>Enter Video Embed</Form.Label>
        <Form.Control
          defaultValue={embed}
          as="textarea"
          onChange={(e) => setEmbed(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="content">
        <Form.Label>Enter Course Content</Form.Label>
        <EditorJS
          editorCore={editorCore}
          uploadImage={uploadImage}
          uploadImageByUrl={uploadImageByUrl}
          postSet={lessonSet}
          key={lessonId.id !== 0 ? lessonId.id : lessonKey}
          content={content}
          adminCodes={adminCodes}
          selectedTopic={currentTopicId}
          communityId={currentCommunityId}
        />
      </Form.Group>
      <div class="d-flex">
        <Button
          className="uuki-btn uuki-community-btn align-self-start"
          onClick={() => (lessonId.id == 0 ? addLesson(1) : editLesson(1))}
        >
          Save & Publish Lesson
        </Button>
        <Button
          className="uuki-btn uuki-community-btn align-self-start ms-2"
         onClick={(e)=>handleGenerateContent()}
        >
          Generate Content with AI
        </Button>
        <a
          className="ms-3 align-self-center"
          onClick={() => (lessonId.id == 0 ? addLesson(0) : editLesson(0))}
        >
          Save as Draft
        </a>
      </div>
      {lessonId.id!=0 &&
      <>	  
      <div>
        <Button
          className="uuki-btn uuki-delete-btn align-self-start mb-5"
          onClick={() => deleteLesson()}
        >
          Delete Lesson
        </Button>	  
      </div>
      </>
      }	  

      <Modal  centered  backdrop="static" show={showLoadingPopup} closeButton={false}>
          <Modal.Body className="p-5 position-relative">
          <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" role="status">
          </Spinner>
          <span className="ms-3">Generating lesson content.Please wait...</span>
          </div>
          
          </Modal.Body>
      </Modal>
    </Stack>
  );
}

export default LessonEdit;
