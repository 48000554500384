import { useState, forwardRef, useImperativeHandle,useEffect } from 'react';
import { Stack, Image } from 'react-bootstrap';
import axios from 'axios';
import { useInView, InView } from 'react-intersection-observer';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as CreateNew } from '../../assets/create-new.svg';
import AddChat from './AddChat';
import Avatar from 'react-avatar';

function ChatMessages(
	{ receiver, commId, receiverName, receiverImage, checkDelete, sendMessage, sending,replyTo,setReplyTo },
	ref
) {
	const [pageCounter, setPageCounter] = useState(1);
	const dispatch = useDispatch();
	const [hasMsgs, setHasMsgs] = useState(true);
	const [needMsg, setNeedMsg] = useState(null);
	const [msgData, setMsgData] = useState([]);
	const currentName = useSelector(
		(state) => state.communityReducer.communityName
	);
	const currentUserId = useSelector((state) => state.userReducer.userId);
	useImperativeHandle(ref, () => ({
		setNewMessage(id, msg, name, profile_picture, type,replyTo) {
			setMsgData((prev) => [
				...msgData,
				[
					{
						id: id,
						date: new Date(),
						msg: msg,
						name: name,
						profile_picture: profile_picture,
						user_id: type,
						replyTo:replyTo
					},
				],
			]);
		},
	}));
	const loadContent = () => {
		axios
			.get('/v2/get_chat', {
				params: {
					page: pageCounter,
					commId: commId,
					receiver: receiver,
				},
			})
			.then(function (response) {
				if (response.data.length < 7) {
					setHasMsgs((prev) => false);
				}
				setPageCounter((prev) => prev + 1);
				setMsgData((prev) => [response.data, ...msgData]);
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	useEffect(() => {
		if(needMsg!=null){
			scrollToMsg(needMsg)
		}else{
			document.querySelector('#messages-'+receiver).scrollBy(0, 800);
		}
	}, [msgData])
	
	const scrollToMsg = (id) => {
		let getChat = document.getElementById('chat-container-'+id)
		if(getChat!=null){
			
			getChat.scrollIntoView({ block:'center',  behavior: 'smooth' })
			var origcolor = getChat.style.backgroundColor
			getChat.style.backgroundColor = "#fff9e2";
			setTimeout(function(){
				getChat.style.backgroundColor = origcolor;
				setNeedMsg((prev)=>null)
			},(1500));
		}else{
			setNeedMsg((prev)=>id)
			loadContent()
		}
	}
	return (
		<Stack gap={3} className='h-100'>
			<Stack className='pt-3 px-4' direction='horizontal' gap={3}>
				{receiver != 0 &&
				receiverImage !== '' &&
				receiverImage !== undefined ? (
					<Image
						src={receiverImage}
						className='chat-profile-image '
						roundedCircle
						loading='lazy'></Image>
				) : (
					<Avatar
						name={receiverName}
						size='32'
						className=''
						style={{ fontSize: '0.5rem' }}
						// className="mobile-profile-image"
						round={true}
					/>
				)}
				<h6 className='m-0'>{receiverName}</h6>
			</Stack>
			<hr className='post-footer-divider m-0'></hr>
			<Stack id={'messages-' + receiver} className='message-area  px-4' gap={3}>
				{hasMsgs && (
					<InView
						as='div'
						onChange={(inView, entry) => {
							inView == true && loadContent();
						}}>
						<div className='p-4 d-flex justify-content-center' ref={ref}>
							<span class='loader-spinner' />
						</div>
					</InView>
				)}
				{msgData.length > 0 &&
					msgData.map((page) =>
						page
							.slice(0)
							.reverse()
							.map((chat) => (
								<>
									{chat.user_id == currentUserId ? (
										<div id={'chat-container-' + chat.id} className='align-self-end d-flex flex-column align-items-end w-100'>
											{Object.entries(chat.replyTo).length > 0&& <small onClick={()=>scrollToMsg(chat.replyTo.id)} style={{opacity:'0.8',cursor:'pointer'}} className="d-flex">Replied to <b className="mx-1">{chat.replyTo.name}:</b><div className="reply-div" dangerouslySetInnerHTML={{ __html: chat.replyTo.msg }}/></small>}
											<div
												onClick={(e) => checkDelete(chat.user_id, chat.id, chat.name, e)}
												className='chat-message px-4 py-2'
												id={'chat-' + chat.id}
												dangerouslySetInnerHTML={{ __html: chat.msg }}></div>
											<span className='chat-info-text'>
												{new Date(chat.date).toLocaleTimeString('en-US', {
													day: '2-digit',
													month: '2-digit',
													hour: '2-digit',
													minute: '2-digit',
												})}
											</span>
										</div>
									) : (
										<>
											{receiver == 0 ? (
												<div id={'chat-container-' + chat.id}  className="d-flex justify-content-start w-100">
													<Stack direction='horizontal' gap={2}>
														{chat.profile_picture !== '' &&
														chat.profile_picture !== undefined ? (
															<Image
																src={chat.profile_picture}
																className='chat-profile-image me-1'
																roundedCircle
																loading='lazy'></Image>
														) : (
															<Avatar
																name={chat.name}
																size='32'
																className='me-1'
																style={{ fontSize: '0.5rem' }}
																// className="mobile-profile-image"
																round={true}
															/>
														)}
														<div class='d-flex flex-column'>
															<span className='chat-info-text'>{chat.name}</span>
															{Object.entries(chat.replyTo).length > 0&& <small onClick={()=>scrollToMsg(chat.replyTo.id)} style={{opacity:'0.8',cursor:'pointer'}} className="d-flex">Replied to <b className="mx-1">{chat.replyTo.name}:</b><div className="reply-div" dangerouslySetInnerHTML={{ __html: chat.replyTo.msg }}/></small>}
															<div
																onClick={(e) =>
																	checkDelete(chat.user_id, chat.id, chat.name, e)
																}
																className='chat-message px-4 py-2'
																id={'chat-' + chat.id}
																dangerouslySetInnerHTML={{
																	__html: chat.msg,
																}}></div>
															<span className='chat-info-text'>
																{new Date(chat.date).toLocaleTimeString('en-US', {
																	day: '2-digit',
																	month: '2-digit',
																	hour: '2-digit',
																	minute: '2-digit',
																})}
															</span>
														</div>
													</Stack>
												</div>
											) : (
												<div id={'chat-container-' + chat.id}  className='d-flex align-self-start flex-column w-100'>
													{Object.entries(chat.replyTo).length > 0&& <small onClick={()=>scrollToMsg(chat.replyTo.id)} style={{opacity:'0.8',cursor:'pointer'}} className="d-flex">Replied to <b className="mx-1">{chat.replyTo.name}:</b><div className="reply-div" dangerouslySetInnerHTML={{ __html: chat.replyTo.msg }}/></small>}
													<div
														onClick={(e) =>
															checkDelete(chat.user_id, chat.id, chat.name, e)
														}
														className='chat-message px-4 py-2'
														id={'chat-' + chat.id}
														dangerouslySetInnerHTML={{
															__html: chat.msg,
														}}></div>
													<span className='chat-info-text '>
														{new Date(chat.date).toLocaleTimeString('en-US', {
															day: '2-digit',
															month: '2-digit',
															hour: '2-digit',
															minute: '2-digit',
														})}
													</span>
												</div>
											)}
										</>
									)}
								</>
							))
					)}
				{sending && <div class='d-flex align-self-end flex-column'>
					<div class="chat-message px-4 py-2 chat-bubble">
						<div class="typing">
							<div class="dot"></div>
							<div class="dot"></div>
							<div class="dot"></div>
						</div>
					</div>
				</div>}
			</Stack>
			<hr className='post-footer-divider m-0'></hr>
			<div class="mt-auto mt-auto p-3 px-4">
				{replyTo.id!=0&&
				<Stack className="reply-popup px-2" direction="horizontal">
					<small >Replying to {replyTo.name}</small>
					<small style={{cursor:'pointer'}} className="ms-auto" onClick={()=>setReplyTo({id:0,name:''})}>x</small>
				</Stack>
				}
				<AddChat  sendMessage={sendMessage} />
			</div>
		</Stack>
	);
}

export default forwardRef(ChatMessages);
