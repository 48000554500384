import {useEffect,useState} from 'react'
import {Stack,Form} from 'react-bootstrap'
import axios from "axios"
import InnerHTML from 'dangerously-set-html-content';

function QuizCompleted({quizId,memberId}) {
    const [data, setData] = useState([])
    useEffect(() => {
        axios
        .post("/v2/get_answers", {
          quizId: quizId,
          memberId:memberId
        })
        .then((res) => {
            setData(res.data.questions)
        })
        .catch(function (error) {
          console.log(" Failed");
        });
    }, [])
    return (
        <>
            {   data.length>0 &&
                data.map((question,index=1)=><>
        {question.content!="" &&
                    <div class="d-flex pt-3"><span>{(index+1)+"."}</span> 
                    {question.content!="" &&
                    <>
                      <InnerHTML html={question.content} />
                    </>
                    }
                    </div>
        }
                    {question.isFIB?
                    <Stack gap={2}>
                        <div className="px-4 post-item-poll-options-bg position-relative border rounded poll-option d-flex align-items-center" id={"correct-"+question.answers[0].correctAnswer+"-selected-true"}>
                            {question.answers[0].studentAnswer}
                            {question.answers[0].correctAnswer ? <i class="fa-solid fa-circle-check me-3 position-absolute end-0"></i> : (!question.answers[0].correctAnswer) && <i class="fa-solid fa-circle-xmark me-3 position-absolute end-0"></i>}
                            </div>
                        <div className="px-4 post-item-poll-options-bg position-relative border rounded poll-option d-flex align-items-center">
                            {question.answers[0].value}
                            <div class=" me-3 position-absolute end-0">Correct Answer</div>
                            </div>
                    </Stack>
                    :<Stack gap={2}>
                    {question.answers
                        .map((answer) => (
                            <div
                                className="post-item-poll-options-bg position-relative border rounded poll-option d-flex align-items-center"
                                id={question.multiCorrect>0&&"correct-"+answer.correctAnswer+"-selected-"+answer.selected}
                                key={answer.id}
                            >
                                {/* {item.is_option_voted ? (
                                    <Form.Check
                                    inline
                                    name="group1"
                                    type="checkbox"
                                    className="poll-option-radio ps-3 position-absolute"
                                    checked={true}
                                    onChange={(e) => handlePollOptionsChecked(item.id, e)}
                                    />
                                ) : ( */}
                                    <Form.Check
                                    inline
                                    name={"question-"+question.id}
                                    type={question.multiCorrect>=2?"checkbox":"radio"}
                                    className="poll-option-radio ps-3 position-absolute"
                                    checked = {answer.selected}
                                    />
                                {/* )} */}
                                {/* <ProgressBar
                                    now={item.percentage}
                                    className="post-item-poll-options-progress bg-transparent h-100"
                                ></ProgressBar> */}
                                <span
                                className="option-text text-truncate text-dark"
                                >
                                {answer.value}
                                </span>
                                {question.multiCorrect>0&&<>{answer.correctAnswer ? <i class="fa-solid fa-circle-check me-3 position-absolute end-0"></i> : (!answer.correctAnswer && answer.selected) && <i class="fa-solid fa-circle-xmark me-3 position-absolute end-0"></i>}</>}
                            </div>
                        ))}
                    </Stack>}
                </>)
            }
        </>
    )
}

export default QuizCompleted
