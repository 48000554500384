import { createSlice } from "@reduxjs/toolkit";

const state = () => {
  const initialState = {
    languages: null,
  };

  return initialState;
};

export const languagesSlice = createSlice({
  name: "languagesSlice",
  initialState: state,
  reducers: {
    languages: (state, action) => {
      state.languages = action.payload;
    },
  },
});

export const { languages } = languagesSlice.actions;

export default languagesSlice.reducer;
