import { Container, Row, Col, Image, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import uukiLogo from "../assets/uuki-logo.png";

import "../styles/signin.css";

function RedeemFreebie() {
  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");  

  document.documentElement.style.setProperty("--primary", "#ffffff");
  document.documentElement.style.setProperty("--secondary", "#eff1f5");
  document.documentElement.style.setProperty("--fontColor", "#2d395a");
  document.documentElement.style.setProperty(
        "--border",
        "1px solid #dfdfdf"
  );

  const signUp = (e) => {
    e.preventDefault();

    if (
      code != null &&
      code !== "" &&	  
      email != null &&
      email !== "" &&
      name != null &&
      name != ""
    ) {
      axios
        .post("/redeem_appsumo_freebie", {
          email: email,
          name: name,
          code: code,		  
        })
        .then((res) => {
			console.log(res.data)
            toast.info(res.data);
		})
        .catch(({ response }) => {
          console.log(response.data);		  
          if (
            response != null &&
            response.data != null &&
            response.data != ""
          ) {
            toast.error(response.data);
          } else {
            toast.error("something went wrong");
          }
        });
    } else {
      if (email == null || email === "") {
        toast.error("Please enter a valid email");
      }
	  
      if (code != null && code === "") {
        toast.error("Code can't be blank");
      }	  

      if (name != null && name === "") {
        toast.error("Name can't be blank");
      }
    }
  };

  
  return (
    <>
      <div
        className="d-flex justify-content-center"
        // style={{ background: "#F8F9FB" }}
      >
        <div className="form-container">
          <div
            className="d-flex flex-column  align-items-center justify-content-center"
            style={{ width: "100%" }}
          >
            <h4 className="pt-5">UUKI Community Guide</h4>
            <Image
                className="mt-4"
                fluid
                style={{ maxHeight: "7rem", maxWidth: "15rem" }}
                src={uukiLogo}
                id="brand_logo"
            />
          </div>

          <Form onSubmit={signUp} className="ms-5 me-5 ps-4 pe-4 mt-5">
            <Form.Group className="mb-3">
              <Form.Label>Enter your email</Form.Label>

              <Form.Control
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                required
                type="email"
                className="input-login-signup"
                placeholder="Enter email"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Enter your name</Form.Label>
              <Form.Control
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                id="name"
                type="text"
                placeholder="Enter name"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Enter AppSumo code</Form.Label>
              <Form.Control
                value={code}
                required
                onChange={(e) => setCode(e.target.value)}
                id="code"
                type="text"
                placeholder="Enter code"
              />
            </Form.Group>
              <div className="d-flex justify-content-center">
                <Button className="my-4  w-50 uuki-btn" type="submit">
                  Redeem
                </Button>
              </div>
          </Form>
          
        </div>
      </div>
    </>
  );
}

export default RedeemFreebie;
