import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Row, Col, Tab, Container } from "react-bootstrap";
import "../styles/settings.css";
import { useParams,useSearchParams } from "react-router-dom";
import { userRole } from "../redux/slice/userSlice";
import { communityDetails, addonDetails } from "../redux/slice/communitySlice";
import { useMediaQuery } from "react-responsive";
import {
  NavbarSettings,
  CommunitySettings,
  NotificationSettings,
  AccountSettings,
  SpaceSettings,
  SpaceArrangeSettings,
  LinkSettings,
  GamificationSettings,
  APISettings,
  WebhookSettings,
  SSOSettings,
  EmbedSettings,
  TagSettings,
  PWASettings,
  SMTPSettings,
  IntegrationSettings,
  MembershipSettings,
  SpaceEdit,
  MembershipEdit,
} from "../components/settings";
import ProfileSettings from "../components/settings/ProfileSettings";

function Settings() {
  const { communitySlug } = useParams();
  const [editSpace, setEditSpace] = useState({});
  const [editMembership, setEditMembership] = useState({});
  document.title = "Community Settings";
  const [communitySettings, setCommunitySettings] = useState({});
  const [notificationSettings, setNotificationSettings] = useState({});
  const [userMemberships, setUserMemberships] = useState({});
  const [spaceSettings, setSpaceSettings] = useState({});
  const [linkSettings, setLinkSettings] = useState({});
  const [rankSettings, setRankSettings] = useState({});
  const [apiSettings, setApiSettings] = useState({});
  const [webhookSettings, setWebhookSettings] = useState({});
  const [memberSettings, setMemberSettings] = useState({});
  const [tagSettings, setTagSettings] = useState({});
  const [membershipSettings, setMembershipSettings] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [key, setKey] = useState(null);
  const [settingsSet, setSettingsSet] = useState(false);
  const dispatch = useDispatch();
  const isBigScreen = useMediaQuery({ query: "(min-width: 1600px)" });

  const currentHost = useSelector(
    (state) => state.communityReducer.communityHost
  );
  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  let [searchParams, setSearchParams] = useSearchParams();

  const currentRole = useSelector((state) => state.userReducer.userRole);
  const setAdmin = (check) => {
      if (check == true) {
        setIsAdmin((prev) => check);
        dispatch(userRole(2));
        if(searchParams.get('openTab')!=null){
          setKey(searchParams.get('openTab'))
        }else{
          setKey("communitySettings")
        }
      }else{
        if(searchParams.get('openTab')!=null){
          setKey(searchParams.get('openTab'))
        }else{
          setKey("notifications")
        }
      }
    
  };

  const setCommunity = (getData) => {
    var newHost = "";
    if (currentHost == "/") {
      newHost = currentHost;
    } else {
      newHost = "/c/" + getData.slug;
    }
    dispatch(
      communityDetails({
        id: getData.id,
        slug: getData.slug,
        name: getData.name,
        host: newHost,
        default_load_topic: getData.default_load_topic,		
      })
    );
  };

  useEffect(() => {
    axios.get("/v2/get_community_settings?commSlug=" + communitySlug)
    .then((response)=>{
      
      currentSlug == "" && setCommunity(response.data);
      setAdmin(response.data.isAdmin);
          setCommunitySettings(response.data);
          setNotificationSettings(response.data.notifications);
          setUserMemberships(response.data.user_memberships);
          setSpaceSettings(response.data.space_data);
          setLinkSettings(response.data.links);
          setRankSettings(response.data.ranks_data);
          setApiSettings(response.data.community_api);
          setWebhookSettings(response.data.webhooks);
          setMemberSettings(response.data.members);
          setTagSettings(response.data.tags);
          setMembershipSettings(response.data.community_membership);
		  dispatch(addonDetails(response.data.enable_addon));
    }).catch((err)=>{
      console.log(err)
    })

    // let endpoints = [
    //   "/v2/get_community_settings?commSlug=" + communitySlug,
      
    // ];
    // axios
    //   .all(endpoints.map((endpoint) => axios.get(endpoint)))
    //   .then(
    //     (data) => (
    //       currentSlug == "" && setCommunity(data[0].data),
    //       setAdmin(data[0].data.isAdmin),
    //       setCommunitySettings(data[0].data),
    //       setNotificationSettings(data[1].data),
    //       setUserMemberships(data[2].data),
    //       setSpaceSettings(data[3].data),
    //       setLinkSettings(data[4].data),
    //       setRankSettings(data[5].data),
    //       setApiSettings(data[6].data),
    //       setWebhookSettings(data[7].data),
    //       setMemberSettings(data[8].data),
    //       setTagSettings(data[9].data),
    //       setMembershipSettings(data[10].data)
    //     )
    //   );
  }, []);
  useEffect(() => {
    if (Object.entries(communitySettings).length > 0) {
      if (communitySettings.theme == "light") {
        

        document.documentElement.style.setProperty("--primary", "#ffffff");
        document.documentElement.style.setProperty("--secondary", "#eff1f5");
        document.documentElement.style.setProperty("--fontColor", "#2d395a");
      } else {
        

        document.documentElement.style.setProperty("--primary", "#2B2B2B");
        document.documentElement.style.setProperty("--secondary", "#000000");
        document.documentElement.style.setProperty("--fontColor", "#ffffff");
      }
      document.documentElement.style.setProperty(
        "--accent",
        communitySettings.color
      );
      document.documentElement.style.setProperty(
        "--fontFamily",
        communitySettings.font
      );
      document.documentElement.style.setProperty(
        "--themeText",
        communitySettings.text_color
      );
      document.documentElement.style.setProperty(
        "--hoverColor",
        communitySettings.hover_color
      );
      setSettingsSet(true);
    }
  }, [communitySettings]);
  return (
    <Tab.Container
      id="settings-nav"
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
      <Row className="settings-wrapper g-0">
        <NavbarSettings
          setKey={setKey}
          communityName={communitySettings.name}
          communityLogo={communitySettings.brand_logo}
          showLogo={communitySettings.show_logo}
          member={{
            id: communitySettings.memberId,
            name: communitySettings.memberName,
            image: communitySettings.memberImage,
            isAdmin: communitySettings.isAdmin,
          }}
        />
        <Col
          className="mt-5 py-4"
          md={isBigScreen ? { span: 7, offset: 4 } : { span: 7, offset: 3 }}
        >
          <Tab.Content className="settings-content center-container-dashboard uuki-box p-3 p-md-4 my-md-2">
            {settingsSet ? (
              <>
                <Tab.Pane eventKey="notifications">
                  <NotificationSettings settings={notificationSettings} />
                </Tab.Pane>
                <Tab.Pane eventKey="account">
                  <AccountSettings
                    settings={communitySettings}
                    setCommunitySettings={setCommunitySettings}
                    memberships={userMemberships}
                  />
                </Tab.Pane>
                {isAdmin && (	
                  <>				
                    <Tab.Pane eventKey="profile">
                      <ProfileSettings> </ProfileSettings>
                    </Tab.Pane>
                    <Tab.Pane eventKey="communitySettings">
                      <CommunitySettings
                        setCommunitySettings={setCommunitySettings}
                        settings={communitySettings}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="spaceSettings">
                      <SpaceSettings
                        setKey={setKey}
                        setSpace={setEditSpace}
                        settings={spaceSettings}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="spaceArrange">
                      <SpaceArrangeSettings settings={spaceSettings} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="links">
                      <LinkSettings
                        setLinkSettings={setLinkSettings}
                        settings={linkSettings}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="gamification">
                      <GamificationSettings
                        setRankSettings={setRankSettings}
                        settings={rankSettings}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="api">
                      <APISettings
                        setApiSettings={setApiSettings}
                        settings={apiSettings}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="webhooks">
                      <WebhookSettings
                        setWebhookSettings={setWebhookSettings}
                        settings={webhookSettings}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="sso">
                      <SSOSettings
                        setApiSettings={setApiSettings}
                        settings={apiSettings}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="embeds">
                      <EmbedSettings settings={spaceSettings} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="tags">
                      <TagSettings
                        settings={tagSettings}
                        members={memberSettings}
                        communitySlug={communitySlug}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="pwa">
                      <PWASettings
                        settings={{
                          icon: communitySettings.pwaIcon,
                          name: communitySettings.pwaName,
                        }}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="smtp">
                      <SMTPSettings
                        settings={{
                          email: communitySettings.mail,
                          username: communitySettings.mailUser,
                          password: communitySettings.mailPass,
                          enabled: communitySettings.mailEnabled,
                          host: communitySettings.mailHost,
                          port: communitySettings.mailPort,
                        }}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="integrations">
                      <IntegrationSettings
                        settings={{
                          discordHook: communitySettings.discordHook,
                          telegramId: communitySettings.telegramId,
                          twitterToken: communitySettings.twitterToken,
                          zoomToken: communitySettings.zoomToken,
                        }}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="memberships">
                      <MembershipSettings
                        membershipUrl={communitySettings.membershipUrl}
                        settings={membershipSettings}
                        setKey={setKey}
                        setMembership={setEditMembership}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="spaceEdit">
                      <SpaceEdit
                        setKey={setKey}
                        editSpace={editSpace}
                        setSpace={setEditSpace}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="membershipEdit">
                      <MembershipEdit
                        setKey={setKey}
                        editMembership={editMembership}
                        setEditMembership={setEditMembership}
                        spaces={spaceSettings}
                        members={memberSettings}
                      />
                    </Tab.Pane>
                  </>
                )}
              </>
            ) : (
              <div className="p-4 d-flex justify-content-center">
                {/* <Spinner animation="border" /> */}
                <span class="loader-spinner" />
              </div>
            )}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}

export default Settings;
