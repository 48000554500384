import { Button, Stack, Form, Image } from "react-bootstrap";
import logoImage from '../../assets/uuki-logo.png'
import { useDebouncedCallback } from 'use-debounce';
import axios from 'axios'
import {toast} from 'react-toastify'
import {useState,useEffect} from 'react'
import {useSelector} from 'react-redux'

function PWASettings({settings}) {
    const [settingsSet, setSettingsSet] = useState(false);
    const [appName, setAppName] = useState('');
    const [appIcon, setAppIcon] = useState('');
    const enableAddon = useSelector((state) => state.communityReducer.enable_addon);
    const currentId = useSelector(
        (state) => state.communityReducer.communityId
    )
    useEffect(() => {
        if(Object.entries(settings).length > 0) {
            setSettingsSet((prev)=>true);
            setAppName(settings.name)
            setAppIcon(settings.icon)
        }
    },[settings])

    const changeName = useDebouncedCallback(
        (value) => {
            axios.post('/change_app_name',{id: currentId,app_name:value})
                .then((res) => {
                    toast.success('PWA Name Updated')
                
                }).catch(function (error) {
                    toast.error('Update Failed')
                });
        },
        1000
    );
    const uploadImage = (file) => {
        axios.get("/get_community_pwa_icon_url",{params:{id:currentId,filename:file.name}})
        .then((res) => {
            res = res.data;
            let url = res.url;
            let fields = res.fields;
            let formData = new FormData();
            for(let key in fields) {
              formData.append(key, fields[key]);
            }
            formData.append('file', file);
            const xhr = new XMLHttpRequest();
            xhr.addEventListener('loadend', uploadComplete, false);
            function uploadComplete(event) {
                let result = "https://communitydata.uuki.live/"+fields['key']
                changePWAIcon(result);
    
            }
            xhr.open("POST", url, true);
            xhr.send(formData);
            toast.success('Image Uploaded')
        
        }).catch(function (error) {
            toast.error('Image Upload Failed')
        });
    }
    const changePWAIcon = (value) => {
        axios.post('/add_community_pwa_icon',{id: currentId,url: value})
            .then((res) => {
                setAppIcon(value)
                toast.success('Community PWA Icon Updated')
            
            }).catch(function (error) {
                toast.error('Update Failed')
            });
        
    }
	const upgradeAddon = () => {
        window.open("https://payments.pabbly.com/subscribe/6219fb0318cd581b431c10bc/premium-addon", '_blank');		
	}	
    return (
        <>
		{enableAddon == 1 ? (
            <Stack gap={4}>
                <Form.Group controlId="name">
                    <Form.Label>App Name</Form.Label>
                    <Form.Control value={appName} onChange={(event)=>{setAppName(event.target.value);changeName(event.target.value)}}/>
                </Form.Group>
            
                <Form.Group controlId="coverImage">
                    <Form.Label>App Icon</Form.Label>
                    <Stack gap={3} direction="horizontal">
                        {appIcon!="" &&
                        <>						
                        <Image className="uuki-logo" src={appIcon} loading="lazy"/>
                        </>
                        }						
                         <label for="file-upload-icon" class="custom-file-upload">
                            <div className="uuki-btn uuki-community-btn text-center">Upload</div>
                        </label>
                        <input id="file-upload-icon" className="d-none" type="file" onChange={(e)=>uploadImage(e.target.files[0])}/> 
                    </Stack>
                </Form.Group>
            </Stack>
			):(
			<Button className="uuki-btn uuki-community-btn align-self-start" onClick={upgradeAddon}>Upgrade</Button>
			)
		}
        </>
    )
}

export default PWASettings
