import React from "react";
import { Button, Container, Image, ProgressBar, Stack } from "react-bootstrap";
import medalImage from "../../../assets/medal.svg";
import { useSelector } from "react-redux";

export default function LeaderboardBlock({ karmaData, setKey }) {
  const currentLanguages = useSelector(
    (state) => state.languagesReducer.languages
  );
  const currentCommunityLanguage = useSelector(
    (state) => state.communityReducer.communityLanguage
  );
  return (
    <>
      <Stack gap={3} className="right-nav-block uuki-box p-3 pt-0">
        <Stack direction="horizontal" gap={2}>
          <Image src={medalImage} laoding="lazy" />
          <span className="pt-3 right-block-title">
            {karmaData != null ? karmaData.total_karma : 0}{" "}
            {currentLanguages[7][currentCommunityLanguage]}
          </span>
          <span className="ms-auto pt-3">
            Level {karmaData != null ? karmaData.level : 0}
          </span>
        </Stack>

        <ProgressBar
          style={{ borderRadius: "1rem", height: "0.6rem" }}
          now={karmaData.percentage}
          className="ps-0"
        />

        <Stack gap={2} direction="horizontal">
          <div className="leaderboard-rank">
            {karmaData != null ? karmaData.current_rank : 0}
          </div>
          <span className="ms-auto">
            {currentLanguages[8][currentCommunityLanguage]}{" "}
            {karmaData != null ? karmaData.next_rank : ""}
          </span>
        </Stack>

        {/* <a href="" className="text-decoration-none" style={{fontFamily:'MetropolisSemiBold'}}>View leaderboard</a> */}
        <Button
          className="view-leaderboard-btn p-0 text-decoration-none"
          onClick={() => setKey("leaderboard")}
        >
          {currentLanguages[9][currentCommunityLanguage]}
        </Button>
      </Stack>
    </>
  );
}
