import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Tab, Nav, Stack, Image, Row, Col, Card } from "react-bootstrap";
import placeholderImage from '../../../assets/courses.jpg'

function Courses({isAdmin}) {
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [key, setKey] = useState("all");
  const [hasEnrolled, setHasEnrolled] = useState(false);
  const navigate = useNavigate();

  const currentSlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );

  const communityId = useSelector(
    (state) => state.communityReducer.communityId
  );
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  useEffect(() => {
    if (inView == true) {
      axios
        .post("/v2/get_courses", {
          commId: communityId,
        })
        .then((res) => {
          if (res.data.find((course) => course.joined == true)) {
            setKey("my");
            setHasEnrolled(true);
          }
          setData(res.data);
          setLoaded(true);
        })
        .catch(function (error) {
          console.log("Course Not Found");
        });
    }
  }, [inView]);
  return (
    <div class="d-flex justify-content-center">
      <Stack gap={3} className=" center-container">
        {loaded == true ? (
          <Tab.Container
            id="course-dashboard"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            {key != "edit" && (
              <Nav
                variant="pills"
                className="text-nowrap mb-3 uuki-box  course-block p-3"
              >
                <Stack direction="horizontal" gap={2}>
                  {hasEnrolled && (
                    <Nav.Item className="dashboard-link">
                      <Nav.Link
                        className="d-flex align-items-center"
                        eventKey="my"
                        style={{ color: "var(--fontColor)" }}
                      >
                        My Courses
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item className="dashboard-link">
                    <Nav.Link
                      className="d-flex align-items-center"
                      eventKey="all"
                      style={{ color: "var(--fontColor)" }}
                    >
                      All Courses
                    </Nav.Link>
                  </Nav.Item>
                </Stack>
              </Nav>
            )}
            <Tab.Content>
              <Tab.Pane className="\" eventKey="my">
                <Row>
                  {data
                    .filter((course) => course.joined == true)
                    .map((course) => (
                      (course.hidden==0 || isAdmin) &&
                      <Col
                        md={6}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/c/" + currentSlug + "/" + course.slug)
                        }
                      >
                        <Card>
                          {course.cover!="" ? 
                          <Card.Img
                            variant="top"
                            style={{ maxHeight: "8rem",objectFit:'cover'}}
                            src={course.cover}
                          /> :
                          <Card.Img
                            variant="top"
                            style={{ maxHeight: "8rem",objectFit:'cover'}}
                            src={placeholderImage}
                          />
                          }
                          <Card.Body className="text-dark">
                            <Card.Title>
                              <h6>{course.title}</h6>
                            </Card.Title>
                            <small>{course.lessons} Lessons</small>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </Tab.Pane>
              <Tab.Pane className="\" eventKey="all">
                <Row>
                  {data.map((course) => (
                    (course.hidden==0 || isAdmin) &&
                    <Col
                      md={6}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/c/" + currentSlug + "/" + course.slug)
                      }
                    >
                      <Card>
                      {course.cover!="" ? 
                          <Card.Img
                            variant="top"
                            style={{ maxHeight: "8rem",objectFit:'cover'}}
                            src={course.cover}
                          /> :
                          <Card.Img
                            variant="top"
                            style={{ maxHeight: "8rem",objectFit:'cover'}}
                            src={placeholderImage}
                          />
                          }
                        <Card.Body className="text-dark">
                          <Card.Title>
                            <h6>{course.title}</h6>
                          </Card.Title>
                          <small>{course.lessons} Lessons</small>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        ) : (
          <div className="p-4 d-flex justify-content-center" ref={ref}>
            {/* <Spinner animation="border" /> */}
            <span class="loader-spinner" />
          </div>
        )}
      </Stack>
    </div>
  );
}

export default Courses;
