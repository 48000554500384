import axios from 'axios'
import {useEffect,useState} from 'react'
import { Button, Image,Stack } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import {useDispatch,useSelector} from 'react-redux'
import {
  showLoginModal,
  showSignupModal,
} from "../redux/slice/authenticationSlice";
import LoginModal from "../components/authentication/LoginModal";
import { topicDetails, communityDetails, deviceDetails } from "../redux/slice/communitySlice";

function JoinCommunity() {
    const { joinUrl } = useParams();
    const [data, setData] = useState(null)
    const [dataSet, setDataSet] = useState(false)
    const isLoggedIn = useSelector((state) => state.userReducer.isUserLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const isToShowLoginModal = useSelector(
      (state) => state.authenticationReducer.showLoginModal
    );
    const isToShowSignupModal = useSelector(
      (state) => state.authenticationReducer.showSignupModal
    );
    const currentUserId = useSelector((state) => state.userReducer.userId);

    const applyStyles = (data) => {	
        if (data.theme == "light") {
          document.documentElement.style.setProperty("--primary", "#ffffff");
          document.documentElement.style.setProperty("--secondary", "#eff1f5");
          document.documentElement.style.setProperty("--fontColor", "#2d395a");
          document.documentElement.style.setProperty(
            "--border",
            "1px solid #dfdfdf"
          );
        } else {
          document.documentElement.style.setProperty("--primary", "#2B2B2B");
          document.documentElement.style.setProperty("--secondary", "#000000");
          document.documentElement.style.setProperty("--fontColor", "#ffffff");
          document.documentElement.style.setProperty(
            "--border",
            "1px solid #030303"
          );
        }
    }
    useEffect(() => {
        axios
        .get("/v2/get_join_details?joinUrl="+joinUrl)
        .then(function (response) {
          setData(response.data) 
		  if(response.data.isMember) {
              window.location.href = "/c/"+response.data.slug;
		  }
          setDataSet((prev)=>true)
		  applyStyles(response.data);
		  dispatch(
            communityDetails({
              theme: response.data.theme,	
              privacyPolicy: response.data.privacy_policy,
              tos: response.data.tos,			  
            })
		  );
        })
        .catch(function (error) {
          console.log(error);
        });
    }, [])

    const acceptJoin = () => {
      axios
      .get("/request_join", {
        params: {
          id: currentUserId,
          commId: data.id,
        },
      })
      .then((response) => {		  
        data.isRequested = true
        window.location.reload();		
      })
      .catch((response) => {
        if (response != null && response.status == 302 || response.status == 301) {
		  window.location.href = response;
		} else {
		  console.log("something went wrong");
		}		
      });
      // axios
      // .get("/accept_join", {
      //   params: {
      //     commId: data.id,
      //   },
      // })
      // .then((response) => {
      //     navigate('/c/'+data.slug)
        
      // })
      // .catch((err) => {
      //   console.error(err);
      // });
    }

    const cancelJoin = () => {
      axios
        .get("/cancel_join", {
          params: {
            id: currentUserId,
            commId: data.id,
          },
        })
        .then((response) => {
          data.isRequested = false
          window.location.reload();		  
        })
        .catch((err) => {
          console.error(err);
        });
    };
    return (
        <div className="d-flex justify-content-center main-row vh-100">
           {dataSet && 
              <div style={{minWidth:'40rem'}} className="center-container join-block uuki-box my-4">
                  {data.logo!="" && <Image fluid src={data.logo}/>}
                  <Stack gap={4} className="p-4 text-center">
                    <div class="d-flex flex-column">
                      <span >You are invited to join</span>
                      <h4><b>{data.name}</b></h4>
                    </div>
                    {/*
                    <hr className='post-footer-divider m-0'></hr>

                    <Stack className="justify-content-center" gap={4} direction="horizontal">
                      <div className="d-flex flex-column">
                        <small>Members</small>
                        <h5>{data.members}</h5>
                      </div>
                      <div className="d-flex flex-column">
                        <small>Posts</small>
                        <h5>{data.posts}</h5>
                      </div>
                      <div className="d-flex flex-column">
                        <small>Spaces</small>
                        <h5>{data.topics}</h5>
                      </div>

                    </Stack>
                    */}

                    <hr className='post-footer-divider m-0'></hr>

                    {
                      !isLoggedIn ? (
                        <>
                          <Button 
                            style={{ maxWidth: '10rem' }} 
                            className="uuki-btn uuki-community-btn align-self-center" 
                            onClick={() => dispatch(showLoginModal(true))}
                            >
                            Login
                          </Button>
                          <Button 
                            style={{ maxWidth: '10rem' }} 
                            className="uuki-btn uuki-community-btn align-self-center" 
                            onClick={() => dispatch(showSignupModal(true))} // Assuming you have a similar action for signup
                            >
                            Signup
                          </Button>
                        </>
                      ) : (
                        <Button 
                          style={{ maxWidth: '10rem' }} 
                          className="uuki-btn uuki-community-btn align-self-center" 
                          onClick={() => data.isRequested ? cancelJoin() : acceptJoin()}
                        >
                          {data.isRequested ? "Cancel Request" : "Request Join"}
                        </Button>
                      )
                    }
                  </Stack>
              </div>
            }
        <LoginModal
        show={isToShowLoginModal || isToShowSignupModal}
        onHide={() => {
          dispatch(showLoginModal(false));
          dispatch(showSignupModal(false));
        }}
        />
        </div>
    )
}

export default JoinCommunity
