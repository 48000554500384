import axios from "axios";
import { useEffect, useState } from "react";
import {
  Button,
  Image,
  Stack,
  Accordion,
  ListGroup,
  Nav,
  ProgressBar,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

function CourseOverview({courseData}) {
  const navigate = useNavigate();
  const currentTopicId = useSelector((state) => state.communityReducer.topicId);
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const communitySlug = useSelector(
    (state) => state.communityReducer.communitySlug
  );
  const communityId = useSelector(
    (state) => state.communityReducer.communityId
  );
  const topicSlug = useSelector((state) => state.communityReducer.topicSlug);
  const enableBeta = useSelector((state) => state.userReducer.enableBeta);

  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  useEffect(() => {
    setData(courseData);
    setLoaded(true);  
  }, [courseData]);
  
  const openTutororReferral = () => {
	  if(enableBeta == 0) {
	      window.open("https://upvir.al/145253/lp145253", '_blank');
	  } else {
		  window.open("/course/" + communitySlug + "/" + topicSlug + "/aitutor", '_blank'); 
	  }
  }
  
  return (
    <div class="d-flex justify-content-center">
      <Stack gap={4} className=" uuki-box  course-block center-container">
        {loaded == true ? (
          <>
            {data.cover != "" ? (
              <Image style={{ maxHeight: "15rem" }} fluid src={data.cover} />
            ) : 
            <div></div>}
            <Stack className="px-4" direction="horizontal">
              <h6 className="m-0">{data.title}</h6>
              <small className="ms-auto">{data.lessonCount} Lessons</small>
            </Stack>
            <Stack gap={5} className="px-4" direction="horizontal">
              <div className="right-nav-block  uuki-box px-4 py-3 w-100 mb-3">
                <small>Completed {Math.round(data.completePercent)}%</small>
                <ProgressBar
                  style={{ height: "0.2rem" }}
                  className="mt-3"
                  now={data.completePercent}
                />
              </div>
              <Stack gap={3}>
                {data.nextLesson != 0 && (
                  <Button
                    className="uuki-btn uuki-community-btn align-self-start"
                    onClick={() =>
                      navigate(
                        "/course/" +
                          communitySlug +
                          "/" +
                          topicSlug +
                          "?lessonId=" +
                          data.nextLesson
                      )
                    }
                  >
                    Continue
                  </Button>
                )}
                <small className="text-center">
                  {data.studentCount} Students Enrolled
                </small>
              </Stack>
            </Stack>
            <hr className="post-footer-divider m-0"></hr>

            <Stack className=" px-4 pb-4">
              <div className="d-flex">
              <span className="pb-3">Course Curriculum</span>
              {courseData.aiTutorEnabled==true? <Button className="uuki-btn uuki-community-btn ms-auto mb-3"  onClick={() =>
                      openTutororReferral()
                    }>Train with AI</Button>:""}
              </div>
              
         
              {data.sections.filter((sec)=>sec.available==true).map((section) => (
                <Accordion
                  classname="rounded"
                  key={section.id}
                  flush
                  alwaysOpen
                >
                  <Accordion.Item>
                    <Accordion.Header className="section-header">
                      <Stack className="w-100 text-dark wrapword" direction="horizontal">
                        {section.title}
                      </Stack>
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                      {section.lessons.map((lesson) => (
                        <Nav.Item className=" ">
                          <Nav.Link
                            style={{ fontSize: "1rem" }}
                            className="py-4 px-4 space-name text-dark d-flex align-items-center border rounded-0"
                            eventKey={lesson.id}
                            onClick={() =>
                              navigate(
                                "/course/" +
                                  communitySlug +
                                  "/" +
                                  topicSlug +
                                  "?lessonId=" +
                                  lesson.id
                              )
                            }
                          >
                            {lesson.completed == true ? (
                              <i
                                className="fa-solid  fa-lg me-2 fa-circle-check"
                                style={{ color: "green" }}
                              ></i>
                            ) : (
                              <i className="fa-regular fa-lg me-2 fa-circle"></i>
                            )}
                            <small className="wrapword">{lesson.title}</small>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                      {section.quizzes.map((quiz) => (
                        <Nav.Item className=" ">
                          <Nav.Link
                            style={{ fontSize: "1rem" }}
                            className="py-4 px-4 space-name text-dark d-flex align-items-center border rounded-0"
                            eventKey={quiz.id}
                            onClick={() =>
                              navigate(
                                "/course/" +
                                  communitySlug +
                                  "/" +
                                  topicSlug +
                                  "?quizId=" +
                                  quiz.id
                              )
                            }
                          >
                            {quiz.submitted == true ? (
                              <i
                                className="fa-solid  fa-lg me-2 fa-circle-check"
                                style={{ color: "green" }}
                              ></i>
                            ) : (
                              <i className="fa-regular fa-lg me-2 fa-circle"></i>
                            )}
                            <small className="wrapword">{quiz.title}</small>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))}
            </Stack>
          </>
        ) : (
          <div className="p-4 d-flex justify-content-center" ref={ref}>
            {/* <Spinner animation="border" /> */}
            <span class="loader-spinner" />
          </div>
        )}
        <div className="p-4"></div>
      </Stack>
    </div>
  );
}

export default CourseOverview;
